import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchSettings = createAsyncThunk(
  'settings/fetchSettings',
  async (_, { rejectWithValue }) => {
    try {
      const userSub = localStorage.getItem('userSub');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/system-settings`);
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const settings = await response.json();
      return settings;
    } catch (error) {
      console.error('Error getting settings:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateSettings = createAsyncThunk(
  'settings/updateSettings',
  async (settings, { rejectWithValue }) => {
    try {
      const userSub = localStorage.getItem('userSub');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/admin/settings/${userSub}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({...settings}),
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const new_settings = await response.json();
      return new_settings;
    } catch (error) {
      console.error('Error updating settings:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    isFetched: false,
    isCheckedForFilter: false, // check if there is a filter name in local storage
    mode: '', // check for it
    notified: false,
    systemSettings: {}
  },
  reducers: {
    setIsFetched: (state, message) => {
      state.isFetched = message.payload;
    },
    setIsCheckedForFilter: (state, message) => {
      state.isCheckedForFilter = message.payload;
    },
    setMode: (state, message) => {
      state.mode = message.payload;
    },
    setNotified: (state, message) => {
      state.notified = message.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.systemSettings = action.payload || {};
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        state.systemSettings = action.payload || {};
      })
  }
});

export const { 
  setIsFetched,
  setIsCheckedForFilter,
  setMode,
  setNotified
} = settingsSlice.actions;

export default settingsSlice.reducer;