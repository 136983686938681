import style from '../style/MainAdminStyles.module.scss';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateSettings } from './../../../features/settings/settingsSlice';

export default function Settings(props) {
    const dispatch = useDispatch();
    const systemSettings = useSelector(state => state.settings.systemSettings);

    const [prematchHistoryLen, setPrematchHistoryLen] = useState(systemSettings?.prematch_history_len || 0);
    const [liveHistoryLen, setLiveHistoryLen] = useState(systemSettings?.live_history_len || 0);
    const [numComparisons, setNumComparisons] = useState(systemSettings?.num_comparisons || 0);
    const [premiumFilterLimit, setPremiumFilterLimit] = useState(systemSettings?.premium_filter_limit || 0);
    const [basicFilterLimit, setBasicFilterLimit] = useState(systemSettings?.basic_filter_limit || 0);
    const [minEV, setMinEV] = useState(systemSettings?.min_ev || 0);

    function handleChange(value, min, max, setValue) {
        const numValue = Number(value);
    
        if (numValue >= min && numValue <= max) {
            setValue(numValue);
        }
    }

    function handleUpdateSettings() {
        dispatch(updateSettings({
            prematch_history_len: prematchHistoryLen,
            live_history_len: liveHistoryLen,
            num_comparisons: numComparisons,
            premium_filter_limit: premiumFilterLimit,
            basic_filter_limit: basicFilterLimit,
            min_ev: minEV
        }));
    }

    return(
        <>
            <div className={style.inputs_wrapper}>
                <div className={style.header}>
                    Odds History
                </div>
                <div className={style.item}>
                    <label>Old Odds Shown</label>
                    <div className={style.input_container}>
                        <input 
                            type="number" 
                            id='min' 
                            min="0"
                            max="10"
                            value={prematchHistoryLen}
                            onChange={(e) => handleChange(e.target.value, 0, 10, setPrematchHistoryLen)}
                            //placeholder='3' 
                        />
                        <span>Prematch</span>
                    </div>
                    <div className={style.input_container}>
                        <input 
                            type="number" 
                            id='max' 
                            min="0"
                            max="10"
                            value={liveHistoryLen}
                            onChange={(e) => handleChange(e.target.value, 0, 10, setLiveHistoryLen)}
                            //placeholder='20' 
                        />
                        <span>Live</span>
                    </div>
                </div>
                
                <div className={style.item}>
                    <label>Sportsbook Comparison</label>
                    <div className={style.input_container}>
                        <input 
                            type="number" 
                            id='min' 
                            min="0"
                            max="50"
                            value={numComparisons}
                            onChange={(e) => handleChange(e.target.value, 0, 50, setNumComparisons)}
                            //placeholder='3' 
                        />
                        <span>Premium Only</span>
                    </div>
                </div>
                <div className={style.header}>
                    Filter Settings
                </div>
                <div className={style.item}>
                    <label>Saved Filters Limit</label>
                    <div className={style.input_container}>
                        <input 
                            type="number" 
                            id='min' 
                            min="0"
                            max="50"
                            value={premiumFilterLimit}
                            onChange={(e) => handleChange(e.target.value, 0, 50, setPremiumFilterLimit)}
                            //placeholder='3' 
                        />
                        <span>Premium</span>
                    </div>
                    <div className={style.input_container}>
                        <input 
                            type="number" 
                            id='max' 
                            min="0"
                            max="50"
                            value={basicFilterLimit}
                            onChange={(e) => handleChange(e.target.value, 0, 50, setBasicFilterLimit)}
                            //placeholder='20' 
                        />
                        <span>Basic</span>
                    </div>
                </div>
                <div className={style.header}>
                    EV+
                </div>
                <div className={style.item}>
                    <label>Profit Percentage</label>
                    <div className={style.input_container}>
                        <input 
                            type="number" 
                            id='min' 
                            min="0"
                            max="50"
                            value={minEV}
                            onChange={(e) => handleChange(e.target.value, 0, 50, setMinEV)}
                            //placeholder='3' 
                        />
                        <span>min</span>
                    </div>
                </div>
                
            </div>
            <div className={style.settings_wrapper}>
                <button className={style.settings_button} onClick={handleUpdateSettings}>
                    Update Settings
                </button>
            </div>
        </>
    )
}