import { Link } from 'react-router-dom';
import style from './style/FreePageStyles.module.scss';

export default function FreePage(params) {
    return(
        <div className={style.freepage}>
            <div className={style.freepage_wrapper}>
                <span>
                    Your FREE subscription does not allow you to view this functionality. <br/><br/>
                    <Link to={'/account/subscriptions'} className={style.link_freepage}>Upgrade</Link> your plan in order to see all bets and access full functionality.
                </span>
                <Link className={style.link_btn} to={'/account/subscriptions'}>
                    Upgrade Plan
                </Link>
            </div>
        </div>
    )
}