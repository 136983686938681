import { Link } from 'react-router-dom';
import style from './style/HowToPageStyles.module.scss';

export default function HowToPagePage(params) {
    return(
        <div className={style.howtopage}>
            <div className={style.howtopage_wrapper}>
                <h1>SureBetFusion Ultimate How To Guide for Sports Betting</h1>
                <p>
                    <a href="https://surebetfusion.com">https://surebetfusion.com</a>
                </p>
                <p>
                    <a href="https://app.surebetfusion.com">https://app.surebetfusion.com</a>
                </p>
                

                <p>
                Welcome to SurebetFusion where precision and technology work for you to provide you with the best opportunities on the planet for arbitrage betting and expected value betting.
                </p>
                <b>First a bit about arbitrage sports betting:</b>
                <p>
                Arbitrage sports betting, also known as "arbing," is a strategy where bettors simultaneously place multiple bets on all possible outcomes of an event across different bookmakers. This method exploits the variations in odds to ensure a risk-free profit, regardless of the event's outcome.
                </p>

                <h2>Types of Arbitrage Bets</h2>
                <p>2-Way Arbitrage (Binary Bets):</p>
                <ul>
                    <li>Overview: Common in sports with two outcomes, like tennis or basketball (considering no draws).</li>
                    <li>
                    Detailed Example: In a tennis match between Player A and Player B, two different bookmakers may offer contrasting odds due to their independent risk assessments. Suppose Bookmaker X offers 2.10 odds on Player A, and Bookmaker Y offers 2.15 on Player B. By calculating stakes using an arbitrage calculator, you can distribute your total bet in such a way that you earn the same profit, no matter who wins.
                    </li>
                    <li>
                    Key Benefit: Reduced risk as you're covering both possible outcomes.
                    </li>
                </ul>
                <p>3-Way Arbitrage (Triangular Bets):</p>
                <ul>
                    <li>Overview: This type is prevalent in sports like football or hockey, where there are three possible outcomes: win, lose, or draw.</li>
                    <li>
                    Detailed Example: Imagine a football match where Bookmaker A gives 3.50 odds for Team 1 to win, Bookmaker B gives 3.60 for a draw, and Bookmaker C gives 2.40 for Team 2 to win. By placing calculated bets on all three outcomes with the respective bookmakers, a guaranteed profit is ensured, regardless of the match result.
                    </li>
                    <li>
                    Key Benefit: Exploits discrepancies in bookmakers' odds for three possible outcomes.
                    </li>
                </ul>
                <p>Middles Bets:</p>
                <ul>
                    <li>Overview: These bets target the "middle" ground between two overlapping bets.</li>
                    <li>
                    Detailed Example: In an NBA game, you might bet on Team A with a -10.5 point spread at one bookmaker and on Team B with a +12.5 point spread at another. If Team A wins by 11 or 12 points, both your bets win, creating a 'middle'.
                    </li>
                    <li>
                    Key Benefit: Offers a chance to win both bets, with a small risk of losing one.
                    </li>
                </ul>
                <p>Corner Bets:</p>
                <ul>
                    <li>Overview: Unique to soccer, focusing on the total number of corners in a match.</li>
                    <li>
                    Detailed Example: One bookmaker offers odds of 1.90 for over 10 corners, while another offers 2.00 for under 11 corners. By carefully placing bets, you cover the possibility of exactly 10 or 11 corners, maximizing chances of a profit.
                    </li>
                    <li>
                    Key Benefit: Targets a specific and often overlooked aspect of the game, opening unique arbitrage opportunities.
                    </li>
                </ul>
                <p>Corner Bets:</p>
                <ul>
                    <li>Overview: Unique to soccer, focusing on the total number of corners in a match.</li>
                    <li>
                    Detailed Example: One bookmaker offers odds of 1.90 for over 10 corners, while another offers 2.00 for under 11 corners. By carefully placing bets, you cover the possibility of exactly 10 or 11 corners, maximizing chances of a profit.
                    </li>
                    <li>
                    Key Benefit: Targets a specific and often overlooked aspect of the game, opening unique arbitrage opportunities.
                    </li>
                </ul>
                <p>Whole Bets:</p>
                <ul>
                    <li>Overview: A combination of various bet types to cover a wide range of outcomes within an event.</li>
                    <li>
                    Detailed Example: In a football match, you might place a 2-way bet on the match result, a middle bet on the point spread, and a corners bet on the total number of corners. This not only diversifies the risk but also increases the chances of profiting from different scenarios within the same event.

                    </li>
                    <li>
                    Key Benefit: Diversification across different bet types for a broader safety net.
                    </li>
                </ul>
                <p>
                These are types of arbitrage bets above that are fully included on SurebetFusion.com not only for prematch but also for live opportunities.  SurebetFusion.com is one of the only platforms available that does live arbitrage events as well.  This is very important as the most money you can make in arbitrage betting is with live.  There is one main reason for this and this is that sports books vary incredibly when games are live and this creates incredible opportunities for you.  This also comes with a draw back that these “opportunities” dont stick around for long so its very important to act quick on them.  You will usually have a few minutes at least which will offer you plenty of time especially when your used to placing bets over an over. One of Surebetfusions unique abilities is to click on the “Bet Now” button which can be seen for many sports books we have (we will continue to add in all sports books for this ability) that not only takes you directly to the bet with the book maker itself but also populates the betslip for the fastest bet placements possible.
                </p>


                <b>Amount of money to start with</b>
                <p>
                We would recommend starting with at least 1000 dollars minimum.  This is also because you need to spread this amount across many sports books.  Now depending on where you are located will depend on the sports books being offered in your locale.  More and more sports books are becoming available however they are not all equal.
                </p>
                <b>Best Sports Books to Use for Arbitrage</b>
                <ul>
                    <li>Pinnacle</li>
                    <li>Bet365</li>
                    <li>3et</li>
                    <li>Bwin</li>
                    <li>Unibet</li>
                    <li>William Hill</li>
                    <li>Draftkings</li>
                    <li>Fanduel</li>
                    <li>BetMGM</li>
                </ul>
                <p>
                You will notice SurebetFusion.com has a tremendous amount of sports books available to you for you to leverage with real time data flows allowing you to take advantage of the most opportunities as soon as they are seen.
                </p>

                <b>SurebetFusion.com Technology</b>
                <p>
                We are built by MIT grads, PhD mathematicians, infrastructure engineers, and a group of professional sports betting professionals that use the platform themselves to bring in 30+% profits on a consistent basis.  SurebetFusion offers prematch and live for both arbitrage (Surebet) and Expected Value enhanced betting.  We are priced at less than half of the online competitors (and 1/10th the price of some of them) and look forward to sharing with you the same platform we use for our successful betting.  One of the main reasons that SurebetFusion.com came to be was due to the lack of a professional platform for arbitrage betting.  We created the platform from scratch for Arb bettors by Arb bettors.  We assure you that you will not find anything that compares to this platform.
                </p>
                <b>How to use Surebet for opportunities</b>
                <p>
                Once you are logged in to the system you will notice at the top of the screen three main options. 
                </p>

                <div className={style.img_container}>
                    <img src={require('./img/howto/1.png')} style={{minWidth: '100px'}} alt="" />
                </div>

                <b>SUREBET</b> = all arbitrage opportunities are here for Pre Match and Live opportunities.<br></br>
                <b>EV+</b> = all Expected Value opportunities can be found here for both Pre Match and Live.<br></br>
                <b>MYBETS</b> = here you can track your bets and keep track of your bankroll and where your profits are coming from.<br></br>

                <br></br>
                <b>We learned about Arbitrage betting now what about Expected Value?</b>
                <p>
                Expected Value betting is something to be combined with your arbitrage betting strategy which we like to “sprinkle” into our bets.  Before we explain Expected Value betting we would like to mention that if everyone only did expected value betting it would put all the sports books out of business because it is a mathematical certainty that when placed over and over you will continue on increasing your bankroll due to the difference between the odds and what “fair value” is.
                </p>

                <h2>Understanding Expected Value Betting in Sports</h2>
                <p>
                Expected Value (EV) betting is a fundamental concept in sports betting and gambling, offering a mathematical approach to evaluate the profitability of bets over the long term. EV indicates the average amount a bettor can expect to win or lose per bet if the same bet is placed under the same conditions repeatedly.
                </p>
                <h3>
                Exploring the Concept of Expected Value
                </h3>
                Basics of Expected Value:
                <ul>
                    <li>Overview: Expected value is calculated by multiplying the probability of winning a bet by the amount you could win per bet, and subtracting the product of the probability of losing and the amount lost per bet.</li>
                    <li>Formula: EV = (Probability of Win × Amount Won per Bet) - (Probability of Losing × Amount Lost per Bet).</li>
                </ul>
                
                Positive EV Betting:
                <ul>
                    <li>Overview: A positive EV indicates a bet that will likely be profitable over time. Bettors seek these opportunities to ensure long-term profitability. SurebetFusion takes this one step further by showing you these opportunities in real-time.</li>
                    <li>Example: If a bookmaker offers 2.5 odds for a team's win, and fair odds gives an estimate of the team's chances of winning at 50%, the EV is calculated as (0.50 × $2.50) - (0.50 × $1.00) = $0.25. This positive EV suggests a profitable bet in the long run.</li>
                </ul>
                
                Negative EV Betting:
                <ul>
                    <li>Overview: Negative EV represents a bet that will likely lose money over time. Wise bettors avoid these bets or reassess their strategies. SurebetFusion does not show negative EV opportunities, only positive EV opportunities are shown.</li>
                    <li>Example: Using the same odds, if fair odds gives the team's chances of winning at only 30%, the EV becomes (0.30 × $2.50) - (0.70 × $1.00) = -$0.45, indicating a losing proposition over time.</li>
                </ul>
                
                
                Applying EV in Various Betting Scenarios:
                <ul>
                    <li>Overview: EV can be applied to different types of bets, including outright wins, over/under, handicaps, and prop bets.</li>
                    <li>Key Benefit: By calculating EV, bettors can quantify the value of a bet, allowing them to make more informed decisions and avoid unprofitable bets.</li>
                </ul>
                Incorporating EV into Betting Strategies:
                <ul>
                    <li>Overview: Successful bettors incorporate EV analysis into their overall betting strategy, using it to select bets, determine stake sizes, and manage their bankrolls.</li>
                    <li>Advanced Tip: Combine EV analysis with other strategies like arbitrage or trend analysis for a comprehensive betting approach.</li>
                </ul>

                <p>
                SurebetFusions EV+ offers you the best opportunities quickly and easily. Below is an example of some EV+ opportunities and what they look like:
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/2.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                In this above example we can see that we are on the EV+ section with a Pre Match (before the game starts) opportunity on the Dallas Stars vs New York Islanders.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/3.png')} style={{minWidth: '100px'}} alt="" />
                </div>

                <p>
                Now looking at the right side of the opportunity we can see that this is a 27.12% EV+ opportunity.  This means that this opportunity is 27.12% higher than fair value.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/4.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                Surebetfusion.com shows you the fair odds in a nice graph to also show you the history of the odds and compares them between the book maker that has the opportunity (in this case FanDuel) and what fair value is.  As you can see we have a good delta of 27.12%. Now lets look at what the winning probability is, as we can see its 21.19%.  So there is a 21.19% chance this will win.  These are great to take advantage of as the payout can be very good.  In this case the odds are at 500 (american odds) meaning if you placed a bet for 25$ you could make a 125$ profit if successful with these odds.  As this is higher than fair value for this bet as to what it should be you can take advantage of this opportunity with this sports book.  Now its still 21.19% likely to win which is not to bad considering you would be paid 500% over your bet amount.  
                </p>

                <h3>Understanding Vig and Width in EV Bets</h3>
                <h4>
                What is Vig (Vigorish)?
                </h4>
                <p>
                Definition: Vigorish, or vig, is the fee or commission that a bookmaker charges for taking a bet. It's essentially how sportsbooks make a profit.
                </p>
                <p>
                In Betting Odds: Vig is typically built into the odds. For instance, in an even match, instead of both outcomes being at even odds (2.00 in decimal format or +100 in American odds), they might be priced at 1.91 (or -110 in American odds). The difference from the true even odds represents the vig.
                </p>
                <p>
                Impact on Bettors: The vig affects the true probability of outcomes. Bettors need to overcome the vig to make a profit in the long term. It's a key factor in determining the true value of a bet.
                </p>
                <h4>
                What is Width?
                </h4>
                <p>
                Definition: Width, in the context of betting markets, refers to the spread between the odds for different outcomes of an event. It's an indicator of the market's efficiency and competitiveness.
                </p>
                <p>
                Example: In a soccer match, if one bookmaker offers 1.95 for Team A to win and 1.95 for Team B to win, the width is smaller compared to another bookmaker offering 1.90 for Team A and 2.00 for Team B. A smaller width generally indicates a more efficient market with less vig.
                </p>
                <p>
                Relevance to EV Betting: Width is important for EV bettors as it impacts the potential value of a bet. Narrower width often means less vig and potentially better value bets.
                </p>
                <h4>
                Applying Vig and Width in EV Betting
                </h4>
                <p>
                Calculating True Odds: To find EV, bettors should first calculate the true odds of an outcome, considering the vig. This involves adjusting the bookmaker's odds to remove the vig and see the implied true probability.
                </p>
                <p>
                Assessing Market Efficiency: By comparing the width across different bookmakers, bettors can gauge the market's efficiency. More efficient markets (narrower width) might offer closer true odds, leading to more accurate EV calculations.
                </p>
                <p>
                Strategic Betting: Understanding vig and width helps in making strategic bets. A positive EV bet is more likely when a bettor finds odds that are better than the true odds, considering the vig.
                </p>
                <p>
                Expected value bets come up all the time and are once again the highest during live games.  This is the same as Surebets (arbitrage bets). 
                </p>
                <p>
                Now that you have a good understanding of what arbitrage betting and expected value betting is all about, how about we look into some examples of arbitrage betting.
                </p>
                <p>
                Arbitrage betting Example 1:
                </p>
                <p>
                <b>2 Way Bet:</b>
                </p>
                <p>
                This is the most common of all arb bets.  It simply allows the bettor to place a bet on bookmaker 1 for Team A to win and another bookmaker 2 (these are different bookmakers) for Team B to win.  Now no matter what your going to win on one of these.  (In the event there is an option for a tie this is known as a 3 way arb bet which we will come too in a minute)
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/5.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                In this example we can see that this soccer match has Betano at total cards under 6.5 and FanDuel with total cards over 6.5.  One of these is going to win.  This is actually a Prematch event as well with a 9.65% profit.  This is excellent.  Now with 1000$ you would profit 96.50$ which you can imagine placing 10 or more of these daily!
                </p>
                <p>
                Now specific information on the above tells us that the game starts 2/26/2024 12:45pm and that this was updated 2 minutes ago as you can see where it says “Updated”.  There is also a calculator looking icon in the right corner which is very good to use.  Lets click on it:
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/6.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                There is excellent information shown here that gives more details on the bet. We can see the bookmaker names, market type (this is the bet category), the odds for each, how much to stake for each bet and the profit amount you will earn. Clicking on each of the odds will show you history of the odds and what they have done over time. This can be very handy to understand how the odds have changed over the last bit of time.  
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/7.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <div className={style.img_container}>
                    <img src={require('./img/howto/8.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                Ideally you would have your FanDuel sportsbook opened as well as your Betano sportsbook to place the bets.  We have added in a “Bet Now” direct link for many of the sports books that allow for instant betting ability. Now our system is always watching the odds for you so if they change and are no longer a Surebet (arbitrage) opportunity our system will let you know in real-time.
                </p>
                <p>
                    <b>
                    Now lets talk about filters
                    </b>
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/9.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                You have full ability and control to select the type of opportunities you want to see as well as the type of sports, bookmakers, profit range, time period for games, and when the bets were presented and shown. (24 hours shows bets updated as opportunities over the last 24 hours)
                </p>
                <p>
                You also have the ability to save filters based on this information so you can quickly access it later on or in turn clear the filter to show everything again.  A good technique is to filter out sportsbooks you are not using so it will only present you with the sportsbooks you have.  That being said lets talk more about sportsbooks specifically for the filtering as there are a few things to be aware of:
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/10.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                You will see in the above image that there are 3 possible settings for a sportsbook.  
                </p>
                <ol>
                    <li>
                        William Hill (Test) *Green check mark
                        <ul>
                            <li>
                            This setting is default and typically on for all.  It means that this sportsbook can show opportunities if one is found.
                            </li>
                        </ul>
                    </li>
                    <li>
                        BetDSI *Green check mark and green highlighted with a green circle
                        <ul>
                            <li>
                            This can be made by clicking on this sportsbook and is a special setting that will only show you opportunities where BetDSI is in at least one of the legs of the bets shown.
                            </li>
                        </ul>
                    </li>
                    <li>
                        BetJACK *No Green Checkmark and not highlighted
                        <ul>
                            <li>
                            This simply means that this sportsbook is not included in any opportunities and wont show anything to do with this sportsbook.
                            </li>
                        </ul>
                    </li>
                </ol>
                <p>
                Putting your sportsbooks in the right setting mentioned above is important.  Make sure you filter out (number 3 example above) sportsbooks that you are not using.  No sense on seeing details of sportsbooks your not using.  The secret of the game though is to get as many as you can so that you have the most opportunities available for you.
                </p>
                <p>
                Some Undocumented ways to leverage Surebetfusion.com
                </p>
                <ol>
                    <li>
                    EV+ for player props
                        <ul>
                            <li>
                            Our EV+ scanner runs 24/7 in real time across all markets and all sports books and there is some amazing ways to leverage this with the sorting by “Winning Probability high to low”

                            </li>
                            <li>
                            Leverage opportunities even if they are shown for other sports books you are not using for these as it can give you a clear indication of which bets are likely to “win” and what the odds are of them.  We like to leverage for example “Winning probability of 65% and higher” and look for odds that are around the -200 area. (american odds format) These alone can build an account up very nicely and this is one way we ourselves build our equity across playbooks.

                            </li>
                        </ul>
                    </li>
                    <li>
                    EV+ Live
                        <ul>
                            <li>
                            This function is fantastic to watch during live games as it shows incredible opportunities for bets in live games that allow for excellent opportunities where the sports books dont have their odds set quite right.  This happens all the time during a live game and also shows the probability of which bets have a good probability percentage of actually winning along with the odds value for payout.
                            </li>
                        </ul>
                    </li>
                    <li>
                    Leverage multiple Play Books
                        <ul>
                            <li>
                            This is somewhat of a known thing of course and especially with arbitrage betting. More books you are on the more opportunities you will have available. 
                            </li>
                            <li>
                            Use multiple play books for the same bets.  If you have a strategy with Surebetfusion that is working, (maybe like one of the EV+ ones mentioned) then use it across multiple play books when placing the bets so you get a better return daily.  
                            </li>
                            <li>
                            For arbitrage keep open multiple windows while logged into your sports books so you can place bets quickly.  Know your sports book navigation!  This is critical as speed with arbitrage is very key especially with live arbs.
                            </li>
                        </ul>
                    </li>
                </ol>

                <p>
                Select sports at the top of the filter does exactly what it says.  You can select which sports you want to be able to see for bets. Most cases you will want to see them all however you also may only prefer certain sports for bets for example “Tennis” which is very popular for arb betting.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/11.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                Selecting the profit range allows you to control the percentage of profit shown for bets.  In most cases you will want to have this set to the maximum level to show all opportunities.  However it can be adjusted within your filter as required.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/12.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                Time period for game in (days) quantity.  This will show the period of days which the scanner will look for opportunities available. 10 days which is default is usually just fine to use.  However if you wanted to adjust this to maybe a smaller time frame and look for only opportunities within 3 days for example you can change this parameter to meet your preferences.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/13.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                Time period for bets update (hours) will show bets that have been updated within this time frame.  The default of 24 hours is usually just fine and this will show all opportunities that have been updated within the last 24 hours.  Keep in mind that SurebetFusion updates bets in realtime 24/7 so this does not mean that it will only update opportunities every 24 hours.  It only means that it will show you all opportunities that have been updated in the last 24 hours. The indicator on each opportunity shows you when it was recently updated by the “Updated” value on each opportunity.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/14.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                Now for saving filters, this is very important and most our users have several filters that they use depending on different scenarios.  The below shows how to save your filter which is at the bottom of the filter side bar left side menu. By clicking on the “Save filter” radio button it will bring up a few additional fields to input the Filter name (put a name that you want to use for this filter) and click on the save filter button.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/15.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <div className={style.img_container}>
                    <img src={require('./img/howto/16.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                After saving your filter you will see your filters listed which can always be retrieved by clicking on the top left filter icon.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/17.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <div className={style.img_container}>
                    <img src={require('./img/howto/18.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                Filters can be retrieved and activated at anytime.  The active filter will always be shown at the top of the filter bar.
                </p>
                <p>
                You can see the active filter is “no-fanduel-ev” which is currently active.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/19.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                To clear all filters you can select at the bottom of the filter menu the “Clear Filter” button. This will clear any existing filter automatically soon as its selected.  You can always activate a filter from the filter menu again at anytime.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/20.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                Most cases after you make a change to something on your filter please remember to click on the “Apply” button at the bottom of the filter navigation menu.
                </p>
                <p>
                Now its no secret that sports books will limit you when you start winning, and some limit you much faster than others.  Here is some tips on keeping things going.
                </p>
                <ol>
                    <li>
                        Use main lines - these bets will keep you off the radar of the sports books and keep your account around.  SurebetFusion has a toggle switch at the top of the filter menu on the left that you can select that will show you only “Main Lines”
                        <div className={style.img_container}>
                            <img src={require('./img/howto/21.png')} style={{minWidth: '100px'}} alt="" />
                        </div>
                    </li>
                    <li>Stick with popular leagues only like Soccer, Baseball, Basketball, Football.  Uncommon leagues will also get your account flagged very early that you will want to avoid.</li>
                    <li>
                    Mix in some parlays and straight bets into your strategies which helps in keeping your account healthy.
                    </li>
                    <li>
                    Round up or down to even numbers.  This is very important and a way to dramatically help keep your account in good standing.  This can be done by the toggle switch within SurebetFusion. Notice on the right side we have this set to Round to 1 and the toggle is turned on.  This will round your stakes to the closest 1.  We recommend using this and maybe consider rounding to 1, 2 or 5.
                    </li>
                </ol>
                <div className={style.img_container}>
                    <img src={require('./img/howto/22.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                Speed is the name of the game when placing arb bets and you can leverage the “Bet Now” buttons on many of the sports books to add in the betslip directly and safely so that you can have the bets placed as fast as possible.
                </p>
                <p>
                The calculator function within SurebetFusion is the main component when you are looking at your bets on the page. Clicking on the calculator brings up the expanded information on the bet your looking at:
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/23.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                After clicking on the calculator icon you will see the following.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/24.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                The left side of this shows the type of arb bet this is “2way” the league type “Baseball” and the percentage of arb profit “3.22%”
                </p>
                <p>
                The right side shows the book makers, your “Bet Now” direct link, market which is the bet name, odds being offered on each bet, the amount of recommended money to use, profit percentage and profit amount. You can also use the “Add to Bets Tracker” to track this bet for you.  Selecting the “Fixed” allows you to manually adjust certain bet characteristics if you want as well such as the amount of money used on each bet side.
                </p>
                <p>
                The calculator item shown above can also be collapsed upwards by using the up arrow on the left side of this by the Calculator name on the top left corner.
                </p>
                <p>
                On the main bets listed on the page you will also see some information which includes the date of the sports match (top right corner), information on the bet as well as when the odds were updated from the sports books last (in this case 16 minutes ago and 15 minutes ago), we also show when Surebetfusion last checked these bets so you know how recent we check on these for you.  This is shown on the right “Updated: 2m” so in this case it was last checked 2 minutes ago.  Now also notice there is a “Remove” button that can remove bets from view.  This is just to keep things as clean as possible for you and can be used to remove bets or groups of bets from view.  These can be added back from your settings as desired.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/25.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                There is also a “Show more” you see on the above which shows the same bets available but with different sports books.
                </p>
                <p>
                EV+ is the same format as mentioned on the above material so nothing more to mention there except they are all single bets of course that are automatically selected for you that present the absolute best EV+ opportunities on the planet for you.  These are scanned in realtime 24/7.
                </p>
                <p>
                Settings which is on the top right of the screen. Clicking on the cog wheel takes you to your settings.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/26.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <div className={style.img_container}>
                    <img src={require('./img/howto/27.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                Personal Profile option above allows you to see your user details and also change your password and user icon if wanted.
                </p>
                <p>
                Bankroll is a virtual bank roll that allows you to track bets using the Bets Tracker with virtual money that works very well and can mimic your real funds overall if you want.  This is a great way to understand how much money your making across the board with all sports books.
                </p>
                <p>
                You can easily add funds here by using the plus and minus dollar signs on the right or quickly by clicking popular amounts as required.
                </p>
                <p>
                Current balance shows your overall virtual bank roll balance.  All bets that are tracked affect your current balance. Each time an amount of funds is added or removed it is tracked as a transaction below. This shows you specifics on the money added, won or lost.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/28.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                In the Preferences section of your settings you will see many options as below.  You will notice some basic settings such as country your from, currency to show your bet opportunities and funds tracking in, no-vig to show vig or not with EV+.  In most cases you will more than likely want to keep on the default of “No” to show you the vig amount but not have it calculated into the EV+ opportunities.  
                </p>
                <p>
                Odds format for the type of odds like American, Decimal, etc.
                </p>
                <p>
                Rounding on all book makers which can be set here to always round your bets.  This is extra to the rounding under the calculator and when set will always round without you having to turn on the toggle each time.
                </p>
                <p>
                Value percentage is showing you from 0% to maximum percent for opportunities.  In most cases you dont need to change this as the maximum will be the default which will show you all percentages for opportunities. 
                </p>
                <p>
                Note* if you are not a Premium subscriber you will only see certain lower percentage ranges for bets.  Free users only can see under 1% arb opportunities for example.
                </p>
                <p>
                Odds setting can specify specific odds ranges only that you want to see.  In most cases the default is good and will show all odds.  However this can be changed if need be.
                </p>
                <p>
                Time to start for match days will show you for example “10” days ahead maximum which is plenty of time before the match starts.  It will also still show you other bets that start at anypoint sooner than the 10 days of course as well. In most cases the default is probably just fine.
                </p>
                <p>
                You can also select certain sports and certain book makers here which will override your filter settings and if selected here under your settings will always be true and turned on until you change things here.  We recommend using the filters for your custom views instead.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/29.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                Under Subscriptions this shows you the available subscriptions we have available for our users. A description of each subscription is mentioned here.
                </p>
                <p>
                You will also notice a “Removed bets” section which contains any removed bets you made from the main page from view.  This is where you can easily add them back to the main view as desired.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/30.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <b>
                My Bets Section
                </b>
                <p> 
                This is a location where you can track all your bets in one location.  You can also break down the bets by leveraging the filter on the left hand side to show certain bets only within the bets you made. This page is pretty self explanatory however there is a few unique abilities here like “Add Bet Manually” in case you want to add a bet that was not tracked previously. You can also delete bets made by using the red delete garbage can icon on the top right of each bet made.
                </p>
                <p>
                By clicking on the share icon under each bet on this page on the bottom right of each bet.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/31.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <div className={style.img_container}>
                    <img src={require('./img/howto/32.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <div className={style.img_container}>
                    <img src={require('./img/howto/33.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                Clicking on the Profit area with the icon here opens up a calendar view for the bets you have made and your progress on a daily basis.
                </p>
                <div className={style.img_container}>
                    <img src={require('./img/howto/34.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <div className={style.img_container}>
                    <img src={require('./img/howto/35.png')} style={{minWidth: '100px'}} alt="" />
                </div>
                <p>
                Thanks everyone, if you have any questions for us you can always reach out to the team at <a href="mailto:info@surebetfusion.com">info@surebetfusion.com</a>
                </p>
            </div>
        </div>
    )
}