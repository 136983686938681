import style from '../style/MainAdminStyles.module.scss';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAnalytics } from './../../../features/admins/adminsSlice';

import { Bar, Line } from 'react-chartjs-2';
import 'chart.js/auto'; // For Chart.js v3

export default function Analytics(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        // getting analytics
        dispatch(fetchAnalytics());
    }, []);

    const analytics = useSelector(state => state.admins.analytics);
    
    const options = {
        responsive: true,
        plugins: {
          legend: {
            display: false, 
          },
          title: {
            display: true,
            //text: 'Chart.js Bar Chart',
            color: 'white', 
          },
        },
        scales: {
          x: {
            ticks: {
              color: 'white', 
            },
            grid: {
                display: false,
            }
          },
          y: {
            ticks: {
              color: 'white', 
            },
            grid: {
                display: false,
            }
          },
        },
      };
      
      const users_labels = analytics?.usersInfo?.map((u => u.date));
      
      const users_data = {
        labels: users_labels,
        datasets: [
          {
            data: analytics?.usersInfo?.map((u => u.newUsersCount)),
            backgroundColor: '#91E617',
          },
        ],
      };

      const user_bets_labels = analytics?.userBetsInfo?.map((u => u.date));
      
      const user_bets_data = {
        labels: user_bets_labels,
        datasets: [
          {
            data: analytics?.userBetsInfo?.map((u => u.newUserBetsCount)),
            backgroundColor: '#91E617',
          },
        ],
      };

    return(
        <div className={style.analytics_container}>
            <div className={style.column}>
                <div className={style.plot_header}>
                    Total # of Bets Placed under MYBETS
                </div>
                <div className={style.plot_block}>
                    <Bar options={options} data={user_bets_data}></Bar>
                </div>
                <div className={style.statistics_section}>
                    <div className={style.statistics_block}>
                        <div className={style.statistics_header}>
                            Active Users
                        </div>
                        <div className={style.statistics_value}>
                            <div>
                                {analytics.activeUsers}
                            </div>
                            
                        </div>
                    </div>
                    <div className={style.statistics_block}>
                        <div className={style.statistics_header}>
                            Blocked Users
                        </div>
                        <div className={style.statistics_value}>
                            <div>
                                {analytics.blockedUsers}
                            </div>
                            
                        </div>
                    </div>
                    <div className={style.statistics_block}>
                        <div className={style.statistics_header}>
                            User Acquisition
                        </div>
                        <div className={style.statistics_value}>
                            <div>
                                -
                            </div>
                            
                        </div>
                    </div>
                    <div className={style.statistics_block}>
                        <div className={style.statistics_header}>
                            User Retention
                        </div>
                        <div className={style.statistics_value}>
                            <div>
                                -%
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.column}>
                <div className={style.plot_header}>
                    Total New Users by date
                </div>
                <div className={style.plot_block}>
                    <Bar options={options} data={users_data}></Bar>
                </div>
                <div className={style.statistics_column_section}>
                    {/* <div className={style.statistics_block}>
                        <div className={style.statistics_header}>
                            Average subscription length
                        </div>
                        <div className={style.statistics_value}>
                            <div>
                                -
                            </div>
                            
                        </div>
                    </div> */}
                    <div className={style.statistics_block}>
                        <div className={style.statistics_header}>
                            Premium Subscriptions
                        </div>
                        <div className={style.statistics_value}>
                            <div>
                                {analytics.premiumSubscribers}
                            </div>
                            
                        </div>
                    </div>
                    <div className={style.statistics_block}>
                        <div className={style.statistics_header}>
                            Basic Subscriptions
                        </div>
                        <div className={style.statistics_value}>
                            <div>
                                {analytics.basicSubscribers}
                            </div>
                            
                        </div>
                    </div>
                    <div className={style.statistics_block}>
                        <div className={style.statistics_header}>
                            Free Subscriptions
                        </div>
                        <div className={style.statistics_value}>
                            <div>
                                {analytics.freeSubscribers}
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.column}>
                <div className={style.plot_block}>
                    <RevenueChart data={analytics.plotData}></RevenueChart>
                </div>
                <div className={style.statistics_column_section}>
                    <div className={style.statistics_block}>
                        <div className={style.statistics_header}>
                            Total Subscription Revenue (USD)
                        </div>
                        <div className={style.statistics_value}>
                            <div>
                                ${analytics.totalRevenue?.toFixed(2)}
                            </div>
                            
                        </div>
                    </div>
                    <div className={style.statistics_block}>
                        <div className={style.statistics_header}>
                            Premium
                        </div>
                        <div className={style.statistics_value}>
                            <div>
                                ${analytics.premiumRevenue?.toFixed(2)}
                            </div>
                            
                        </div>
                    </div>
                    <div className={style.statistics_block}>
                        <div className={style.statistics_header}>
                            Basic
                        </div>
                        <div className={style.statistics_value}>
                            <div>
                                ${analytics.basicRevenue?.toFixed(2)}
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

const RevenueChart = ({ data }) => {
    data = data ?? ({basicRevenue: {}, premiumRevenue: {}});

    const labels = Object.keys(data.basicRevenue); 
  
    const basicData = Object.values(data.basicRevenue);
    const premiumData = Object.values(data.premiumRevenue);
  
    const chartData = {
      labels,
      datasets: [
        {
          label: 'Basic Revenue',
          data: basicData,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Premium Revenue',
          data: premiumData,
          borderColor: 'rgb(54, 162, 235)',
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
        }
      ],
    };
  
    const options = {
        responsive: true,
        plugins: {
          legend: {
            display: true, 
            labels: {
                color: 'white', 
            } 
          },

        },
      scales: {
        x: {
            ticks: {
              color: 'white', 
            },
            grid: {
                display: false,
            }
          },
        y: {
          beginAtZero: true,
          ticks: {
            color: 'white', 
          },
          grid: {
              display: false,
          }
        }
      }
    };
  
    return <Line data={chartData} options={options} />;
};