import React, { useState, useEffect } from 'react';
import style from './styles/RemovedBetsStyle.module.scss';

import { styled } from '@mui/material/styles';
import colors from '../../utils/colors';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useSelector, useDispatch } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { formatDateString } from './../../utils/time_converter';

import { deleteHiddenBet, removeHiddenBet, fetchHiddenBet } from './../../features/bets/betsSlice';

const CustomCheckbox = styled(Checkbox)({
    color: 'white', // Border color
    '&.Mui-checked': {
      color: colors.green,
    },
});

export default function RemovedBets(params) {
    const dispatch = useDispatch();
    const removed_bets = useSelector(state => state.bets.removedBets);
    const [selectedHiddenBets, setSelectedHiddenBet] = useState([]);

    useEffect(() => {
        dispatch(fetchHiddenBet());
    }, []);

    function handleCheckboxChange(id) {
        if (selectedHiddenBets.includes(id)) {
            setSelectedHiddenBet(selectedHiddenBets.filter(item => item !== id));
        } else {
            setSelectedHiddenBet([...selectedHiddenBets, id]);
        }
    }

    function handleSelectAllChange() {
        if (selectedHiddenBets.length === removed_bets.length) {
            setSelectedHiddenBet([]);
        } else {
            setSelectedHiddenBet(removed_bets.map(n => n.id));
        }
    }

    async function handleRemove() {
        dispatch(removeHiddenBet(selectedHiddenBets));
        await dispatch(deleteHiddenBet(selectedHiddenBets));
    }

    return(
        <div className={style.notifications_page}>
            <div className={style.setting_Wrapper}>
                <div className={style.item}>
                    <FormControlLabel 
                        control={
                        <CustomCheckbox 
                            checked={selectedHiddenBets.length === removed_bets.length}
                            onChange={handleSelectAllChange}
                        />
                        } 
                        label={'Select All'} 
                    />
                    <span className={style.deselect} onClick={() => setSelectedHiddenBet([])}>
                        Deselect All
                    </span>
                    <span className={style.remove} onClick={handleRemove}>
                        <img src={require('./img/trash.png')} alt="Close icon" />
                    </span>
                </div>
            </div>
            <div className={style.notifications_table}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"><span>Filter</span></TableCell>
                                <TableCell align="center"><span>Game</span></TableCell>
                                <TableCell align="center"><span>Type</span></TableCell>
                                <TableCell align="center"><span>Markets</span></TableCell>
                                <TableCell align="center"><span>Mode</span></TableCell>
                                <TableCell align="center"><span>Time</span></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {removed_bets.map((row) => (
                            <TableRow 
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, color: row.new ? 'green' : '#fff' }}
                            >
                                <TableCell align="center"><span>{row.filter_name}</span></TableCell>
                                <TableCell align="center"><span>{row.game_name}</span></TableCell>
                                <TableCell align="center"><span>{row.type}</span></TableCell>
                                <TableCell align="center"><span>{row.type === "single" ? row.bet_name : row.group_name}</span></TableCell>
                                <TableCell align="center"><span>{row.mode}</span></TableCell>
                                <TableCell align="center"><span>{formatDateString(row.createdAt)}</span></TableCell>
                                <TableCell align="center">
                                <CustomCheckbox 
                                    checked={selectedHiddenBets.includes(row.id)}
                                    onChange={() => handleCheckboxChange(row.id)}
                                />
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}
