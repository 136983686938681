import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';

import style from './styles/MainStyles.module.scss';

import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import FiltersComponent from '../components/FiltersComponent';
import MyBetFilter from '../components/MyBetFilter';
import HeaderComponent from '../components/HeaderComponent';
import BetsListComponent from '../components/BetsListComponent';
import CalculatorComponent from '../components/CalculatorComponent';
import Account from '../components/account/Account';
import MainAdmin from '../components/admin/MainAdmin';
import Notifications from '../components/Notifications';
import FreePage from '../components/FreePage';
import HowToPage from '../components/HowToPage';

import colors from '../utils/colors';

import {
  BrowserRouter,
  Routes,
  Route,
  NavLink,
  Link
} from "react-router-dom";

import LogIn from '../components/account/LogIn';
import CreateAccount from '../components/account/CreateAccount';

import { setMode, setIsFetched, fetchSettings } from '../features/settings/settingsSlice';
import { updateAll } from '../features/preferences/preferencesSlice';
import { setUserBets, fetchUserBets } from '../features/bets/betsSlice';
import { fetchUserInfo, fetchUserTransactions } from '../features/user/userSlice';
import { fetchUserFilters } from '../features/filter/filterSlice';

import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { Navigate } from 'react-router-dom';
import ResetPassword from '../components/account/ResetPassword';
import ChangePassword from '../components/account/ChangePassword';
import ConfirmPassword from '../components/account/ConfirmPassword';

import socketServerAPI from '../http/socketServerAPI';
import restServerAPI from '../http/restServerAPI';

import { setNotificationStatus, switchNotification } from './../features/user/userSlice';
import { setNotified } from './../features/settings/settingsSlice';

function MainPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  
  const subscriptionType = useSelector(state => state.user.subscriptionType);
  const isBlocked = useSelector(state => state.user.isBlocked);
  const notificationStatus = useSelector(state => state.user.notificationsEnabled);
  const notified = useSelector(state => state.settings.notified);

  const checkIsBlocked = async () => {
    if (isBlocked) {
      await signOut();
      setIsAuthenticated(false);
    }
  }

  checkIsBlocked();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    await checkAuthState();

    const userSub = localStorage.getItem('userSub');
    if (userSub) {
      const savedPreferences = await restServerAPI.get_preferences(userSub);
      if (savedPreferences) {
        dispatch(updateAll(savedPreferences));
      }
    }
    
    // getting user info
    await dispatch(fetchUserInfo(userSub));

    // getting filters
    await dispatch(fetchUserFilters(userSub));

    // getting user bets
    await dispatch(fetchUserBets(userSub));

    // getting user transactions
    await dispatch(fetchUserTransactions(userSub));

    // get settings
    await dispatch(fetchSettings());

    dispatch(setIsFetched(true));
    setIsDataFetched(true);
    
    // initiating connection with WebSocket server
    socketServerAPI.init();
  }

  const checkAuthState = async () => {
    try {
      const session = await fetchAuthSession();
      if (session && session.userSub) {
        const currentTime = new Date().getTime();
        if (session.tokens.accessToken.payload.exp * 1000 < currentTime) {
          await signOut();
          setIsAuthenticated(false);
          return;
        }
        console.log('session: ', session);

        // setting userSub to localstorage
        localStorage.setItem('userSub', session.userSub);

        setIsAuthenticated(true);
      } else {
        // removing userSub from localstorage
        localStorage.removeItem('userSub');

        setIsAuthenticated(false);
      }
    } catch (error) {
      console.log(error);
      setIsAuthenticated(false);
    }
  };

  const mode = useSelector(state => state.settings.mode);

  const [mobileSubheaderStatus, setMobileSubheaderStatus] = useState(false);

  const handleClick = (name) => {
    dispatch(setMode(name));
  }

  return (
    <Grid container style={{ width: '100%' }}>
      <BrowserRouter>
        <Grid container item style={{ height: '70px' }}>
          <Grid item xs={12} style={{ height: '100%' }}>
            <HeaderComponent isDataFetched={isDataFetched} isAuthenticated={isAuthenticated}></HeaderComponent>
          </Grid>
        </Grid>
        {
          isAuthenticated ? 
          <div className={`${style.mobile_header} ${mode === "prematch" || mode === "live" || mode === "ev" || mode === "evLive" || mode === "mybets" ? style.submenu : ''}`}>
            <div className={style.left}>
              <Link
                id="basic-button"
                className={`${style.button} ${mode === "prematch" || mode === "live" ? classes.buttonActive : classes.buttonInactive}`}
                to={'prematch'}
              >
                <span>SUREBET</span>
              </Link>
              <Link
                id="basic-button"
                className={`${style.button} ${mode === "ev" || mode === "evLive" ? classes.buttonActive : classes.buttonInactive}`}
                to={subscriptionType !== "free" ? 'ev' : 'freepage'}
              >
                <span>EV+</span>
              </Link>
              <Link
                id="basic-button"
                className={`${style.button} ${mode === "mybets" ? classes.buttonActive : classes.buttonInactive}`}
                to={subscriptionType !== "free" ? 'mybets' : 'freepage'}
              >
                <span>MYBETS</span>
              </Link>
              {/* <Link
                id="basic-button"
                className={`${style.button} ${mode === "notifications" ? classes.buttonActive : classes.buttonInactive}`}
                to={'notifications'}
              >
                <img src={require('../components/img/notif_gray.png')} alt="" />
              </Link> */}
              <img 
                src={require('../components/img/notif_gray.png')} 
                onClick={() => dispatch(setNotified(false))} 
                style={{marginLeft: '10px', cursor: 'pointer'}} alt="" 
              />
              {
                notified ? <span className={style.badge}></span> : null
              }
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch 
                  checked={notificationStatus}
                  onChange={(event) => {
                    dispatch(setNotificationStatus(event.target.checked));
                    dispatch(switchNotification(event.target.checked));
                  }}
                  inputProps={{ 'aria-label': 'ant design' }} 
                />
              </Stack>
            </div>
            {
              subscriptionType === 'superadmin' ?
              <Link to={"/admin"} className={`${style.admin_button} ${mode.includes("admin") ? classes.buttonActive : classes.buttonInactive}`}>
                ADMIN
              </Link>
              : null
            }
          </div>
          : null
        }
        {
          isAuthenticated ? 
          <div className={style.mobile_subheader}>
            {
              mode === "prematch" || mode === "live" ?
              <div className={style.mobile_links}>
                <Link
                  id="basic-button"
                  className={`${style.button} ${mode === "prematch" ? style.button_active : ''}`}
                  to={'prematch'}
                >
                  <b>Prematch</b>
                </Link>
                <Link
                  id="basic-button"
                  className={`${style.button} ${mode === "live" ? style.button_active : ''}`}
                  to={(!subscriptionType.includes("basic") && subscriptionType !== "free") ? 'live' : 'freepage'}
                >
                  <b>Live</b>
                </Link>
                {/* <Link
                  id="basic-button"
                  className={`${style.button} ${mode === "ev" ? style.button_active : ''}`}
                  to={subscriptionType !== "free" ? 'ev' : 'account/subscriptions'}
                >
                  <b>EV+</b>
                </Link>
                <Link
                  id="basic-button"
                  className={`${style.button} ${mode === "mybets" ? style.button_active : ''}`}
                  to={subscriptionType !== "free" ? 'mybets' : 'account/subscriptions'}
                >
                  <b>MYBETS</b>
                </Link> */}
              </div> : ''
            }
            {
              mode === "ev" || mode === "evLive" ?
              <div className={style.mobile_links}>
                <Link
                  id="basic-button"
                  className={`${style.button} ${mode === "ev" ? style.button_active : ''}`}
                  to={subscriptionType !== "free" ? 'ev' : 'freepage'}
                >
                  <b>Prematch</b>
                </Link>
                <Link
                  id="basic-button"
                  className={`${style.button} ${mode === "evLive" ? style.button_active : ''}`}
                  to={(!subscriptionType.includes("basic") && subscriptionType !== "free") ? 'evLive' : 'freepage'}
                >
                  <b>Live</b>
                </Link>
              </div> : ''
            }
            {
              mode === "prematch" || mode === "live" || mode === "ev" || mode === "evLive" || mode === "mybets" ?
              <div className={style.filter_icon} onClick={() => setMobileSubheaderStatus(true)}>
                <img src={require('../components/img/filter_button.svg').default} alt="Filter icon" />
              </div> : ''
            }
          </div> : null
        }
        
        <Routes>
          {
           isDataFetched ? isAuthenticated ?
              <>
                <Route path='/account/bankroll' element={subscriptionType !== "free" ? <Account /> : <Navigate to="/account/subscriptions" replace />} />
                <Route path='/account/*' element={<Account />} />
                <Route path='/admin/*' element={<MainAdmin />} />
                <Route path='/' element={<Navigate to="/prematch" replace />} />
                <Route path="*" element={<MainElement mobileSubheaderStatus={mobileSubheaderStatus} setMobileSubheaderStatus={setMobileSubheaderStatus} mode={mode} />} />
                <Route path='/change-password' element={<ChangePassword />} />
                <Route path='/notifications' element={<Notifications />} />
                <Route path='/freepage' element={<FreePage />} />
                <Route path='/howto' element={<HowToPage />} />
              </> :
              <>
                <Route path='/signup' element={<CreateAccount />} />
                <Route path='/login' element={<LogIn />} />
                <Route path='/reset' element={<ResetPassword />} />
                <Route path='/confirm' element={<ConfirmPassword />} />
                <Route path='/howto' element={<HowToPage />} />
                <Route path='*' element={<LogIn />} />
              </>
              : null
          }
        </Routes>
      </BrowserRouter>
    </Grid>
  );
}

const useStyles = makeStyles({
  root: {
    height: '100vh'
  },
  buttonActive: {
    color: '#428500 !important',
    position: 'relative',
    fontWeight: '700 !important',
    '&:after': { 
      content: '""',
      position: 'absolute',
      width: '100%', 
      height: '3px', 
      bottom: '-5px',
      left: '0',
      backgroundColor: '#428500', 
      transition: 'all 0.3s ease', 
    }
  },
  buttonInactive: {
    transition: 'color 0.2s ease',
    '&:hover': {
      color: '#91E617'
    }
  }
});

export default MainPage;

function MainElement(props) {
  const [hideFilterWrapper, setHideFilterWrapper] = useState(false);

  return(
    <>
      <div className={style.calculator_wrapper}>
        {/* Calculator Block */}
        <div className={style.calculator_container} >
          {/* Calculator content goes here */}
          {
            props.mode == 'mybets' ? '' :
            <CalculatorComponent></CalculatorComponent>
          }
        </div>

        {/* Betting History Block */}
        <Grid item style={{ flexGrow: 1 }}>
          {/* Betting history content goes here */}
          <div></div>
        </Grid>
      </div>

      {/* Second Row */}
      <Grid container item style={{ height: 'calc(100vh - 110px)' }}>
        {/* Settings Block */}
        <div className={`${style.filter_wrapper} ${hideFilterWrapper ? style.hide : ''} ${props.mobileSubheaderStatus ? style.active : ''}`}>
          { props.mode === 'mybets' ? 
            <MyBetFilter setMobileSubheaderStatus={props.setMobileSubheaderStatus} hideFilterWrapper={hideFilterWrapper} setHideFilterWrapper={setHideFilterWrapper}></MyBetFilter>
            : <FiltersComponent setMobileSubheaderStatus={props.setMobileSubheaderStatus} hideFilterWrapper={hideFilterWrapper} setHideFilterWrapper={setHideFilterWrapper}></FiltersComponent>
          }
        </div>

        {/* Bets Block */}
        <Grid className={`${style.betslist_wrapper} ${hideFilterWrapper ? style.full_width : ''}`}>
          {/* Bets content goes here */}
          {
            <BetsListComponent mode={props.mode}></BetsListComponent>
          }
        </Grid>

        {/* Right Side Block (Calculator and Betting History) */}
      </Grid>
    </>
  )
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
    // Добавляем стили для неактивного состояния кружочка
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'rgba(0, 0, 0, 0.25) !important', // Светло-серый фон трека при активном состоянии
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: '#050033', // Черный кружочек
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25) !important', // Светло-серый фон трека
    boxSizing: 'border-box',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#91E617 !important', // Светло-серый фон трека при активном состоянии
  },
}));