export function convertAmericanToEuropean(americanOdds) {
  if (americanOdds > 0) {
    return (americanOdds / 100) + 1;
  } else {
    return 1 + (100 / Math.abs(americanOdds));
  }
}

export function convertEuropeanToAmerican(europeanOdds) {
  if (europeanOdds >= 2) {
    return Math.round((europeanOdds - 1) * 100);
  } else {
    return Math.round(-100 / (europeanOdds - 1));
  }
}