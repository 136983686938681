import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Asynchronous thunk to get user information
export const fetchUserInfo = createAsyncThunk(
  'user/fetchUserInfo',
  async (userSub, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/users/${userSub}`);
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const userInfo = await response.json();
      return userInfo;
    } catch (error) {
      console.error('Error fetching user info:', error);
      return rejectWithValue(error.message);
    }
  }
);

// Asynchronous method for sending updated data to the server
export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (userData, { dispatch, getState }) => {
    const userSub = localStorage.getItem('userSub');
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/users/${userSub}`, {
      method: 'PATCH',
      body: userData,
    });

    if (!response.ok) {
      throw new Error('Could not update user data');
    }

    return response.json();
  }
);

// fetch user transactions
export const fetchUserTransactions = createAsyncThunk(
  'user/fetchUserTransactions',
  async (userSub, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/transactions/${userSub}`);
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const userTransactions = await response.json();
      return userTransactions;
    } catch (error) {
      console.error('Error fetching user transactions:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const addUserTransaction = createAsyncThunk(
  'user/addUserTransaction',
  async (transactionData, { dispatch, getState }) => {
    const userSub = localStorage.getItem('userSub');
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/transactions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({userSub, transactionData}),
    });

    if (!response.ok) {
      throw new Error('Could not add user transaction');
    }
    const transaction = await response.json();
    return transaction;
  }
);

export const deleteNotifications = createAsyncThunk(
  'user/deleteNotifications',
  async (notificationIds, { dispatch, getState }) => {
    const userSub = localStorage.getItem('userSub');
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/notifications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({userSub, notificationIds}),
    });

    if (!response.ok) {
      throw new Error('Could not delete notifications');
    }
    const notifications = await response.json();
    return notifications;
  }
);

export const deleteImage = createAsyncThunk(
  'user/deleteImage',
  async (data, { dispatch, getState }) => {
    const userSub = localStorage.getItem('userSub');
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/users/delete-photo/${userSub}`);

    if (!response.ok) {
      throw new Error('Could not delete image');
    }

    return await response.json();
  }
);

export const switchNotification = createAsyncThunk(
  'user/switchNotification',
  async (notificationStatus, { dispatch, getState }) => {
    const userSub = localStorage.getItem('userSub');
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/users/switch-notifications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({userSub, notificationStatus}),
    });

    if (!response.ok) {
      throw new Error('Could not switch notifications');
    }

    return await response.json();
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    firstName: '',
    lastName: '',
    email: '',
    telNumber: '',
    subscriptionType: 'free',
    image: null,
    transactions: [],
    notifications: [],
    newNotifications: 0,
    notificationsEnabled: false,
    isBlocked: false,
    status: 'idle', // request status
    error: null,
  },
  reducers: {
    setFirstName: (state, message) => {
      state.firstName = message.payload;
    },
    setLastName: (state, message) => {
      state.lastName = message.payload;
    },
    setEmail: (state, message) => {
      state.email = message.payload;
    },
    setImage: (state, message) => {
      state.image = message.payload;
    },
    setNotifications: (state, message) => {
      state.notifications = message.payload;
    },
    addNotification: (state, message) => {
      state.notifications = [{...message.payload, new: true}, ...state.notifications];
      state.newNotifications = state.newNotifications + 1;
    },
    removeNotifications: (state, message) => {
      state.notifications = state.notifications.filter(n => !message.payload?.includes(n.id));
    },
    setNotificationStatus: (state, message) => {
      state.notificationsEnabled = !state.notificationsEnabled;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.firstName = action.payload.firstName;
        state.lastName = action.payload.lastName;
        state.email = action.payload.email;
        state.telNumber = action.payload.telNumber;
        state.subscriptionType = action.payload.subscription_name;
        state.image = action.payload.imageUrl;
        state.notificationsEnabled = action.payload.notificationsEnabled;
        state.isBlocked = action.payload.isBlocked;
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Handling the asynchronous updateUser action
      .addCase(updateUser.pending, (state) => {
        state.updateStatus = 'loading'; 
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.updateStatus = 'succeeded';
        state.firstName = action.payload.firstName;
        state.lastName = action.payload.lastName;
        state.email = action.payload.email;
        state.telNumber = action.payload.telNumber;
        state.image = action.payload.imageUrl;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.updateStatus = 'failed';
        state.updateError = action.payload;
      })
      // transactions handlers
      .addCase(fetchUserTransactions.fulfilled, (state, action) => {
        state.updateStatus = 'succeeded';
        state.transactions = action.payload;
      })
      .addCase(addUserTransaction.fulfilled, (state, action) => {
        state.updateStatus = 'succeeded';
        state.transactions = [...state.transactions, action.payload];
      })
      .addCase(deleteImage.fulfilled, (state, action) => {
        state.updateStatus = 'succeeded';
        state.image = null;
      })
  },
});

export const { 
  setFirstName,
  setLastName,
  setEmail,
  setNotifications,
  addNotification,
  removeNotifications,
  setNotificationStatus
} = userSlice.actions;

export default userSlice.reducer;