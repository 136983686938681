import { useState } from 'react';
import { updatePassword } from '@aws-amplify/auth';
import style from './styles/LoginStyle.module.scss';

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

/**
 * ChangePassword Component
 * 
 * This component provides the functionality for a user to change their password.
 * It wraps the `NewPasswordComponent`, which contains the form fields required for
 * entering the old and new passwords.
 */
export default function ChangePassword() {
    return(
        <div className={`${style.auth_container} ${style.reset}`}>
            <form>
                <NewPasswordComponent />  
            </form>
        </div>
    )
}

/**
 * NewPasswordComponent
 * 
 * This component renders the input fields for the old password and new password, along with
 * functionality to toggle the visibility of these passwords. It provides a form for users to
 * submit their old and new passwords in order to update their password.
 * 
 * Users are given feedback on the success or failure of the password change through a text
 * message displayed on the screen. This component uses local state to manage the input values
 * and visibility states of the password fields.
 */
function NewPasswordComponent(props) {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [passwordInfo, setPasswordInfo] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    async function changeUserPassword(event, oldPassword, newPassword) {
        event.preventDefault();
        try {
            await updatePassword({ oldPassword, newPassword });
            setPasswordInfo('Password changed successfully');
            console.log('Password changed successfully');
        } catch (error) {
            console.error('Error changing password: ', error);
            setPasswordInfo('Error changing password: ' + error.message);
        }
    }

    return(
        <>
            <span className={`${style.header} ${style.new_password}`} style={{paddingTop: '100px'}}>Change Password</span>

            <div className={style.inputs_wrapper}>
                <div className={style.item}>
                    <label htmlFor="old_password">Old Password</label>
                    <input 
                        type={showPassword ? 'text' : 'password'}
                        name="password" 
                        id='old_password' 
                        placeholder='Old Password' 
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                    { showPassword ? <VisibilityOutlinedIcon onClick={() => setShowPassword(false)} /> : <VisibilityOffOutlinedIcon onClick={() => setShowPassword(true)} /> }
                </div>
                <div className={style.item}>
                    <label htmlFor="new_password">New Password</label>
                    <input 
                        type={showConfirmPassword ? 'text' : 'password'}
                        name="password"
                        id='new_password' 
                        placeholder='New Password' 
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    { showConfirmPassword ? <VisibilityOutlinedIcon onClick={() => setShowConfirmPassword(false)} /> : <VisibilityOffOutlinedIcon onClick={() => setShowConfirmPassword(true)} /> }
                </div>
                <div style={{color: 'white', textAlign: 'center'}}>
                    {passwordInfo}
                </div>
            </div>
            <button className={style.button} onClick={(event) => changeUserPassword(event, oldPassword, newPassword)}>Change Password</button>
        </>
    )
}