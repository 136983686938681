import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import sorting_types from '../../utils/sorting_arb_types';

// Asynchronous thunk to get user bets
export const fetchUserBets = createAsyncThunk(
  'bets/fetchUserBets',
  async (userSub, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/bets/${userSub}`);
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const userBets = await response.json();
      
      return userBets;
    } catch (error) {
      console.error('Error fetching user bets:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const addUserBet = createAsyncThunk(
  'bets/addUserBet',
  async (betData, { dispatch, getState }) => {
    const userSub = localStorage.getItem('userSub');
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/bets`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({userSub, betData}),
    });

    if (!response.ok) {
      throw new Error('Could not update user bets');
    }

    const bet = await response.json();
    return bet
  }
);

export const deleteUserBet = createAsyncThunk(
  'bets/deleteUserBet',
  async (betId, { getState }) => {
    const userSub = localStorage.getItem('userSub');
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/bets/${userSub}/${betId}`, {
      method: 'DELETE'
    });

    if (!response.ok) {
      throw new Error('Could not delete user bet');
    }
  }
);

export const updateUserBet = createAsyncThunk(
  'bets/updateUserBet',
  async (data, { getState }) => {
    const userSub = localStorage.getItem('userSub');
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/bets/${userSub}/${data.betId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({userSub, ...data}),
    });

    if (!response.ok) {
      throw new Error('Could not update user bet');
    }

    const bet = await response.json();
    return bet;
  }
);

export const updateUserBetStatus = createAsyncThunk(
  'bets/updateUserBetStatus',
  async (data, { getState }) => {
    const userSub = localStorage.getItem('userSub');
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/bets/status/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({userSub, ...data}),
    });

    if (!response.ok) {
      throw new Error('Could not update user bet status');
    }

    const bet = await response.json();
    return bet;
  }
);

export const fetchProfitGraphs = createAsyncThunk(
  'bets/fetchProfitGraphs',
  async (_, { rejectWithValue }) => {
    try {
      const userSub = localStorage.getItem('userSub');
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/bets-graph/${userSub}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({timezone}),
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const profitGraphs = await response.json();
      
      return profitGraphs;
    } catch (error) {
      console.error('Error fetching profit graphs:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const hideBet = createAsyncThunk(
  'bets/hideBet',
  async (bet_data, { rejectWithValue }) => {
    try {
      const userSub = localStorage.getItem('userSub');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/hide-bet/${userSub}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...bet_data
        }),
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      
      return result;
    } catch (error) {
      console.error('Error fetching profit graphs:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchHiddenBet = createAsyncThunk(
  'user/fetchHiddenBet',
  async (_, { dispatch, getState }) => {
    const userSub = localStorage.getItem('userSub');
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/removed-bets/${userSub}`);

    if (!response.ok) {
      throw new Error('Could not fetch removed bets');
    }
    const hiddenBets = await response.json();
    return hiddenBets;
  }
);

export const deleteHiddenBet = createAsyncThunk(
  'user/deleteHiddenBet',
  async (hiddenBetsIds, { dispatch, getState }) => {
    const userSub = localStorage.getItem('userSub');
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/removed-bets`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({userSub, hiddenBetsIds}),
    });

    if (!response.ok) {
      throw new Error('Could not delete hidden bets');
    }
    const hiddenBets = await response.json();
    return hiddenBets;
  }
);

export const betsSlice = createSlice({
  name: 'bets',
  initialState: {
    arbBets: {
      prematch: [],
      live: [],
      ev: [],
      evLive: [],
    },
    page: {
      prematch: 0,
      live: 0,
      ev: 0,
      evLive: 0,
    },
    limit: {
      prematch: 10,
      live: 10,
      ev: 10,
      evLive: 10,
    },
    total: {
      prematch: 0,
      live: 0,
      ev: 0,
      evLive: 0,
    },
    hasNext: {
      prematch: false,
      live: false,
      ev: false,
      evLive: false,
    },
    selectedBet: {
      prematch: null,
      live: null,
      ev: null,
      evLive: null,
    },
    sorting: sorting_types[0],
    userBets: [],
    userBetsFiltered: [],
    profitGraph: {},
    removedBets: [],
    status: 'idle', // request status
    error: null,
  },
  reducers: {
    setArbBets: (state, message) => {
      state.arbBets = {
        ...state.arbBets,
        [message.payload.mode]: message.payload.data
      };
    },
    setPage: (state, message) => {
      state.page = {
        ...state.page,
        [message.payload.mode]: message.payload.data
      };
    },
    setLimit: (state, message) => {
      state.limit = {
        ...state.limit,
        [message.payload.mode]: message.payload.data
      };
    },
    setTotal: (state, message) => {
      state.total = {
        ...state.total,
        [message.payload.mode]: message.payload.data
      };
    },
    setHasNext: (state, message) => {
      state.hasNext = {
        ...state.hasNext,
        [message.payload.mode]: message.payload.data
      };
    },
    setSelectedBet: (state, message) => {
      state.selectedBet = {
        ...state.selectedBet,
        [message.payload.mode]: message.payload.data
      };
    },
    setUserBets: (state, message) => {
      const updateOrAddBet = (bet) => {
        const index = state.userBets.findIndex(item => item.betInfo.id === bet.betInfo.id);
        if (index !== -1) {
          state.userBets[index] = bet; // Replace an existing object
        } else {
          state.userBets.push(bet); // Add new object
        }
      };
    
      if (Array.isArray(message.payload)) {
        message.payload.forEach(bet => updateOrAddBet(bet));
      } else {
        updateOrAddBet(message.payload);
      }

      state.userBets.sort((a, b) => b.timestamp - a.timestamp);

      // temp
      //localStorage.setItem('savedBets', JSON.stringify(state.userBets));
    },
    removeUserBet: (state, message) => {
      const betIdToRemove = message.payload.betId;
      state.userBets = state.userBets.filter(bet => bet.betId !== betIdToRemove);
      state.userBets.sort((a, b) => b.timestamp - a.timestamp);

      state.userBetsFiltered = state.userBetsFiltered.filter(bet => bet.betId !== betIdToRemove);
      state.userBetsFiltered.sort((a, b) => b.timestamp - a.timestamp);

      // temp
      //localStorage.setItem('savedBets', JSON.stringify(state.userBets));
    },
    setUserBetsFiltered: (state, message) => {
      state.userBetsFiltered = message.payload;
    },
    setSorting: (state, message) => {
      state.sorting = message.payload;
    },
    removeHiddenBet: (state, message) => {
      state.removedBets = state.removedBets.filter(n => !message.payload?.includes(n.id));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserBets.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserBets.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const bets = action.payload?.sort((a, b) => b.timestamp - a.timestamp);
        state.userBets = bets;
        state.userBetsFiltered = bets;
      })
      .addCase(fetchUserBets.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // adding new bet
      .addCase(addUserBet.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addUserBet.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userBets = [action.payload, ...state.userBets];
        state.userBetsFiltered = [action.payload, ...state.userBetsFiltered];
      })
      .addCase(addUserBet.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateUserBet.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.userBets = state.userBets.map(bet => {
          if (bet.id === action.payload.id) {
            return action.payload;
          }
          return bet;
        });

        state.userBetsFiltered = state.userBetsFiltered.map(bet => {
          if (bet.id === action.payload.id) {
            return action.payload;
          }
          return bet;
        });
      })
      .addCase(updateUserBetStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.userBets = state.userBets.map(bet => {
          if (bet.id === action.payload.id) {
            return action.payload;
          }
          return bet;
        });

        state.userBetsFiltered = state.userBetsFiltered.map(bet => {
          if (bet.id === action.payload.id) {
            return action.payload;
          }
          return bet;
        });
      })
      .addCase(fetchProfitGraphs.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.profitGraph = action.payload;
      })
      .addCase(hideBet.fulfilled, (state, action) => {
        const { status, betId, groupId, mode, type } = action.payload;

        if (status === 'success') {
          if (mode === 'surebet') {
            if (type === 'single') {
              const updatedArbGroups = [];

              for (const arb_group of state.arbBets.prematch) {
                  const filteredArbGroup = [];

                  for (const bet of arb_group) {
                      const shouldRemove = bet.id === betId;

                      if (!shouldRemove) {
                          filteredArbGroup.push(bet);
                      }
                  }

                  if (filteredArbGroup.length > 0) {
                      updatedArbGroups.push(filteredArbGroup);
                  }
              }

              state.arbBets.prematch = updatedArbGroups;
              state.total.prematch = state.total.prematch - 1;
            } else if (type === 'group') {
              state.arbBets.prematch = state.arbBets.prematch.filter(arb_group => {
                if (groupId === arb_group[0].group_key) {
                  state.total.prematch = state.total.prematch - arb_group.length;
                }

                return groupId !== arb_group[0].group_key;
              });
            }
          }

          if (mode === 'ev') {
            if (type === 'single') {
              const updatedEVGroups = [];

              for (const arb_group of state.arbBets.ev) {
                  const filteredEVGroup = {...arb_group, odds: []};

                  for (const bet of arb_group.odds) {
                      const shouldRemove = bet.id === betId;

                      if (!shouldRemove) {
                        filteredEVGroup.odds.push(bet);
                      }
                  }

                  if (filteredEVGroup.odds.length > 0) {
                    updatedEVGroups.push(filteredEVGroup);
                  }
              }

              state.arbBets.ev = updatedEVGroups;
              state.total.ev = state.total.ev - 1;
            } else if (type === 'group') {
              state.arbBets.ev = state.arbBets.ev.filter(group => {
                if (groupId === group.odds[0].market_id) {
                  state.total.ev = state.total.ev - group.odds.length;
                }

                return groupId !== group.odds[0].market_id;
              });
            }
          }
        }
      })
      .addCase(fetchHiddenBet.fulfilled, (state, action) => {
        state.removedBets = action.payload?.data;
      })
  }
});

export const { 
  setArbBets,
  setPage,
  setLimit,
  setTotal,
  setHasNext,
  setSelectedBet,
  setUserBets,
  removeUserBet,
  setUserBetsFiltered,
  setSorting,
  removeHiddenBet
} = betsSlice.actions;

export default betsSlice.reducer;