import { useState, useEffect } from 'react';
import style from './styles/AccountStyle.module.scss';
import SelectComponent from '../SelectComponent';
import bookmakers from '../../utils/bookmakers';
import sports from '../../utils/sports';
import countries from '../../utils/countries';
import currencies from '../../utils/currencies';
import FormControlLabel from '@mui/material/FormControlLabel';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import colors from '../../utils/colors';
import { useSelector, useDispatch } from 'react-redux';
import { 
    updateBookmakers,
    updateRequiredBookmakers,
    updateSports,
    updateCountry,
    updateOddsFormat,
    updateCurrency,
    updateTimeToMatch,
    updatePercentageRange,
    updateOddsRange,
    updateRounding,
    updateNoVig
} from '../../features/preferences/preferencesSlice';
import areArraysEqual from './../../utils/compare_arrays';
import restServerAPI from '../../http/restServerAPI';

import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';


const decimalToAmerican = (decimalOdds) => {
    if (decimalOdds >= 2.0) {
      return Math.round((decimalOdds - 1) * 100);
    } else {
      return Math.round(-100 / (decimalOdds - 1));
    }
};

const americanToDecimal = (americanOdds) => {
    if (americanOdds > 0) {
      return ((americanOdds / 100) + 1).toFixed(2);
    } else {
      return ((100 / Math.abs(americanOdds)) + 1).toFixed(2);
    }
};

/**
 * Preferences Component
 *
 * This component is designed for users to customize their betting preferences, including selected bookmakers,
 * sports, odds format, currency, country, and other betting-related preferences. It supports both free and
 * subscribed users, with subscribed users having access to more comprehensive customization options.
 *
 * The component uses React hooks for state management and Redux for retrieving and updating global state.
 * Conditional rendering is utilized to display different options based on the user's subscription type.
 * It provides a user-friendly interface for adjusting betting settings, such as preferred bookmakers, sports,
 * value percentage range, odds, and time to match start.
 *
 * Users can search and select from a predefined list of bookmakers and sports. For bookmakers, users can also
 * specify required bookmakers that should always appear in their bets. Preferences are saved by interacting with
 * a REST API, and updates are reflected in the Redux store to ensure consistency across the application.
 */
export default function Preferences(props) {
    const dispatch = useDispatch();
    const subscriptionType = useSelector(state => state.user.subscriptionType);
    const navigate = useNavigate();

    const selected_bookmakers = useSelector(state => state.preferences.bookmakers);
    const selected_required_bookmakers = useSelector(state => state.preferences.requiredBookmakers);
    const selected_sports = useSelector(state => state.preferences.sports);
    const selected_country = useSelector(state => state.preferences.country);
    const selected_odds_format = useSelector(state => state.preferences.oddsFormat);
    const selected_currency = useSelector(state => state.preferences.currency);
    const min_percentage = useSelector(state => state.preferences.minPercentage);
    const max_percentage = useSelector(state => state.preferences.maxPercentage);
    const time_to_match = useSelector(state => state.preferences.timeToMatch);
    const rounding = useSelector(state => state.preferences.rounding);
    const no_vig = useSelector(state => state.preferences.noVig);
    const min_odds = useSelector(state => state.preferences.minOdds);
    const max_odds = useSelector(state => state.preferences.maxOdds);

    const [selectedBookmakersState, setSelectedBookmakersState] = useState(selected_bookmakers);
    const [selectedReqBookmakersState, setSelectedReqBookmakersState] = useState(selected_required_bookmakers);
    const [selectedSportsState, setSelectedSportsState] = useState(selected_sports);
    const [selectedCountryState, setSelectedCountryState] = useState(selected_country);
    const [selectedOddsFormatState, setSelectedOddsFormatState] = useState(selected_odds_format);
    const [selectedCurrencyState, setSelectedCurrencyState] = useState(selected_currency);
    const [selectedMinPercentageState, setSelectedMinPercentageState] = useState(min_percentage);
    const [selectedMaxPercentageState, setSelectedMaxPercentageState] = useState(max_percentage);
    const [selectedTimeToMatchState, setSelectedTimeToMatchState] = useState(time_to_match);
    const [selectedRoundingState, setSelectedRoundingState] = useState(rounding);
    const [noVigState, setNoVigState] = useState(no_vig === false ? "No" : "Yes");
    const [selectedMinOddsState, setSelectedMinOddsState] = useState(
        selectedOddsFormatState === "Decimal" ? americanToDecimal(min_odds) : min_odds
    );
    const [selectedMaxOddsState, setSelectedMaxOddsState] = useState(
        selectedOddsFormatState === "Decimal" ? americanToDecimal(max_odds) : max_odds
    );

    const [displayAllBookmakers, setDisplayAllBookmakers] = useState(false);
    const [displayAllSports, setDisplayAllSports] = useState(false);

    const savePreferences = async () => {
        if (subscriptionType === "free") {
            await restServerAPI.update_preferences(
                localStorage.getItem('userSub'),
                {
                    country: selectedCountryState,
                    currency: selectedCurrencyState,
                    oddsFormat: selectedOddsFormatState,
                    rounding: selectedRoundingState,
                }
            );
            
            // update redux state
            dispatch(updateCountry(selectedCountryState));
            dispatch(updateCurrency(selectedCurrencyState));
            dispatch(updateOddsFormat(selectedOddsFormatState));
            dispatch(updateRounding(selectedRoundingState));

            return;
        };

        const minOddsToSend = selectedOddsFormatState === "Decimal" ? decimalToAmerican(selectedMinOddsState) : selectedMinOddsState;
        const maxOddsToSend = selectedOddsFormatState === "Decimal" ? decimalToAmerican(selectedMaxOddsState) : selectedMaxOddsState;

        await restServerAPI.update_preferences(
            localStorage.getItem('userSub'),
            {
                country: selectedCountryState,
                currency: selectedCurrencyState,
                oddsFormat: selectedOddsFormatState,
                rounding: selectedRoundingState,
                minPercentage: selectedMinPercentageState,
                maxPercentage: selectedMaxPercentageState,
                minOdds: minOddsToSend,
                maxOdds: maxOddsToSend,
                timeToMatch: selectedTimeToMatchState,
                sports: selectedSportsState,
                bookmakers: selectedBookmakersState,
                requiredBookmakers: selectedReqBookmakersState,
                noVig: noVigState === "Yes" ? true : false
            }
        );
        
        // update redux state
        dispatch(updateBookmakers(selectedBookmakersState));
        dispatch(updateRequiredBookmakers(selectedReqBookmakersState));
        dispatch(updateSports(selectedSportsState));
        dispatch(updateCountry(selectedCountryState));
        dispatch(updateCurrency(selectedCurrencyState));
        dispatch(updateOddsFormat(selectedOddsFormatState));
        dispatch(updateRounding(selectedRoundingState));
        dispatch(updatePercentageRange([selectedMinPercentageState, selectedMaxPercentageState]));
        dispatch(updateOddsRange([selectedMinOddsState, selectedMaxOddsState]));
        dispatch(updateTimeToMatch(selectedTimeToMatchState));
        dispatch(updateNoVig(noVigState === "Yes" ? true : false));
    }

    const checkIsChanged = () => {
        let flag = false;

        let noVigBinary = noVigState === "Yes" ? true : false;

        if (
            selectedCountryState !== selected_country ||
            selectedCurrencyState !== selected_currency ||
            selectedOddsFormatState !== selected_odds_format ||
            selectedMinPercentageState !== min_percentage ||
            selectedMaxPercentageState !== max_percentage ||
            selectedMinOddsState !== min_odds ||
            selectedMaxOddsState !== max_odds ||
            selectedTimeToMatchState !== time_to_match ||
            selectedRoundingState !== rounding ||
            noVigBinary !== no_vig ||
            !areArraysEqual(selectedBookmakersState, selected_bookmakers) ||
            !areArraysEqual(selectedReqBookmakersState, selected_required_bookmakers) ||
            !areArraysEqual(selectedSportsState, selected_sports)
        ) {
            flag = true;
        }

        return flag;
    }

    const handleBookmakersChange = (item) => {
        if (subscriptionType === "free") return;

        const newSelectedBookmakers = Array.isArray(selectedBookmakersState) ? [...selectedBookmakersState] : [];
      
        const index = newSelectedBookmakers.indexOf(item);
        if (index !== -1) {
            newSelectedBookmakers.splice(index, 1);
    
            const newSelectedRequiredBookmakers = Array.isArray(selectedReqBookmakersState) ? [...selectedReqBookmakersState] : [];
            const indexRequired = newSelectedRequiredBookmakers.indexOf(item);
            if (indexRequired !== -1) {
                newSelectedRequiredBookmakers.splice(indexRequired, 1);
                setSelectedReqBookmakersState(newSelectedRequiredBookmakers);
            }
        } else {
            newSelectedBookmakers.push(item);
        }
        
        setSelectedBookmakersState(newSelectedBookmakers);

        setDisplayAllBookmakers(false);
    };

    const handleRequiredBookmakersChange = (e, item) => {
        e.stopPropagation();

        if (subscriptionType === "free") return;

        const index = selectedBookmakersState.indexOf(item);
        if (index !== -1) {
            if (selectedReqBookmakersState.length < 2 && !selectedReqBookmakersState.includes(item)) {
                setSelectedReqBookmakersState([...selectedReqBookmakersState, item]);
            } else {
                const newSelectedRequiredBookmakers = Array.isArray(selectedReqBookmakersState) ? [...selectedReqBookmakersState] : [];
                const indexRequired = newSelectedRequiredBookmakers.indexOf(item);
                if (indexRequired !== -1) {
                newSelectedRequiredBookmakers.splice(indexRequired, 1);
                }
        
                setSelectedReqBookmakersState(newSelectedRequiredBookmakers);
            }
        }

        setDisplayAllBookmakers(false);
    }

    const handleSportsChange = (item) => {
        if (subscriptionType === "free") return;

        const newSelectedSports = Array.isArray(selectedSportsState) ? [...selectedSportsState] : [];
      
        const index = newSelectedSports.indexOf(item);
        if (index !== -1) {
          newSelectedSports.splice(index, 1);
        } else {
          newSelectedSports.push(item);
        }
      
        setSelectedSportsState(newSelectedSports);

        setDisplayAllSports(false);
    };

    const handleCountryChange = (item) => {      
        setSelectedCountryState(item);
    };

    const handleCurrencyChange = (item) => {      
        setSelectedCurrencyState(item);
    };

    const handleNoVigChange = (item) => {      
        setNoVigState(item);
    };

    const handleOddsFormatChange = (item) => { 
        if (selectedOddsFormatState !== item) {
            if (item === "Decimal") {
                setSelectedMinOddsState(americanToDecimal(selectedMinOddsState));
                setSelectedMaxOddsState(americanToDecimal(selectedMaxOddsState));
            } else {
                setSelectedMinOddsState(decimalToAmerican(selectedMinOddsState));
                setSelectedMaxOddsState(decimalToAmerican(selectedMaxOddsState));
            }
        }

        setSelectedOddsFormatState(item);
    };

    const handleRoundingChange = (item) => {      
        setSelectedRoundingState(item);
    };

    const handleMinPercentageChange = (item) => {     
        if (subscriptionType === "free") return;

        if (subscriptionType.includes("basic")) {
            if (item > 30) {
                item = 30;
            }
        } else if (subscriptionType.includes("premium")) {
            if (item > 50) {
                item = 50;
            }
        }
        setSelectedMinPercentageState(item);
    };

    const handleMaxPercentageChange = (item) => { 
        if (subscriptionType === "free") return;

        if (subscriptionType.includes("basic")) {
            if (item > 30) {
                item = 30;
            }
        } else if (subscriptionType.includes("premium")) {
            if (item > 50) {
                item = 50;
            }
        }
        setSelectedMaxPercentageState(item);
    };

    const handleMinOddsChange = (item) => { 
        if (subscriptionType === "free") return;

        // if (selectedMaxOddsState >= item)
        //     setSelectedMinOddsState(item);
        // else {
        //     setSelectedMinOddsState(selectedMaxOddsState);
        // }
        setSelectedMinOddsState(item);
    };
    
    const handleMaxOddsChange = (item) => { 
        if (subscriptionType === "free") return;

        setSelectedMaxOddsState(item);
    };

    const handleTimeToMatchChange = (item) => {    
        if (subscriptionType === "free") return;

        setSelectedTimeToMatchState(item);
    };
    
    const CustomCheckbox = styled(Checkbox)({
        color: 'white', // Border color
        '&.Mui-checked': {
          color: colors.green,
        },
    });

    useEffect(() => {
        if (displayAllBookmakers) {
            setSelectedBookmakersState(bookmakers);
        }
    }, [displayAllBookmakers])

    useEffect(() => {
        if (displayAllSports) {
            setSelectedSportsState(sports);
        }
    }, [displayAllSports])

    const clearBookmakers = () => {
        if (subscriptionType === "free") return;

        setSelectedBookmakersState([]);
        setSelectedReqBookmakersState([]);
        setDisplayAllBookmakers(false);
    }

    const clearSports = () => {
        if (subscriptionType === "free") return;

        setSelectedSportsState([]);
        setDisplayAllSports(false);
    }

    // bookmakers search
    const [inputValue, setInputValue] = useState('');

    const handleChange = (e) => {
        if (subscriptionType === "free") return;

        setInputValue(e.target.value);
    };

    const handleSelect = (item) => {
        if (subscriptionType === "free") return;

        const newSelectedBookmakers = Array.isArray(selectedBookmakersState) ? [...selectedBookmakersState] : [];
      
        const index = newSelectedBookmakers.indexOf(item);
        if (index !== -1) {
            if (selectedReqBookmakersState.length < 2 && !selectedReqBookmakersState.includes(item)) {
                setSelectedReqBookmakersState([...selectedReqBookmakersState, item]);
            } else {
                newSelectedBookmakers.splice(index, 1);
      
                const newSelectedRequiredBookmakers = Array.isArray(selectedReqBookmakersState) ? [...selectedReqBookmakersState] : [];
                const indexRequired = newSelectedRequiredBookmakers.indexOf(item);
                if (indexRequired !== -1) {
                  newSelectedRequiredBookmakers.splice(indexRequired, 1);
                }
      
                setSelectedReqBookmakersState(newSelectedRequiredBookmakers);
            }
        } else {
            newSelectedBookmakers.push(item);
        }
        
        setSelectedBookmakersState(newSelectedBookmakers);
        setInputValue(''); 
    };

    const filteredBookmakers = inputValue
        ? bookmakers.filter(bookmaker => bookmaker.toLowerCase().startsWith(inputValue.toLowerCase()))
        : [];

    const redirectToPage = async (event) => {
        event.preventDefault();
        navigate('/account/subscriptions');
    }

    return(
        <>
            <div className={`${style.container} ${style.preferences_container}`}>
                <div className={`${style.left_column} ${style.inputs_wrapper} ${'preferences_wrapper'}`}>
                    <div className={style.item}>
                        <label>Country</label>
                        <SelectComponent
                            label="Country" 
                            data={countries}
                            selectedData={selectedCountryState}
                            onChange={(data) => handleCountryChange(data)}
                        ></SelectComponent>
                    </div>                    
                    <div className={style.item}>
                        <label>Currency</label>
                        <SelectComponent
                            label="Currency" 
                            data={currencies}
                            selectedData={selectedCurrencyState}
                            onChange={(data) => handleCurrencyChange(data)}
                        ></SelectComponent>
                    </div> 
                    <div className={style.item}>
                        <label>Use No-Vig % in EV+</label>
                        <SelectComponent
                            label="Use No-Vig % in EV+" 
                            data={["Yes", "No"]}
                            selectedData={noVigState}
                            onChange={(data) => handleNoVigChange(data)}
                        ></SelectComponent>
                    </div>     
                    <div className={style.item}>
                        <label>Odds Format</label>
                        <SelectComponent
                            label="Odds Format" 
                            data={['American', 'Decimal']}
                            selectedData={selectedOddsFormatState}
                            onChange={(data) => handleOddsFormatChange(data)}
                        ></SelectComponent>
                    </div>    
                    <div className={style.item}>
                        <label>Rounding on All Bookmakers</label>
                        <SelectComponent
                            label="Rounding on All Bookmakers" 
                            data={['0.01', '0.001']}
                            selectedData={selectedRoundingState}
                            onChange={(data) => handleRoundingChange(data)}
                        ></SelectComponent>
                    </div>    
                    <div className={style.item}>
                        <label>Value Percentage</label>
                        <div className={style.input_container}>
                            <input 
                                type="number" 
                                id='min' 
                                min="0"
                                max="50"
                                value={selectedMinPercentageState}
                                onChange={(e) => handleMinPercentageChange(e.target.value)}
                                //placeholder='3' 
                            />
                            <span>min</span>
                        </div>
                        <div className={style.input_container}>
                            <input 
                                type="number" 
                                id='max' 
                                min="0"
                                max="50"
                                value={selectedMaxPercentageState}
                                onChange={(e) => handleMaxPercentageChange(e.target.value)}
                                //placeholder='20' 
                            />
                            <span>max</span>
                        </div>
                    </div>
                    <div className={style.item}>
                        <label>Odds</label>
                        <div className={style.input_container}>
                            <input
                                type="number" 
                                id="min" 
                                min={selectedOddsFormatState === "American" ? "-10000" : "1.01"}
                                placeholder={selectedOddsFormatState === "American" ? "-10000" : "1.01"} 
                                step={selectedOddsFormatState === "American" ? "1" : "0.1"}
                                value={selectedMinOddsState}
                                onChange={(e) => handleMinOddsChange(e.target.value)}
                            />
                            <span>min</span>
                        </div>
                        <div className={style.input_container}>
                            <input 
                                type="number" 
                                id="max" 
                                min={selectedOddsFormatState === "American" ? "-10000" : "1.01"}
                                placeholder={selectedOddsFormatState === "American" ? "10000" : "101"} 
                                step={selectedOddsFormatState === "American" ? "1" : "0.1"}
                                value={selectedMaxOddsState}
                                onChange={(e) => handleMaxOddsChange(e.target.value)}
                            />
                            <span>max</span>
                        </div>

                    </div>
                    <div className={style.item}>
                        <label>Time to Match Start (days)</label>
                        <div className={style.input_container}>
                            <input 
                                type="text" 
                                id='min' 
                                value={selectedTimeToMatchState}
                                onChange={(e) => handleTimeToMatchChange(e.target.value)}
                                //placeholder='300' 
                            />
                            <span>at most</span>
                        </div>
                    </div>
                </div>
                {
                    subscriptionType === "free" ?
                    <div className={style.free_wrapper}>
                        <p>
                        Your FREE subscription does not allow you to view this functionality. <br /><br />
                        <span>Upgrade</span> your plan in order to see all bets and access full functionality.
                        </p>
                        <button onClick={redirectToPage}>
                        Upgrade Plan
                        </button>
                    </div> : ''
                }
                <div className={`${style.right_column} ${subscriptionType === "free" ? style.free : ''}`}>
                    <div className={style.inputs_wrapper}>
                        <div className={`${style.item} ${style.item_wrapper}`}>
                            <label>Sports</label>
                            <button onClick={() => clearSports()}>
                                <img src={require('./img/close.png')} alt="Close icon" />
                            </button>
                            <a id="Sports">
                                <img src={require('./img/tooltips.png')} alt="Close icon" />
                            </a>
                            <Tooltip
                                className='tooltips'
                                key={'right-start'}
                                anchorSelect="#Sports"
                                content={`Clear all sports`}
                                place={'right-start'}
                            />
                        </div>
                    </div>
                    <div className={style.list_item}>
                        <FormControlLabel 
                            control={
                            <CustomCheckbox 
                                checked={displayAllSports} 
                                onChange={() => {
                                    if (subscriptionType === "free") return;
                                    setDisplayAllSports(!displayAllSports);
                                }}
                            />
                            } 
                            label={'All Sports'} 
                        />
                        <a id={`all-sports`}>
                            <img src={require('./img/tooltips.png')} alt="Close icon" />
                        </a>
                        <Tooltip
                            className='tooltips'
                            key={'right-start'}
                            anchorSelect={`#all-sports`}
                            content={`Select all sports`}
                            place={'right-end'}
                        />
                    </div>
                    {
                        sports.map((item, index) => 
                            <div className={style.list_item}>
                                <FormControlLabel 
                                    key={index} 
                                    control={
                                    <CustomCheckbox 
                                        checked={selectedSportsState.includes(item)} 
                                        onChange={() => handleSportsChange(item)}
                                    />
                                    } 
                                    label={item} 
                                />
                                <a id={`sports-${index}`}>
                                    <img src={require('./img/tooltips.png')} alt="Close icon" />
                                </a>
                                <Tooltip
                                    className='tooltips'
                                    key={'right-start'}
                                    anchorSelect={`#sports-${index}`}
                                    content={`Choose up to 2 required sports that will appear in all sports`}
                                    place={'right-end'}
                                />
                            </div>
                        )
                    }
                    <div className={style.inputs_wrapper}>
                        <div className={style.item}>
                            <div className={`${style.item_wrapper}`}>
                                <label>Bookmakers</label>
                                <button onClick={() => clearBookmakers()}>
                                    <img src={require('./img/close.png')} alt="Close icon" />
                                </button>
                                <a id="Bookmakers">
                                    <img src={require('./img/tooltips.png')} alt="Close icon" />
                                </a>
                                <Tooltip
                                    className='tooltips'
                                    key={'right-start'}
                                    anchorSelect="#Bookmakers"
                                    content={`Clear all bookmakers`}
                                    place={'right-end'}
                                />
                            </div>
                            <div className={style.input_block}>
                                <input 
                                    type="text" 
                                    id='bookmakers' 
                                    placeholder='Bookmakers' 
                                    value={inputValue}
                                    onChange={handleChange}
                                />
                                {inputValue && (
                                    <ul className={style.autocomplete_list}>
                                    {filteredBookmakers.map(bookmaker => (
                                        <li key={bookmaker} onClick={() => handleSelect(bookmaker)}>
                                            {bookmaker}
                                        </li>
                                    ))}
                                    </ul>
                                )}
                                <img src={require('./img/search.png')} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className={style.list_item}>
                        <FormControlLabel 
                            control={
                            <CustomCheckbox 
                                checked={displayAllBookmakers} 
                                onChange={() => {
                                    if (subscriptionType === "free") return;
                                    setDisplayAllBookmakers(!displayAllBookmakers);
                                }}
                            />
                            } 
                            label={'All Bookmakers'} 
                        />
                        <a id={`all_bookmakers`}>
                            <img src={require('./img/tooltips.png')} alt="Close icon" />
                        </a>
                        <Tooltip
                            className='tooltips'
                            key={'right-start'}
                            anchorSelect={`#all_bookmakers`}
                            content={`Select all bookmakers`}
                            place={'right-end'}
                        />
                    </div>
                    {
                        bookmakers.map((item, index) => 
                            <div className={style.list_item}>
                                <FormControlLabel 
                                    style={{width: '30px'}}
                                    key={index} 
                                    control={
                                    <CustomCheckbox 
                                        checked={selectedBookmakersState.includes(item)} 
                                        onChange={() => handleBookmakersChange(item)}
                                    />
                                    } 
                                    label="" 
                                />
                                <span style={{cursor: 'pointer', paddingRight: '10px'}} onClick={(e) => handleRequiredBookmakersChange(e, item)}>
                                    <span style={selectedReqBookmakersState.includes(item) ? {color: colors.green}: {color: '#fff'}}>{item}</span> {selectedBookmakersState.includes(item) ? 
                                    <FiberManualRecordIcon sx={{ 
                                    height: '12px', 
                                    width: '12px',
                                    color: selectedReqBookmakersState.includes(item) ? colors.green : colors.grey
                                    }}></FiberManualRecordIcon> : null}
                                </span>
                                <a id={`bookmaker-${index}`}>
                                    <img src={require('./img/tooltips.png')} alt="Close icon" />
                                </a>
                                <Tooltip
                                    className='tooltips'
                                    key={'right-start'}
                                    anchorSelect={`#bookmaker-${index}`}
                                    content={`Choose up to 2 required bookmakers that will appear in all bets`}
                                    place={'right-end'}
                                />
                            </div>
                        )
                    }
                </div>
                <button 
                    className={`${checkIsChanged() ? style.save_button : style.save_button_inactive} ${style.preferences_save}`}
                    onClick={savePreferences}
                >
                    Save
                </button>
            </div>
        </>
    )
}