import { useNavigate } from 'react-router-dom';
import style from './styles/LoginStyle.module.scss';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { confirmSignUp } from 'aws-amplify/auth';

/**
 * CreateAccount Component
 *
 * This component manages the account creation process, starting from verifying the user's email
 * to confirming the email with a verification code. It transitions between different stages of the
 * account creation process based on user actions and server responses.
 */
export default function CreateAccount() {
    const [email, setEmail] = useState('');
    const [stage, setStage] = useState('VERIFY_EMAIL');

    const [error, setError] = useState(null);
    
    const [code, setCode] = useState(new Array(6).fill(''));

    const handleCodeChange = (index) => (e) => {
        const newCode = [...code];
        newCode[index] = e.target.value.slice(0, 1); // Trim the input to one digit
        setCode(newCode);

        // Move focus to the next field if the current one is filled
        if (index < 5 && e.target.value) {
        document.getElementById(`code-${index + 1}`).focus();
        }
    };

    const handleKeyDown = (index) => (e) => {
        if (e.key === 'Backspace' && !code[index]) {
            // Move focus to the previous field if the current one is empty
            if (index > 0) {
                document.getElementById(`code-${index - 1}`).focus();
            }
        }
    };
    
    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text').slice(0, 6).split('');
        const newCode = [...code];
        pastedData.forEach((digit, index) => {
            newCode[index] = digit;
            document.getElementById(`code-${index}`).value = digit;
        });
        setCode(newCode);
        document.getElementById(`code-${pastedData.length - 1}`).focus();
    };

    const handleSetCode = async (event) => {
        event.preventDefault();

        try {
            await confirmSignUp({
                username: email, 
                confirmationCode: code.join('')
            });
            setStage("VERIFIED");

            console.log('Successfully confirmed email');
        } catch (error) {
            setError(error.message || error);
        }
    }

    return (
        stage === "VERIFY_EMAIL" ? <VerifyEmailPage 
        email={email} 
        setEmail={setEmail}
        //resendEmail={(e) => handleResendCode(e)}
        handleSetCode={handleSetCode}
        code={code}
        handleKeyDown={handleKeyDown}
        handlePaste={handlePaste}
        handleCodeChange={handleCodeChange}
        error={error}
    ></VerifyEmailPage> : <EmailConfirmedPage></EmailConfirmedPage>
    )
}

/**
 * VerifyEmailPage Component
 * 
 * This component presents a form for the user to verify their email by entering a verification code sent to their email.
 * It allows the user to input their email, enter the verification code, and handles code verification through the parent component's function.
 * Additionally, it provides feedback to the user in case of any errors during the verification process.
 */
function VerifyEmailPage({email, setEmail, handleCodeChange, handleSetCode, handleKeyDown, handlePaste, code, error}) {
    return(
        <div className={`${style.auth_container} ${style.reset}`}>
            <form> 
                <div className={style.back_link}>
                    <img 
                        src={require('./img/arrow.svg').default} 
                        className={style.arrow} 
                        alt="Arrow icon" 
                        style={{ transform: 'rotate(90deg)' }}
                    />
                    <Link to="/login">Back to Log In</Link>
                </div>
                <img src={require('./img/message.png')} className={style.header_padding} alt="" />
                <span className={`${style.header} ${style.under_img}`}>Verify Your Email</span>
                <div className={style.inputs_wrapper}>
                    <div className={style.item}>
                        
                        <input name="email" onChange={(e) => setEmail(e.target.value)} type="email" id='account_email' placeholder='Email' />
                    </div>
                </div>
                <span className={`${style.subheader_text} ${style.code_page}`}>
                    Please enter the 6 digit verification code sent to <span>{email}</span> 
                </span>
                <div className={style.code_wrapper} onPaste={handlePaste}>
                    {code.map((digit, index) => (
                        <div key={index} className={`${style.item_code}`}>
                            <input
                                className={style.code_input}
                                id={`code-${index}`}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={handleCodeChange(index)}
                                onKeyDown={handleKeyDown(index)}
                                onFocus={(e) => e.target.select()}
                            />
                        </div>
                    ))}
                </div>
                <div className={style.bottom_element} style={{color: '#FF5252'}}>
                    {error && <p>{error}</p>}
                </div>
                <div className={style.button_wrapper}>
                    
                    <button className={style.button} onClick={handleSetCode}>Confirm</button>
                </div>
            </form>
        </div>
    )
}

/**
 * EmailConfirmedPage Component
 * 
 * This component displays a success message indicating that the user's email has been successfully verified
 * and prompts the user to log in. It provides a button that redirects the user to the login page.
 */
function EmailConfirmedPage() {
    const navigate = useNavigate();
    
    const redirectToLogin = async (event) => {
        event.preventDefault();
        navigate('/login');
    }

    return(
        <div className={`${style.auth_container} ${style.reset}`}>
            <form className={style.centered}>
                <span className={style.successText}>Your registation has been confirmed</span>
                <button className={style.button} onClickCapture={(event) => redirectToLogin(event)}>Log In</button>
            </form>
        </div>
    )
}