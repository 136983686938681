const sports = [
  "football",
  "basketball",
  "baseball",
  "mma",
  "boxing",
  "hockey",
  "soccer",
  "tennis",
  "golf",
  "rugby",
  "volleyball",
  "esports"
];

export default sports;