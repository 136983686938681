import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
import colors from '../utils/colors';

function formatValueText(value) {
  if (value === 50)
    return `${value}+%`;
  return `${value}%`;
}

const minDistance = 1;

function SliderComponent({value, setValue, maxValue}) {
  const handleChange = (event, newValue, activeThumb) => {
    if (maxValue) {
      if (newValue[1] > maxValue) {
        newValue[1] = maxValue;
      }

      if ((newValue[0] + minDistance) > maxValue) {
        newValue[0] = maxValue - minDistance;
      }
    }

    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 50 - minDistance);
        setValue([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue([clamped - minDistance, clamped]);
      }
    } else {
      setValue(newValue);
    }
  };

  if (value[0] === null || value[1] === null) {
    value = [0, 50];
  }

  return (
    <Slider
      getAriaLabel={() => 'Minimum distance shift'}
      value={value}
      onChange={handleChange}
      valueLabelDisplay="auto"
      getAriaValueText={formatValueText}
      valueLabelFormat={formatValueText}
      sx={{
        '& .MuiSlider-thumb': {
          color: colors.green
        },
        '& .MuiSlider-track': {
          color: colors.green
        },
        '& .MuiSlider-rail': {
          color: "#acc4e4"
        },
      }}
      disableSwap
      min={0}
      max={50}
    />
  );
}

export default SliderComponent;