import React, { useState, useEffect } from 'react';
import style from './style/NotificationsStyle.module.scss';

import { styled } from '@mui/material/styles';
import colors from '../utils/colors';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { useSelector, useDispatch } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { formatDateString } from './../utils/time_converter';

import { removeNotifications, deleteNotifications } from './../features/user/userSlice';

export default function Notifications(params) {
    const dispatch = useDispatch();
    const notifications = useSelector(state => state.user.notifications);
    const [selectedNotifications, setSelectedNotifications] = useState([]);

    const CustomCheckbox = styled(Checkbox)({
        color: 'white', // Border color
        '&.Mui-checked': {
          color: colors.green,
        },
    });

    function handleCheckboxChange(id) {
        if (selectedNotifications.includes(id)) {
            setSelectedNotifications(selectedNotifications.filter(item => item !== id));
        } else {
            setSelectedNotifications([...selectedNotifications, id]);
        }
    }

    function handleSelectAllChange() {
        if (selectedNotifications.length === notifications.length) {
            setSelectedNotifications([]);
        } else {
            setSelectedNotifications(notifications.map(n => n.id));
        }
    }

    async function handleRemove() {
        dispatch(removeNotifications(selectedNotifications));
        await dispatch(deleteNotifications(selectedNotifications));
    }

    return(
        <div className={style.notifications_page}>
            <span className={style.notifications_header}>Notifications</span>
            <div className={style.warning_wrapper}>
                <img src={require('./img/warning.png')} alt="" />
                <span>Notifications are permanently deleted from this storage after 24 hours.</span>
            </div>
            <div className={style.setting_Wrapper}>
                <div className={style.item}>
                    <FormControlLabel 
                        control={
                        <CustomCheckbox 
                            checked={selectedNotifications.length === notifications.length}
                            onChange={handleSelectAllChange}
                        />
                        } 
                        label={'Select All'} 
                    />
                    <span className={style.deselect}>
                        Deselect All
                    </span>
                    <span className={style.remove} onClick={handleRemove}>
                        <img src={require('./img/trash.png')} alt="Close icon" />
                    </span>
                    <div className={style.dropdown_button}>
                        <Dropdown>
                            <Menu slots={{ listbox: Listbox }}>
                                <MenuItem>Profile</MenuItem>
                                <MenuItem>Language settings</MenuItem>
                                <MenuItem>Log out</MenuItem>
                            </Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className={style.item}>
                    <div className={style.dropdown_button}>
                        <Dropdown>
                            <MenuButton>
                                <img src={require('./img/filter_white.png')} alt="" />
                                <span>Alphabetically, A-Z</span>
                                <img className='arrow' src={require('./img/arrow_white.png')} alt="" />
                            </MenuButton>

                            <Menu slots={{ listbox: Listbox }}>
                                <MenuItem>Alphabetically, A-Z</MenuItem>
                                <MenuItem>Alphabetically, Z-A</MenuItem>
                            </Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className={style.notifications_table}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="center">Date/Time</TableCell>
                            <TableCell align="center">Category</TableCell>
                            <TableCell align="center">Bet Type</TableCell>
                            <TableCell align="center">Notification</TableCell>
                            <TableCell align="center">Saved Filter</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {notifications.map((row) => (
                            <TableRow 
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, color: row.new ? 'green' : '#fff' }}
                            >
                                <TableCell align="center">{formatDateString(row.createdAt)}</TableCell>
                                <TableCell align="center">{row.category}</TableCell>
                                <TableCell align="center">{row.betType}</TableCell>
                                <TableCell align="center">{row.notification}</TableCell>
                                <TableCell align="center">{row.filter}</TableCell>
                                <TableCell align="center">
                                <CustomCheckbox 
                                    checked={selectedNotifications.includes(row.id)}
                                    onChange={() => handleCheckboxChange(row.id)}
                                />
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}


const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#99CCF3',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E6',
    700: '#0059B3',
    800: '#004C99',
    900: '#003A75',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  

const Listbox = styled('ul')(
    ({ theme }) => `
    font-size: 14px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    min-width: 200px;
    border-radius: 5px; 
    overflow: auto;
    outline: 0px;
    background: #383838;
    border: none;
    color: white;
    box-shadow: none;
    z-index: 1;
    `,
  );
  
  const MenuItem = styled(BaseMenuItem)(
    ({ theme }) => `
    list-style: none;
    padding: 12px;
    border-radius: 0;
    cursor: default;
    user-select: none;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${menuItemClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    }
  
    &.${menuItemClasses.disabled} {
      color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${menuItemClasses.disabled}) {
      background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[50]};
      color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
    }
    `,
  );
  
  const MenuButton = styled(BaseMenuButton)(
    ({ theme }) => `
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    padding: 8px 16px;
    color: white;
    cursor: pointer;
    background: transparent;
    border: none;
    font-weight: 600;

    &.Mui-expanded {
        .arrow {
            transform: rotate(180deg);
        }
    }
    `,
  );