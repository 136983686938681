import React, { useState, useEffect } from 'react';
import style from '../style/MainAdminStyles.module.scss';
import SelectComponent from '../../SelectComponent';
import { resetPassword } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { updateUser, blockUsers, unblockUsers, deleteUsers, fetchAllUsers } from './../../../features/admins/adminsSlice';
import { useSelector, useDispatch } from 'react-redux';

export default function UserDetails({selectedUser}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState(selectedUser.firstName);
    const [lastName, setLastName] = useState(selectedUser.lastName);
    const [email, setEmail] = useState(selectedUser.email);
    const [telNumber, setTelNumber] = useState(selectedUser.telNumber);
    const [subscription, setSubscription] = useState(selectedUser.subscription_name);
    const [image, setImage] = useState(selectedUser.imageUrl);
    const [isBlocked, setIsBlocked] = useState(selectedUser.isBlocked);

    const [info, setInfo] = useState('');

    const changeFirstName = (e) => {
        setFirstName(e.target.value);
    }

    const changeLastName = (e) => {
        setLastName(e.target.value);
    }

    const changeEmail = (e) => {
        setEmail(e.target.value);
    }

    const changeTel = (e) => {
        setTelNumber(e.target.value);
    }

    const changeSubscription = (e) => {
        setSubscription(e);
    }

    const sendLink = async () => {
        try {
            await resetPassword({ username : selectedUser.email});
            const now = new Date();

            // Преобразуем дату и время в строку
            const dateString = now.toLocaleDateString(); // Получаем дату в локальном формате
            const timeString = now.toLocaleTimeString(); // Получаем время в локальном формате

            setInfo(<span className={style.info}>
                A password reset link was sent to <span className={style.special}>{selectedUser.email}</span><br />
                <span className={style.subtext}>on {dateString} at {timeString}</span>
            </span>);
        } catch (error) {
            setInfo('Error: ', error.message);
        }
    }

    const handleUpdateUser = () => {
        dispatch(updateUser({
            id: selectedUser.id,
            firstName,
            lastName,
            email,
            telNumber,
            subscription_name: subscription
        }));
    }

    const [modularIsOpen, setModularIsOpen] = useState(false);

    const blockUser = () => {
        setModularIsOpen(false);
        dispatch(blockUsers([selectedUser.id]));
        setIsBlocked(!isBlocked);
        dispatch(fetchAllUsers());
    }

    const unblockUser = () => {
        setModularIsOpen(false);
        dispatch(unblockUsers([selectedUser.id]));
        setIsBlocked(!isBlocked);
        dispatch(fetchAllUsers());
    }

    const deleteUser = () => {
        setModularIsOpen(false);
        dispatch(deleteUsers([selectedUser.id]));
        dispatch(fetchAllUsers());
        navigate('/admin/');
    }

    return(
        <>
            <div className={style.user_info}>
                <div className={style.left_column}>
                    <div className={style.account_img}>
                        <img 
                            src={typeof image === 'string' ? `${process.env.REACT_APP_API_URL}images/${image}` : require('./../../account/img/account_temp.png')} 
                            className={style.main_img} alt="" 
                        />
                    </div>
                    <div className={style.inputs_wrapper}>
                        <div className={`${style.item} ${style.disabledItem}`}>
                            <label htmlFor="account_first_name">First Name</label>
                            <input 
                                type="text" 
                                id='account_first_name' 
                                placeholder='First Name' 
                                value={firstName}
                                onChange={changeFirstName}
                            />
                        </div>
                        <div className={`${style.item} ${style.disabledItem}`}>
                            <label htmlFor="account_last_name">Last Name</label>
                            <input 
                                type="text" 
                                id='account_last_name' 
                                placeholder='Last Name' 
                                value={lastName}
                                onChange={changeLastName}
                            />
                        </div>
                        <div className={`${style.item} ${style.disabledItem}`}>
                            <label htmlFor="account_email">Email</label>
                            <input 
                                type="email" 
                                id='account_email' 
                                placeholder='Email' 
                                value={email}
                                onChange={changeEmail}
                            />
                        </div>
                        <div className={`${style.item} ${style.disabledItem}`}>
                            <label htmlFor="account_phone">Phone</label>
                            <input 
                                type="phone" 
                                id='account_phone' 
                                placeholder='Phone' 
                                value={telNumber}
                                onChange={changeTel}
                            />
                        </div>
                        <div className={style.item}>
                            <label>Reset Password</label>
                            <button className={style.send_link} onClick={sendLink}>
                                Send Link to User
                            </button>
                            { info }
                        </div>
                    </div>
                </div>
                <div className={style.center_column}>
                    <div className={style.inputs_wrapper}>
                        <div className={`${style.item} ${'admin_select'}`}>
                            <label>Change Subscription</label>
                            <SelectComponent
                                label="Change Subscription" 
                                data={['premium', 'premium year', 'basic', 'basic year', 'free', 'superadmin']}
                                selectedData={subscription}
                                onChange={changeSubscription}
                            ></SelectComponent>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.action_wrapper}>
                {
                    isBlocked ?
                    <div className={style.secondary_btn} onClick={() => setModularIsOpen('unblock')}>
                        <img src={require('./img/unblock.png')} style={{width: '20px'}} alt="Unblock icon" />
                        <span>Unblock User</span>
                    </div>
                    :
                    <div className={style.secondary_btn} onClick={() => setModularIsOpen('block')}>
                        <img src={require('./img/block.png')} alt="Block icon" />
                        <span>Block User</span>
                    </div>
                }
                <div className={style.secondary_btn} onClick={() => setModularIsOpen('delete')}>
                    <img src={require('./img/delete.png')} alt="Delete icon" />
                    <span className={style.red}>Delete User</span>
                </div>
                <button className={style.main_button} onClick={handleUpdateUser}>
                    Update Profile
                </button>
            </div>
            {
                modularIsOpen === 'block' ?
                <div className={style.module}>
                    <div className={style.module_wrapper}>
                        <div className={style.content}>
                            <img src={require('./../img/block.png')} alt="" />
                            <span>Are you sure you want to <span className={style.red}>block</span> user?</span>
                        </div>
                        <div className={style.info}>
                            Once blocked, their information will remain in the system and they won’t be able to carry out any further actions or register with the same Email address.
                        </div>
                        <div className={style.action_block}>
                            <button className={style.left} onClick={() => setModularIsOpen(false)}>Cancel</button>
                            <button className={style.right} onClick={blockUser}>Block</button>
                        </div>
                    </div>
                </div> : ''
            }
            {
                modularIsOpen === 'unblock' ?
                <div className={style.module}>
                    <div className={style.module_wrapper}>
                        <div className={style.content}>
                            <img src={require('./../img/unblock.png')} alt="" />
                            <span>Are you sure you want to <span className={style.red}>unblock</span> user?</span>
                        </div>
                        <div className={style.info}>
                            
                        </div>
                        <div className={style.action_block}>
                            <button className={style.left} onClick={() => setModularIsOpen(false)}>Cancel</button>
                            <button className={style.right} onClick={unblockUser}>Unblock</button>
                        </div>
                    </div>
                </div> : ''
            }
            {
                modularIsOpen === 'delete' ?
                <div className={style.module}>
                    <div className={style.module_wrapper}>
                        <div className={style.content}>
                            <img src={require('./../img/trashbox.png')} alt="" />
                            <span>Are you sure you want to <span className={style.red}>delete</span> user?</span>
                        </div>
                        <div className={style.info}>
                            
                        </div>
                        <div className={style.action_block}>
                            <button className={style.left} onClick={() => setModularIsOpen(false)}>Cancel</button>
                            <button className={style.right} onClick={deleteUser}>Delete</button>
                        </div>
                    </div>
                </div> : ''
            }
        </>
    )
}