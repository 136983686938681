import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import store from './store';
import './App.css';
import './components/style/global.scss';
import MainPage from './pages/MainPage';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MainPage></MainPage>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
