import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import bookmakers from './../../utils/bookmakers';
import sports from './../../utils/sports';

import { 
  updateBookmakers,
  updateSports,
  updateAll
} from './../preferences/preferencesSlice';

const defaultPercentageRange = [0, 50];
const defaultGamePeriod = 10;
const defaultBetPeriod = 24;
const defaultTypes = ["surebet-2way", "surebet-3way", "surebet-whole", "surebet-middle", "surebet-quarter"];

// Asynchronous thunk to get user filters
export const fetchUserFilters = createAsyncThunk(
  'filter/fetchUserFilters',
  async (userSub, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/filters/${userSub}`);
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const userFilters = await response.json();
      
      const objectMap = userFilters.reduce((obj, item) => {
        obj[item.name] = item;
        return obj;
      }, {});
      
      return objectMap;
    } catch (error) {
      console.error('Error fetching user filters:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const addFilter = createAsyncThunk(
  'filter/addFilter',
  async (filterData, { dispatch, getState }) => {
    const userSub = localStorage.getItem('userSub');
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/filters`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({userSub, filterData}),
    });

    if (!response.ok) {
      throw new Error('Could not update user filter');
    }

    const filter = await response.json();
    return {
      [filter?.name]: filter
    }
  }
);

export const deleteFilter = createAsyncThunk(
  'filter/deleteFilter',
  async (filterName, { getState }) => {
    const userSub = localStorage.getItem('userSub');
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/filters/${userSub}/${filterName}`, {
      method: 'DELETE'
    });

    if (!response.ok) {
      throw new Error('Could not delete user filter');
    }

    // Returning the name of the removed filter so that the state in the reducer can be updated
    return filterName;
  }
);

export const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    isInitialLoading: true,
    isLoadingMore: false,
    types: defaultTypes,
    allSports: sports,
    selectedSports: sports,
    allBookmakers: bookmakers,
    selectedBookmakers: bookmakers,
    selectedRequiredBookmakers: [],
    // startDate: dayjs(1700010660000),
    // endDate: dayjs(1700010660000),
    startDate: null,
    endDate: null,
    gamePeriod: defaultGamePeriod,
    betPeriod: defaultBetPeriod,
    percentageRange: defaultPercentageRange,
    minOdds: -10000,
    maxOdds: 10000,
    userFilters: {},
    selectedFilterId: null,
    status: 'idle', // request status
    error: null,
  },
  reducers: {
    setIsInitialLoading: (state, message) => {
      state.isInitialLoading = message.payload;
    },
    setIsLoadingMore: (state, message) => {
      state.isLoadingMore = message.payload;
    },
    // set all sports displayed in filter block
    setAllSports: (state, message) => {
      state.allSports = message.payload;
    },
    // set selected sports displayed in filter block
    setSelectedSports: (state, message) => {
      state.selectedSports = message.payload;
    },
    // set all bookmakers displayed in filter block
    setAllBookmakers: (state, message) => {
      state.allBookmakers = message.payload;
    },
    // set selected bookmakers displayed in filter block
    setSelectedBookmakers: (state, message) => {
      state.selectedBookmakers = message.payload;
    },
    // set selected required bookmakers displayed in filter block
    setSelectedRequiredBookmakers: (state, message) => {
      state.selectedRequiredBookmakers = message.payload;
    },
    // set precentage range displayed in filter block
    setRange: (state, message) => {
      state.percentageRange = message.payload;
    },
    // set data range for events
    setStartDate: (state, message) => {
      state.startDate = dayjs(message.payload * 1000);
    },
    setEndDate: (state, message) => {
      state.endDate = dayjs(message.payload * 1000);
    },
    // set time period for events
    setGamePeriod: (state, message) => {
      state.gamePeriod = Number(message.payload);
    },
    // set types or arb bets
    setTypes: (state, message) => {
      state.types = message.payload;
    },
    // set time of validity for arb bets
    setBetPeriod: (state, message) => {
      state.betPeriod = message.payload;
    },
    // set filter id
    setSelectedFilterId: (state, message) => {
      state.selectedFilterId = message.payload;
    },
    setMinOdds: (state, message) => {
      state.minOdds = message.payload;
    },
    setMaxOdds: (state, message) => {
      state.maxOdds = message.payload;
    },
    setToDefault: (state, message) => {
      state.types = defaultTypes;
      state.selectedSports = state.allSports;
      state.selectedBookmakers = state.allBookmakers;
      state.percentageRange = message.payload?.percentage || defaultPercentageRange;
      state.gamePeriod = message.payload?.period || defaultGamePeriod;
      state.betPeriod = defaultBetPeriod;
      state.selectedRequiredBookmakers = message.payload?.required || [];
      state.startDate = null;
      state.endDate = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateBookmakers, (state, action) => {
      state.allBookmakers = action.payload || bookmakers;
      state.selectedBookmakers = action.payload || bookmakers;
    });
    builder.addCase(updateSports, (state, action) => {
      state.allSports = action.payload || sports;
      state.selectedSports = action.payload || sports;
    });
    builder.addCase(updateAll, (state, action) => {
      const data = action.payload;
      if (data) {
        if (data.bookmakers) {
          state.allBookmakers = data.bookmakers || bookmakers;
          state.selectedBookmakers = data.bookmakers || bookmakers;
        }
        if (data.requiredBookmakers) {
          state.selectedRequiredBookmakers = data.requiredBookmakers || [];
        }
        if (data.sports) {
          state.allSports = data.sports || sports;
          state.selectedSports = data.sports || sports;
        }
        
        if (data.minPercentage) {
          if (
            data.minPercentage && 
            data.minPercentage >= defaultPercentageRange[0] && 
            data.minPercentage <= defaultPercentageRange[1]
          ) {
            state.percentageRange = [data.minPercentage, state.percentageRange[1]];
          } else {
            state.percentageRange = [defaultPercentageRange[0], state.percentageRange[1]];
          }
        }
        if (data.maxPercentage) {
          if (data.maxPercentage && 
            data.maxPercentage >= defaultPercentageRange[0] && 
            data.maxPercentage <= defaultPercentageRange[1]
          ) {
            state.percentageRange = [state.percentageRange[0], data.maxPercentage];
          } else {
            state.percentageRange = [state.percentageRange[0], defaultPercentageRange[1]];
          }
        }
        if (data.timeToMatch) {
          state.gamePeriod = data.timeToMatch || 10;
        }
        if (data.minOdds) {
          state.minOdds = data.minOdds;
        }
        if (data.maxOdds) {
          state.maxOdds = data.maxOdds;
        }
      }
    });
    // getting filters data
    builder.addCase(fetchUserFilters.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchUserFilters.fulfilled, (state, action) => {
      state.status = 'succeeded';

      state.userFilters = action.payload;
    });
    builder.addCase(fetchUserFilters.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
    // adding new filter
    builder.addCase(addFilter.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(addFilter.fulfilled, (state, action) => {
      state.status = 'succeeded';

      state.userFilters = {...state.userFilters, ...action.payload};
    });
    builder.addCase(addFilter.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
    // removing filter
    builder.addCase(deleteFilter.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(deleteFilter.fulfilled, (state, action) => {
      state.status = 'succeeded';

      delete state.userFilters[action.payload];
    });
    builder.addCase(deleteFilter.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  }
});

export const { 
  setIsInitialLoading,
  setIsLoadingMore,
  setAllSports,
  setSelectedSports, 
  setAllBookmakers,
  setSelectedBookmakers, 
  setSelectedRequiredBookmakers,
  setRange, 
  setStartDate, 
  setEndDate,
  setGamePeriod,
  setTypes,
  setBetPeriod,
  setToDefault,
  setSelectedFilterId,
  setMinOdds,
  setMaxOdds
} = filterSlice.actions;

export default filterSlice.reducer;