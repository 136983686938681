import style from '../style/MainAdminStyles.module.scss';
import SelectComponent from '../../SelectComponent';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { signUp } from 'aws-amplify/auth';
import { createUser } from './../../../features/admins/adminsSlice';

export default function UserCreation(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [telNumber, setTelNumber] = useState('');
    const [password, setPassword] = useState('');
    const [subscription, setSubscription] = useState('free');
    //const [image, setImage] = useState(selectedUser.imageUrl);

    const [error, setError] = useState(null);

    const changeFirstName = (e) => {
        setFirstName(e.target.value);
    }

    const changeLastName = (e) => {
        setLastName(e.target.value);
    }

    const changeEmail = (e) => {
        setEmail(e.target.value);
    }

    const changeTel = (e) => {
        setTelNumber(e.target.value);
    }

    const changePass = (e) => {
        setPassword(e.target.value);
    }

    const changeSubscription = (e) => {
        setSubscription(e);
    }

    const handleCreateUser = async () => {
        let userSub = null;
        setError(null);
        try {
            const { userId } = await signUp({
                username: email,
                password: password,
                options: {
                    userAttributes: {
                        email: email,
                        given_name: firstName,
                        family_name: lastName,
                    },
                },
            });
            userSub = userId;
        } catch (error) {
            console.log(error)
            setError(error.message);
        }

        if (!error && userSub) {
            dispatch(createUser({
                userSub,
                firstName,
                lastName,
                email,
                telNumber,
                subscription_name: subscription
            }));

            navigate('/admin/');
        }
    };

    return(
        <>
            
            <div className={style.close_button}>
                <img src={require('./img/back.png')} alt="" />
            </div>
            <div className={`${style.container} ${style.user}`}>
                <div className={style.user_info}>
                    <div className={style.left_column}>
                        
                        <div className={style.inputs_wrapper}>
                            <div className={style.item}>
                                <label htmlFor="account_first_name">First Name</label>
                                <input 
                                    type="text" 
                                    id='account_first_name' 
                                    placeholder='First Name' 
                                    value={firstName}
                                    onChange={changeFirstName}
                                />
                            </div>
                            <div className={style.item}>
                                <label htmlFor="account_last_name">Last Name</label>
                                <input 
                                    type="text" 
                                    id='account_last_name' 
                                    placeholder='Last Name' 
                                    value={lastName}
                                    onChange={changeLastName}
                                />
                            </div>
                            <div className={style.item}>
                                <label htmlFor="account_phone">Phone</label>
                                <input 
                                    type="phone" 
                                    id='account_phone' 
                                    placeholder='Phone' 
                                    value={telNumber}
                                    onChange={changeTel}
                                />
                            </div>
                            <div className={style.item}>
                                <label htmlFor="account_email">Email</label>
                                <input 
                                    type="email" 
                                    id='account_email' 
                                    placeholder='Email' 
                                    value={email}
                                    onChange={changeEmail}
                                />
                            </div>
                            
                            {
                                /*
                                <div className={style.item}>
                                    <label>Create Password</label>
                                    <button className={style.send_link} onClick={() => {}}>
                                        Send Link to User
                                    </button>
                                    {  }
                                </div>
                                */
                            }
                            <div className={style.item}>
                                <label htmlFor="account_phone">Create Password Manually</label>
                                <input 
                                    type="password" 
                                    id='password' 
                                    placeholder='Password' 
                                    value={password}
                                    onChange={changePass}
                                />
                            </div>
                            <div style={{color: '#FF5252'}}>
                                {error}
                            </div>
                        </div>
                    </div>
                    <div className={style.center_column}>
                        <div className={style.inputs_wrapper}>
                            <div className={`${style.item} ${'admin_select'}`}>
                                <label>Change Subscription</label>
                                <SelectComponent
                                    label="Change Subscription" 
                                    data={['premium', 'premium year', 'basic', 'basic year', 'free', 'superadmin']}
                                    selectedData={subscription}
                                    onChange={changeSubscription}
                                ></SelectComponent>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.action_wrapper}>
                    <button className={style.main_button} onClick={handleCreateUser}>
                        Create User
                    </button>
                </div>
            </div>
        </>
    )
}