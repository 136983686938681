export default function calculateArbitrage(total, odds, fixedType, fixedValue, isRoundingOn, roundingStep = 1) {
  function roundToFixed(value, decimals) {
    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
  }
  
  if (odds.length == 2) {
    const [odds1, odds2] = odds;

    // Function for rounding up the bet
    function roundUp(total) {
      return Math.ceil(total / roundingStep) * roundingStep;
    }

    function roundDown(total) {
      return Math.floor(total / roundingStep) * roundingStep;
    }

    // calculate the inverse values of the coefficients
    let inverseOdds1 = 1 / odds1;
    let inverseOdds2 = 1 / odds2;

    let bet1, bet2;

    if (fixedType === 1) {
      bet1 = fixedValue;
      if (isRoundingOn) {
        bet2 = roundDown((inverseOdds2 / inverseOdds1) * bet1);
      } else {
        bet2 = (inverseOdds2 / inverseOdds1) * bet1;
      }

      total = bet1 + bet2;
    } else if (fixedType === 2) {
      bet2 = fixedValue;
      if (isRoundingOn) {
        bet1 = roundDown((inverseOdds1 / inverseOdds2) * bet2);
      } else {
        bet1 = (inverseOdds1 / inverseOdds2) * bet2;
      }
      total = bet1 + bet2;
    } else {
      // calculate the total reciprocal value
      let totalInverse = inverseOdds1 + inverseOdds2;

      // calculate bets on each outcome
      if (isRoundingOn) {
        bet1 = roundDown((inverseOdds1 / totalInverse) * total);
      } else {
        bet1 = (inverseOdds1 / totalInverse) * total;
      }
      bet2 = total - bet1;
    }

    // calculate the profit percentage for each bet
    let profit1 = (bet1 * odds1) - total;
    let profitPercentage1 = (profit1 / total) * 100;

    let profit2 = (bet2 * odds2) - total;
    let profitPercentage2 = (profit2 / total) * 100;

    // Average Profit Percentage
    let averageProfitPercentage = (profitPercentage1 + profitPercentage2) / 2;

    return [
      [bet1.toFixed(2), bet2.toFixed(2)], 
      [profit1.toFixed(2), profit2.toFixed(2)], 
      [profitPercentage1.toFixed(2), profitPercentage2.toFixed(2)], 
      averageProfitPercentage.toFixed(2)
    ];
  } else if (odds.length == 3) {
    const [odds1, odds2, odds3] = odds;

    // Function for rounding up the bet
    function roundUp(total) {
      return Math.ceil(total / roundingStep) * roundingStep;
    }

    function roundDown(total) {
      return Math.floor(total / roundingStep) * roundingStep;
    }

    // calculate the inverse values of the coefficients
    let inverseOdds1 = 1 / odds1;
    let inverseOdds2 = 1 / odds2;
    let inverseOdds3 = 1 / odds3;

    let bet1, bet2, bet3;

    if (fixedType === 1) {
      bet1 = fixedValue;
      if (isRoundingOn) {
        bet2 = roundDown((bet1 * odds1 / odds2) / (1 - (1 / odds2 + 1 / odds3)));
      } else {
        bet2 = (bet1 * odds1 / odds2) / (1 - (1 / odds2 + 1 / odds3));
      }
      bet3 = total - bet1 - bet2;
    } else if (fixedType === 2) {
      bet2 = fixedValue;
      if (isRoundingOn) {
        bet1 = roundDown((bet2 * odds2 / odds1) / (1 - (1 / odds1 + 1 / odds3)));
      } else {
        bet1 = (bet2 * odds2 / odds1) / (1 - (1 / odds1 + 1 / odds3));
      }
      bet3 = total - bet1 - bet2;
    } else if (fixedType === 3) {
      bet3 = fixedValue;
      if (isRoundingOn) {
        bet1 = roundDown((bet3 * odds3 / odds1) / (1 - (1 / odds1 + 1 / odds2)));
      } else {
        bet1 = (bet3 * odds3 / odds1) / (1 - (1 / odds1 + 1 / odds2));
      }
      bet2 = total - bet3 - bet1;
    } else {
      // calculate the total reciprocal value
      let totalInverse = inverseOdds1 + inverseOdds2 + inverseOdds3;

      // calculate the profit percentage for each bet
      bet1 = (inverseOdds1 / totalInverse) * total;
      bet2 = (inverseOdds2 / totalInverse) * total;
      bet3 = total - bet2 - bet1;

      // calculate bets on each outcome
      if (isRoundingOn) {
        bet1 = roundDown((inverseOdds1 / totalInverse) * total);
        bet2 = roundDown((inverseOdds2 / totalInverse) * total);
        bet3 = total - bet2 - bet1;
      } else {
        bet1 = (inverseOdds1 / totalInverse) * total;
        bet2 = (inverseOdds2 / totalInverse) * total;
        bet3 = total - bet2 - bet1;
      }
    }

    // calculate the profit percentage for each bet
    let profit1 = (bet1 * odds1) - total;
    let profitPercentage1 = (profit1 / total) * 100;

    let profit2 = (bet2 * odds2) - total;
    let profitPercentage2 = (profit2 / total) * 100;

    let profit3 = (bet3 * odds3) - total;
    let profitPercentage3 = (profit3 / total) * 100;

    // Average Profit Percentage
    let averageProfitPercentage = (profitPercentage1 + profitPercentage2 + profitPercentage3) / 3;

    bet1 = roundToFixed(bet1, 2);
    bet2 = roundToFixed(bet2, 2);
    bet3 = roundToFixed(bet3, 2);

    const sum = bet1 + bet2 + bet3;
    if (sum !== total) {
      bet3 += (total - sum);
    }

    return [
      [bet1.toFixed(2), bet2.toFixed(2), bet3.toFixed(3)], 
      [profit1.toFixed(2), profit2.toFixed(2), profit3.toFixed(2)], 
      [profitPercentage1.toFixed(2), profitPercentage2.toFixed(2), profitPercentage3.toFixed(2)], 
      averageProfitPercentage.toFixed(2)
    ];
  }
}