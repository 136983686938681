import style from '../style/MainAdminStyles.module.scss';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import React, { useState, useEffect } from 'react';

import Collapse from '@mui/material/Collapse';

import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import prices from './../../../utils/prices';

import { fetchUserInvoices } from './../../../features/admins/adminsSlice';
import { useSelector, useDispatch } from 'react-redux';

import { styled } from '@mui/material/styles';

function getPlanNameById(planId) {
    const planName = Object.keys(prices).find(key => prices[key] === planId);
    return planName || 'Unknown Plan';
}

export default function PaymentHistory({userId}) {
    const dispatch = useDispatch();
    const invoices = useSelector(state => state.admins.invoices);
    console.log(invoices)

    useEffect(() => {
        dispatch(fetchUserInvoices(userId));
    }, []);   

    return(
        <>
            <div className={style.filter}>
                <Dropdown>
                    <MenuButton>
                        <img src={require('../img/filter.png')} alt="" />
                    </MenuButton>

                    <Menu slots={{ listbox: Listbox }}>
                        <MenuItem>Test 1</MenuItem>
                        <MenuItem>Test 2</MenuItem>
                    </Menu>
                </Dropdown>
            </div>
            <div className={style.user_table}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="center">Transaction ID</TableCell>
                            <TableCell align="center">Date/Time</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">Subscription</TableCell>
                            <TableCell align="center">Amount</TableCell>
                            <TableCell align="center">Status</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {<Row invoices={invoices.invoices ?? []} />}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}

function Row({invoices}) {
  const data = Array.from({ length: 10 });

  const [open, setOpen] = useState(data.fill(false));

  const handleToggle = (index) => {
    const newOpenState = [...open];
    newOpenState[index] = !newOpenState[index];
    setOpen(newOpenState);
  };

  return (
    <>
      {invoices.map((item, index) => (
          <React.Fragment key={index}>
              <TableRow 
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                  <TableCell align="center">{item.id}</TableCell>
                  <TableCell align="center">{
                    (new Date(item.created * 1000)).toLocaleString()
                  }</TableCell>
                  <TableCell>
                        <IconButton
                            aria-label="expand row"
                            // size="small"
                            onClick={() => handleToggle(index)}
                        >
                            {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                  </TableCell>
                  <TableCell align="center">{getPlanNameById(item?.lines?.data[0]?.plan?.id)?.toUpperCase()}</TableCell>
                  <TableCell align="center">${item.total / 100}USD</TableCell>
                  <TableCell align="center">{item.status}</TableCell>
              </TableRow>
              <TableRow style={{display: open[index] ? 'table-row' : 'none'}} className={style.table_dropdown_wrapper}>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                      <Collapse in={open[index]} timeout="auto" unmountOnExit className={style.table_dropdown}>
                          <div className={style.dropdown_wrapper}>
                              <div className={style.item_wrapper}>
                                  <div className={style.item}>
                                      <span className={style.item_name}>Subscription</span>
                                      <span className={style.info}>{getPlanNameById(item?.lines?.data[0]?.plan?.id)?.toUpperCase()}</span>
                                  </div>
                                  <div className={style.item}>
                                      <span className={style.item_name}>Amount</span>
                                      <span className={style.info}>${item.total / 100}USD</span>
                                  </div>
                                  <div className={style.item}>
                                      <span className={style.item_name}>Status</span>
                                      <span className={style.info}>{item.status}</span>
                                  </div>
                              </div>
                          </div>
                      </Collapse>
                  </TableCell>
              </TableRow>
          </React.Fragment>
      ))}
    </>
  );
}

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#99CCF3',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E6',
    700: '#0059B3',
    800: '#004C99',
    900: '#003A75',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  

const Listbox = styled('ul')(
    ({ theme }) => `
    font-size: 14px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    min-width: 200px;
    border-radius: 5px; 
    overflow: auto;
    outline: 0px;
    background: #383838;
    border: none;
    color: white;
    box-shadow: none;
    z-index: 1;
    `,
);

const MenuItem = styled(BaseMenuItem)(
    ({ theme }) => `
    list-style: none;
    padding: 12px;
    border-radius: 0;
    cursor: default;
    user-select: none;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${menuItemClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    }
  
    &.${menuItemClasses.disabled} {
      color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${menuItemClasses.disabled}) {
      background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[50]};
      color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
    }
    `,
);

const MenuButton = styled(BaseMenuButton)(
    ({ theme }) => `
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    padding: 8px 16px;
    color: white;
    cursor: pointer;
    background: transparent;
    border: none;
    font-weight: 600;

    &.Mui-expanded {
        .arrow {
            transform: rotate(180deg);
        }
    }
    `,
);