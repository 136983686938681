let bookmakers = ['BetDSI', 'BetJACK', 'ParlayPlay (6 Pick)', 'Betly', 'Betfair Exchange (Lay)', 'Desert Diamond', 'Betway (Arizona)', 
'PointsBet (New Jersey)', 'theScore', 'Wind Creek', 'TABtouch', 'Sky Bet', 'Sleeper', 'PointsBet (Iowa)', 'BetRivers', 'BetVictor', 'Bodog', 'DraftKings', 
'Ladbrokes (Australia)', 'PowerPlay', 'Betway (Virginia)', 'PointsBet (Kansas)', 'Suprabets', 'Hard Rock', 
'Betway (Canada)', 'TwinSpires', 'SI', 'BetMGM', 'Betano', 'Betfair Exchange', 'HotStreak', 'PointsBet (Michigan)', 'Fliff', 'PointsBet (Louisiana)', 'NorthStar Bets', 
'DraftKings (Tennessee)', 'BetOpenly', 'PointsBet (Ohio)', 'Sportsbet', 'Unibet (Australia)', 'WynnBET (Michigan)', 'Betway', 
'Betsafe', 'BetDSI (Superbook)', 'LowVig', 'Circa Sports', 'Golden Nugget (New Jersey)', 'WynnBET', 'PlayNow', 'Prophet Exchange', 
'Sports Interaction', 'PointsBet (Colorado)', 'MyBookie', 'PointsBet (Pennsylvania)', 'SugarHouse', 'Xbet', '10bet', 'BetRivers (New York)', 'FanDuel', 'Play Eagle', 
'Betfred', 'Fanatics', 'WynnBET (New York)', 'Golden Nugget', 'Barstool', 'ClutchBet', 'William Hill', 'Neds', 'Ladbrokes', 'BookMaker', 'Betway (Ohio)', 
'PointsBet (Illinois)', 'Caesars', 'Sporttrade', 'SX Bet', 'Smarkets', 'Caesars (Ontario)', 'Play Alberta', 'Borgata', 
'Golden Nugget (Michigan)', 'BetOnline', 'Resorts World Bet', 'Action 24/7', 'BetOpenly (Instant DFS)', 'betPARX', 
'William Hill (United Kingdom)', 'Coral', 'Tipico', 'BET99', 'Mise-o-jeu', 'TAB (New Zealand)', 'Proline', '888sport (Canada)', 
'Four Winds', 'Unibet (United Kingdom)', 'Betly (Tennessee)', 'ThriveFantasy', 'PointsBet (West Virginia)', 'PrizePicks', 'PointsBet (Maryland)', 'PointsBet (Ontario)', 
'Paddy Power', 'Kutt', 'PointsBet (Indiana)', 'PointsBet (Australia)', 'Stake', 'bet365', 'partypoker', 'Casumo', 
'Pinnacle', 'BetAnySports', 'DraftKings (Illinois)', 'Circa Vegas', 'Unibet', 'BetUS', 'Underdog Fantasy', 'PointsBet (New York)', 'Bally Bet', 'bwin', 'TAB', 'FanDuel (Ontario)', 
'DRF', 'PointsBet (Virginia)', 'SuperBook', 'Bovada', 'BetUK', 'FireKeepers', 'Sporttrade (Colorado)', 'LeoVegas', 'SBK', 'TonyBet', 'ESPN BET', '1XBet', '888sport', 'Codere', 'BetDEX', 'DraftKings (Pick 6)', 'Underdog Sportsbook', 'ParlayPlay', 'Pinny'];

export default bookmakers;