import style from './style/MainAdminStyles.module.scss';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import colors from '../../utils/colors';

import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate  } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import Collapse from '@mui/material/Collapse';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { fetchAllUsers, blockUsers, unblockUsers, deleteUsers, setSelectedUser } from './../../features/admins/adminsSlice';
import { useSelector, useDispatch } from 'react-redux';

const CustomCheckbox = styled(Checkbox)({
    color: 'white', // Border color
    '&.Mui-checked': {
      color: colors.green,
    },
});

export default function MainAccount(props) {
    const dispatch = useDispatch();
    const [selectedLetter, setSelectedLetter] = useState('');
    const [searchValue, handleSearchChange] = useState('');
    const [modularIsOpen, setModularIsOpen] = useState(false);

    const users = useSelector(state => state.admins.users);

    useEffect(() => {
        // getting user bets
        dispatch(fetchAllUsers());
    }, []);
    
    const letterArray = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const navigate = useNavigate();

    const showUser = (user) => {
        dispatch(setSelectedUser(user));
        navigate('/admin/user');
    }

    const handleLetterClick = (letter) => {
        if (selectedLetter === letter) {
            setSelectedLetter('');
        } else {
            setSelectedLetter(letter);
        }
    };

    const [selectedUsers, setSelectedUsers] = useState([]);

    const [isSelectedAll, setSelectedAll] = useState(false);

    const handleSelectAll = () => {
        setSelectedAll(!isSelectedAll);
    }

    const deselectAll = () => {
        setSelectedUsers([]);
    }

    const blockSelectedUsers = () => {
        setModularIsOpen(false);
        dispatch(blockUsers(selectedUsers));
        dispatch(fetchAllUsers());
        setSelectedUsers([]);
    }

    const unblockSelectedUsers = () => {
        setModularIsOpen(false);
        dispatch(unblockUsers(selectedUsers));
        dispatch(fetchAllUsers());
        setSelectedUsers([]);
    }

    const deleteSelectedUsers = () => {
        setModularIsOpen(false);
        dispatch(deleteUsers(selectedUsers));
        dispatch(fetchAllUsers());
        setSelectedUsers([]);
    }

    const [sorting, setSorting] = useState('Creation, Oldest to Newest');
    const [filtering, setFiltering] = useState('All Subscriptions');

    return(
        <>
            <div className={`${style.container} ${style.user_list}`}>
                <div className={style.navigation_block}>
                    <div className={style.letter_wrapper}>
                        {
                            letterArray.map((item, index) => 
                                <span 
                                    className={`${style.letter} ${selectedLetter === item ? style.selected_letter : ''}`} 
                                    key={index} onClick={() => handleLetterClick(item)}
                                >
                                    {item}
                                </span>
                            )
                        }
                    </div>
                    <div className={style.right_block}>
                        <div className={style.input_block}>
                            <input 
                                type="text" 
                                id='users' 
                                placeholder='Search user by email' 
                                value={searchValue}
                                onChange={(e) => handleSearchChange(e.target.value)}
                            />
                            {/* {inputValue && (
                                <ul className={style.autocomplete_list}>
                                {filteredBookmakers.map(bookmaker => (
                                    <li key={bookmaker} onClick={() => handleSelect(bookmaker)}>
                                        {bookmaker}
                                    </li>
                                ))}
                                </ul>
                            )} */}
                            <img src={require('./img/search.png')} alt="" />
                        </div>
                        <div className={style.info_text}>
                            <span style={{color: 'red'}}>*</span>non-paying users with Basic or Premium subscription
                        </div>
                    </div>
                </div>
                
                <div className={style.setting_Wrapper}>
                    <div className={style.item}>
                        <FormControlLabel 
                            control={
                            <CustomCheckbox 
                                checked={isSelectedAll}
                                onChange={() => handleSelectAll()}
                            />
                            } 
                            label={'Select All'} 
                        />
                        <span className={style.deselect} onClick={deselectAll}>
                            <img src={require('./img/close.png')} alt="" />
                            Deselect All
                        </span>
                        <div className={style.dropdown_button}>
                            <Dropdown>
                                <MenuButton>
                                    <span>Actions</span>
                                    <img className='arrow' src={require('./img/arrow.png')} alt="" />
                                </MenuButton>

                                <Menu slots={{ listbox: Listbox }}>
                                    <MenuItem onClick={() => setModularIsOpen('block')}>
                                        <img className={style.button_img} src={require('./img/block.png')} alt="" />Block User(s)
                                    </MenuItem>
                                    <MenuItem onClick={() => setModularIsOpen('unblock')}>
                                        <img className={style.button_img} src={require('./img/unblock.png')} alt="" />Unblock User(s)
                                    </MenuItem>
                                    <MenuItem onClick={() => setModularIsOpen('delete')}>
                                        <img className={style.button_img} src={require('./img/trashbox.png')} alt="" />Delete User(s)
                                    </MenuItem>
                                </Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className={style.item}>
                        <div className={`${style.dropdown_button} ${style.filter_dropdown}`}>
                            <Dropdown>
                                <MenuButton>
                                    <span>{filtering}</span>
                                    <img className='arrow' src={require('./img/arrow.png')} alt="" />
                                </MenuButton>

                                <Menu slots={{ listbox: Listbox }}>
                                    <MenuItem onClick={() => setFiltering('All Subscriptions')}>All Subscriptions</MenuItem>
                                    <MenuItem onClick={() => setFiltering('Free')}>Free</MenuItem>
                                    <MenuItem onClick={() => setFiltering('Basic')}>Basic</MenuItem>
                                    <MenuItem onClick={() => setFiltering('Premium')}>Premium</MenuItem>
                                    <MenuItem onClick={() => setFiltering('Non-Paying')}>Non-Paying</MenuItem>
                                </Menu>
                            </Dropdown>
                        </div>
                        <div className={`${style.dropdown_button} ${style.filter_dropdown}`}>
                            <Dropdown>
                                <MenuButton>
                                    <img src={require('./img/filter.png')} alt="" />
                                    <span>{sorting}</span>
                                    <img className='arrow' src={require('./img/arrow.png')} alt="" />
                                </MenuButton>

                                <Menu slots={{ listbox: Listbox }}>
                                    <MenuItem onClick={() => setSorting('Creation, Oldest to Newest')}>Creation, Oldest to Newest</MenuItem>
                                    <MenuItem onClick={() => setSorting('Creation, Newest to Oldest')}>Creation, Newest to Oldest</MenuItem>
                                    <MenuItem onClick={() => setSorting('Alphabetically, A-Z')}>Alphabetically, A-Z</MenuItem>
                                    <MenuItem onClick={() => setSorting('Alphabetically, Z-A')}>Alphabetically, Z-A</MenuItem>
                                </Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className={`${style.user_table} ${style.checkbox_table}`}>
                    <div className={style.total_label}>
                        <span>Total users: {users.length}</span>
                    </div>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell align="center">User ID</TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">Phone #</TableCell>
                                {/* <TableCell align="center">Last Login</TableCell> */}
                                <TableCell align="center">First Name</TableCell>
                                <TableCell align="center">Last Name</TableCell>
                                <TableCell align="center">Blocked</TableCell>
                                <TableCell align="center">Subscription</TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {<Row 
                                    showUser={showUser} 
                                    selectedLetter={selectedLetter} 
                                    searchValue={searchValue} 
                                    isSelectedAll={isSelectedAll}
                                    setSelectedAll={() => {
                                        setSelectedAll(false);
                                    }}
                                    selectedUsers={selectedUsers}
                                    setSelectedUsers={setSelectedUsers}
                                    sorting={sorting}
                                    filtering={filtering}
                                />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            {
                modularIsOpen === 'block' ?
                <div className={style.module}>
                    <div className={style.module_wrapper}>
                        <div className={style.content}>
                            <img src={require('./img/block.png')} alt="" />
                            <span>Are you sure you want to <span className={style.red}>block</span> users?</span>
                        </div>
                        <div className={style.info}>
                            Once blocked, their information will remain in the system and they won’t be able to carry out any further actions or register with the same Email address.
                        </div>
                        <div className={style.action_block}>
                            <button className={style.left} onClick={() => setModularIsOpen(false)}>Cancel</button>
                            <button className={style.right} onClick={blockSelectedUsers}>Block</button>
                        </div>
                    </div>
                </div> : ''
            }
            {
                modularIsOpen === 'unblock' ?
                <div className={style.module}>
                    <div className={style.module_wrapper}>
                        <div className={style.content}>
                            <img src={require('./img/unblock.png')} alt="" />
                            <span>Are you sure you want to <span className={style.red}>unblock</span> users?</span>
                        </div>
                        <div className={style.info}>
                            
                        </div>
                        <div className={style.action_block}>
                            <button className={style.left} onClick={() => setModularIsOpen(false)}>Cancel</button>
                            <button className={style.right} onClick={unblockSelectedUsers}>Unblock</button>
                        </div>
                    </div>
                </div> : ''
            }
            {
                modularIsOpen === 'delete' ?
                <div className={style.module}>
                    <div className={style.module_wrapper}>
                        <div className={style.content}>
                            <img src={require('./img/trashbox.png')} alt="" />
                            <span>Are you sure you want to <span className={style.red}>delete</span> users?</span>
                        </div>
                        <div className={style.info}>
                            
                        </div>
                        <div className={style.action_block}>
                            <button className={style.left} onClick={() => setModularIsOpen(false)}>Cancel</button>
                            <button className={style.right} onClick={deleteSelectedUsers}>Delete</button>
                        </div>
                    </div>
                </div> : ''
            }
        </>
    )
}

function Row(props) {
    const data = Array.from({ length: 10 });
    const users = useSelector(state => state.admins.users);

    const filteredUsers = 
        props.selectedLetter !== '' ? users.filter(user => user.email.toLowerCase().startsWith(props.selectedLetter.toLowerCase())) : users;

    const searchedUsers = props.searchValue !== '' ? filteredUsers.filter(user => user.email.toLowerCase().startsWith(props.searchValue.toLowerCase())) : filteredUsers;
    // const searchedUsers = props.searchValue !== '' ? 
    //     filteredUsers.filter(user => user.email.toLowerCase().startsWith(props.searchValue.toLowerCase())).map(user => user.id) : [];

    let sortedUsers;
    if (props.sorting === 'Creation, Oldest to Newest') {
        sortedUsers = searchedUsers.slice().sort((a, b) => a.id - b.id);
    } else if (props.sorting === 'Creation, Newest to Oldest') {
        sortedUsers = searchedUsers.slice().sort((a, b) => b.id - a.id);
    } else if (props.sorting === 'Alphabetically, A-Z') {
        sortedUsers = searchedUsers.slice().sort((a, b) => a.email.localeCompare(b.email));
    } else if (props.sorting === 'Alphabetically, Z-A') {
        sortedUsers = searchedUsers.slice().sort((a, b) => b.email.localeCompare(a.email));
    } else {
        sortedUsers = searchedUsers;
    }

    if (props.filtering === 'Non-Paying') {
        sortedUsers = sortedUsers.filter(user => user.subscription_name !== "free" && !user.subscription_id);
    } else if (props.filtering === 'Free') {
        sortedUsers = sortedUsers.filter(user => user.subscription_name === "free");
    } else if (props.filtering === 'Basic') {
        sortedUsers = sortedUsers.filter(user => user.subscription_name.includes('basic'));
    } else if (props.filtering === 'Premium') {
        sortedUsers = sortedUsers.filter(user => user.subscription_name.includes('premium'));
    }

    const [isMobile, setIsMobile] = useState(false);
    const [open, setOpen] = useState(data.fill(false));

    const handleToggle = (index) => {
      const newOpenState = [...open];
      newOpenState[index] = !newOpenState[index];
      setOpen(newOpenState);
    };

    function handleCheckboxChange(id) {
        if (props.selectedUsers.includes(id)) {
            props.setSelectedUsers(props.selectedUsers.filter(item => item !== id));
        } else {
            props.setSelectedUsers([...props.selectedUsers, id]);
        }
    }

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            setIsMobile(screenWidth < 921);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (props.isSelectedAll) {
            props.setSelectedUsers(filteredUsers.map(u => u.id));
        }
    }, [props.isSelectedAll]);

    useEffect(() => {
        if (props.selectedUsers.length !== users.length) {
            props.setSelectedAll();
        }
    }, [props.selectedUsers]);

    return (
      <>
        {sortedUsers.map((user, index) => (
            <React.Fragment key={index}>
                <TableRow 
                    sx={{ 
                        '&:last-child td, &:last-child th': { border: 0 },
                        '& td:first-of-type': props.selectedUsers.includes(user.id) ? {
                            borderLeft: '1px solid #91E617',
                        } : { border: 0 },
                        '& td:last-of-type, & th:last-of-type': props.selectedUsers.includes(user.id) ? {
                            border: 0, 
                            borderLeft: '1px solid #91E617'
                        } : { border: 0 },
                        '& td, & th': props.selectedUsers.includes(user.id) ? { 
                            borderBottom: '1px solid #91E617', 
                            borderTop: '1px solid #91E617', 
                            //fontWeight: searchedUsers.includes(user.id) ? 'bold' : 'normal', 
                        } : {
                            border: 0, 
                            //fontWeight: searchedUsers.includes(user.id) ? 'bold' : 'normal'
                        },
                    }}
                >
                    <TableCell onClick={() => isMobile ? false : props.showUser(user)} align="center">{user.id}</TableCell>
                    <TableCell 
                        onClick={() => isMobile ? false : props.showUser(user)} 
                        align="center"
                    >{user.email}</TableCell>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            // size="small"
                            onClick={() => handleToggle(index)}
                        >
                            {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell onClick={() => isMobile ? false : props.showUser(user)} align="center">{user.telNumber ?? ''}</TableCell>
                    {/* <TableCell onClick={() => isMobile ? false : props.showUser(user)} align="center">01/02/24@ 3:11PM</TableCell> */}
                    <TableCell onClick={() => isMobile ? false : props.showUser(user)} align="center">{user.firstName}</TableCell>
                    <TableCell onClick={() => isMobile ? false : props.showUser(user)} align="center">{user.lastName}</TableCell>
                    <TableCell onClick={() => isMobile ? false : props.showUser(user)} align="center">{user.isBlocked ? 'true' : 'false'}</TableCell>
                    <TableCell onClick={() => isMobile ? false : props.showUser(user)} align="center">
                        {user.subscription_name?.[0].toUpperCase() + user.subscription_name?.substring(1)}
                        {user.subscription_name !== "free" && !user.subscription_id ? <span style={{color: 'red'}}>*</span> : ''}
                    </TableCell>
                    <TableCell align="center">
                        <CustomCheckbox 
                            checked={props.selectedUsers.includes(user.id)}
                            onChange={() => handleCheckboxChange(user.id)}
                        />
                    </TableCell>
                </TableRow>
                <TableRow style={{display: open[index] ? 'table-row' : 'none'}} className={style.table_dropdown_wrapper}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                        <Collapse in={open[index]} timeout="auto" unmountOnExit className={style.table_dropdown}>
                            <div className={style.dropdown_wrapper}>
                                <div className={style.item_wrapper}>
                                    <div className={style.item}>
                                        <span className={style.item_name}>First Name</span>
                                        <span className={style.info}>{user.firstName}</span>
                                    </div>
                                    <div className={style.item}>
                                        <span className={style.item_name}>Last Name</span>
                                        <span className={style.info}>{user.lastName}</span>
                                    </div>
                                    <div className={style.item}>
                                        <span className={style.item_name}>Phone #</span>
                                        <span className={style.info}>{user.telNumber ?? ''}</span>
                                    </div>
                                </div>
                                <div className={style.item_wrapper}>
                                    {/* <div className={style.item}>
                                        <span className={style.item_name}>Last Login</span>
                                        <span className={style.info}>01/02/24<br />@ 3:11PM</span>
                                    </div> */}
                                    <div className={style.item}>
                                        <span className={style.item_name}>Subscription</span>
                                        <span className={style.info}>
                                            {user.subscription_name?.[0].toUpperCase() + user.subscription_name?.substring(1)}
                                            {user.subscription_name !== "free" && !user.subscription_id ? <span style={{color: 'red'}}>*</span> : ''}
                                        </span>
                                    </div>
                                    <div className={`${style.item} ${style.link}`}>
                                        <span className={style.info} onClick={() => props.showUser(user)}>View Profile</span>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        ))}
      </>
    );
  }

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#99CCF3',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E6',
    700: '#0059B3',
    800: '#004C99',
    900: '#003A75',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  

const Listbox = styled('ul')(
    ({ theme }) => `
    font-size: 14px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    min-width: 200px;
    border-radius: 5px; 
    overflow: auto;
    outline: 0px;
    background: #383838;
    border: none;
    color: white;
    box-shadow: none;
    z-index: 1;
    `,
  );
  
  const MenuItem = styled(BaseMenuItem)(
    ({ theme }) => `
    list-style: none;
    padding: 12px;
    border-radius: 0;
    cursor: default;
    user-select: none;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${menuItemClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    }
  
    &.${menuItemClasses.disabled} {
      color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${menuItemClasses.disabled}) {
      background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[50]};
      color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
    }
    `,
  );
  
  const MenuButton = styled(BaseMenuButton)(
    ({ theme }) => `
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    padding: 8px 16px;
    color: white;
    cursor: pointer;
    background: transparent;
    border: none;
    font-weight: 600;

    &.Mui-expanded {
        .arrow {
            transform: rotate(180deg);
        }
    }
    `,
  );