import * as React from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import SelectComponent from './SelectComponent';
import Checkbox from '@mui/material/Checkbox';
import calculateArbitrage from './../utils/calculator';
import { convertAmericanToEuropean } from './../utils/odds_converter';

import { useSelector, useDispatch } from 'react-redux';
import { setUserBets, addUserBet } from './../features/bets/betsSlice';
import { fetchUserTransactions } from './../features/user/userSlice';
import colors from '../utils/colors';

import style from './style/CalculatorStyles.module.scss';
import { useState } from 'react';
import { useEffect } from 'react';

import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

function getRandomColor() {
  const r = Math.floor(Math.random() * 256); // Random number from 0 to 255
  const g = Math.floor(Math.random() * 256); // Random number from 0 to 255
  const b = Math.floor(Math.random() * 256); // Random number from 0 to 255
  const a = 0.6; // Set the transparency
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const CustomCheckbox = styled(Checkbox)({
  color: colors.green, // Border color
  '&.Mui-checked': {
    color: colors.green,
  },
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

/**
 * Calculator Component
 * 
 * This component serves as a calculator for arbitrage or value bets, allowing users to calculate 
 * the stake distribution and potential profits based on the selected bet's odds. It integrates 
 * with the application's Redux store to access and modify global states like user preferences, 
 * selected bets, and system settings.
 * 
 * Features include:
 * - Dynamically loading bet details from the selected bet in the global state.
 * - Adjusting stake amounts and recalculating profits on the fly.
 * - Supporting different odds formats and handling edge cases like changed odds or no longer 
 *   valid surebets.
 * - Displaying a history of odds changes for each bet.
 * - Rounding stakes to preferred units and toggling between automatic and manual stake input.
 * 
 * State Management:
 * - Utilizes local state for UI interactions like toggling the calculator visibility, managing input fields for stakes,
 *   and controlling whether specific parts of the UI are enabled or disabled based on the bet type or user's subscription.
 * 
 * Redux Store:
 * - Accesses various parts of the global state, such as user settings and the currently selected bet, to pre-populate fields
 *   and calculate stakes and profits.
 * - Dispatches actions to save calculated bets to the user's bet tracker.
 * 
 * The component structure is designed to handle both arbitrage and expected value (EV) bets, adjusting its functionality
 * and presentation according to the context in which it is used. It aims to provide a user-friendly interface for 
 * complex calculations, facilitating better decision-making for the user.
 */
export default function CustomizedTables() {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const mode = useSelector(state => state.settings.mode);
  const systemSettings = useSelector(state => state.settings.systemSettings);
  const subscriptionType = useSelector(state => state.user.subscriptionType);
  const selectedBet = useSelector(state => state.bets.selectedBet[mode]);
  const oddsFormat = useSelector(state => state.preferences.oddsFormat);
  const no_vig = useSelector(state => state.preferences.noVig);

  const [currentBet, setCurrentBet] = useState(selectedBet);

  const [rounds, setRounds] = useState(1);
  const [openCalculatorStatus, setOpenCalculatorStatus] = useState(false);
  const [buttonText, setButtonText] = useState('Add to Bets Tracker');
  const [clickedSave, setClickedSave] = useState(false);
  const [isHistoryOpened, setHistoryOpened] = useState(false);
  const [selectedBetHistory, setSelectedBetHistory] = useState(null);
  const [historyData, setHistoryData] = useState({});

  const [checkedFixed, setCheckedFixed] = useState(null);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [totalStake, setTotalStake] = useState(100);

  const [amountToStake_1, setAmountToStake_1] = useState(0);
  const [amountToStake_2, setAmountToStake_2] = useState(0);
  const [amountToStake_3, setAmountToStake_3] = useState(0);

  const [averageProfit, setAverageProfit] = useState(0);

  let amountToStakeGroups = [
    [amountToStake_1, setAmountToStake_1],
    [amountToStake_2, setAmountToStake_2],
    [amountToStake_3, setAmountToStake_3]
  ];

  const [profits, setProfits] = useState([]);
  const [profitsAbsolute, setProfitsAbsolute] = useState([]);
  const [odds, setOdds] = useState([]);

  const handleCheckboxChange = (index) => {
    if (checkedFixed === index)
      setCheckedFixed(null);
    else
      setCheckedFixed(index);
  };

  // const handleLinkClick = (deep_link_url) => {
  //   const url = deep_link_url?.replace('.<STATE>', '')?.replace('<STATE>.', '')?.replace('<COUNTRY>/<STATE>', 'us/az')?.replace('sportsbook-<STATE>.', '');
    
  //   try {
  //     window.open(url, '_blank');
  //   } catch (error) {
  //     return null;
  //   }
  // };

  const generateLink = (deep_link_url) => {
    const url = deep_link_url?.replace('.<STATE>', '')?.replace('<STATE>.', '')?.replace('<COUNTRY>/<STATE>', 'us/az')?.replace('sportsbook-<STATE>.', '');
    return url;
  }

  useEffect(() => {
    if (selectedBet) {
      if (mode !== "ev" && mode !== "evLive") {
        if (currentBet?.id !== selectedBet?.id) {
          setOpenCalculatorStatus(true);
        }
        setCurrentBet(selectedBet);

        if (selectedBet.bets.length === 2) {
          setAmountToStake_1(selectedBet.bets[0].amount_to_buy.toFixed(2));
          setAmountToStake_2(selectedBet.bets[1].amount_to_buy.toFixed(2));
        } else if (selectedBet.bets.length === 3) {
          setAmountToStake_1(selectedBet.bets[0].amount_to_buy.toFixed(2));
          setAmountToStake_2(selectedBet.bets[1].amount_to_buy.toFixed(2));
          setAmountToStake_3(selectedBet.bets[2].amount_to_buy.toFixed(2));
        }
        
        setOdds(selectedBet.bets.map((row) => row.decimal_price));
        setProfitsAbsolute(selectedBet.bets.map((row) => row.expected_earnings.toFixed(2)));
        setProfits(selectedBet.bets.map((row) => row.expected_earnings.toFixed(2)));
      } else if (mode === "ev" || mode === "evLive") {
        if (currentBet?.id !== selectedBet?.id) {
          setOpenCalculatorStatus(true);
        }
        setCurrentBet(selectedBet);
      }
    }
  }, [selectedBet]);

  useEffect(() => {
    if (selectedBet && odds.length) {
      const [betsStake, profitsAbsolute, profits, averageProfitPercentage] = recalculate_profits();
    
      setAmountToStake_2(Number(betsStake[1]));

      if (selectedBet?.bets.length === 3) {
        setAmountToStake_3(Number(betsStake[2]));
      }

      if (!isNaN(betsStake[0]) && !isNaN(betsStake[1])) {
        setTotalStake(betsStake.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0));
      }

      setProfits(profits);
      setProfitsAbsolute(profitsAbsolute);
      setAverageProfit(averageProfitPercentage);
    }
  }, [amountToStake_1]);

  useEffect(() => {
    if (selectedBet && odds.length) {
      const [betsStake, profitsAbsolute, profits, averageProfitPercentage] = recalculate_profits();
      
      setAmountToStake_1(Number(betsStake[0]));

      if (selectedBet?.bets.length === 3) {
        setAmountToStake_3(Number(betsStake[2]));
      }

      if (!isNaN(betsStake[0]) && !isNaN(betsStake[1])) {
        setTotalStake(betsStake.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0));
      }

      setProfits(profits);
      setProfitsAbsolute(profitsAbsolute);
      setAverageProfit(averageProfitPercentage);
    }
  }, [amountToStake_2]);

  useEffect(() => {
    if (selectedBet && odds.length) {
      const [betsStake, profitsAbsolute, profits, averageProfitPercentage] = recalculate_profits();
      
      setAmountToStake_1(Number(betsStake[0]));
      setAmountToStake_2(Number(betsStake[1]));

      if (!isNaN(betsStake[0]) && !isNaN(betsStake[1])) {
        setTotalStake(betsStake.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0));
      }

      setProfits(profits);
      setProfitsAbsolute(profitsAbsolute);
      setAverageProfit(averageProfitPercentage);
    }
  }, [amountToStake_3]);

  useEffect(() => {
    if (selectedBet && mode !== "ev" && mode !== "evLive" && odds.length) {
      const [betsStake, profitsAbsolute, profits, averageProfitPercentage] = recalculate_profits();
      
      setAmountToStake_1(Number(betsStake[0]));
      setAmountToStake_2(Number(betsStake[1]));

      if (selectedBet?.bets.length === 3) {
        setAmountToStake_3(Number(betsStake[2]));
      }

      if (!isNaN(betsStake[0]) && !isNaN(betsStake[1])) {
        setTotalStake(betsStake.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0));
      }

      setProfits(profits);
      setProfitsAbsolute(profitsAbsolute);
      setAverageProfit(averageProfitPercentage);
    }
  }, [totalStake, rounds, isSwitchOn]);

  const recalculate_profits = () => {
    let fixedValue = null;
    if (checkedFixed === 1) {
      fixedValue = Number(amountToStake_1);
    } else if (checkedFixed === 2) {
      fixedValue = Number(amountToStake_2);
    } else if (checkedFixed === 3) {
      fixedValue = Number(amountToStake_3);
    }

    return calculateArbitrage(totalStake, odds, checkedFixed, fixedValue, isSwitchOn, rounds);
  }

  const addBetToTracker = async () => {
    const data = {
      betInfo: selectedBet
    };

    const currentDate = new Date();
    data.timestamp = currentDate.getTime();

    if (mode !== "ev" && mode !== "evLive") {
      if (selectedBet.bets.length === 2) {
        data.amountToStake = [amountToStake_1, amountToStake_2];
      } else if (selectedBet.bets.length === 3) {
        data.amountToStake = [amountToStake_1, amountToStake_2, amountToStake_3];
      }

      data.profits = profits;
      data.profitsAbsolute = profitsAbsolute;
    } else {
      data.amountToStake = [totalStake];
      data.profits = [((convertAmericanToEuropean(Number(selectedBet.price)) - 1)*100).toFixed(2)];
      data.profitsAbsolute = [((convertAmericanToEuropean(Number(selectedBet.price)) - 1) * totalStake).toFixed(2)];
    }

    data.betId = selectedBet.id + data.timestamp.toString();
    data.status = "Pending";
    data.totalStake = totalStake;

    //dispatch(setUserBets(data));
    await dispatch(addUserBet(data));

    const userSub = localStorage.getItem('userSub');
    await dispatch(fetchUserTransactions(userSub));

    setButtonText('Added to Bets Tracker');
    setTimeout(() => {
      setButtonText('Add to Bets Tracker');
    }, 2000);

    setClickedSave(true);
  }

  const getOddsHistory = async (betId, bet) => {
    let history_length = 10;
    if (mode === "prematch") {
      history_length = systemSettings.prematch_history_len ?? 10;
    } else {
      history_length = systemSettings.live_history_len ?? 10;
    }

    setSelectedBetHistory(betId);

    const market_id = bet.market_id;

    let same_bets = [];

    if (subscriptionType?.includes('premium') || subscriptionType === "superadmin") {
      selectedBet.groups.forEach(surebet => {
        surebet.bets.forEach(b => {
          if (b.market_id === market_id) {
            same_bets.push(b);
          }
        });
      });
  
      same_bets = Object.values(same_bets.reduce((acc, item) => {
        if (!acc[item.bookmaker] && item.bookmaker !== bet.bookmaker) acc[item.bookmaker] = item;
        return acc;
      }, {}));
    }

    const main = bet.history.timestamp.slice(-history_length).map((timestamp, index) => ({
      timestamp,
      decimal_price: bet.history.decimal_price.slice(-history_length)[index],
      price: bet.history.price.slice(-history_length)[index],
    }));
  
    const additional = same_bets.map(bet => ({
      id: bet.id,
      bookmaker: bet.bookmaker,
      history: bet.history.timestamp.slice(-history_length).map((timestamp, index) => ({
        timestamp,
        decimal_price: bet.history.decimal_price.slice(-history_length)[index],
        price: bet.history.price.slice(-history_length)[index],
      })),
    }));

    setHistoryData({
      main,
      bookmaker: bet.bookmaker,
      additional,
    });
    setHistoryOpened(true);
  }

  const getOddsHistoryEV = (bet) => {
    let history_length = 10;
    if (mode === "ev") {
      history_length = systemSettings.prematch_history_len ?? 10;
    } else {
      history_length = systemSettings.live_history_len ?? 10;
    }

    setSelectedBetHistory(bet.id);

    let same_bets = [];

    if (subscriptionType?.includes('premium') || subscriptionType === "superadmin") {
      same_bets = bet.groups.odds;

      same_bets = Object.values(same_bets.reduce((acc, item) => {
        if (!acc[item.sports_book_name] && item.sports_book_name !== bet.sports_book_name) acc[item.sports_book_name] = item;
        return acc;
      }, {}));
    }

    const main = bet.history.last_run.slice(-history_length).map((timestamp, index) => ({
      timestamp,
      price: bet.history.price.slice(-history_length)[index],
      decimal_price: convertAmericanToEuropean(Number(bet.history.price.slice(-history_length)[index]))?.toFixed(2),
    }));
  
    const additional = same_bets.map(bet => ({
      id: bet.id,
      bookmaker: bet.sports_book_name,
      history: bet.history.last_run.slice(-history_length).map((timestamp, index) => ({
        timestamp,
        price: bet.history.price.slice(-history_length)[index],
        decimal_price: convertAmericanToEuropean(Number(bet.history.price.slice(-history_length)[index]))?.toFixed(2),
      })),
    }));

    additional.push({
      id: 'Fair Odds',
      bookmaker: 'Fair Odds',
      history: bet.pinnacle_history.last_run.slice(-history_length).map((timestamp, index) => ({
        timestamp,
        price: bet.pinnacle_history.price.slice(-history_length)[index],
        decimal_price: convertAmericanToEuropean(Number(bet.pinnacle_history.price.slice(-history_length)[index]))?.toFixed(2),
      })),
    });

    setHistoryData({
      main,
      bookmaker: bet.sports_book_name,
      additional,
    });
    setHistoryOpened(true);
  }

  return (
    <div className={`${style.root}`}>
      {
        clickedSave ? <NotificationPopUp setClickedSave={setClickedSave}></NotificationPopUp> : null
      }
      {
        isHistoryOpened ? <HistoryPopUp chartData={historyData}
          setHistoryOpened={setHistoryOpened} 
          setSelectedBetHistory={setSelectedBetHistory}
          isEv={mode === "ev" || mode === "evLive" ? true : false}
          oddsFormat={oddsFormat}
          num_comparisons={systemSettings.num_comparisons ?? 10}
        ></HistoryPopUp> : null
      }
      <div className={`${style.calculator_switch} ${style.inner_block}`}>
        <div className={style.switch_button} onClick={() => setOpenCalculatorStatus(!openCalculatorStatus)}>
          <img src={require('./img/calculate_white_icon.svg').default} alt="Filter icon" />
          <span>Calculator</span>
          <img src={require('./img/arrow.svg').default} alt="Arrow icon" className={`${style.switch_arrow} ${openCalculatorStatus ? style.active : ''}`} />
        </div>
      </div>
      { openCalculatorStatus ? 
        selectedBet ? 
          <div className={`${style.calculator_wrapper}`}>
            <div className={style.header}>
              {
                selectedBet.not_surebet ? 
                <div className={style.info_container}>
                  <img src={require('./img/warning_yellow.png')} style={{width: '20px', height: '20px', marginRight: '5px'}} alt="" />
                  <span>
                    { `Odds changed ` }
                    { 
                      selectedBet.bets.filter((bet) => {
                        if (bet.price !== bet.new_price) {
                          return true;
                        }

                        return false;
                      }).map(bet => `to ${oddsFormat === "American" ? bet.new_price : bet.new_decimal_price?.toFixed(2)} on ${bet.bookmaker}`).join(' and ')
                    }
                  </span>
                </div> : null
              }
              <div className={style.icon}>
                {
                  mode === "ev" || mode === "evLive" ? 
                    <>
                      <span>{"EV+"}</span>
                      <span className={style.delimiter}>|</span>
                      <span>{no_vig ? selectedBet.ev_no_vig.toFixed(2) : selectedBet.ev.toFixed(2)}%</span>
                    </>
                  :
                    <>
                      <span className={selectedBet.not_surebet ? style.text_red : ''}>{selectedBet.arbitrage_type.replace("surebet-", "")}</span>
                      <span className={style.delimiter}>|</span>
                      <span>{selectedBet.game_id.split("^")[0]}</span>
                      <span className={style.delimiter}>|</span>
                      <span>{selectedBet.arbitrage_percentage.toFixed(2)}%</span>
                      {
                        selectedBet.not_surebet ?
                        <>
                          <span className={style.delimiter}>|</span>
                          <span className={style.text_red}>No longer a surebet</span>
                        </> : null
                      }
                    </>
                }
              </div>
              <input type="text" className={`${style.calculator_input} ${selectedBet.not_surebet ? style.input_field_red : ''}`}
                value={mode === "ev" || mode === "evLive" ? 
                `${selectedBet.home_team} - ${selectedBet.away_team}`
                :
                `${selectedBet.league} / ${selectedBet.game_name}`}
              />
            </div>
            <div className={style.calculator_table}>
              <TableContainer>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center"><span className={classes.font}>Bookmaker</span></StyledTableCell>
                      <StyledTableCell align="center"><span className={classes.font}>Market</span></StyledTableCell>
                      <StyledTableCell align="center"><span className={classes.font}>Odds</span></StyledTableCell>
                      <StyledTableCell align="center"><span className={classes.font}>Stake $</span></StyledTableCell>
                      <StyledTableCell align="center"><span className={classes.font}>Profit %</span></StyledTableCell>
                      <StyledTableCell align="center"><span className={classes.font}>Profit $</span></StyledTableCell>
                      {
                        mode !== "ev" && mode !== "evLive" ? 
                        <StyledTableCell align="center"><span className={classes.font}>Fixed</span></StyledTableCell>
                        : null
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mode === "ev" || mode === "evLive" ? 
                    <StyledTableRow>
                      <StyledTableCell align="center">
                        <span className={classes.font}>{selectedBet.sports_book_name}</span>
                        {/* {
                          selectedBet?.deep_link_url ? 
                          <button onClick={() => handleLinkClick(selectedBet?.deep_link_url)} className={style.link_button}>
                            <b><span>Bet Now</span></b>
                          </button>
                          : null
                        } */}

                        {
                            selectedBet?.deep_link_url ? 
                            <a href={generateLink(selectedBet?.deep_link_url)} target="_blank" rel="noopener noreferrer" className={style.link_button}>
                              <b><span>Bet Now</span></b>
                            </a>
                            : null
                        }
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <span className={classes.font}>
                          {`${selectedBet.market_name} ${selectedBet.selection_basic} ${selectedBet.selection_line ?? ''} ${selectedBet.selection_points ?? ''}`}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {
                          subscriptionType !== "free" && !subscriptionType.includes("basic") ?
                          <div 
                            className={`${style.odds_field} ${selectedBetHistory === selectedBet.id ? style.odds_field_selected : ''}`} 
                            onClick={() => getOddsHistoryEV(selectedBet)}
                          >
                            <span className={`${classes.font} ${style.link}`}>
                              {oddsFormat === "American" ? selectedBet.price : convertAmericanToEuropean(Number(selectedBet.price))?.toFixed(2)}
                            </span>
                            {
                              selectedBetHistory === selectedBet.id ? 
                              <img 
                                src={require('./img/reload_green.png')} 
                                className={style.reload_icon} 
                                onClick={() => getOddsHistoryEV(selectedBet)} alt="" 
                              />
                              : 
                              <img 
                                src={require('./img/reload_black.png')} 
                                className={style.reload_icon} 
                                onClick={() => getOddsHistoryEV(selectedBet)} alt="" 
                              />
                            }
                          </div>
                          : 
                          <span className={classes.font}>{oddsFormat === "American" ? selectedBet.price : convertAmericanToEuropean(Number(selectedBet.price))}</span>
                        }
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          disabled
                          //style={{width: '90px'}}
                          className={`${style.value_input} ${style.stake_input}`}
                          id="outlined-size-small"
                          value={totalStake}
                          size="small"
                          InputProps={{
                            startAdornment: <InputAdornment className={`${style.input_adornment}`} position="start">$</InputAdornment>,
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <span className={classes.font}>
                          {`${((convertAmericanToEuropean(Number(selectedBet.price)) - 1)*100).toFixed(2)}%`}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <span className={classes.font}>{((convertAmericanToEuropean(Number(selectedBet.price)) - 1) * totalStake).toFixed(2)}</span>
                      </StyledTableCell>
                    </StyledTableRow>
                    :
                    selectedBet.bets.map((row, index) => (
                      <StyledTableRow key={row.index} className={selectedBet.not_surebet ? style.border_red : ''}>
                        <StyledTableCell align="center">
                          <span className={classes.font}>{row.bookmaker}</span>
                          {/* {
                            row?.deep_link_url ? 
                            <button onClick={() => handleLinkClick(row?.deep_link_url)} className={style.link_button}>
                              <b><span>Bet Now</span></b>
                            </button>
                            : null
                          } */}

                          {
                            row?.deep_link_url ? 
                            <a href={generateLink(row?.deep_link_url)} target="_blank" rel="noopener noreferrer" className={style.link_button}>
                              <b><span>Bet Now</span></b>
                            </a>
                            : null
                          }
                          
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <span className={classes.font}>{row.bet_name.trim()}</span>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {
                            subscriptionType !== "free" && !subscriptionType.includes("basic") ?
                            <div 
                              className={`${style.odds_field} ${selectedBetHistory === row.id ? style.odds_field_selected : ''}`} 
                              onClick={() => getOddsHistory(row.id, row)}
                            >
                              <span className={`${classes.font} ${style.link}`}>
                                {oddsFormat === "American" ? row.price : row.decimal_price?.toFixed(2)}
                              </span>
                              {
                                selectedBetHistory === row.id ? 
                                <img 
                                  src={require('./img/reload_green.png')} 
                                  className={style.reload_icon} 
                                  onClick={() => getOddsHistory(row.id, row)} alt="" 
                                />
                                : 
                                <img 
                                  src={require('./img/reload_black.png')} 
                                  className={style.reload_icon} 
                                  onClick={() => getOddsHistory(row.id, row)} alt="" 
                                />
                              }
                            </div>
                            : 
                            <span className={classes.font}>
                              {oddsFormat === "American" ? row.price : row.decimal_price?.toFixed(2)}
                            </span>
                          }
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <TextField
                            className={`${style.value_input} ${style.stake_input}`}
                            //style={{width: '90px'}}
                            id="outlined-size-small"
                            value={amountToStakeGroups[index][0]}
                            disabled={checkedFixed !== index + 1}
                            onChange={(e) => {
                              const newValue = Number(e.target.value);
                              amountToStakeGroups[index][1](newValue);
                            }}
                            size="small"
                            InputProps={{
                              startAdornment: <InputAdornment className={`${style.input_adornment}`} position="start">$</InputAdornment>,
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <span className={classes.font}>{`${profits[index]}%`}</span>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <span className={classes.font}>${profitsAbsolute[index]}</span>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomCheckbox checked={checkedFixed === index + 1} onChange={() => handleCheckboxChange(index + 1)} />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className={style.calculator_action}>
              <div className={`${style.action_item} ${style.action_item_select}`}>
                <span>Round to</span>
                <SelectComponent
                  label="Rounds" 
                  data={Array.from({ length: 20 }, (_, index) => index + 1)}
                  selectedData={rounds}
                  onChange={(data) => setRounds(data)}
                ></SelectComponent>
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch 
                  checked={isSwitchOn}
                  onChange={(event) => setIsSwitchOn(event.target.checked)}
                  inputProps={{ 'aria-label': 'ant design' }} 
                />
              </Stack>              
            </div>
              <div className={`${style.action_item} ${style.action_item_inline}`}>
                <span>Total</span>
                <TextField
                  className={`${style.total_input} ${selectedBet.not_surebet ? style.input_red : ''}`}
                  InputProps={{
                    startAdornment: <InputAdornment className={`${style.action_inline_symbol} ${style.start}`} position="start">$</InputAdornment>,
                    endAdornment: <InputAdornment className={`${style.action_inline_symbol} ${style.end}`} position="end">USD</InputAdornment>,
                  }}
                  disabled={checkedFixed !== null}
                  id="outlined-size-small"
                  value={totalStake}
                  onChange={(e) => {
                    setTotalStake(Number(e.target.value));
                  }}
                />
              </div>
              {
                subscriptionType !== "free" ? 
                <button className={`${style.action_button}`} disabled={selectedBet.not_surebet === true} onClick={() => addBetToTracker()}>
                  {buttonText}
                </button>
                : null
              }
            </div>
          </div> :
          <div className={style.no_bets}>
            No bets selected in calculator
          </div>
         : <></>
      }
      </div>
  );
}


function NotificationPopUp(props) {
  const toggler_value = localStorage.getItem('notShowPopUpCalculator') === "true";
  const [noMoreDisplay, setNoMoreDisplay] = useState(toggler_value);

  if (toggler_value) {
    return null;
  }

  return(
    <div className={style.modular_wrapper}>
      <div className={style.modular_item}>
        <div className={style.top}>
          <div className={style.name_wrapper}>
            <img className={style.icon} src={require('./img/tick.png')} alt="" />
            <span className={style.subname}>This sure bet was added to the Bets Tracker. You can find it in MyBets.</span>
          </div>
          <div className={style.checkbox_area} onClick={() => {
              setNoMoreDisplay(!noMoreDisplay);
          }}>
            <CustomCheckbox 
              checked={noMoreDisplay} 
              
            />
            <span className={style.info}>Don’t show this again</span>
          </div>
        </div>
        
        <div className={style.action_wrapper}>
          
          <button onClick={() => {
            localStorage.setItem('notShowPopUpCalculator', noMoreDisplay);
            props.setClickedSave(false);
          }}>
            <span>OK</span>
          </button>
        </div>
        
      </div>
    </div>
  )
}

function HistoryPopUp({chartData, setHistoryOpened, setSelectedBetHistory, oddsFormat, isEv, num_comparisons}) {
  const [selectedBookmakers, setSelectedBookmakers] = useState(isEv ? ['Fair Odds'] : []);

  const toggleBookmaker = (bookmaker) => {
    setSelectedBookmakers(prev => {
      if (prev.includes(bookmaker)) {
        return prev.filter(b => b !== bookmaker); // Удаление, если уже выбран
      } else {
        return [...prev, bookmaker]; // Добавление, если не выбран
      }
    });
  };

  const getUniqueSortedTimestamps = (mainData, additionalData) => {
    const mainTimestamps = mainData.map(d => d.timestamp * 1000);
    const additionalTimestamps = additionalData.flatMap(d => d.history.map(h => h.timestamp * 1000));
    const allTimestamps = [...mainTimestamps, ...additionalTimestamps];
    const uniqueTimestamps = Array.from(new Set(allTimestamps));
    uniqueTimestamps.sort((a, b) => new Date(a) - new Date(b));
    return uniqueTimestamps.map(timestamp => new Date(timestamp).toLocaleTimeString());
  };

  const labels = getUniqueSortedTimestamps(chartData.main, chartData.additional);
  const data = {
    labels,
    datasets: [
      {
        label: chartData.bookmaker,
        data: chartData.main.map(d => ({ 
          x: new Date(d.timestamp * 1000).toLocaleTimeString(), 
          y: oddsFormat === "American" ? d.price : d.decimal_price
        })),
        fill: false,
        backgroundColor: '#91E617',
        borderColor: '#91E617',
      },
      ...selectedBookmakers.map(bookmaker => {
        const additionalData = chartData.additional.find(b => b.bookmaker === bookmaker);
        const color = getRandomColor();
        return {
          label: bookmaker,
          data: additionalData.history.map(d => ({ 
            x: new Date(d.timestamp * 1000).toLocaleTimeString(), 
            y: oddsFormat === "American" ? d.price : d.decimal_price
          })),
          fill: false,
          // Установите другие цвета для различия линий
          backgroundColor: color,
          borderColor: color,
        };
      })
    ],
  };

  const options = {
    scales: {
      x: {
        ticks: {
          color: '#fff', // X-axis label text color
        },
        title: {
          display: true,
          color: '#fff', // X Axis Title Color
        },
      },
      y: {
        ticks: {
          color: '#fff', // Y-axis label text color
        },
        title: {
          display: true,
          color: '#fff', // Y Axis Title Color
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: '#fff', // Legend text color
        },
      },
    },
    maintainAspectRatio: false
  };

  return(
    <div className={style.modular_wrapper}>
      <div className={style.modular_item_calculator}>
        <div className={style.history_wrapper}>
          <div className={style.history_header}>
            <span className={style.history_text}>Odds History</span>
          </div>
          <div className={style.close} onClick={() => {
            setHistoryOpened(false);
            setSelectedBetHistory(null);
          }}>
            <img src={require('./img/close.png')} alt="" />
          </div>
        </div>
        <div className={style.plot_block}>
          <div className={style.plot}>
            <Line data={data} style={{backgroundColor: '#D9D9D926'}} options={options} />
          </div>
          { 
            chartData.additional.length ? 
            <div className={style.plot_selectors}>
              {chartData.additional.slice(-num_comparisons).map((d, index) => {
                return <div className={style.plot_selector}>
                  <input 
                    type="radio" 
                    id={`${d.id}_${d.bookmaker}`} 
                    name={`${d.id}_${d.bookmaker}`} 
                    //value={d.bookmaker}
                    onClick={() => toggleBookmaker(d.bookmaker)}
                    checked={selectedBookmakers.includes(d.bookmaker)}
                  ></input>
                  <label 
                    for={`${d.id}_${d.bookmaker}`}
                  >{d.bookmaker}</label>
                </div>
              })}
            </div> : null
          }
          
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  header: {
    fontSize: '18px',
    borderRadius: '10px 10px 0 0',
    backgroundColor: colors.night,
    color: '#fff',
    padding: '10px'
  },
  font: {
    fontSize: '16px'
  }
});

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));