import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import colors from '../utils/colors';
import { AppBar, Toolbar, Typography, IconButton, Button } from '@material-ui/core';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import { useSelector, useDispatch } from 'react-redux';
import { setMode } from '../features/settings/settingsSlice';

import style from './style/HeaderStyles.module.scss';

import logo from './../assets/logo.png';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HandshakeIcon from '@mui/icons-material/Handshake';

import { ReactComponent as SettingLogo } from './img/setting_icon.svg';
import { ReactComponent as ActiveSettingLogo } from './img/active_setting.svg';
import { setNotificationStatus, switchNotification } from './../features/user/userSlice';
import { setNotified } from './../features/settings/settingsSlice';

import { signOut } from 'aws-amplify/auth';

function HeaderComponent({isDataFetched, isAuthenticated}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const mode = useSelector(state => state.settings.mode);
  const subscriptionType = useSelector(state => state.user.subscriptionType);

  //const notificationCount = useSelector(state => state.user.newNotifications);
  const notificationStatus = useSelector(state => state.user.notificationsEnabled);
  const notified = useSelector(state => state.settings.notified);

  const [ openPopUp, setOpenPopUp ] = useState(false);

  useEffect(() => {
    dispatch(setMode(location.pathname.slice(1)));
  }, [navigate]);

  const handleSignOut = async () => {
    await signOut();
    window.location.href = '/'
  };

  const handleOpenPopUp = () => {
    setOpenPopUp(true);
  }

  return (
    <div className={`${classes.headerBox} ${style.header_box}`}>
      { 
        openPopUp ? <NotificationPopUp setOpenPopUp={setOpenPopUp} handleSignOut={handleSignOut}></NotificationPopUp> : null
      }
      <AppBar position="static" className={`${style.header_bar} ${classes.headerBox}`}>
        <Toolbar className={style.header_container}>
          
          <div className={style.mobile}>
            <Link
              id="basic-button"
              className={`${style.button} ${mode === "prematch" || mode === "live" ? classes.buttonActive : classes.buttonInactive}`}
              to={'prematch'}
            >
              <span>SUREBET</span>
            </Link>
            {/* <Link
              id="basic-button"
              className={`${style.button} ${mode === "live" ? classes.buttonActive : classes.buttonInactive}`}
              to={(!subscriptionType.includes("basic") && subscriptionType !== "free") ? 'live' : 'account/subscriptions'}
            > */}
              {/* <OnlinePredictionIcon style={{width: '22px', height: '22px', marginRight: '5px'}}></OnlinePredictionIcon> */}
              {/* <span>Live</span>
            </Link> */}
            <Link
              id="basic-button"
              className={`${style.button} ${mode === "ev" || mode === "evLive" ? classes.buttonActive : classes.buttonInactive}`}
              to={subscriptionType !== "free" ? 'ev' : 'freepage'}
            >
              <span>EV+</span>
            </Link>
            {/* <Link
              id="basic-button"
              className={`${style.button} ${mode === "evLive" ? classes.buttonActive : classes.buttonInactive}`}
              to={subscriptionType !== "free" ? 'evLive' : 'account/subscriptions'}
            >
              <span>EV+ Live</span>
            </Link> */}
            <Link
              id="basic-button"
              className={`${style.button} ${mode === "mybets" ? classes.buttonActive : classes.buttonInactive}`}
              to={subscriptionType !== "free" ? 'mybets' : 'freepage'}
            >
              <span>MYBETS</span>
            </Link>
            <img 
              src={require('./img/notif.png')} 
              onClick={() => dispatch(setNotified(false))} 
              style={{marginLeft: '10px', cursor: 'pointer'}} alt="" 
            />
            {
              notified ? <span className={style.badge}></span> : null
            }
            <Stack direction="row" spacing={1} alignItems="center">
              <AntSwitch 
                checked={notificationStatus}
                onChange={(event) => {
                  dispatch(setNotificationStatus(event.target.checked));
                  dispatch(switchNotification(event.target.checked));
                }}
                inputProps={{ 'aria-label': 'ant design' }} 
              />
            </Stack> 
          </div>
          <div className={style.logo}>
            <img src={logo} style={{width: '180px'}} alt="BetSmartAI" />
            {
            (isDataFetched && isAuthenticated) ? 
              <div className={`${style.status_wrapper} ${style.free}`}>
                <span>{subscriptionType?.toUpperCase()}</span>
              </div>
              : null
            }
          </div>

          {/* <a href={'/account'}>
            <IconButton color="inherit" >
              <img src={require('./img/account_icon.svg').default} alt="Account icon" />
            </IconButton>
          </a> */}
          <div className={style.right_header}>
            {
              <div className={`${style.how_to_wrapper}`}>
                <a 
                  href="https://surebetfusion.getrewardful.com" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  style={{ textDecoration: 'none', color: 'inherit', display: 'inline-flex', alignItems: 'center' }}
                >
                  <HandshakeIcon />
                </a>
                <span>
                  <a 
                    href="https://surebetfusion.getrewardful.com" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    Become an Affiliate
                  </a>
                </span>
              </div>
            }
            
            <div className={`${style.how_to_wrapper}`}>
              <Link to="/howto" 
                style={{ textDecoration: 'none', color: 'inherit', display: 'inline-flex', alignItems: 'center' }}
              >
                <HelpOutlineIcon />
              </Link>
              <span><Link to={"/howto"}>How To Guide</Link></span>
            </div>
            {
              subscriptionType === 'superadmin' ?
              <Link to={"/admin"} className={`${style.admin_button} ${mode.includes("admin") ? style.active : ''}`}>
                ADMIN
              </Link>
              : null
            }
            <Link to={'/account'} className={`${style.setting_button} ${mode.includes("account") ? style.active : ''}`}>
              <IconButton color="inherit" >
                {
                  mode.includes("account") ?
                  <ActiveSettingLogo /> : 
                  <SettingLogo />
                }
              </IconButton>
            </Link>
            <button className={style.exit_btn} onClick={() => handleOpenPopUp()}>
              <img src={require('./img/exit.png')} alt="" />
            </button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

function NotificationPopUp({setOpenPopUp, handleSignOut}) {
  return(
    <div className={style.modular_wrapper}>
      <div className={`${style.modular_item} ${style.red_border}`}>
        <div className={style.top}>
          <div className={style.name_wrapper}>
            
            <div>
              <span className={style.name}>Log Out</span><br></br>
              <br></br>
              <span className={style.subname}>Are you sure you want to logout?</span>
            </div>
          </div>
        </div>
        
        <div className={style.action_wrapper}>
          
          <button onClick={() => {
              setOpenPopUp(false);
          }}>
            <span className={style.cancel_button}>Cancel</span>
          </button>

          <button className={style.green_button} onClick={() => {
            handleSignOut();
          }}>
            <span>Log Out</span>
          </button>
        </div>
        
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  headerBox: {
    height: '100%',
    color: 'white',
  },
  logoText: {
    fontWeight: '800',
    fontSize: '24px'
  },
  greenColor: {
    color: colors.green
  },
  buttonActive: {
    fontSize: '16px',
    background: 'none',
    color: '#91E617',
    position: 'relative',
    '&:after': { 
      content: '""',
      position: 'absolute',
      width: '100%', 
      height: '3px', 
      bottom: 0,
      left: '0',
      backgroundColor: colors.green, 
      transition: 'all 0.3s ease', 
    }
  },
  buttonInactive: {
    fontSize: '16px',
    background: 'none',
    color: '#fff',
    position: 'relative',
    transition: 'color 0.2s ease',
    '&:hover': {
      color: '#91E617'
    }
  }
});

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export default HeaderComponent;