import { useNavigate } from 'react-router-dom';
import style from './styles/LoginStyle.module.scss';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import colors from '../../utils/colors';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { signUp, resendSignUpCode, confirmSignUp } from 'aws-amplify/auth';

import RestServerAPI from './../../http/restServerAPI';

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

/**
 * CreateAccount Component
 *
 * This component manages the user registration process for the application.
 * It allows users to input their personal information, agree to terms and conditions,
 * and then verify their email through a confirmation code sent to their email address.
 * Upon successful registration, users are directed to verify their email and, 
 * subsequently, to log in.
 *
 * The registration process consists of several stages:
 * 1. SET_DATA: Where users fill out their personal information and agree to terms.
 * 2. VERIFY_EMAIL: Users enter a confirmation code sent to their email to verify it.
 * 3. VERIFIED: Confirmation that the user's email has been successfully verified.
 *
 * Each stage presents a different UI to the user, guiding them through the registration process.
 * 
 * The component utilizes local state for managing user input, registration stages,
 * showing password functionality, and error handling.
 */
export default function CreateAccount() {
    const [stage, setStage] = useState('SET_DATA');
    const [rememberStatus, setRememberStatus] = useState(false);
    const [userAttributes, setUserAttributes] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      });
    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    
    const handleInputChange = (event) => {
        setUserAttributes({ ...userAttributes, [event.target.name]: event.target.value });
    };

    const CustomCheckbox = styled(Checkbox)({
        color: 'white', // Border color
        '&.Mui-checked': {
          color: colors.green,
        },
    });

    const [code, setCode] = useState(new Array(6).fill(''));

    const handleCodeChange = (index) => (e) => {
        const newCode = [...code];
        newCode[index] = e.target.value.slice(0, 1); // Trim the input to one digit
        setCode(newCode);

        // Move focus to the next field if the current one is filled
        if (index < 5 && e.target.value) {
        document.getElementById(`code-${index + 1}`).focus();
        }
    };

    const handleKeyDown = (index) => (e) => {
        if (e.key === 'Backspace' && !code[index]) {
            // Move focus to the previous field if the current one is empty
            if (index > 0) {
                document.getElementById(`code-${index - 1}`).focus();
            }
        }
    };
    
    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text').slice(0, 6).split('');
        const newCode = [...code];
        pastedData.forEach((digit, index) => {
            newCode[index] = digit;
            document.getElementById(`code-${index}`).value = digit;
        });
        setCode(newCode);
        document.getElementById(`code-${pastedData.length - 1}`).focus();
    };


    const handleSignUp = async (event) => {
        event.preventDefault();
        if (!rememberStatus) {
            setError('You must agree to the terms and conditions');
            return;
        }
        try {
            const { userId } = await signUp({
                username: userAttributes.email,
                password: userAttributes.password,
                options: {
                    userAttributes: {
                        email: userAttributes.email,
                        given_name: userAttributes.firstName,
                        family_name: userAttributes.lastName,
                    },
                },
            });

            console.log('Registration is successful: ', userId);

            await RestServerAPI.register_user(userId, {
                firstName: userAttributes.firstName,
                lastName: userAttributes.lastName,
                email: userAttributes.email
            });
            setError(null);
            setStage("VERIFY_EMAIL");
        } catch (error) {
            setError(error.message || error);
        }
    }

    const handleResendCode = async (event) => {
        event.preventDefault();

        try {
            await resendSignUpCode({
                username: userAttributes.email,
                password: userAttributes.password,
                options: {
                    userAttributes: {
                        email: userAttributes.email,
                        given_name: userAttributes.firstName,
                        family_name: userAttributes.lastName,
                    },
                },
            });

            console.log('Successfully resend email confirmation');
        } catch (error) {
            setError(error.message || error);
        }
    }

    const handleSetCode = async (event) => {
        event.preventDefault();

        try {
            await confirmSignUp({
                username: userAttributes.email, 
                confirmationCode: code.join('')
            });
            setStage("VERIFIED");

            console.log('Successfully confirmed email');
        } catch (error) {
            setError(error.message || error);
        }
    }

    const navigate = useNavigate();

    const redirectToLogin = async (event) => {
        event.preventDefault();
        navigate('/login');
    }

    return (
        stage === "SET_DATA" ?
        <div className={style.auth_container}>
            <form className={style.signup_form} onSubmit={handleSignUp}>
                <span className={style.header}>Create Your Account</span>
                <div className={style.inputs_wrapper}>
                    <div className={style.item}>
                        <label htmlFor="account_first_name">First Name</label>
                        <input name="firstName" onChange= {handleInputChange} type="text" id='account_first_name' placeholder='First Name' />
                    </div>
                    <div className={style.item}>
                        <label htmlFor="account_last_name">Last Name</label>
                        <input name="lastName" onChange= {handleInputChange} type="text" id='account_last_name' placeholder='Last Name' />
                    </div>
                    <div className={style.item}>
                        <label htmlFor="account_email">Email</label>
                        <input name="email" onChange= {handleInputChange} type="email" id='account_email' placeholder='Email' />
                    </div>
                    <div className={style.item}>
                        <label htmlFor="account_password">Password</label>
                        <input name="password" onChange= {handleInputChange} type={showPassword ? 'text' : 'password'} id='account_password' placeholder='Password' />
                        { showPassword ? <VisibilityOutlinedIcon onClick={() => setShowPassword(false)} /> : <VisibilityOffOutlinedIcon onClick={() => setShowPassword(true)} /> }
                    </div>
                </div>
                <div className={style.bottom_wrapper}>
                    <FormControlLabel 
                        control={
                        <CustomCheckbox 
                            checked={rememberStatus} 
                            onChange={() => setRememberStatus(!rememberStatus)}
                        />
                        } 
                        label={<span>* I am at least 18 years of age and I have read, accepted and agree to the <a href="#">Terms and Conditions.</a></span>} 
                    />
                </div>
                <div className={style.bottom_element}>
                    {error && <p>{error}</p>}
                </div>
                <button type="submit" className={style.button}>Create Account</button>
                <span href="#" className={style.bottom_element}>
                    <span>Already have an account? </span>
                    <Link to="/login">Log In</Link>
                </span>
            </form>
        </div>
    : stage === "VERIFY_EMAIL" ? <VerifyEmailPage 
        email={userAttributes.email} 
        resendEmail={(e) => handleResendCode(e)}
        handleSetCode={handleSetCode}
        code={code}
        handleKeyDown={handleKeyDown}
        handlePaste={handlePaste}
        handleCodeChange={handleCodeChange}
        error={error}
    ></VerifyEmailPage> : <EmailConfirmedPage></EmailConfirmedPage>
    )
}

function VerifyEmailPage({email, resendEmail, handleCodeChange, handleSetCode, handleKeyDown, handlePaste, code, error}) {
    return(
        <div className={`${style.auth_container} ${style.reset}`}>
            <form> 
                <div className={style.back_link}>
                    <img 
                        src={require('./img/arrow.svg').default} 
                        className={style.arrow} 
                        alt="Arrow icon" 
                        style={{ transform: 'rotate(90deg)' }}
                    />
                    <Link to="/login">Back to Log In</Link>
                </div>
                <img src={require('./img/message.png')} className={style.header_padding} alt="" />
                <span className={`${style.header} ${style.under_img}`}>Verify Your Email</span>
                <span className={`${style.subheader_text} ${style.code_page}`}>
                    Please enter the 6 digit verification code sent to <span>{email}</span> 
                </span>
                <div className={style.code_wrapper} onPaste={handlePaste}>
                    {code.map((digit, index) => (
                        <div key={index} className={`${style.item_code}`}>
                            <input
                                className={style.code_input}
                                id={`code-${index}`}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={handleCodeChange(index)}
                                onKeyDown={handleKeyDown(index)}
                                onFocus={(e) => e.target.select()}
                            />
                        </div>
                    ))}
                </div>
                <div className={style.bottom_element} style={{color: '#FF5252'}}>
                    {error && <p>{error}</p>}
                </div>
                <div className={style.button_wrapper}>
                    <button className={`${style.button} ${style.second_button}`} onClick={resendEmail}>Resend Code</button>
                    <button className={style.button} onClick={handleSetCode}>Confirm</button>
                </div>
            </form>
        </div>
    )
}

function EmailConfirmedPage() {
    const navigate = useNavigate();
    
    const redirectToLogin = async (event) => {
        event.preventDefault();
        navigate('/login');
    }

    return(
        <div className={`${style.auth_container} ${style.reset}`}>
            <form className={style.centered}>
                <span className={style.successText}>Your registation has been confirmed</span>
                <button className={style.button} onClickCapture={(event) => redirectToLogin(event)}>Log In</button>
            </form>
        </div>
    )
}