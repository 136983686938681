import { styled } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const CustomDateTimePicker = styled(DateTimePicker)({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#fff', // border color
    },
    '& label': {
      borderColor: '#fff', // label color
    },
    '&:hover fieldset': {
      borderColor: '#fff', // border hover color
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fff', // border color on focus
    },
    '& input': {
      color: '#fff', // text color
    },
  },
  '& .MuiInputLabel-root': {
    color: '#fff', // Label text color
  },
  '& .MuiInputBase-input': {
    color: '#fff', // Input text color
  },
  '& .MuiIconButton-root': {
    color: '#fff', // Icon color
  },
  '& label.Mui-focused': {
    color: 'white', // Label text color when focused
  },
});

export default CustomDateTimePicker;