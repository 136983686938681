function timestamp_to_string(timestamp) {
  let date_time = new Date(timestamp * 1000);
  const formattedDate = date_time.toLocaleDateString("en-US");
  const formattedTime = date_time.toLocaleTimeString("en-US");
  return `${formattedDate} ${formattedTime}`;
}

function getCurrentTimestampFormatted() {
  const now = new Date();
  const day = now.getDate().toString().padStart(2, '0');
  const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months start at 0
  const year = now.getFullYear().toString().substr(-2); // We get the last two digits of the year

  let hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // hour '0' should be '12'
  hours = hours.toString().padStart(2, '0');

  return `${day}/${month}/${year} @ ${hours}:${minutes}${ampm}`;
}

function formatDateString(dateString) {
  const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  const dateObj = new Date(dateString);

  const day = dateObj.getDate().toString().padStart(2, '0');
  const month = months[dateObj.getMonth()];
  const year = dateObj.getFullYear().toString().substring(2);

  let hours = dateObj.getHours();
  const minutes = dateObj.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // hour '0' should be '12'

  return `${month}/${day}/${year} @ ${hours}:${minutes}${ampm}`;
}

export {
  getCurrentTimestampFormatted,
  formatDateString
};

export default timestamp_to_string;