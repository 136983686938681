import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './styles/LoginStyle.module.scss';
import { Link } from 'react-router-dom';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import RestServerAPI from './../../http/restServerAPI';

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

/**
 * ResetPassword Component
 *
 * This component facilitates the password reset process for users who have forgotten their password. It guides the user through several stages:
 * 1. SET_EMAIL: Where users are prompted to enter the email associated with their account.
 * 2. SET_CODE: Users receive a verification code via email, which they must enter to verify their identity.
 * 3. SET_NEW_PASSWORD: Users can set a new password for their account.
 *
 * Each stage of the process is handled by a sub-component designed to capture the necessary information, such as the user's email, the verification code,
 * and the new password. The component handles input changes, form submissions, and transitions between stages, maintaining the current stage in its state.
 *
 * Error handling is implemented to provide feedback to the user if an email is not registered or if there are issues with the verification code or the
 * password reset process. Successful completion of the process redirects the user to the login page.
 */
export default function ResetPassword() {
    const navigate = useNavigate();
    const [stage, setStage] = useState('SET_EMAIL');

    const [userAttributes, setUserAttributes] = useState({
        email: '',
        password: '',
      });

    const [error, setError] = useState(null);
    
    const handleInputChange = (event) => {
        setError(null);
        setUserAttributes({ ...userAttributes, [event.target.name]: event.target.value });
    };

    const [code, setCode] = useState(new Array(6).fill(''));

    const handleCodeChange = (index) => (e) => {
        const newCode = [...code];
        newCode[index] = e.target.value.slice(0, 1); // Trim the input to one digit
        setCode(newCode);

        // Move focus to the next field if the current one is filled
        if (index < 5 && e.target.value) {
        document.getElementById(`code-${index + 1}`).focus();
        }
    };

    const handleKeyDown = (index) => (e) => {
        if (e.key === 'Backspace' && !code[index]) {
            // Move focus to the previous field if the current one is empty
            if (index > 0) {
                document.getElementById(`code-${index - 1}`).focus();
            }
        }
    };
    
    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text').slice(0, 6).split('');
        const newCode = [...code];
        pastedData.forEach((digit, index) => {
            newCode[index] = digit;
            document.getElementById(`code-${index}`).value = digit;
        });
        setCode(newCode);
        document.getElementById(`code-${pastedData.length - 1}`).focus();
    };

    const handleResetPassword = async (event) => {
        event.preventDefault();
        try {
            if (error) {
                return;
            }

            const isUsed = await RestServerAPI.check_email(userAttributes.email);

            if (!isUsed) {
                setError('This email address is not registered in our system. Please add a different email address.');
                return;
            }
            await resetPassword({ username : userAttributes.email});
            setError('If the email is registered, you will receive a code to reset your password');
            setStage('SET_CODE');
        }
        catch (error) {
            setError(error.message || error);
        }
    }

    const handleSetCode = async (event) => {
        event.preventDefault();
        setStage('SET_NEW_PASSWORD');
    }

    const handleConfirmResetPassword = async (event) => {
        event.preventDefault();
        try {
            await confirmResetPassword({ username : userAttributes.email, confirmationCode: code.join(''), newPassword: userAttributes.password});
            setError('Password updated');
            setStage('PASSWORD_UPDATED');
        }
        catch (error) {
            setError(error.message || error);
        }
    }

    const redirectToLogin = async (event) => {
        event.preventDefault();
        navigate('/login');
    }

    let StageComponent = null;
    if (stage === 'SET_EMAIL') {
        StageComponent = <EmailComponent 
            handleInputChange={handleInputChange} 
            handleResetPassword={handleResetPassword}
            error={error} 
        />;
    } else if (stage === 'SET_CODE') {
        StageComponent = <CodeComponent 
            handleInputChange={handleInputChange} 
            handleCodeChange={handleCodeChange}
            handleResetPassword={handleResetPassword}
            email={userAttributes.email}
            code={code}
            handleSetCode={handleSetCode} 
            handleKeyDown={handleKeyDown}
            handlePaste={handlePaste}
            error={error} 
        />;
    } else if (stage === 'SET_NEW_PASSWORD') {
        StageComponent = <NewPasswordComponent 
            handleInputChange={handleInputChange} 
            handleConfirmResetPassword={handleConfirmResetPassword} 
            error={error} 
        />;
    }

    return(
        <div className={`${style.auth_container} ${style.reset}`}>
            {
                stage !== 'PASSWORD_UPDATED' ? 
                <form>
                    {
                        StageComponent
                    }
                    
                </form>
                : <form className={style.centered}>
                    <span className={style.successText}>Your password has been restored successfully</span>
                    <button className={style.button} onClickCapture={(event) => redirectToLogin(event)}>Log In</button>
                </form>
            }
        </div>
    )
}

function EmailComponent(props) {
    return(
        <>
            <div className={style.back_link}>
                <img 
                    src={require('./img/arrow.svg').default} 
                    className={style.arrow} 
                    alt="Arrow icon" 
                    style={{ transform: 'rotate(90deg)' }}
                />
                <Link to="/login">Back to Log In</Link>
            </div>
            <span className={`${style.header} ${style.header_padding}`}>Reset Password</span>
            <img src={require('./img/message.png')} alt="" />
            <span className={style.header_text}>
                Email Details
            </span>
            <span className={style.subheader_text}>
                Please enter your email to verify your profile information
            </span>
            <div className={style.inputs_wrapper}>
                <div className={style.item}>
                    <input name="email" onChange={props.handleInputChange} type="email" id='account_email' placeholder='Email' />
                </div>
            </div>
            <div className={style.bottom_element_2}>
                {
                    props.error && <>
                        <img src={require('./img/attention.png')} alt="" />
                        <p style={{color: '#FF5252'}}>{props.error}</p>
                    </>
                }
            </div>
            <button className={`${style.button} ${props.error ? style.button_inactive : ''}`} onClick={props.handleResetPassword}>Continue</button>
        </>
    )
}

function CodeComponent(props) {
    return(
        <>
            <div className={style.back_link}>
                <img 
                    src={require('./img/arrow.svg').default} 
                    className={style.arrow} 
                    alt="Arrow icon" 
                    style={{ transform: 'rotate(90deg)' }}
                />
                <Link to="/login">Back to Log In</Link>
            </div>
            <img src={require('./img/message.png')} className={style.header_padding} alt="" />
            <span className={`${style.header} ${style.under_img}`}>Verify Your Email</span>
            <span className={`${style.subheader_text} ${style.code_page}`}>
                Please enter the 6 digit verification code sent to <span>{props.email}</span> 
            </span>
            <div className={style.code_wrapper} onPaste={props.handlePaste}>
                {props.code.map((digit, index) => (
                    <div key={index} className={`${style.item_code}`}>
                        <input
                            className={style.code_input}
                            id={`code-${index}`}
                            type="text"
                            maxLength="1"
                            value={digit}
                            onChange={props.handleCodeChange(index)}
                            onKeyDown={props.handleKeyDown(index)}
                            onFocus={(e) => e.target.select()}
                        />
                    </div>
                ))}
            </div>
            <div className={style.bottom_element}>
                {props.error && <p>{props.error}</p>}
            </div>
            <div className={style.button_wrapper}>
                <button className={`${style.button} ${style.second_button}`} onClick={props.handleResetPassword}>Resend Code</button>
                <button className={style.button} onClick={props.handleSetCode}>Confirm</button>
            </div>
        </>
    )
}

function NewPasswordComponent(props) {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    return(
        <>
            <div className={style.back_link}>
                <img 
                    src={require('./img/arrow.svg').default} 
                    className={style.arrow} 
                    alt="Arrow icon" 
                    style={{ transform: 'rotate(90deg)' }}
                />
                <Link to="/login">Back to Log In</Link>
            </div>
            <span className={`${style.header} ${style.new_password} ${style.header_padding}`}>Reset Password</span>


            <div className={style.inputs_wrapper}>
                <div className={style.item}>
                    <label htmlFor="new_password">New Password</label>
                    <input type={showPassword ? 'text' : 'password'} name="password" onChange={props.handleInputChange} id='new_password' placeholder='New Password' />
                    { showPassword ? <VisibilityOutlinedIcon onClick={() => setShowPassword(false)} /> : <VisibilityOffOutlinedIcon onClick={() => setShowPassword(true)} /> }
                </div>
                <div className={style.item}>
                    <label htmlFor="conf_new_password">Confirm Password</label>
                    <input type={showConfirmPassword ? 'text' : 'password'} name="password" onChange={props.handleInputChange} id='conf_new_password' placeholder='Confirm Password' />
                    { showConfirmPassword ? <VisibilityOutlinedIcon onClick={() => setShowConfirmPassword(false)} /> : <VisibilityOffOutlinedIcon onClick={() => setShowConfirmPassword(true)} /> }
                </div>
            </div>
            <button className={style.button} onClick={props.handleConfirmResetPassword}>Reset Password</button>
        </>
    )
}