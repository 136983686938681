import style from './styles/LoginStyle.module.scss';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import colors from '../../utils/colors';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { signIn, fetchAuthSession } from 'aws-amplify/auth';
import RestServerAPI from './../../http/restServerAPI';

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

/**
 * LogIn Component
 *
 * This component is responsible for handling user logins to the application.
 * Users can input their email and password to authenticate. There is also an option to
 * remember the login status for future sessions and a link for users who have forgotten
 * their password. Upon successful login, users are redirected to the '/prematch' page.
 *
 * Features include showing/hiding the password for better user experience and
 * error handling for login failures. It uses an external API to fetch the user's
 * IP address and sends login information including the user ID, login time, and IP
 * address to the server for logging and security purposes.
 */
export default function LogIn() {
    const [rememberStatus, setRememberStatus] = useState(false);

    const [userAttributes, setUserAttributes] = useState({
        email: '',
        password: '',
      });

    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    
      const handleInputChange = (event) => {
        setUserAttributes({ ...userAttributes, [event.target.name]: event.target.value });
    };

    // obtaining an IP address via an external API
    async function getIpAddress() {
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            return data.ip;
        } catch (error) {
            console.error('Failed to obtain IP address:', error);
            return '';
        }
    }

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const user = await signIn({username: userAttributes.email, password: userAttributes.password});

            const session = await fetchAuthSession();

            const userId = session.userSub;

            const loginTime = new Date().getTime();

            // Getting information about the browser and device
            //const browserInfo = navigator.userAgent;

            // Допустим, у вас есть функция getIpAddress для получения IP-адреса через API
            const ipAddress = await getIpAddress();

            await RestServerAPI.sendLoginInfoToServer({
                userId, 
                login_time: loginTime,
                ip_address: ipAddress,
                //browser_info: browserInfo,
                //device_info: browserInfo
            });
            
            window.location.href = '/prematch';
        } catch (error) {
            setError(error.message || error);
        }
    };

    const CustomCheckbox = styled(Checkbox)({
        color: 'white', // Border color
        '&.Mui-checked': {
          color: colors.green,
        },
    });

    return(
        <div className={style.auth_container}>
            <form onSubmit={handleLogin}>
                <span className={style.header}>Log In</span>
                <div className={style.inputs_wrapper}>
                    <div className={style.item}>
                        <label htmlFor="account_email">Email</label>
                        <input name="email" onChange={handleInputChange} type="email" id='account_email' placeholder='Email' />
                    </div>
                    <div className={style.item}>
                        <label htmlFor="account_password">Password</label>
                        <input name="password" onChange={handleInputChange} type={showPassword ? 'text' : 'password'} id='account_password' placeholder='Password' />
                        { showPassword ? <VisibilityOutlinedIcon onClick={() => setShowPassword(false)} /> : <VisibilityOffOutlinedIcon onClick={() => setShowPassword(true)} /> }
                    </div>
                </div>
                <div className={style.bottom_wrapper}>
                    <FormControlLabel 
                        control={
                        <CustomCheckbox 
                            checked={rememberStatus} 
                            onChange={() => setRememberStatus(!rememberStatus)}
                        />
                        } 
                        label={'Remember me'} 
                    />
                    <Link to={'/reset'}>Forgot Password?</Link>
                </div>
                <div className={style.bottom_element}>
                    {error && <p>{error}</p>}
                </div>
                <button className={style.button}>Log In</button>
                <span href="#" className={style.bottom_element}>
                    <span>Don’t have an account yet? </span>
                    <Link to="/signup">Create Account</Link>
                </span>
            </form>
        </div>
    )
}