import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Tooltip } from 'react-tooltip';

import colors from '../utils/colors';
import { convertAmericanToEuropean } from './../utils/odds_converter';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedBet } from '../features/bets/betsSlice';

import style from './style/BetStyles.module.scss';

const calculate_time = (otherTimestamp) => {
  // Current time in Unix Timestamp (in seconds)
  const now = Math.floor(Date.now() / 1000);

  // Time difference in seconds
  const differenceInSeconds = now - otherTimestamp;

  // Determining in which units of measurement to display the difference
  let timeDifference;

  if (differenceInSeconds >= 3600) {
    // Displayed in hours
    const hours = Math.floor(differenceInSeconds / 3600);
    timeDifference = `${hours} h`;

    return timeDifference;
  } else if (differenceInSeconds >= 60) {
    // Displayed in minutes
    const minutes = Math.floor(differenceInSeconds / 60);
    timeDifference = `${minutes} m`;

    return timeDifference;
  } else {
    // Displayed in seconds
    timeDifference = `${differenceInSeconds.toFixed(0)} s`;

    return timeDifference;
  }
}

/**
 * EVBetComponent
 * 
 * Renders individual Expected Value (EV) bet items, combining bet details with game information.
 * This component is designed to display EV bets, including bookmaker, match-up details, market, and odds.
 * It also highlights new bets briefly to attract user attention and provides an interactive UI element
 * to calculate and evaluate the EV bet further.
 * 
 * Features include:
 * - Displaying the bet's details such as the sports book name, teams involved, market type, and odds.
 * - Highlighting new bets using a blinking effect for a short duration to make them stand out.
 * - Showing additional calculated values like expected value percentage, winning probability, Vig, and width,
 *   providing a deeper insight into the bet's value.
 * - Enabling users to trigger calculations or further actions through a button click, enhancing interactive capabilities.
 * 
 * The component utilizes Redux for state management, fetching user preferences like odds format and no-vig settings
 * to adjust the display accordingly. It also integrates with custom utility functions to format and convert odds
 * and timestamps, ensuring data is presented in a user-friendly manner.
 * 
 * `useEffect` is employed to manage the highlight state of new bets, ensuring it's only active for a brief period
 * after the component mounts. This transient visual cue aids in quickly identifying newly added bets.
 */
function EVBetComponent({bet, game_info, bets_group, handleOpenDeleteBetPopup}) {
  const classes = useStyles();

  const mode = useSelector(state => state.settings.mode);
  const oddsFormat = useSelector(state => state.preferences.oddsFormat);
  const no_vig = useSelector(state => state.preferences.noVig);
  const dispatch = useDispatch();

  const [isHighlighted, setIsHighlighted] = useState(false);

  const selected_filter_id = useSelector(state => state.filter.selectedFilterId);

  let extended_bet = {...bet, ...game_info, groups: bets_group};

  useEffect(() => {
    if (bet.isNew) {
      const timer = setTimeout(() => {
        setIsHighlighted(false);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [bet.isNew]);

  const handleClick = (data) => {
    dispatch(setSelectedBet({mode, data}));
  }

  const getImage = (path) => {
    try {
      const cleanPath = path.replace(/\//g, '');

      return `${process.env.PUBLIC_URL}/bookmakers-img/${cleanPath}.png`;
    } catch (error) {
      return null;
    }
  };

  return (
    <div className={style.bet_item_container}>
      <Grid container item xs={12} className={`${isHighlighted === true ? 'blink' : null} ${style.bet_item} ${style.ev_bet_item}`} >
        <Grid item xs={3} className={`${classes.item} ${style.bookmaker_name}`}>
          <img src={getImage(bet.sports_book_name)}  />
          <Typography>{bet.sports_book_name}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <Typography>{`${bet.home_team} vs ${bet.away_team}`}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <Typography>{`${bet.market_name} ${bet.selection_basic} ${bet.selection_line ?? ''} ${bet.selection_points ?? ''}`}</Typography>
        </Grid>
        <Grid item xs={1} className={classes.item}>
          <Typography>
            {
              calculate_time(bet.timestamp).replace(/\s/g, '')
            }
            <>
              <a id="Status" style={{paddingLeft: '3px'}}>
                <img src={require('./img/tooltips.png')} alt="Close icon" />
              </a>
              <Tooltip
                className='tooltips'
                key={'right-start'}
                anchorSelect="#Status"
                content={`Last detected odds change`}
                place={'right-start'}
              />
            </>
          </Typography>
        </Grid>
        <Grid item xs={1} className={classes.item} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Typography><b>{oddsFormat === "American" ? bet.price : convertAmericanToEuropean(Number(bet.price))?.toFixed(2)}</b></Typography>
        </Grid>
        <Grid item xs={3} className={style.calculate_wrapper}>
          <div className={style.calculate_items} style={{ 
            color: colors.green,
          }}>
            <div className={style.Calculate_container}>
              <Typography>{no_vig ? bet.ev_no_vig.toFixed(2) : bet.ev.toFixed(2)}%</Typography>
              {/* <Button style={{minWidth: '10px', marginLeft: '4px'}} onClick={() => handleClick(bet)}> */}
              <Button style={{minWidth: '10px', marginLeft: '4px'}}>
                <img src={require('./img/calculate_icon.svg').default} alt="Calculate icon" onClick={() => handleClick(extended_bet)} />
              </Button>
            </div>
            <div className={style.Calculate_container}>
              <Typography><span className={style.white_text}>Winning Prob{no_vig ? ' (No-Vig)' : ''}:</span><br/> {no_vig ? bet.prob_no_vig : bet.probability}%</Typography>
            </div>
            <div className={style.Calculate_container} style={{
              justifyContent: 'flex-end'
            }}>
              <Typography>
                <span className={style.white_text}>Vig:</span> {bet.vig?.toFixed(2)}<br/>
                <span className={style.white_text}>Width:</span> {!isNaN(bet.width) ? bet.width?.toFixed(0) : 0}<br/>
                {
                  // <span className={style.white_text}>{bet.ev_no_vig} / {bet.prob_no_vig}</span><br/>
                }
                <span className={style.white_text}>Updated:</span> {calculate_time(bet.last_run).replace(/\s/g, '')}
                {
                    selected_filter_id && (mode === "ev") ?
                    <>
                      <br></br>
                      <span className={style.remove_button} onClick={() => {

                        const data = {
                          filterId: selected_filter_id,
                          userId: null,
                          betId: bet.id,
                          groupId: bet.market_id,
                          validUntil: dayjs(bet.start_date).add(3, 'hour').valueOf(),
                          mode: "ev",
                          game_name: `${bet.home_team} vs ${bet.away_team}`,
                          group_name: `${bet.market_name} ${bet.selection_basic} ${bet.selection_line ?? ''} ${bet.selection_points ?? ''}`,
                          bet_name: `${`${bet.market_name} ${bet.selection_basic} ${bet.selection_line ?? ''} ${bet.selection_points ?? ''}`} [${bet.sports_book_name}]`
                        }

                        handleOpenDeleteBetPopup(data);
                      }}>Remove <DeleteIcon></DeleteIcon></span>
                    </> : null
                  }
              </Typography>
            </div>
          </div>
          
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
  },
  header: {
    background: colors.green,
    color: 'white',
  },
  item: {
    padding: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    background: colors.green,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  percentText: {
    fontSize: '20px',
    fontWeight: 'bold',
    paddingLeft: '10px',
    padding: '5px',
    boxSizing: 'border-box',
    
  },
  sport: {
    background: colors.night,
  },
  sportText: {
    fontSize: '20px',
    padding: '5px',
    paddingRight: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  info: {
    background: colors.night,
  },
  infoText: {
    fontSize: '18px',
    padding: '5px',
    paddingRight: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center'
  },
  bets: {
    background: colors.celadon
  },
  delimiter: {
    height: '2px',
    width: '100%',
    backgroundColor: colors.night
  },
  moreButton: {
    cursor: 'pointer',
    width: '100%',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.night,
    color: '#fff',
    borderBottom: `2px outset ${colors.celadon}`,
  }
});

export default EVBetComponent;