import React, { useState, useRef } from 'react';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import MyBetComponent from './MyBetComponent';
import { removeUserBet, deleteUserBet } from './../features/bets/betsSlice';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import html2canvas from 'html2canvas';
import { ReactComponent as ClockLogo } from './img/clock_hour_ticker_time_times_icon.svg';
import logo from './../assets/logo.png';

import {
  TwitterShareButton,
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from 'react-share';

import {
  XIcon,
  FacebookIcon,
  RedditIcon,
  TelegramIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
} from 'react-share';

import { fetchUserTransactions } from '../features/user/userSlice';
import RestServerAPI from './../http/restServerAPI';

import colors from '../utils/colors';
import timestamp_to_string from '../utils/time_converter';

import style from './style/ArbitrageBetStyles.module.scss';

function MyBetGroupComponent({bet}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const componentRef = useRef();

  const [imageURL, setImageURL] = useState('');
  const [isScreenshotMode, setScreenshotMode] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [isShowSharePopUp, setShowSharePopUp] = useState(false);

  const [showNotification, setShowNotification] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const removeBetFromTracker = async () => {
    await dispatch(deleteUserBet(bet.id)); // delete from db
    await dispatch(removeUserBet(bet));

    const userSub = localStorage.getItem('userSub');
    await dispatch(fetchUserTransactions(userSub));
  }

  const handleEditBet = () => {
    setEditMode(true);
  }

  let betStatus = bet.status;
  const regex = /(?<!Half )\/?Won on Leg (\d+)/g;
  const matches = betStatus.matchAll(regex);

  const matchesArray = Array.from(matches);
  if (matchesArray.length > 0) {
    let statusArr = [];
    for (const match of matchesArray) {
      let i = parseInt(match[1], 10); 
      statusArr.push(`on Leg ${i}`);
    }

    betStatus = `Won $${bet.profitResult?.toFixed(2)} ` + statusArr.join(' and ');
  }

  if (betStatus === 'Won') {
    betStatus = `Won $${bet.profitResult?.toFixed(2)}`; 
  }

  const select_place_style = (status) => {
    if (status.includes("Won")) {
      return style.place_info_won;
    } else if (status.includes("Pending") || status.includes("Refunded")) {
      return style.place_info_pending;
    } else if (status === "Lost" || (status.includes("Lost") && !status.includes("Won"))) {
      return style.place_info_lost;
    } else {
      return style.place_info_pending;
    }
  }
  
  const select_status_style = (status) => {
    if (status.includes("Won")) {
      return style.status_info_won;
    } else if (status.includes("Pending") || status.includes("Refunded")) {
      return style.status_info_pending;
    } else if (status === "Lost" || (status.includes("Lost") && !status.includes("Won"))) {
      return style.status_info_lost;
    } else {
      return style.status_info_pending;
    }
  }

  const handleTakeScreenshot = async () => {
    setScreenshotMode(true); // Activate screenshot mode
    setShowSharePopUp(true);

    // waiting for the component update
    setTimeout(async () => {
      const element = componentRef.current;

      // Set the width of the element so that the screenshot matches the desktop version
      element.style.width = '1500px';

      const canvas = await html2canvas(element, {
        windowWidth: 1500, // Specifying the width of the window so that the screenshot looks like on a desktop
        windowHeight: element.scrollHeight, // Adapting the screenshot height to the content
      });

      const dataUrl = canvas.toDataURL('image/png');
      setScreenshotMode(false);

      element.style.width = 'auto'; // Return to original width

      const url = await RestServerAPI.getImageUrl(dataUrl);
      setImageURL(url);
      setIsGenerated(true);
    }, 0);
  };

  const startDate = bet.betInfo.start_date ? 
    dayjs(bet.betInfo.start_date).valueOf() : dayjs(bet.betInfo.game_id.split("^")[4]).valueOf()
  const isStarted = dayjs().valueOf() > startDate;

  return (
    <>
      {
        isEditMode ?
        <div className={`${style.edit_bg} ${isEditMode ? style.active : ''}`}></div> : ''
      }
      <Grid container className={`${style.root} ${style.mybets_root} ${isEditMode ? style.edit : ''}`} >
        { showNotification ? 
          <NotificationPopUp 
            removeBetFromTracker={removeBetFromTracker} 
            setShowNotification={setShowNotification} 
          ></NotificationPopUp> 
          : null
        }
        {
          isShowSharePopUp ? 
          <SharePopUp setShowSharePopUp={setShowSharePopUp} imageURL={imageURL} isGenerated={isGenerated} setIsGenerated={setIsGenerated}></SharePopUp> 
          : null
        }
        {/* Первая строка */}
        <div className={style.header}>
          <div className={style.icon}>
            {/* <SportsSoccerIcon style={{color: colors.night}}></SportsSoccerIcon> */}
            <div className={style.bet_meta}>
              <span>
                {
                  `${bet.betInfo.arbitrage_type ? bet.betInfo.arbitrage_type.replace("surebet-", "") : "EV+"} 
                  ${bet.betInfo ? bet.betInfo.is_live ? " (Live)" : " (Prematch)" : bet.is_live ? " (Live)" : " (Prematch)"}`
                }
              </span>
              <span className={style.delimiter}>|</span>
              <span>{bet.betInfo.sport ?? bet.betInfo.game_id.split("^")[0]}</span>
              <div className={`${style.place_info} ${select_place_style(bet.status)}`}>
                <p>Placed on {timestamp_to_string(bet.timestamp / 1000)}</p>
              </div>
            </div>
            <div className={`${style.status_info} ${select_status_style(bet.status)}`}>
              <p>
                {betStatus} 
                {
                  betStatus.includes('Pending') && isStarted ? 
                  <>
                    <a id="Status" style={{paddingLeft: '5px'}}>
                      <img src={require('./img/tooltips.png')} alt="Close icon" />
                    </a>
                    <Tooltip
                      className='tooltips'
                      key={'right-start'}
                      anchorSelect="#Status"
                      content={`Grade Manually!`}
                      place={'right-start'}
                    />
                  </> : null
                }
              </p>
            </div>
          </div>
          <div className={style.info}>
            <Typography className={style.info_text} style={{marginRight: '10px'}}>
              {
                bet.betInfo.start_date ? 
                timestamp_to_string(dayjs(bet.betInfo.start_date).valueOf() / 1000) :
                timestamp_to_string(dayjs(bet.betInfo.game_id.split("^")[4]).valueOf() / 1000)
              }
              
            </Typography>
            {/* <AccessTimeIcon sx={{paddingLeft: '10px', width: '20px', height: '20px'}}></AccessTimeIcon> */}
            <ClockLogo style={{fill: '#fff', width: '18px', height: '18px'}}></ClockLogo>
            {
              betStatus === "Pending" ? 
              <span className={style.edit_button} onClick={() => handleEditBet()}>Edit</span>
              : null
            }
            <div className={style.close_wrapper}>
              <img src={require('./img/trash.png')} alt="Close icon" onClick={() => setShowNotification(true)} />
            </div>
          </div>
        </div>

        {/* Second and third lines */}
        <MyBetComponent 
          data={bet} 
          isEditMode={isEditMode} 
          setEditMode={setEditMode} 
          handleTakeScreenshot={handleTakeScreenshot}
          isScreenshotMode={false}
          isStarted={isStarted}
        ></MyBetComponent>
        
        {/* {
          showMore ?
          bets_group.map((arb_bet, index) => {
            return <>
              {
                <MyBetComponent arb_bet={arb_bet}></MyBetComponent>
              }
              { bets_group.length !== index + 1 ? <div className={style.items_delimiter}></div> : null }
              
            </>
          })
          : <MyBetComponent arb_bet={bets_group[0]}></MyBetComponent>
        } */}
        {/* {
          bets_group.length > 1 ? 
          <div className={style.more_button} onClick={toggleMore}>
            {
              showMore ? 
                <div className={style.show_button}>
                  <span>Show less</span>
                  <img src={require('./img/arrow.svg').default} alt="Arrow icon" style={{transform: 'rotateX(180deg)'}} />
                </div>
              : <div className={style.show_button}>
                  <span>Show more</span>
                  <img src={require('./img/arrow.svg').default} alt="Arrow icon" />
                </div>
            }
          </div>
          : null
        } */}
      </Grid>
      {/* {imageURL && <img src={imageURL} alt="Скриншот" />} */}
      {
        isScreenshotMode ? 
        <div 
          ref={componentRef} 
          className={style.screenshot_container} 
          style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}
        >
          {/* Content you want to screenshot */}
          <div className={style.screenshot_logo}>
            <img src={logo} style={{width: '240px'}} alt="BetSmartAI" />
          </div>
          <Grid container className={`${style.root} ${style.mybets_root} ${isEditMode ? style.edit : ''}`} >
            { showNotification ? 
              <NotificationPopUp 
                removeBetFromTracker={removeBetFromTracker} 
                setShowNotification={setShowNotification} 
              ></NotificationPopUp> 
              : null
            }
            {/* First line */}
            <div className={style.header}>
              <div className={style.icon}>
                {/* <SportsSoccerIcon style={{color: colors.night}}></SportsSoccerIcon> */}
                <span>
                  {
                    `${bet.betInfo.arbitrage_type ? bet.betInfo.arbitrage_type.replace("surebet-", "") : "EV+"} 
                    ${bet.betInfo ? bet.betInfo.is_live ? " (Live)" : " (Prematch)" : bet.is_live ? " (Live)" : " (Prematch)"}`
                  }
                </span>
                <span className={style.delimiter}>|</span>
                <span>{bet.betInfo.sport ?? bet.betInfo.game_id.split("^")[0]}</span>
                <div className={`${style.place_info} ${select_place_style(bet.status)}`}>
                  <p>Placed on {timestamp_to_string(bet.timestamp / 1000)}</p>
                </div>
                <div className={`${style.status_info} ${select_status_style(bet.status)}`}>
                  <p>
                    {betStatus} 
                    {
                      betStatus.includes('Pending') ? 
                      <>
                        <a id="Status" style={{paddingLeft: '5px'}}>
                          <img src={require('./img/tooltips.png')} alt="Close icon" />
                        </a>
                        <Tooltip
                          className='tooltips'
                          key={'right-start'}
                          anchorSelect="#Status"
                          content={`Grade Manually!`}
                          place={'right-start'}
                        />
                      </> : null
                    }
                  </p>
                </div>
              </div>
              <div className={style.info}>
                <Typography className={style.info_text} style={{marginRight: '10px'}}>
                  {
                    bet.betInfo.start_date ? 
                    timestamp_to_string(dayjs(bet.betInfo.start_date).valueOf() / 1000) :
                    timestamp_to_string(dayjs(bet.betInfo.game_id.split("^")[4]).valueOf() / 1000)
                  }
                  
                </Typography>
                {/* <AccessTimeIcon sx={{paddingLeft: '10px', width: '20px', height: '20px'}}></AccessTimeIcon> */}
                <ClockLogo style={{fill: '#fff', width: '18px', height: '18px'}}></ClockLogo>
                {
                  !isScreenshotMode ? 
                  <>
                    <span className={style.edit_button} onClick={() => handleEditBet()}>Edit</span>
                    <div className={style.close_wrapper}>
                      <img src={require('./img/trash.png')} alt="Close icon" onClick={() => setShowNotification(true)} />
                    </div>
                  </> : null
                }
              </div>
            </div>

            {/* Second and third lines */}
            <MyBetComponent 
              data={bet} 
              isEditMode={isEditMode} 
              setEditMode={setEditMode} 
              handleTakeScreenshot={handleTakeScreenshot}
              isScreenshotMode={isScreenshotMode}
              isStarted={isStarted}
            ></MyBetComponent>
          </Grid>
        </div> : null
      }
    </>
  );
}

function SharePopUp({setShowSharePopUp, imageURL, isGenerated, setIsGenerated}) {
  return(
    <div className={style.modular_wrapper}>
      <div className={`${style.modular_item}`}>
        <div className={style.top}>
          <div className={style.name_wrapper}>
            <div>
              {
                isGenerated ?
                <>
                  <span className={style.share_name}>Share your bet</span><br></br>
                  <br></br>
                  <div className={style.share_icons}>
                    <TwitterShareButton
                      url={imageURL}
                      title={"Check out my bet!"}
                      hashtags={["betting", "sports", "surebetfusion"]}
                      className="Demo__some-network__share-button"
                    >
                      <XIcon size={32} round />
                    </TwitterShareButton>

                    <FacebookShareButton
                      url={imageURL}
                      quote={"Check out my bet!"}
                      hashtag="#surebetfusion"
                      className="Demo__some-network__share-button"
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>

                    <RedditShareButton
                      url={imageURL}
                      title={"Check out my bet!"}
                      className="Demo__some-network__share-button"
                    >
                      <RedditIcon size={32} round />
                    </RedditShareButton>

                    <TelegramShareButton
                      url={imageURL}
                      title={"Check out my bet!"}
                      className="Demo__some-network__share-button"
                    >
                      <TelegramIcon size={32} round />
                    </TelegramShareButton>

                    <WhatsappShareButton
                      url={imageURL}
                      title={"Check out my bet!"}
                      separator=":: "
                      className="Demo__some-network__share-button"
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>

                  </div>
                </>
                : <span className={style.share_name}>Generating screenshot, please wait...</span>
              }
              
            </div>
          </div>
        </div>
        
        <div className={style.action_wrapper}>
          
          <button onClick={() => {
              setShowSharePopUp(false);
              setIsGenerated(false);
            }}>
            <span>Close</span>
          </button>
        </div>
        
      </div>
    </div>
  )
}

function NotificationPopUp(props) {
  return(
    <div className={style.modular_wrapper}>
      <div className={`${style.modular_item} ${style.red_border}`}>
        <div className={style.top}>
          <div className={style.name_wrapper}>
            <img className={style.icon} src={require('./img/trashbox.png')} alt="" />
            <div>
              <span className={style.name}>Are you sure you want to <span className={style.red}>delete</span> this bet from the Bets Tracker?</span><br></br>
              <br></br>
              <span className={style.subname}>Once deleted, all data related to this bet will be deleted permanently</span>
            </div>
          </div>
        </div>
        
        <div className={style.action_wrapper}>
          
          <button onClick={() => {
              props.setShowNotification(false);
            }}>
            <span>Cancel</span>
          </button>

          <button className={style.red_button} onClick={() => {
            props.removeBetFromTracker();
            props.setShowNotification(false);
          }}>
            <span>Delete</span>
          </button>
        </div>
        
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
    backgroundColor: colors.celadon
  },
  header: {
    background: colors.green,
    color: 'white',
  },
  item: {
    padding: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'
  },
  percentText: {
    fontSize: '20px',
    fontWeight: 'bold',
    paddingLeft: '10px',
    padding: '5px',
    boxSizing: 'border-box',
    
  },
  sport: {
    background: colors.night,
  },
  sportText: {
    fontSize: '20px',
    padding: '5px',
    paddingRight: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center'
  },
  bets: {
    background: colors.celadon
  }
});

export default MyBetGroupComponent;