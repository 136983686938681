class RestServerAPI {
  async register_user(userSub, userInfo) {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userSub, ...userInfo })
    });

    if (response.status === 200) {
      return await response.json();
    } else {
      //throw new Error('Ошибка при регистрации пользователя');
    }
  }

  async get_preferences(userSub) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/preferences/${userSub}`);
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      const preferences = await response.json();

      return preferences;
    } catch (error) {
      console.error('Error fetching preferences:', error);
    }
  }

  async update_preferences(userSub, preferencesData) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/preferences/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userSub, preferencesData })
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      const preferences = await response.json();

      return preferences;
    } catch (error) {
      console.error('Error fetching preferences:', error);
    }
  }

  async check_email(email) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/users/check-email/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });
  
      const { value } = await response.json();

      return value;
    } catch (error) {
      console.error('Error checking email:', error);
    }
  }

  async get_odds_history(betId, additionalBetIds) {
    try {
      const userSub = localStorage.getItem('userSub');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/bets/odds-history/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userSub, betId, additionalBetIds })
      });
  
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching odds history:', error);
    }
  }

  async sendLoginInfoToServer(loginData) {
    try {
        await fetch(`${process.env.REACT_APP_API_URL}api/login-info/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginData),
        });
    } catch (error) {
        console.error('Error sending login information:', error);
    }
  }

  async getImageUrl(imageDataUrl) {
    // Convert imageDataUrl to Blob
    const response = await fetch(imageDataUrl);
    const blob = await response.blob();

    // Use FormData to build the request
    let formData = new FormData();
    formData.append('screenshot', blob, 'screenshot.png');

    try {
      let result = await fetch(`${process.env.REACT_APP_API_URL}api/screenshots`, {
        method: 'POST',
        body: formData,
      });

      result = await result.json();

      if (result.imageName) {
        return `https://app.surebetfusion.com/screenshots/${result.imageName}`;
        //console.log('Uploaded image name:', result.imageName);
      } else {
        //console.error('Server did not return image name:', result);
        return null;
      }
    } catch (error) {
      console.error('Upload error:', error);
      return null;
    }
  }
}

export default new RestServerAPI();