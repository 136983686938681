import React, { useState } from 'react';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BetComponent from './BetComponent';

import colors from '../utils/colors';
import timestamp_to_string from '../utils/time_converter';

import style from './style/ArbitrageBetStyles.module.scss';

/**
 * ArbitrageBetComponent
 * 
 * This component is responsible for displaying a single group of arbitrage bets. It showcases
 * the arbitrage type, the associated sport, and a timestamp for when the game starts. Each bet
 * within the group is represented by a child component, BetComponent, that further details
 * the specifics of the bet.
 * 
 * Utilizing the useState hook from React, this component maintains a local state to control
 * the visibility of the "Show more" or "Show less" functionality, allowing users to toggle
 * the display of detailed information for all bets in the group or just a summarized view.
 * 
 * The component structure is divided into three main parts:
 * - The header section displays the arbitrage type and sport, alongside the game's starting
 *   time converted from a UNIX timestamp to a readable string.
 * - The main content dynamically renders either a detailed view of all bets in the group or
 *   a collapsed view showing limited information, based on the user's interaction with the
 *   "Show more" button.
 * - The "Show more/less" button allows users to expand or collapse the detailed bet information,
 *   enhancing the usability and navigability of the displayed data.
 * 
 * This component aims to provide a concise yet informative presentation of arbitrage betting
 * opportunities, with user-friendly interactions for exploring bet details.
 */
function ArbitrageBetComponent({bets_group, handleOpenDeleteBetPopup}) {
  const [showMore, setShowMore] = useState(false);
  const classes = useStyles();

  const toggleMore = () => {
    setShowMore(!showMore);
  }

  return (
    <Grid container className={style.root} >
      {/* First line */}
      <div className={style.header}>
        <div className={style.icon}>
          {/* <SportsSoccerIcon style={{color: colors.night}}></SportsSoccerIcon> */}
          <span>{bets_group[0].arbitrage_type.replace("surebet-", "")}</span>
          <span className={style.delimiter}>|</span>
          <span>{bets_group[0].game_id.split("^")[0]}</span>
        </div>
        {
          bets_group[0].status === "suspended" || bets_group[0].status === "half" ? <div style={{padding: "0 5px 0 5px"}}>
            <span style={{color: "#FFF700"}}>Game on stoppage</span>
          </div> : null
        }
        <div className={style.info}>
          <Typography className={style.info_text}>
            {
              timestamp_to_string(dayjs(bets_group[0].game_id.split("^")[4]).valueOf() / 1000)
            }
            
          </Typography>
          <AccessTimeIcon sx={{paddingLeft: '10px', width: '20px', height: '20px'}}></AccessTimeIcon>
        </div>
      </div>

      {/* Second and third lines */}
      {
        showMore ?
        bets_group.slice(0, 20).map((arb_bet, index) => {
          return <>
            {
              <BetComponent key={arb_bet.id} arb_bet={arb_bet} bets_group={bets_group} handleOpenDeleteBetPopup={handleOpenDeleteBetPopup}></BetComponent>
            }
            { bets_group.length !== index + 1 ? <div className={style.items_delimiter}></div> : null }
            
          </>
        })
        : <BetComponent arb_bet={bets_group[0]} bets_group={bets_group} handleOpenDeleteBetPopup={handleOpenDeleteBetPopup}></BetComponent>
      }
      {
        bets_group.length > 1 ? 
        <div className={style.more_button} onClick={toggleMore}>
          {
            showMore ? 
              <div className={style.show_button}>
                <span>Show less</span>
                <img src={require('./img/arrow.svg').default} alt="Arrow icon" style={{transform: 'rotateX(180deg)'}} />
              </div>
            : <div className={style.show_button}>
                <span>Show more</span>
                <img src={require('./img/arrow.svg').default} alt="Arrow icon" />
              </div>
          }
        </div>
        : null
      }
    </Grid>
  );
}

const useStyles = makeStyles({
  root: {
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
    backgroundColor: colors.celadon
  },
  header: {
    background: colors.green,
    color: 'white',
  },
  item: {
    padding: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'
  },
  percentText: {
    fontSize: '20px',
    fontWeight: 'bold',
    paddingLeft: '10px',
    padding: '5px',
    boxSizing: 'border-box',
    
  },
  sport: {
    background: colors.night,
  },
  sportText: {
    fontSize: '20px',
    padding: '5px',
    paddingRight: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center'
  },
  bets: {
    background: colors.celadon
  }
});

export default ArbitrageBetComponent;