import { createSlice } from '@reduxjs/toolkit';
import bookmakers from './../../utils/bookmakers';
import sports from './../../utils/sports';

const oddsFormatDefault = 'American';
const currencyDefault = 'USD';
const countryDefault = 'Canada';
const roundingDefault = '0.01';
const defaultPercentage = [0, 50];
const defaultTimeToMatch = 10;

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState: {
    country: countryDefault,
    currency: currencyDefault,
    oddsFormat: oddsFormatDefault,
    rounding: roundingDefault,
    minPercentage: defaultPercentage[0],
    maxPercentage: defaultPercentage[1],
    minOdds: null,
    maxOdds: null,
    timeToMatch: defaultTimeToMatch,
    sports: sports,
    bookmakers: bookmakers,
    requiredBookmakers: [],
    noVig: false
  },
  reducers: {
    updateBookmakers: (state, message) => {
      state.bookmakers = message.payload || bookmakers;
    },
    updateRequiredBookmakers: (state, message) => {
      state.requiredBookmakers = message.payload || [];
    },
    updateSports: (state, message) => {
      state.sports = message.payload || sports;
    },
    updateCountry: (state, message) => {
      state.country = message.payload || countryDefault;
    },
    updateOddsFormat: (state, message) => {
      state.oddsFormat = message.payload || oddsFormatDefault;
    },
    updateCurrency: (state, message) => {
      state.currency = message.payload || currencyDefault;
    },
    updateRounding: (state, message) => {
      state.rounding = message.payload || roundingDefault;
    },
    updateNoVig: (state, message) => {
      state.noVig = message.payload;
    },
    updateOddsRange: (state, message) => {
      state.minOdds = message.payload[0];
      state.maxOdds = message.payload[1];
    },
    updatePercentageRange: (state, message) => {
      if (
        message.payload[0] && 
        message.payload[0] >= defaultPercentage[0] && 
        message.payload[0] <= defaultPercentage[1]
      ) {
        state.minPercentage = message.payload[0];
      } else {
        state.minPercentage = defaultPercentage[0];
      }

      if (message.payload[1] && 
        message.payload[1] >= defaultPercentage[0] && 
        message.payload[1] <= defaultPercentage[1]
      ) {
        state.maxPercentage = message.payload[1];
      } else {
        state.maxPercentage = defaultPercentage[1];
      }
    },
    updateTimeToMatch: (state, message) => {
      state.timeToMatch = message.payload || defaultTimeToMatch;
    },
    updateAll: (state, message) => {
      const data = message.payload;
      if (data) {
        if (data.bookmakers) {
          state.bookmakers = data.bookmakers || bookmakers;
        }
        if (data.requiredBookmakers) {
          state.requiredBookmakers = data.requiredBookmakers || [];
        }
        if (data.sports) {
          state.sports = data.sports || sports;
        }
        if (data.country) {
          state.country = data.country || countryDefault;
        }
        if (data.oddsFormat) {
          state.oddsFormat = data.oddsFormat || oddsFormatDefault;
        }
        if (data.currency) {
          state.currency = data.currency || currencyDefault;
        }
        if (data.minPercentage) {
          if (
            data.minPercentage && 
            data.minPercentage >= defaultPercentage[0] && 
            data.minPercentage <= defaultPercentage[1]
          ) {
            state.minPercentage = data.minPercentage;
          } else {
            state.minPercentage = defaultPercentage[0];
          }
        }
        if (data.maxPercentage) {
          if (data.maxPercentage && 
            data.maxPercentage >= defaultPercentage[0] && 
            data.maxPercentage <= defaultPercentage[1]
          ) {
            state.maxPercentage = data.maxPercentage;
          } else {
            state.maxPercentage = defaultPercentage[1];
          }
        }
        if (data.minOdds) {
          state.minOdds = data.minOdds;
        }
        if (data.maxOdds) {
          state.maxOdds = data.maxOdds;
        }
        if (data.timeToMatch) {
          state.timeToMatch = data.timeToMatch || defaultTimeToMatch;
        }
        if (data.rounding) {
          state.rounding = data.rounding || roundingDefault;
        }
        if (data.noVig) {
          state.noVig = data.noVig;
        }
      }
    },
  },
});

export const { 
  updateBookmakers,
  updateRequiredBookmakers,
  updateSports,
  updateCountry,
  updateOddsFormat,
  updateCurrency,
  updateRounding,
  updatePercentageRange,
  updateOddsRange,
  updateTimeToMatch,
  updateNoVig,
  updateAll
} = preferencesSlice.actions;

export default preferencesSlice.reducer;