import style from './styles/AccountStyle.module.scss';

import React, { useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';

import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';

import { useSelector, useDispatch } from 'react-redux';
import { addUserTransaction } from '../../features/user/userSlice';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { getCurrentTimestampFormatted } from '../../utils/time_converter';

/**
 * Bankroll Component
 * 
 * This component is designed for managing the user's bankroll within the account settings.
 * It features interactive buttons to increase or decrease the balance, reflecting the user's
 * financial transactions related to their account.
 * 
 * Upon interacting with the "+" or "-" buttons, the component dynamically renders either the
 * `UpBalance` or `DownBalance` component, allowing users to adjust their bankroll accordingly.
 * This dynamic rendering is facilitated by managing the state of `moduleName` through the useState
 * hook, which determines which component to display based on the user's selection.
 */
export default function Bankroll(props) {
    const [moduleName, setModuleName] = useState('upbalance');

    return(
        <>
            <div className={`${style.container} ${style.bank_container}`}>
              <div className={style.item_action}>
                <button className={style.up} onClick={() => setModuleName('upbalance')}>
                  <span>+$</span>
                </button>
                <button className={style.down} onClick={() => setModuleName('downbalance')}>
                  <span>-$</span>
                </button>
              </div>
              {moduleName ? 
                <div className={style.modular_wrapper}>
                  {moduleName === 'upbalance' ? <UpBalance setModuleName={setModuleName} /> : ''}
                  {moduleName === 'downbalance' ? <DownBalance setModuleName={setModuleName} /> : ''}
                </div> : ''
              }
            </div>
        </>
    )
}

const sorting_types_local = [
  "Date, Most Recent to Oldest",
  "Date, Oldest to Recent",
  "Value, From largest to smallest",
  "Value, From smallest to largest",
];
  
function sortTransactions(transactions, sortType) {
  if (sortType === sorting_types_local[0]) {
    return transactions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  } else if (sortType === sorting_types_local[1]) {
    return transactions.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  } else if (sortType === sorting_types_local[2]) {
    return transactions.sort((a, b) => b.amount - a.amount);
  } else if (sortType === sorting_types_local[3]) {
    return transactions.sort((a, b) => a.amount - b.amount);
  }
  return transactions;
}

function UpBalance(props) {
    const dispatch = useDispatch();
  
    const [sorting, setSorting] = useState(sorting_types_local[0]);
  
    const [amountToAdd, setAmountToAdd] = useState(0);
  
    const userTransactions = useSelector(state => state.user.transactions);
    const [transactionsLocal, setTransactionsLocal] = useState([...userTransactions].reverse());
  
    const [totalAmount, setTotalAmount] = useState(userTransactions.reduce((prev, transaction) => prev + transaction.amount, 0));

    const [showPopUp, setShowPopUp] = useState(false);
  
    useEffect(() => {
      setTotalAmount(userTransactions.reduce((prev, transaction) => prev + transaction.amount, 0));
      setTransactionsLocal(sortTransactions([...userTransactions].reverse(), sorting));
    }, [userTransactions]);
  
    const addTransaction = async () => {
      if (Number(amountToAdd) <= 0) {
        setShowPopUp(true);
        return;
      }

      const data = {
        transactionId: Math.random().toString(36).substr(2, 9) + Date.now().toString(36),
        timestamp: getCurrentTimestampFormatted(),
        amount: Number(amountToAdd),
        balance: totalAmount + Number(amountToAdd),
      }
  
      await dispatch(addUserTransaction(data));
      setAmountToAdd(0);
    }
  
    const setBalance = (value) => {
      setAmountToAdd(value);
    }
  
    return(
      <div className={style.modular_item}>
        <div className={style.top}>
          <div className={style.name_wrapper}>
            <span className={`${style.subname} ${style.green}`}>Top up your balance!</span>
          </div>
        </div>
        <div className={style.middle_wrapper}>
            <div className={style.balance_wrapper}>
              <span>Current Balance</span>
              <div className={style.value}>
                  <span>$</span>
                  <input 
                    type="number" 
                    value={totalAmount?.toFixed(2)}
                  />
                  <span>USD</span>
              </div>
            </div>
            <div className={style.list_wrapper}>
                <span>Most Popular Amounts </span>
                <div className={style.value_list}>
                    <button onClick={() => setBalance(100)}>
                        <div>
                        <span>$</span>
                        <span>100</span>
                        </div>
                        <span>USD</span>
                    </button>
                    <button onClick={() => setBalance(500)}>
                        <div>
                        <span>$</span>
                        <span>500</span>
                        </div>
                        <span>USD</span>
                    </button>
                    <button onClick={() => setBalance(1000)}>
                        <div>
                        <span>$</span>
                        <span>1000</span>
                        </div>
                        <span>USD</span>
                    </button>
                </div>
            </div>
        </div>
        <div className={style.action_wrapper}>
          <div className={`${style.input_wrapper} ${style.white}`}>
            <span>Add Other Amount</span>
            <div className={style.value}>
                <span>$</span>
                <input 
                    type="number" 
                    value={amountToAdd}
                    onChange={(e) => setAmountToAdd(e.target.value)}
                  />
                <span>USD</span>
            </div>
          </div>
          <div className={`${style.input_wrapper}`}>
            <span>New Balance</span>
            <div className={style.value}>
                <span>$</span>
                <input 
                  type="number" 
                  value={(totalAmount + Number(amountToAdd))?.toFixed(2)}
                />
                <span>USD</span>
            </div>
          </div>
          <button onClick={addTransaction}>
            <span>Update</span>
          </button>
        </div>
        { (Number(amountToAdd) <= 0) ?
        <div style={{marginBottom: '10px'}}><span style={{color: '#FF5252'}}>Add Amount must be greater than zero</span></div> : null
        }
        <div className={style.table_wrapper}>
          <div className={style.table_header}>
            <span className={style.name}>Bankroll History</span>
            {/* <div className={style.dropdown_button}>
              <Dropdown>
                  <MenuButton>
                      <span>{sorting}</span>
                      <img src={require('./img/filter_white.png')} alt="" />
                  </MenuButton>
  
                  <Menu style={{zIndex: '20'}} slots={{ listbox: Listbox }}>
                    {sorting_types_local.map(s => <MenuItem sx={sorting === s ? {color: colors.green} : {}} onClick={() => {
                        setSorting(s);
                        setTransactionsLocal(sortTransactions(transactionsLocal, s));
                    }}>{s}</MenuItem>)}
                  </Menu>
              </Dropdown>
            </div> */}
          </div>
          <div className={style.modular_table}>
              <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                      <TableHead>
                      <TableRow>
                          <TableCell align="center">Transaction ID</TableCell>
                          <TableCell align="center">Date/Time</TableCell>
                          <TableCell align="center">Amount</TableCell>
                          <TableCell align="center">Balance</TableCell>
                      </TableRow>
                      </TableHead>
                      <TableBody>
                      {
                        transactionsLocal.map((trx) => (
                          <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center">{trx.transactionId}</TableCell>
                            <TableCell align="center">{trx.timestamp}</TableCell>
                            <TableCell align="center">
                              {trx.amount > 0 ? '' : '-'}
                              $<span className={trx.amount > 0 ? style.green : style.red}>{Math.abs(trx.amount?.toFixed(2))}</span>
                            </TableCell>
                            <TableCell align="center">${trx.balance?.toFixed(2)}</TableCell>
                          </TableRow>
                        ))
                      }
                      </TableBody>
                  </Table>
              </TableContainer>
          </div>
        </div>
      </div>
    )
  }

  function DownBalance(props) {
    const dispatch = useDispatch();
    const [sorting, setSorting] = useState(sorting_types_local[0]);
    const [amountToWithdraw, setAmountToWithdraw] = useState(0);
  
    const userTransactions = useSelector(state => state.user.transactions);
    const [transactionsLocal, setTransactionsLocal] = useState([...userTransactions].reverse());
  
    const [totalAmount, setTotalAmount] = useState(userTransactions.reduce((prev, transaction) => prev + transaction.amount, 0));
  
    useEffect(() => {
      setTotalAmount(userTransactions.reduce((prev, transaction) => prev + transaction.amount, 0));
      setTransactionsLocal(sortTransactions([...userTransactions].reverse(), sorting));
    }, [userTransactions]);
  
    const addTransaction = async () => {
      if (Number(amountToWithdraw) <= 0) {
        return;
      }

      const data = {
        transactionId: Math.random().toString(36).substr(2, 9) + Date.now().toString(36),
        timestamp: getCurrentTimestampFormatted(),
        amount: -1 * Number(amountToWithdraw),
        balance: totalAmount - Number(amountToWithdraw),
      }
  
      await dispatch(addUserTransaction(data));
      setAmountToWithdraw(0);
    }
  
    return(
      <>
      <div className={style.modular_item}>
        <div className={style.top}>
          <div className={style.name_wrapper}>
            <span className={`${style.subname} ${style.red}`}>Withdraw Balance </span>
          </div>
        </div>
        <div className={`${style.balance_wrapper} ${style.withdraw_block}`}>
          <span>Current Balance</span>
          <div className={style.value}>
              <span>$</span>
              <input 
                type="number" 
                value={totalAmount}
              />
              <span>USD</span>
          </div>
        </div>
        <div className={style.action_wrapper}>
          <div className={`${style.input_wrapper} ${style.white}`}>
            <span>Withdraw Amount</span>
            <div className={style.value}>
                <span>$</span>
                <input 
                    
                    value={amountToWithdraw}
                    onChange={(e) => setAmountToWithdraw(e.target.value)}
                  />
                <span>USD</span>
            </div>
          </div>
          <div className={`${style.input_wrapper}`}>
            <span>New Balance</span>
            <div className={`${style.value} ${style.red_border}`}>
                <span>$</span>
                <input 
                  type="number" 
                  value={totalAmount - Number(amountToWithdraw)}
                />
                <span>USD</span>
            </div>
          </div>
          <button onClick={addTransaction}>
            <span>Update</span>
          </button>
        </div>
        { (Number(amountToWithdraw) <= 0) ?
        <div style={{marginBottom: '10px'}}><span style={{color: '#FF5252'}}>Withdraw Amount must be greater than zero</span></div> : null
        }
        <div className={style.table_wrapper}>
          <div className={style.table_header}>
            <span className={style.name}>Bankroll History</span>
            {/* <div className={style.dropdown_button}>
              <Dropdown>
                  <MenuButton>
                      <span>{sorting}</span>
                      <img src={require('./img/filter_white.png')} alt="" />
                  </MenuButton>
  
                  <Menu style={{zIndex: '20'}} slots={{ listbox: Listbox }}>
                    {sorting_types_local.map(s => <MenuItem sx={sorting === s ? {color: colors.green} : {}} onClick={() => {
                        setSorting(s);
                        setTransactionsLocal(sortTransactions(transactionsLocal, s));
                    }}>{s}</MenuItem>)}
                  </Menu>
              </Dropdown>
            </div> */}
          </div>
          <div className={style.modular_table}>
              <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                      <TableHead>
                      <TableRow>
                          <TableCell align="center">Transaction ID</TableCell>
                          <TableCell align="center">Date/Time</TableCell>
                          <TableCell align="center">Amount</TableCell>
                          <TableCell align="center">Balance</TableCell>
                      </TableRow>
                      </TableHead>
                      <TableBody>
                      {
                        transactionsLocal.map((trx) => (
                          <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center">{trx.transactionId}</TableCell>
                            <TableCell align="center">{trx.timestamp}</TableCell>
                            <TableCell align="center">
                              {trx.amount > 0 ? '' : '-'}
                              $<span className={trx.amount > 0 ? style.green : style.red}>{Math.abs(trx.amount?.toFixed(2))}</span>USD
                            </TableCell>
                            <TableCell align="center">${trx.balance?.toFixed(2)}USD</TableCell>
                          </TableRow>
                        ))
                      }
                      </TableBody>
                  </Table>
              </TableContainer>
          </div>
        </div>
      </div>
      </>
    )
  }

  const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#99CCF3',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E6',
    700: '#0059B3',
    800: '#004C99',
    900: '#003A75',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const Listbox = styled('ul')(
    ({ theme }) => `
    font-size: 14px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    min-width: 200px;
    border-radius: 5px; 
    overflow: auto;
    outline: 0px;
    background: #383838;
    border: none;
    color: white;
    box-shadow: none;
    z-index: 1;
    `,
  );
  
  const MenuItem = styled(BaseMenuItem)(
    ({ theme }) => `
    list-style: none;
    padding: 12px;
    border-radius: 0;
    cursor: default;
    user-select: none;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${menuItemClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    }
  
    &.${menuItemClasses.disabled} {
      color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${menuItemClasses.disabled}) {
      background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[50]};
      color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
    }
    `,
  );
  
  const MenuButton = styled(BaseMenuButton)(
    ({ theme }) => `
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    padding: 8px 16px;
    color: white;
    cursor: pointer;
    background: transparent;
    border: none;
    font-weight: 600;
  
    &.Mui-expanded {
        .arrow {
            transform: rotate(180deg);
        }
    }
    `,
  );