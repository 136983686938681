import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAllUsers = createAsyncThunk(
  'admins/fetchAllUsers',
  async (_, { rejectWithValue }) => {
    try {
      const userSub = localStorage.getItem('userSub');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/admin/users/${userSub}`);
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const users = await response.json();
      
      return users;
    } catch (error) {
      console.error('Error fetching all users:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const blockUsers = createAsyncThunk(
  'admins/blockUsers',
  async (userIds, { rejectWithValue }) => {
    try {
      const userSub = localStorage.getItem('userSub');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/admin/block/${userSub}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({userIds}),
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

    } catch (error) {
      console.error('Error blocking users:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const unblockUsers = createAsyncThunk(
  'admins/unblockUsers',
  async (userIds, { rejectWithValue }) => {
    try {
      const userSub = localStorage.getItem('userSub');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/admin/unblock/${userSub}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({userIds}),
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

    } catch (error) {
      console.error('Error unblocking users:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const deleteUsers = createAsyncThunk(
  'admins/deleteUsers',
  async (userIds, { rejectWithValue }) => {
    try {
      const userSub = localStorage.getItem('userSub');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/admin/delete/${userSub}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({userIds}),
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

    } catch (error) {
      console.error('Error unblocking users:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateUser = createAsyncThunk(
  'admins/deleteUsers',
  async (userInfo, { rejectWithValue }) => {
    try {
      const userSub = localStorage.getItem('userSub');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/admin/update-user/${userSub}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({userInfo}),
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const user = await response.json();
      return user;
    } catch (error) {
      console.error('Error updating user:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const createUser = createAsyncThunk(
  'admins/createUser',
  async (userInfo, { rejectWithValue }) => {
    try {
      const userSub = localStorage.getItem('userSub');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/admin/create-user/${userSub}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({userInfo}),
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const user = await response.json();
      return user;
    } catch (error) {
      console.error('Error creating user:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchUserLogins = createAsyncThunk(
  'admins/userLogins',
  async (userId, { rejectWithValue }) => {
    try {
      const userSub = localStorage.getItem('userSub');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/admin/logins/${userSub}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({userId}),
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const logins = await response.json();
      return logins;
    } catch (error) {
      console.error('Error getting logins:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAnalytics = createAsyncThunk(
  'admins/fetchAnalytics',
  async (_, { rejectWithValue }) => {
    try {
      const userSub = localStorage.getItem('userSub');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/admin/analytics/${userSub}`);
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const analytics = await response.json();
      return analytics;
    } catch (error) {
      console.error('Error getting analytics:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchUserInvoices = createAsyncThunk(
  'admins/userInvoices',
  async (userId, { rejectWithValue }) => {
    try {
      const userSub = localStorage.getItem('userSub');
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/admin/invoices/${userSub}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({userId}),
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const invoices = await response.json();
      return invoices;
    } catch (error) {
      console.error('Error getting user invoices:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const adminsSlice = createSlice({
  name: 'admins',
  initialState: {
    users: [],
    selectedUser: {},
    loginsHistory: [],
    analytics: {},
    invoices: {},
    status: 'idle', // request status
    error: null,
  },
  reducers: {
    setUsers: (state, message) => {
      state.users = message.payload;
    },
    setSelectedUser: (state, message) => {
      state.selectedUser = message.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selectedUser = action.payload;
      })
      .addCase(fetchUserLogins.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loginsHistory = action.payload ?? [];
      })
      .addCase(fetchAnalytics.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.analytics = action.payload ?? {};
      })
      .addCase(fetchUserInvoices.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.invoices = action.payload ?? [];
      })
    }
});

export const { 
  setUsers,
  setSelectedUser,
} = adminsSlice.actions;

export default adminsSlice.reducer;