import { io } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import { 
  setArbBets, 
  setHasNext, 
  setPage, 
  setTotal,
  setSelectedBet
} from '../features/bets/betsSlice';
import { 
  setNotifications,
  addNotification
} from '../features/user/userSlice';
import { 
  setIsInitialLoading, 
  setIsLoadingMore
} from '../features/filter/filterSlice';
import { 
  setNotified
} from '../features/settings/settingsSlice';
import store from '../store';
import playSound from './../utils/play_sound';

class SocketServerAPI {
  constructor() {}

  init() {
    this.socket = io(process.env.REACT_APP_API_URL, {
      query: {
        token: uuidv4(),
        userId: localStorage.getItem('userSub'),
      }
    });
    this.initEventHandlers();
  }

  initEventHandlers() {
    this.socket.on('connect', this.handleConnect.bind(this));
    this.socket.on('filteredBetsDataPrematch', this.handleFilteredBetsData.bind(this, 'prematch'));
    this.socket.on('filteredBetsDataLive', this.handleFilteredBetsData.bind(this, 'live'));
    this.socket.on('filteredBetsDataEV', this.handleFilteredBetsData.bind(this, 'ev'));
    this.socket.on('filteredBetsDataEVLive', this.handleFilteredBetsData.bind(this, 'evLive'));
    this.socket.on('filteredBetsDataUpdatedPrematch', this.handleFilteredBetsDataUpdated.bind(this, 'prematch'));
    this.socket.on('filteredBetsDataUpdatedLive', this.handleFilteredBetsDataUpdated.bind(this, 'live'));
    this.socket.on('filteredBetsDataUpdatedEV', this.handleFilteredBetsDataUpdated.bind(this, 'ev'));
    this.socket.on('filteredBetsDataUpdatedEVLive', this.handleFilteredBetsDataUpdated.bind(this, 'evLive'));
    this.socket.on('notificationsAll', this.handleNotificationsAll.bind(this));
    this.socket.on('notification', this.handleAddNotification.bind(this));
  }

  async handleConnect() {
    const state = store.getState();
  
    const page = 1;
    const limit = 10;
    const types = state.filter.types;
    const sports = state.filter.selectedSports;
    const bookmakers = state.filter.selectedBookmakers;
    const required_bookmakers = state.filter.selectedRequiredBookmakers;
    const range = state.filter.percentageRange;
    const start_date = state.filter.startDate;
    const end_date = state.filter.endDate;
    const game_period = state.filter.gamePeriod;
    const bet_period = state.filter.betPeriod;
    const sorting_type = state.bets.sorting;

    //const user_min_odds = state.preferences.minOdds;
    //const user_max_odds = state.preferences.maxOdds;
    const min_odds = state.filter.minOdds;
    const max_odds = state.filter.maxOdds;

    const selected_filter_id = state.filter.selectedFilterId;
  
    // Resend the filter when reconnecting
    this.socket.emit('requestFilteredBetsPrematch', {
      page,
      limit,
      types,
      sports,
      bookmakers,
      required_bookmakers,
      range,
      start_date, 
      end_date,
      game_period,
      bet_period,
      sorting_type,
      minOdds: min_odds,
      maxOdds: max_odds,
      selected_filter_id
    });
    // TODO add requests for live and ev+
  }

  handleFilteredBetsData(mode, betsData) {
    // get current page
    const state = store.getState();
    const current_page = state.bets.page[mode];

    // get new page index
    const page = betsData.page;

    if (current_page + 1 === page) {
      store.dispatch(setPage({mode, data: page}));

      const total = betsData.total;
      store.dispatch(setTotal({mode, data: total}));

      store.dispatch(setArbBets({mode, data: betsData.data}));

      //set hasNext
      store.dispatch(setHasNext({mode, data: betsData.next}));

      store.dispatch(setIsInitialLoading(false));
      store.dispatch(setIsLoadingMore(false));
    }
  }

  handleFilteredBetsDataUpdated(mode, betsData) {
    let flagNew = false;

    if (mode === "prematch" || mode === "live") {
      const state = store.getState();

      // set of unique indexes
      const ids = new Set();
      state.bets.arbBets[mode].forEach(group => {
        group.forEach(b => ids.add(b.id));
      });

      let newBets = betsData.data;

      newBets = newBets.map(group => group.map(b => {
        if (!ids.has(b.id) && state.bets.arbBets[mode].length !== 0) {
          flagNew = true;
          return {
            isNew: true,
            ...b
          }
        } else {
          return {
            isNew: false,
            ...b
          }
        }
      }));

      // update bet in calculator
      let selected_id = state.bets.selectedBet[mode]?.id;
      if (selected_id) {
        let flag = false;
        newBets.forEach(group => {
          group.forEach(b => {
            if (b.id === selected_id) {
              // change in future
              let updateFlag = false;

              if (b.bets.length === 2) {
                if (
                  b.bets[0].price !== state.bets.selectedBet[mode]?.bets[0].price ||
                  b.bets[1].price !== state.bets.selectedBet[mode]?.bets[1].price
                ) {
                  updateFlag = true;
                }
              } else if (b.bets.length === 3) {
                if (
                  b.bets[0].price !== state.bets.selectedBet[mode]?.bets[0].price ||
                  b.bets[1].price !== state.bets.selectedBet[mode]?.bets[1].price ||
                  b.bets[2].price !== state.bets.selectedBet[mode]?.bets[2].price
                ) {
                  updateFlag = true;
                }
              }

              // updating if it is no longer a surebet
              if (state.bets.selectedBet[mode].not_surebet !== b.not_surebet) {
                updateFlag = true;
              }

              if (updateFlag) {
                store.dispatch(setSelectedBet({mode, data: b}));
              }

              flag = true;
            }
          });
        });

        if (!flag) {
          store.dispatch(setSelectedBet({mode, data: null}));
        }
      }

      const total = betsData.total;
      store.dispatch(setTotal({mode, data: total}));

      store.dispatch(setArbBets({mode, data: newBets}));

      //set hasNext
      store.dispatch(setHasNext({mode, data: betsData.next}));

      // notify users
      if (state.settings.mode === "prematch" && mode === "prematch" && flagNew) {
        if (state.user.notificationsEnabled) {
          store.dispatch(setNotified(true));
          playSound();
        }
      } else if (state.settings.mode === "live" && mode === "live" && flagNew) {
        if (state.user.notificationsEnabled) {
          store.dispatch(setNotified(true));
          playSound();
        }
      }
    
    } else if (mode === "ev") {
      const state = store.getState();

      // set of unique indexes
      const ids = new Set();
      state.bets.arbBets[mode].forEach(group => {
        group?.odds?.forEach(o => ids.add(o.id));
      });

      let newBets = betsData.data;

      newBets = newBets.map(group => {
        return {
          ...group,
          odds: group.odds.map(b => {
            if (!ids.has(b.id) && state.bets.arbBets[mode].length !== 0) {
              flagNew = true;
              return {
                isNew: true,
                ...b
              }
            } else {
              return {
                isNew: false,
                ...b
              }
            }
          })
        }
      });

      // update bet in calculator
      let selected_id = state.bets.selectedBet[mode]?.id;
      if (selected_id) {
        let flag = false;
        newBets.forEach(group => {
          group.odds.forEach(b => {
            if (b.id === selected_id) {
              store.dispatch(setSelectedBet({mode, data: b}));
              flag = true;
            }
          });
        });
        if (!flag) {
          store.dispatch(setSelectedBet({mode, data: null}));
        }
      }

      const total = betsData.total;
      store.dispatch(setTotal({mode, data: total}));

      store.dispatch(setArbBets({mode, data: newBets}));

      //set hasNext
      store.dispatch(setHasNext({mode, data: betsData.next}));
    } else if (mode === "evLive") {
      const state = store.getState();
      
      // set of unique indexes
      const ids = new Set();
      state.bets.arbBets[mode].forEach(group => {
        group?.odds?.forEach(o => ids.add(o.id));
      });

      let newBets = betsData.data;

      newBets = newBets.map(group => {
        return {
          ...group,
          odds: group.odds.map(b => {
            if (!ids.has(b.id) && state.bets.arbBets[mode].length !== 0) {
              flagNew = true;
              return {
                isNew: true,
                ...b
              }
            } else {
              return {
                isNew: false,
                ...b
              }
            }
          })
        }
      });

      // update bet in calculator
      let selected_id = state.bets.selectedBet[mode]?.id;
      if (selected_id) {
        let flag = false;
        newBets.forEach(group => {
          group.odds.forEach(b => {
            if (b.id === selected_id) {
              store.dispatch(setSelectedBet({mode, data: b}));
              flag = true;
            }
          });
        });
        if (!flag) {
          store.dispatch(setSelectedBet({mode, data: null}));
        }
      }

      const total = betsData.total;
      store.dispatch(setTotal({mode, data: total}));

      store.dispatch(setArbBets({mode, data: newBets}));

      //set hasNext
      store.dispatch(setHasNext({mode, data: betsData.next}));
    }
  }

  // method to send requests
  async getBetsFromServer(mode, {
    page, 
    limit, 
    types,
    sports, 
    bookmakers, 
    required_bookmakers,
    range, 
    start_date, 
    end_date,
    game_period,
    bet_period,
    sorting_type,
    no_vig = false,
    minOdds,
    maxOdds,
    selected_filter_id
  }) {
    if (page === 1) {
      store.dispatch(setPage({mode, data: 0}));
      store.dispatch(setIsInitialLoading(true));
    } else {
      store.dispatch(setIsLoadingMore(true));
    }
  
    start_date = start_date ? start_date.unix() : start_date;
    end_date = end_date ? end_date.unix() : end_date;
  
    if (mode === "prematch") {
      this.socket.emit('requestFilteredBetsPrematch', {
        page,
        limit,
        types,
        sports,
        bookmakers,
        required_bookmakers,
        range,
        start_date, 
        end_date,
        game_period,
        bet_period,
        sorting_type,
        minOdds,
        maxOdds,
        selected_filter_id
      });
    } else if (mode === "live") {
      this.socket.emit('requestFilteredBetsLive', {
        page,
        limit,
        types,
        sports,
        bookmakers,
        required_bookmakers,
        range,
        start_date, 
        end_date,
        game_period,
        bet_period,
        sorting_type,
        minOdds,
        maxOdds,
        selected_filter_id
      });
    } else if (mode === "ev") {
      this.socket.emit('requestFilteredBetsEV', {
        page,
        limit,
        types,
        sports,
        bookmakers,
        range,
        start_date, 
        end_date,
        game_period,
        bet_period,
        sorting_type,
        no_vig,
        minOdds,
        maxOdds,
        selected_filter_id
      });
    } else if (mode === "evLive") {
      this.socket.emit('requestFilteredBetsEVLive', {
        page,
        limit,
        types,
        sports,
        bookmakers,
        range,
        start_date, 
        end_date,
        game_period,
        bet_period,
        sorting_type,
        no_vig,
        minOdds,
        maxOdds,
        selected_filter_id
      });
    }
  }

  handleNotificationsAll(notifications) {
    store.dispatch(setNotifications(notifications));
  }

  handleAddNotification(notification) {
    store.dispatch(addNotification(notification));
  }
}

export default new SocketServerAPI();