import { useState, useEffect } from 'react';
import style from './styles/AccountStyle.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser, deleteImage } from './../../features/user/userSlice';

/**
 * MainAccount Component
 *
 * This component allows users to view and edit their main account information,
 * including first name, last name, email, and telephone number. It also supports
 * uploading, changing, and deleting the profile picture. Changes are not saved
 * automatically; users must explicitly click the "Save" button to apply their changes.
 *
 * The component integrates with Redux for state management, allowing it to access
 * and dispatch user-related actions. It provides form inputs for editing user details
 * and leverages a file input for profile picture updates, which is hidden and triggered
 * via a separate upload photo button for enhanced user experience.
 *
 * Deletion of the profile picture is confirmed through a modal dialog to prevent accidental
 * removals. This component also provides a direct link for users to navigate to the change
 * password page.
 */
export default function MainAccount(props) {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [modularIsOpen, setModularIsOpen] = useState(false);

    const firstName = useSelector(state => state.user.firstName);
    const lastName = useSelector(state => state.user.lastName);
    const email = useSelector(state => state.user.email);
    const telNumber = useSelector(state => state.user.telNumber);
    const userImage = useSelector(state => state.user.image);

    const [localFirstName, setLocalFirstName] = useState(firstName);
    const [localLastName, setLocalLastName] = useState(lastName);
    const [localEmail, setLocalEmail] = useState(email);
    const [localTelNumber, setLocalTelNumber] = useState(telNumber);
    const [image, setImage] = useState(userImage);

    useEffect(() => {
        setLocalFirstName(firstName);
    }, [firstName]);

    useEffect(() => {
        setLocalLastName(lastName);
    }, [lastName]);

    useEffect(() => {
        setLocalEmail(email);
    }, [email]);

    useEffect(() => {
        setLocalTelNumber(telNumber);
    }, [telNumber]);

    useEffect(() => {
        setImage(userImage);
    }, [userImage]);

    const handleFirstNameChange = (event) => {
        setLocalFirstName(event.target.value);
    };
    
    const handleLastNameChange = (event) => {
        setLocalLastName(event.target.value);
    };

    const handleTelNumberChange = (event) => {
        setLocalTelNumber(event.target.value);
    };

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0];
            setImage(img);
        }
    };

    const handlePhotoUpload = () => {
        document.getElementById('fileInput').click();
        setIsOpen(false);
    };

    const handleDeletePhoto = async () => {
        if (typeof image === 'string') {
            // delete image from server
            await dispatch(deleteImage());
        } else {
            setImage(null);
        }

        setIsOpen(false);
        setModularIsOpen(false);
    };

    const handleSave = () => {
        const formData = new FormData();
        formData.append('firstName', localFirstName);
        formData.append('lastName', localLastName);
        formData.append('telNumber', localTelNumber);

        if (image && typeof image !== 'string') {
            formData.append('image', image, image.name);
        }
        
        dispatch(updateUser(formData));
    };

    const checkIsChanged = () => {
        let flag = false;

        if (
            localFirstName !== firstName ||
            localLastName !== lastName ||
            localEmail !== email ||
            localTelNumber !== telNumber ||
            image !== userImage
        ) {
            flag = true;
        }

        return flag;
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return(
        <>
            <div className={style.container}>
                <span className={style.name}>
                    {firstName + ' ' + lastName}
                </span>
                <span className={style.email}>
                    {localEmail}
                </span>
                <div className={style.account_img}>
                    <img 
                        src={image ? typeof image === 'string' ? `${process.env.REACT_APP_API_URL}images/${image}` : URL.createObjectURL(image) : require('./img/account_temp.png')} 
                        className={style.main_img} 
                        alt="" 
                    />
                    <div className={style.dropdown}>
                        <img 
                            src={require('./img/arrow.svg').default} 
                            className={style.arrow} 
                            alt="Arrow icon" 
                            onClick={toggleMenu} 
                            style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                        />
                        {isOpen && (
                            <div className={style.dropdown_menu}>
                                <div className={style.dropdown_item} onClick={handlePhotoUpload}>Upload Photo</div>
                                <div className={`${style.dropdown_item} ${style.red}`} onClick={() => setModularIsOpen(true)}>Delete Photo</div>
                            </div>
                        )}
                    </div>
                    <input 
                        type="file" 
                        id="fileInput" 
                        style={{ display: 'none' }} 
                        onChange={handleImageChange}
                    />
                </div>
                <div className={style.inputs_wrapper}>
                    <div className={style.item}>
                        <label htmlFor="account_first_name">First Name</label>
                        <input 
                            type="text" 
                            id='account_first_name' 
                            value={localFirstName} 
                            placeholder='First Name' 
                            onChange={handleFirstNameChange}
                        />
                    </div>
                    <div className={style.item}>
                        <label htmlFor="account_last_name">Last Name</label>
                        <input 
                            type="text" 
                            id='account_last_name' 
                            value={localLastName}
                            placeholder='Last Name'
                            onChange={handleLastNameChange} 
                        />
                    </div>
                    <div className={style.item}>
                        <label htmlFor="phone_number">Phone Number (optional)</label>
                        <input 
                            type="tel" 
                            id='phone_number' 
                            value={localTelNumber}
                            placeholder='Phone Number' 
                            onChange={handleTelNumberChange} 
                        />
                    </div>
                </div>
                <a href='/change-password' className={style.action}>Change Password</a>
            </div>
            <button 
                className={`${checkIsChanged() ? style.save_button : style.save_button_inactive} ${style.account_save}`} 
                onClick={handleSave}
            >Save</button>
            {
                modularIsOpen ?
                <div className={style.module}>
                    <div className={style.module_wrapper}>
                        <div className={style.content}>
                            <img src={require('./img/trash_red.png')} alt="" />
                            <span>Are you sure you want to <span className={style.red}>delete</span> your profile picture?</span>
                        </div>
                        <div className={style.action_block}>
                            <button className={style.left} onClick={() => setModularIsOpen(false)}>Cancel</button>
                            <button className={style.right} onClick={() => handleDeletePhoto()}>Delete</button>
                        </div>
                    </div>
                </div> : ''
            }
        </>
    )
}