// store.js
import { configureStore } from '@reduxjs/toolkit';
import filterReducer from './features/filter/filterSlice';
import betsReducer from './features/bets/betsSlice';
import settingsReducer from './features/settings/settingsSlice';
import preferencesReducer from './features/preferences/preferencesSlice';
import userReducer from './features/user/userSlice';
import adminsReducer from './features/admins/adminsSlice';

export default configureStore({
  reducer: {
    filter: filterReducer,
    bets: betsReducer,
    settings: settingsReducer,
    preferences: preferencesReducer,
    user: userReducer,
    admins: adminsReducer
  },
});