const awsconfig = {
    Auth: {
        Cognito: {
            userPoolId: "us-east-1_v2hNs7ak6",
            userPoolClientId: "2tp6eu2snb8dp41f5ngmfcq0k8",
            signUpVerificationMethod: 'link',
            region: "us-east-1",
        }
    },
};

export default awsconfig;