import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  disableScrollLock: true
};

// function getStyles(item, selectedData, theme) {
//   return {
//     fontWeight:
//     selectedData.indexOf(item) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

const CustomSelect = styled(Select)({
  width: 'auto',
  paddingRight: '16px',
  '& .MuiOutlinedInput-input': {
    color: 'white', // Text color
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white', // Border color
  },
  '&:hover .MuiOutlinedInput-input': {
    color: 'white', // Hover text color
  },
  '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white', // Border hover color
  },
  '&.Mui-focused .MuiOutlinedInput-input': {
    color: 'white', // Text color on focus
  },
  '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white', // Border color on focus
  },
  '& .MuiSvgIcon-root': {
    color: 'white', // Arrow color
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },
});

function SelectComponent({label, data, selectedData = [], onChange}) {
  const theme = useTheme();
  const [selected, setSelected] = React.useState(selectedData);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelected(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    onChange(value);
  };

  React.useEffect(() => {
    setSelected(
      typeof selectedData === 'string' ? selectedData.split(',') : selectedData,
    );
  }, [selectedData])

  return (
    <CustomSelect
      // multiple
      displayEmpty
      value={selected}
      onChange={handleChange}
      input={<OutlinedInput />}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <em>{label}</em>;
        }

        // return selected.join(', ');
        return selected;
      }}
      MenuProps={MenuProps}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {data.map((d) => (
        <MenuItem
          key={d}
          value={d}
          // style={getStyles(d, selected, theme)}
        >
          {d}
        </MenuItem>
      ))}
    </CustomSelect>
  );
}

export default SelectComponent;