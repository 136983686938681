import React, { useState } from 'react';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import colors from '../utils/colors';
import timestamp_to_string from '../utils/time_converter';
import EVBetComponent from './EVBetComponent';

import style from './style/ArbitrageBetStyles.module.scss';

/**
 * EVGroupComponent
 * 
 * This component serves as a container for displaying a group of Expected Value (EV) bets associated with a specific market.
 * It organizes the bets in a collapsible view, allowing users to either view a summary with the top bet or expand the view
 * to see all bets within the group. Each bet is represented using the EVBetComponent, showcasing detailed information
 * like bookmaker, teams, market, and calculated EV values.
 * 
 * Features include:
 * - Collapsible functionality to show more or less information based on user interaction, improving the usability
 *   and navigational experience within a potentially large dataset of EV bets.
 * - The header section that displays general information about the market, including the type of bets (EV+), the sport category,
 *   and the start date of the event, offering quick insight into the bet group's context.
 * - Utilization of a "show more/show less" toggle button, providing a user-friendly way to manage the amount of information displayed.
 * 
 * The component enhances the presentation of EV bets by grouping them in a meaningful way, making it easier for users to assess
 * and select bets of interest. The structured layout and interactive elements support an engaging user experience while
 * navigating through EV betting options.
 */
function EVGroupComponent({market_bets, handleOpenDeleteBetPopup}) {
  const [showMore, setShowMore] = useState(false);
  const classes = useStyles();
  const toggleMore = () => {
    setShowMore(!showMore);
  }

  return (
    <Grid container className={style.root} >
      <div className={style.header}>
        <div className={style.icon}>
          <span>EV+</span>
          <span className={style.delimiter}>|</span>
          <span>{market_bets.sport}</span>
        </div>
        <div className={style.info}>
          <Typography className={style.info_text}>
            {
              timestamp_to_string(dayjs(market_bets.start_date).valueOf() / 1000)
            }
            
          </Typography>
          <AccessTimeIcon sx={{paddingLeft: '10px', width: '20px', height: '20px'}}></AccessTimeIcon>
        </div>
      </div>

      {/* Second and third lines */}
      {
        showMore ?
        market_bets.odds.map((bet, index) => {
          return <>
            <EVBetComponent 
              bets_group={market_bets} 
              bet={bet} 
              game_info={{sport: market_bets.sport, start_date: market_bets.start_date}} 
              key={bet.id}
              handleOpenDeleteBetPopup={handleOpenDeleteBetPopup}
            ></EVBetComponent>
            { market_bets.odds.length !== index + 1 ? <div className={style.items_delimiter}></div> : null }
          </>
        })
        : 
        <EVBetComponent 
          bets_group={market_bets} 
          bet={market_bets.odds[0]} 
          game_info={{sport: market_bets.sport, start_date: market_bets.start_date}}
          handleOpenDeleteBetPopup={handleOpenDeleteBetPopup}
        ></EVBetComponent>
      }
      {
        market_bets.odds.length > 1 ? 
        <div className={style.more_button} onClick={toggleMore}>
          {
            showMore ? 
            <div className={style.show_button}>
              <span>Show less</span>
              <img src={require('./img/arrow.svg').default} alt="Arrow icon" style={{transform: 'rotateX(180deg)'}} />
            </div>
          : <div className={style.show_button}>
              <span>Show more</span>
              <img src={require('./img/arrow.svg').default} alt="Arrow icon" />
            </div>
          }
        </div>
        : null
      }
    </Grid>
  );
}

const useStyles = makeStyles({
  root: {
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
    backgroundColor: colors.celadon
  },
  header: {
    background: colors.green,
    color: 'white',
  },
  item: {
    padding: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    background: colors.green,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  percentText: {
    fontSize: '20px',
    fontWeight: 'bold',
    paddingLeft: '10px',
    padding: '5px',
    boxSizing: 'border-box',
    
  },
  sport: {
    background: colors.night,
  },
  sportText: {
    fontSize: '20px',
    padding: '5px',
    paddingRight: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center'
  },
  info: {
    background: colors.night,
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center'
  },
  infoText: {
    fontSize: '15px',
    padding: '5px',
    paddingRight: '5px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center'
  },
  bets: {
    background: colors.celadon
  },
  delimiter: {
    height: '2px',
    width: '100%',
    backgroundColor: colors.grey
  },
  moreButton: {
    borderRadius: '10px',
    margin: '3px',
    cursor: 'pointer',
    width: '100%',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.night,
    color: '#fff',
    borderBottom: `2px outset ${colors.celadon}`,
    marginRight: '30%',
    marginLeft: '30%'
  },
});

export default EVGroupComponent;