import React, { useState, useRef, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';

import { styled } from '@mui/material/styles';

import colors from '../utils/colors';
import ArbitrageBetComponent from './ArbitrageBetComponent';

import { Navigate, useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { addUserTransaction } from './../features/user/userSlice';
import { setSorting, fetchProfitGraphs, addUserBet, hideBet } from './../features/bets/betsSlice';

import CircularProgress from '@mui/material/CircularProgress';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { convertAmericanToEuropean, convertEuropeanToAmerican } from './../utils/odds_converter';

import socketServerAPI from '../http/socketServerAPI';
import LazyLoading from './LazyLoading';
import EVGroupComponent from './EVGroupComponent';
import MyBetGroupComponent from './MyBetGroupComponent';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import { ReactComponent as ShareLogo } from './img/share.svg';
import html2canvas from 'html2canvas';
import dayjs from 'dayjs';
import logo from './../assets/logo.png';
import { ReactComponent as XLogo } from './img/x_icon.svg';

import {
  TwitterShareButton,
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from 'react-share';

import {
  XIcon,
  FacebookIcon,
  RedditIcon,
  TelegramIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
} from 'react-share';

import RestServerAPI from './../http/restServerAPI';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import style from './style/BetsListStyles.module.scss';

import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

import CustomDateTimePicker from './CustomDateTimePicker';

import { getCurrentTimestampFormatted } from './../utils/time_converter';
import sorting_arb_types from './../utils/sorting_arb_types';
import sorting_ev_types from '../utils/sorting_ev_types.js';
import defaultBookmakers from '../utils/bookmakers';
import defaultSports from '../utils/sports';

/**
 * BetsListComponent
 * 
 * This component serves as the main interface for displaying lists of bets based on the selected mode (e.g., prematch, live, EV, EV Live, or my bets).
 * It dynamically renders bets according to user filters and preferences, facilitating easy navigation and interaction with the betting data.
 * 
 * Key Features:
 * - Dynamic Loading: Incorporates an intersection observer to detect when the user scrolls to the bottom of the list, triggering the loading of additional bets if available.
 * - Modular Integration: Depending on the mode, it renders different types of bets, including arbitrage bets for prematch and live modes, and expected value (EV) bets for EV modes.
 * - User Bets: In the "my bets" mode, it displays bets placed by the user, allowing for easy management and review of their betting activity.
 * - Profit and Equity Display: For "my bets" mode, it showcases total profits and equity, with functionalities to update the balance directly from this interface.
 * - Sorting Functionality: Offers sorting options for bets based on user selection, enhancing the ability to find bets that meet specific criteria.
 * - Subscription Check: Validates the user's subscription type to determine access to certain functionalities, redirecting to subscription upgrade options when necessary.
 * 
 * The component enhances the user experience by providing a comprehensive view of bets tailored to the user's interests and subscription level.
 * It represents a central piece of the application, enabling users to effectively engage with the betting data provided by the platform.
 */
function BetsListComponent({mode}) {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const arbBets = useSelector(state => state.bets.arbBets[mode]);
  
  // temp
  const myBetsFiltered = useSelector(state => state.bets.userBetsFiltered);
  const myBetsAll = useSelector(state => state.bets.userBets);
  const profitGraphData = useSelector(state => state.bets.profitGraph);

  const subscriptionType = useSelector(state => state.user.subscriptionType);

  const page = useSelector(state => state.bets.page[mode]);
  const limit = useSelector(state => state.bets.limit[mode]);
  const hasNext = useSelector(state => state.bets.hasNext[mode]);
  const total = useSelector(state => state.bets.total[mode]);

  const isFetched = useSelector(state => state.settings.isFetched);
  const isCheckedForFilter = useSelector(state => state.settings.isCheckedForFilter);
  const isInitialLoading = useSelector(state => state.filter.isInitialLoading);
  const isLoadingMore = useSelector(state => state.filter.isLoadingMore);
  const selected_sports = useSelector(state => state.filter.selectedSports);
  const selected_bookmakers = useSelector(state => state.filter.selectedBookmakers);
  const selected_required_bookmakers = useSelector(state => state.filter.selectedRequiredBookmakers);
  const percentage_range = useSelector(state => state.filter.percentageRange);
  const start_date = useSelector(state => state.filter.startDate);
  const end_date = useSelector(state => state.filter.endDate);
  const bet_types = useSelector(state => state.filter.types);
  const game_period = useSelector(state => state.filter.gamePeriod);
  const bet_period = useSelector(state => state.filter.betPeriod);
  const sorting_type = useSelector(state => state.bets.sorting);
  const no_vig = useSelector(state => state.preferences.noVig);

  const selected_filter_id = useSelector(state => state.filter.selectedFilterId);

  // const user_min_odds = useSelector(state => state.preferences.minOdds);
  // const user_max_odds = useSelector(state => state.preferences.maxOdds);
  const min_odds = useSelector(state => state.filter.minOdds);
  const max_odds = useSelector(state => state.filter.maxOdds);

  const userTransactions = useSelector(state => state.user.transactions);

  const selectedSorting = useSelector(state => state.bets.sorting);

  const [totalAmount, setTotalAmount] = useState(userTransactions.reduce((prev, transaction) => prev + transaction.amount, 0));
  const [totalProfit, setTotalProfit] = useState(myBetsAll.reduce((prev, bet) => prev + (bet.profitResult ?? 0), 0));

  const [isProfitHistoryOpened, setIsProfitHistoryOpened] = useState(false);

  const [, forceUpdate] = useState();

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Force a state update, which will cause the component to re-render
      forceUpdate(s => !s);
    }, 5000); // Update every 5 seconds

    // Clearing the interval when unmounting a component
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setTotalAmount(userTransactions.reduce((prev, transaction) => prev + transaction.amount, 0));
  }, [userTransactions]);

  useEffect(() => {
    setTotalProfit(myBetsAll.reduce((prev, bet) => prev + (bet.profitResult ?? 0), 0));
  }, [myBetsAll]);

  const [moduleName, setModuleName] = useState(false);

  const observer = useRef();
  const lastElementRef = useRef();
  const listRef = useRef(null);

  const callback = (entries) => {
    if (entries[0].isIntersecting && hasNext) {
      loadNextPage(page + 1);
    }
  };

  useEffect(() => {
    if (isFetched && isCheckedForFilter) {
      if (page === 0) {
        loadNextPage(page + 1);
      }
      if (listRef.current) {
        listRef.current.scrollTo(0, 0);
      }
    }
  }, [mode, isFetched, isCheckedForFilter]);

  useEffect(() => {
    observer.current = new IntersectionObserver(callback);
    if (lastElementRef.current) {
      observer.current.observe(lastElementRef.current);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [hasNext, page, mode]);

  const [openDeleteBetPopup, setOpenDeleteBetPopup] = useState(false);

  const handleOpenDeleteBetPopup = (data) => {
    setOpenDeleteBetPopup(data);
  }

  const loadNextPage = async (page) => {
    if (mode === "prematch" || mode === "live" || mode === "ev" || mode === "evLive") {
      socketServerAPI.getBetsFromServer(mode, {
        page,
        limit,
        types: bet_types,
        sports: selected_sports, 
        bookmakers: selected_bookmakers,
        required_bookmakers: selected_required_bookmakers,
        range: percentage_range,
        start_date,
        end_date,
        game_period,
        bet_period,
        sorting_type,
        no_vig,
        minOdds: min_odds,
        maxOdds: max_odds,
        selected_filter_id
      });
    }
  }

  const updateCurrentPage = async (sorting_type) => {
    if (mode === "prematch" || mode === "live" || mode === "ev" || mode === "evLive") {
      socketServerAPI.getBetsFromServer(mode, {
        page: 1,
        limit,
        types: bet_types,
        sports: selected_sports, 
        bookmakers: selected_bookmakers,
        required_bookmakers: selected_required_bookmakers,
        range: percentage_range,
        start_date,
        end_date,
        game_period,
        bet_period,
        sorting_type,
        no_vig,
        minOdds: min_odds,
        maxOdds: max_odds,
        selected_filter_id
      });
    }
  }

  const handleOpenProfitGraph = async () => {
    dispatch(fetchProfitGraphs());
    setIsProfitHistoryOpened(true);
  }

  if (!isFetched) {
    return <LazyLoading></LazyLoading>;
  }

  if (mode === "live") {
    if (subscriptionType === 'premium' || subscriptionType === 'premium year' ||
        subscriptionType === 'superadmin' || subscriptionType === 'developer') {

    } else {
      return <Navigate to="/account/subscriptions" replace state={{ from: location }} />
    }
  } else if (mode === "ev" || mode === "evLive" || mode === "mybets") {
    if (subscriptionType === 'free') {
      return <Navigate to="/account/subscriptions" replace state={{ from: location }} />
    }
  }

  if (mode && mode !== "mybets" && (isInitialLoading || !isFetched)) {
    return <LazyLoading></LazyLoading>;
  }

  return (
    <div className={style.root}>
      {
        mode === 'mybets' && isProfitHistoryOpened ? 
        <ProfitHistoryPopUp 
          profitGraphData={profitGraphData}
          setIsProfitHistoryOpened={setIsProfitHistoryOpened}
        ></ProfitHistoryPopUp> 
        : null
      }
      {
        openDeleteBetPopup ? <DeleteBetPopUp setOpenDeleteBetPopup={setOpenDeleteBetPopup} openDeleteBetPopup={openDeleteBetPopup}></DeleteBetPopUp> : null
      }
      {
        mode !== 'mybets' ?
        <div className={`${style.control_panel}`}>
          <div className={style.header_text}>
            { mode === "ev" || mode === "evLive" ? `Displayed ${arbBets.reduce((sum, group) => sum + group.odds.length, 0)} of ${total} bets` : '' }
            { mode === "prematch" || mode === "live" ? `Displayed ${arbBets.reduce((sum, group) => sum + group.length, 0)} of ${total} bets` : '' }
          </div>
          <div className={style.dropdown_button}>
          {
              mode !== '' ? 
              <Dropdown>
                  <MenuButton>
                      <img src={require('./img/filter.png')} alt="" />
                      <span>{selectedSorting}</span>
                      <img className='arrow' src={require('./img/arrow.png')} alt="" />
                  </MenuButton>

                  <Menu slots={{ listbox: Listbox }}>
                      {
                        mode === "ev" || mode === "evLive" ?
                        sorting_ev_types.map((s, i) => <MenuItem key={i} sx={selectedSorting === s ? {color: colors.green} : {}} onClick={() => {
                          dispatch(setSorting(s));
                          updateCurrentPage(s);
                        }}>{s}</MenuItem>)
                        : 
                        sorting_arb_types.map((s, i) => <MenuItem key={i} sx={selectedSorting === s ? {color: colors.green} : {}} onClick={() => {
                          dispatch(setSorting(s));
                          updateCurrentPage(s);
                        }}>{s}</MenuItem>)
                      }
                  </Menu>
              </Dropdown>
              : null
            }
          </div>
          {/* <div>
            <SortIcon></SortIcon>
          </div> */}
        </div> : 
        <>
          <div className={style.mybets_statistics}>
            <div className={style.item}>
              <span className={style.name}>Total Bets</span>
              <div className={style.value}>
                <span>{myBetsFiltered.length}</span>
              </div>
            </div>
            <div className={style.item}>
              <span className={style.name}>Pending Bets</span>
              <div className={style.value}>
                <span>{myBetsFiltered.filter((b) => b.status === "Pending").length}</span>
              </div>
            </div>
            <div className={style.item}>
              <span className={style.name}>Profit</span>
              <div className={`${style.value} ${isProfitHistoryOpened ? style.value_selected : null}`}>
                <img src={require('./img/plot.png')} className={style.plot_icon} onClick={handleOpenProfitGraph} alt=""></img>
                <div className={style.price_wrapper}>
                  $
                  <span className={style.price}>{totalProfit?.toFixed(2)}</span>
                  USD
                </div>
              </div>
            </div>
            {/* <div className={style.item}>
              <span className={style.name}>Equity</span>
              <div className={style.value}>
                <div className={style.price_wrapper}>
                  $
                  <span className={style.price}>{totalEquity?.toFixed(2)}</span>
                  USD
                </div>
              </div>
            </div> */}
            <div className={style.item}>
              <span className={style.name}>Equity</span>
              <div className={style.value}>
                <div className={style.price_wrapper}>
                  $
                  <span className={style.price}>{totalAmount?.toFixed(2)}</span>
                  USD
                </div>
              </div>
            </div>
            <div className={style.item_action}>
              <button className={style.up} onClick={() => setModuleName('upbalance')}>
                <span>+$</span>
              </button>
              <button className={style.down} onClick={() => setModuleName('downbalance')}>
                <span>-$</span>
              </button>
            </div>
            <div className={style.add_bet_container}>
              <button 
                variant="contained" 
                className={`${style.add_bet_button}`}
                onClick={() => setModuleName('addbet')}
              >Add Bet Manually</button>
            </div>
          </div>
          {moduleName ? 
            <div className={style.modular_wrapper}>
              {moduleName === 'upbalance' ? <UpBalance setModuleName={setModuleName} /> : ''}
              {moduleName === 'downbalance' ? <DownBalance setModuleName={setModuleName} /> : ''}
              {moduleName === 'addbet' ? <AddBet setModuleName={setModuleName} /> : ''}
            </div> : ''
          }
        </>
      }
      <div className={`${classes.list} ${mode === 'mybets' ? style.mybets_list : ''}`} ref={listRef} id="list">
        {
          mode === "ev" ?
          arbBets.filter((bet) => {
            const betStartTime = new Date(bet.start_date).getTime();
            return betStartTime > Date.now();
          }).map((bet, i) => (
            <EVGroupComponent key={bet?.odds[0].id} market_bets={bet} handleOpenDeleteBetPopup={handleOpenDeleteBetPopup}></EVGroupComponent>
          )) : ''
        }
        {
          mode === "evLive" ?
          arbBets.map((bet, i) => (
            <EVGroupComponent key={bet?.odds[0].id} market_bets={bet}></EVGroupComponent>
          )) : ''
        }
        {
          mode === "prematch" || mode === "live" ?
          arbBets.map((bet, i) => (
            <ArbitrageBetComponent key={bet[0]?.id} bets_group={bet} handleOpenDeleteBetPopup={handleOpenDeleteBetPopup}></ArbitrageBetComponent>
          )) : ''
        }
        {
          mode === "mybets" ?
          myBetsFiltered.map((bet, i) => (
            <MyBetGroupComponent key={bet?.id} bet={bet}></MyBetGroupComponent>
          )) : ''
        }
        {
          hasNext ? 
            <div className={classes.reload} ref={lastElementRef}>
              {
                isLoadingMore === true ? <CircularProgress style={{color: colors.green, padding: '15px'}} size={24} /> : null
              }
            </div>
          : null
        }
      </div>
    </div>
  );
}


function DeleteBetPopUp({setOpenDeleteBetPopup, openDeleteBetPopup}) {
  const dispatch = useDispatch();

  const [isSingle, setIsSingle] = useState(false);

  return(
    <div className={style.modular_wrapper}>
      <div className={`${style.modular_item}`}>
        <div className={style.top}>
          <div className={style.name_wrapper}>
            <span className={`${style.name} ${style.green}`}>
              Are you sure you want to delete the bet?
            </span>
            
          </div>
          
        </div>

        <div className={style.checkbox_area} style={{paddingTop: '10px', paddingBottom: '10px'}} onClick={() => {
            setIsSingle(!isSingle);
        }}>
          <CustomCheckbox 
            checked={isSingle} 
            
          />
          <span className={style.info} style={{color: '#fff'}}>Delete the group of bets</span>
        </div>

        <div style={{color: '#fff', fontSize: '14px', paddingTop: '10px', paddingBottom: '10px', fontStyle: 'italic'}}>
          * To see your removed bets again from view, please go to your settings in the “Removed Bets” area.
        </div>
        
        <div className={style.action_wrapper_v2}>
          
          <button onClick={() => {
              setOpenDeleteBetPopup(false);
          }} className={style.cancel_button}>
            <span>Cancel</span>
          </button>

          <button className={style.confirm_button} onClick={() => {
            if (!isSingle) {
              const data = {
                filterId: openDeleteBetPopup.filterId, 
                userId: localStorage.getItem('userSub'), 
                betId: openDeleteBetPopup.betId, 
                validUntil: openDeleteBetPopup.validUntil,
                mode: openDeleteBetPopup.mode,
                type: "single",
                game_name: openDeleteBetPopup.game_name,
                bet_name: openDeleteBetPopup.bet_name
              }

              dispatch(hideBet(data));
            } else {
              const data = {
                filterId: openDeleteBetPopup.filterId, 
                userId: localStorage.getItem('userSub'), 
                groupId: openDeleteBetPopup.groupId, 
                validUntil: openDeleteBetPopup.validUntil,
                mode: openDeleteBetPopup.mode,
                type: "group",
                game_name: openDeleteBetPopup.game_name,
                group_name: openDeleteBetPopup.group_name,
              }

              dispatch(hideBet(data));
            }

            setOpenDeleteBetPopup(false);
          }}>
            <span>Yes</span>
          </button>
        </div>
        
      </div>
    </div>
  )
}

const sorting_types_local = [
  "Date, Most Recent to Oldest",
  "Date, Oldest to Recent",
  "Value, From largest to smallest",
  "Value, From smallest to largest",
];

function sortTransactions(transactions, sortType) {
  if (sortType === sorting_types_local[0]) {
    return transactions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  } else if (sortType === sorting_types_local[1]) {
    return transactions.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  } else if (sortType === sorting_types_local[2]) {
    return transactions.sort((a, b) => b.amount - a.amount);
  } else if (sortType === sorting_types_local[3]) {
    return transactions.sort((a, b) => a.amount - b.amount);
  }
  return transactions;
}

function UpBalance(props) {
  const dispatch = useDispatch();

  const [sorting, setSorting] = useState(sorting_types_local[0]);

  const [amountToAdd, setAmountToAdd] = useState(0);

  const userTransactions = useSelector(state => state.user.transactions);
  const [transactionsLocal, setTransactionsLocal] = useState([...userTransactions].reverse());

  const [totalAmount, setTotalAmount] = useState(userTransactions.reduce((prev, transaction) => prev + transaction.amount, 0));

  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    setTotalAmount(userTransactions.reduce((prev, transaction) => prev + transaction.amount, 0));
    setTransactionsLocal(sortTransactions([...userTransactions].reverse(), sorting));
  }, [userTransactions]);

  const addTransaction = async () => {
    if (Number(amountToAdd) <= 0) {
      setShowPopUp(true);
      return;
    }

    const data = {
      transactionId: Math.random().toString(36).substr(2, 9) + Date.now().toString(36),
      timestamp: getCurrentTimestampFormatted(),
      amount: Number(amountToAdd),
      balance: totalAmount + Number(amountToAdd),
    }

    await dispatch(addUserTransaction(data));
    setAmountToAdd(0);
  }

  const setBalance = (value) => {
    setAmountToAdd(value);
  }

  return(
    <>
      { showPopUp ?
          <div className={style.popup}>
              <div className={style.module_wrapper}>
                  <div className={style.content}>
                      <span>Add Amount must be greater than zero</span>
                  </div>
                  <div className={style.action_block}>
                      <button className={style.right} onClick={() => setShowPopUp(false)}>OK</button>
                  </div>
              </div>
          </div> : null
      }
      <div className={style.modular_item}>
        <div className={style.top}>
          <div className={style.name_wrapper}>
            <span className={`${style.name} ${style.green}`}>Your Bankroll</span>
            <span className={style.subname}>Top up your balance!</span>
          </div>
          <div className={style.right_part}>
            <div className={style.balance_wrapper}>
              <span>Current Balance</span>
              <div className={style.value}>
                  <span>$</span>
                  <input 
                    type="number" 
                    value={totalAmount?.toFixed(2)}
                  />
                  <span>USD</span>
              </div>
            </div>
            <div className={style.close} onClick={() => props.setModuleName(false)}>
              <img src={require('./img/close.png')} alt="" />
            </div>
          </div>
        </div>
        <div className={style.value_list}>
          <button onClick={() => setBalance(100)}>
            <div>
              <span>$</span>
              <span>100</span>
            </div>
            <span>USD</span>
          </button>
          <button onClick={() => setBalance(500)}>
            <div>
              <span>$</span>
              <span>500</span>
            </div>
            <span>USD</span>
          </button>
          <button onClick={() => setBalance(1000)}>
            <div>
              <span>$</span>
              <span>1000</span>
            </div>
            <span>USD</span>
          </button>
        </div>
        <div className={style.action_wrapper}>
          <div className={`${style.input_wrapper} ${style.white}`}>
            <span>Add Other Amount</span>
            <div className={style.value}>
                <span>$</span>
                <input 
                    type="number" 
                    value={amountToAdd}
                    onChange={(e) => setAmountToAdd(e.target.value)}
                  />
                <span>USD</span>
            </div>
          </div>
          <div className={`${style.input_wrapper}`}>
            <span>New Balance</span>
            <div className={style.value}>
                <span>$</span>
                <input 
                  type="number" 
                  value={(totalAmount + Number(amountToAdd))?.toFixed(2)}
                />
                <span>USD</span>
            </div>
          </div>
          <button onClick={addTransaction}>
            <span>Update</span>
          </button>
        </div>
        <div className={style.table_wrapper}>
          <div className={style.table_header}>
            <span className={style.name}>Bankroll History</span>
            <div className={style.dropdown_button}>
              <Dropdown>
                  <MenuButton>
                      <span>{sorting}</span>
                      <img src={require('./img/filter_white.png')} alt="" />
                  </MenuButton>

                  <Menu style={{zIndex: '2000'}} slots={{ listbox: Listbox }}>
                    {sorting_types_local.map(s => <MenuItem sx={sorting === s ? {color: colors.green} : {}} onClick={() => {
                        setSorting(s);
                        setTransactionsLocal(sortTransactions(transactionsLocal, s));
                    }}>{s}</MenuItem>)}
                  </Menu>
              </Dropdown>
            </div>
          </div>
          <div className={style.modular_table}>
              <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                      <TableHead>
                      <TableRow>
                          <TableCell align="center"><span>Transaction ID</span></TableCell>
                          <TableCell align="center"><span>Date/Time</span></TableCell>
                          <TableCell align="center"><span>Amount</span></TableCell>
                          <TableCell align="center"><span>Balance</span></TableCell>
                      </TableRow>
                      </TableHead>
                      <TableBody>
                      {
                        transactionsLocal.map((trx) => (
                          <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center"><span>{trx.transactionId}</span></TableCell>
                            <TableCell align="center"><span>{trx.timestamp}</span></TableCell>
                            <TableCell align="center"><span>
                              {trx.amount > 0 ? '' : '-'}
                              $<span className={trx.amount > 0 ? style.green : style.red}>{Math.abs(trx.amount?.toFixed(2))}</span></span>
                            </TableCell>
                            <TableCell align="center"><span>${trx.balance?.toFixed(2)}</span></TableCell>
                          </TableRow>
                        ))
                      }
                      </TableBody>
                  </Table>
              </TableContainer>
          </div>
        </div>
      </div>
    </>
  )
}

function DownBalance(props) {
  const dispatch = useDispatch();
  const [sorting, setSorting] = useState(sorting_types_local[0]);
  const [amountToWithdraw, setAmountToWithdraw] = useState(0);

  const userTransactions = useSelector(state => state.user.transactions);
  const [transactionsLocal, setTransactionsLocal] = useState([...userTransactions].reverse());

  const [totalAmount, setTotalAmount] = useState(userTransactions.reduce((prev, transaction) => prev + transaction.amount, 0));

  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    setTotalAmount(userTransactions.reduce((prev, transaction) => prev + transaction.amount, 0));
    setTransactionsLocal(sortTransactions([...userTransactions].reverse(), sorting));
  }, [userTransactions]);

  const addTransaction = async () => {
    if (Number(amountToWithdraw) <= 0) {
      setShowPopUp(true);
      return;
    }

    const data = {
      transactionId: Math.random().toString(36).substr(2, 9) + Date.now().toString(36),
      timestamp: getCurrentTimestampFormatted(),
      amount: -1 * Number(amountToWithdraw),
      balance: totalAmount - Number(amountToWithdraw),
    }

    await dispatch(addUserTransaction(data));
    setAmountToWithdraw(0);
  }

  return(
    <>
      { showPopUp ?
          <div className={style.popup}>
              <div className={style.module_wrapper}>
                  <div className={style.content}>
                      <span>Withdraw Amount must be greater than zero</span>
                  </div>
                  <div className={style.action_block}>
                      <button className={style.right} onClick={() => setShowPopUp(false)}>OK</button>
                  </div>
              </div>
          </div> : null
      }
      <div className={style.modular_item}>
        <div className={style.top}>
          <div className={style.name_wrapper}>
            <span className={`${style.name} ${style.red}`}>Withdraw Balance </span>
          </div>
          <div className={style.right_part}>
            <div className={style.balance_wrapper}>
              <span>Current Balance</span>
              <div className={style.value}>
                  <span>$</span>
                  <input 
                    type="number" 
                    value={totalAmount?.toFixed(2)}
                  />
                  <span>USD</span>
              </div>
            </div>
            <div className={style.close} onClick={() => props.setModuleName(false)}>
              <img src={require('./img/close.png')} alt="" />
            </div>
          </div>
        </div>
        <div className={style.action_wrapper}>
          <div className={`${style.input_wrapper} ${style.white}`}>
            <span>Withdraw Amount</span>
            <div className={style.value}>
                <span>$</span>
                <input 
                    
                    value={amountToWithdraw}
                    onChange={(e) => setAmountToWithdraw(e.target.value)}
                  />
                <span>USD</span>
            </div>
          </div>
          <div className={`${style.input_wrapper}`}>
            <span>New Balance</span>
            <div className={`${style.value} ${style.red_border}`}>
                <span>$</span>
                <input 
                  type="number" 
                  value={(totalAmount - Number(amountToWithdraw))?.toFixed(2)}
                />
                <span>USD</span>
            </div>
          </div>
          <button onClick={addTransaction}>
            <span>Update</span>
          </button>
        </div>
        <div className={style.table_wrapper}>
          <div className={style.table_header}>
            <span className={style.name}>Bankroll History</span>
            <div className={style.dropdown_button}>
              <Dropdown>
                  <MenuButton>
                      <span>{sorting}</span>
                      <img src={require('./img/filter_white.png')} alt="" />
                  </MenuButton>

                  <Menu style={{zIndex: '2000'}} slots={{ listbox: Listbox }}>
                    {sorting_types_local.map(s => <MenuItem sx={sorting === s ? {color: colors.green} : {}} onClick={() => {
                        setSorting(s);
                        setTransactionsLocal(sortTransactions(transactionsLocal, s));
                    }}>{s}</MenuItem>)}
                  </Menu>
              </Dropdown>
            </div>
          </div>
          <div className={style.modular_table}>
              <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                      <TableHead>
                      <TableRow>
                          <TableCell align="center"><span>Transaction ID</span></TableCell>
                          <TableCell align="center"><span>Date/Time</span></TableCell>
                          <TableCell align="center"><span>Amount</span></TableCell>
                          <TableCell align="center"><span>Balance</span></TableCell>
                      </TableRow>
                      </TableHead>
                      <TableBody>
                      {
                        transactionsLocal.map((trx) => (
                          <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="center"><span>{trx.transactionId}</span></TableCell>
                            <TableCell align="center"><span>{trx.timestamp}</span></TableCell>
                            <TableCell align="center"><span>
                              {trx.amount > 0 ? '' : '-'}
                              $<span className={trx.amount > 0 ? style.green : style.red}>{Math.abs(trx.amount?.toFixed(2))}</span></span>
                            </TableCell>
                            <TableCell align="center"><span>${trx.balance?.toFixed(2)}</span></TableCell>
                          </TableRow>
                        ))
                      }
                      </TableBody>
                  </Table>
              </TableContainer>
          </div>
        </div>
      </div>
    </>
  )
}


function ProfitHistoryPopUp({profitGraphData, setIsProfitHistoryOpened}) {
  const [alignment, setAlignment] = useState('week');

  const [graphView, setGraphView] = useState(true);
  const componentRef = useRef();

  const [imageURL, setImageURL] = useState('');
  const [isScreenshotMode, setScreenshotMode] = useState(false);
  const [isShowSharePopUp, setShowSharePopUp] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);

  const handleChangeView = (event) => {
    setGraphView(event.target.checked);
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  function filterDataForGraph(data, interval) {
    if (!data) return [];
    let filteredData = [];
  
    if (interval === 'week') {
      filteredData = data.slice(-7);
    } else if (interval === 'month') {
      // Add every 5th element
      filteredData = data.filter((item, index) => index % 5 === 0);
    } else if (interval === 'year') {
      const lastProfitPerMonth = data.reduce((acc, item) => {
        const month = dayjs(item.date).format('MMM YYYY'); 
        acc[month] = item;
        return acc;
      }, {});

      filteredData = Object.values(lastProfitPerMonth).slice(-12);
    } else {
      filteredData = [...data];
    }
  
    // Checking if today's date is in the filtered data
    const today = new Date().toISOString().slice(0, 10);
    const todayData = data.find(item => item.date === today);
    if (todayData && !filteredData.some(item => item.date === today)) {
      // If today's date is missing, add it to the end of the array
      filteredData.push(todayData);
    }
  
    return filteredData;
  }

  const dataForGraph = useMemo(() => {
    return filterDataForGraph(profitGraphData[`${alignment}Data`], alignment);
  }, [alignment, profitGraphData]);

  const data = {
    labels: dataForGraph.map(item => {
      if (alignment === 'week') {
        return formatDateToWeekday(item.date);
      } else if (alignment === 'month') {
        return formatDateToDayMonth(item.date);
      } else if (alignment === 'year') {
        return formatDateToMonth(item.date);
      } else {
        return item.date;
      }
    }),
    datasets: [
      {
        label: 'Profit',
        data: dataForGraph.map(item => item.profitResult),
        fill: false,
        backgroundColor: '#91E617',
        borderColor: '#91E617',
      },
    ],
  };

  const options = {
    responsive: true,
    
    scales: {
      x: {
        ticks: {
          color: '#fff', // X-axis label text color
        },
        title: {
          display: true,
          color: '#fff', // X Axis Title Color
        },
      },
      y: {
        ticks: {
          color: '#fff', // Y-axis label text color
        },
        title: {
          display: true,
          color: '#fff', // Y Axis Title Color
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#fff', // Legend text color
        },
      },
    },
    maintainAspectRatio: false
  };

  const handleTakeScreenshot = async () => {
    setScreenshotMode(true); // Activate screenshot mode
    setShowSharePopUp(true);

    // waiting for the component update
    setTimeout(async () => {
      const element = componentRef.current;

      // Set the width of the element so that the screenshot matches the desktop version
      element.style.width = '670px';

      const canvas = await html2canvas(element, {
        windowWidth: 670, // Specifying the width of the window so that the screenshot looks like on a desktop
        windowHeight: element.scrollHeight, // Adapting the screenshot height to the content
      });

      const dataUrl = canvas.toDataURL('image/png');
      setScreenshotMode(false);

      element.style.width = 'auto'; // Return to original width

      const url = await RestServerAPI.getImageUrl(dataUrl);
      setImageURL(url);
      setIsGenerated(true);

      setShowSharePopUp(true);
    }, 0);
  };

  function formatDateToWeekday(dateString) {
    return dayjs(dateString).format('ddd'); 
  }

  function formatDateToDayMonth(dateString) {
    return dayjs(dateString).format('DD/MM');
  }

  function formatDateToMonth(dateString) {
    return dayjs(dateString).format('MMM');
  }

  return(
    <>
      <div className={style.modular_wrapper_profit}>
        {
          isShowSharePopUp ? 
          <SharePopUp setShowSharePopUp={setShowSharePopUp} imageURL={imageURL} isGenerated={isGenerated} setIsGenerated={setIsGenerated}></SharePopUp> 
          : null
        }
        <div className={style.modular_item}>
          <div className={style.history_wrapper}>
            <div className={style.history_header}>
              <span className={style.history_text}>Profit Tracking</span>
            </div>
            
            <div className={style.close} onClick={() => {
              setIsProfitHistoryOpened(false);
            }}>
              <img src={require('./img/close.png')} alt="" />
            </div>
          </div>
          <div className={style.toggles}>
            <div>
              {
                graphView ?
                <StyledToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                  aria-label="profit tracking"
                  size="small"
                >
                  <StyledToggleButton value="week" aria-label="week">
                    Week
                  </StyledToggleButton>
                  <StyledToggleButton value="month" aria-label="month">
                    Month
                  </StyledToggleButton>
                  <StyledToggleButton value="year" aria-label="year">
                    Year
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
                : null
              }
            </div>
            <div className={style.switch_toggle}>
              <span>
                Calendar View
              </span>
              <CustomSwitch checked={graphView} onChange={handleChangeView}  />
              <span>
                Graph View
              </span>
            </div>
          </div>
          <div className={style.plot_block}>
            <div className={style.plot}>
              {
                graphView ?
                <Line data={data} style={{backgroundColor: '#D9D9D926'}} options={options} />
                : <ProfitCalendar profitData={profitGraphData.yearData}></ProfitCalendar>
              }
            </div>
            
          </div>
          {
            !graphView ? 
            <div 
              className={style.share_button} 
              onClick={handleTakeScreenshot}
            >
              <ShareLogo style={{ fill: "#91E617", width: '30px', height: '30px' }}></ShareLogo>
            </div> : null
          }
        </div>
      </div>
      {
        isScreenshotMode ? 
        <div 
          ref={componentRef} 
          className={style.screenshot_container} 
          style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}
        >
          <div className={style.screenshot_logo}>
            <img src={logo} style={{width: '240px'}} alt="BetSmartAI" />
          </div>
          <div className={style.modular_wrapper_profit_screenshot}>
            <div className={style.modular_item}>
              <div className={style.history_wrapper}>
                <div className={style.history_header}>
                  <span className={style.history_text}>Profit Tracking</span>
                </div>
              </div>
              
              <div className={style.plot_block}>
                <div className={style.plot}>
                  <ProfitCalendar profitData={profitGraphData.yearData}></ProfitCalendar>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        : null
      }
    </>
  )
}

function SharePopUp({setShowSharePopUp, imageURL, isGenerated, setIsGenerated}) {
  return(
    <div className={style.modular_wrapper_share}>
      <div className={`${style.modular_item}`}>
        <div className={style.top}>
          <div className={style.name_wrapper}>
            {
              isGenerated ?
              <div>
                <span className={style.name}>Share your bet</span><br></br>
                <br></br>
                <div className={style.share_icons}>
                  <TwitterShareButton
                    url={imageURL}
                    title={"Check out my bet!"}
                    hashtags={["betting", "sports", "surebetfusion"]}
                    className="Demo__some-network__share-button"
                  >
                    <XIcon size={32} round />
                  </TwitterShareButton>

                  <FacebookShareButton
                    url={imageURL}
                    quote={"Check out my bet!"}
                    hashtag="#surebetfusion"
                    className="Demo__some-network__share-button"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>

                  <RedditShareButton
                    url={imageURL}
                    title={"Check out my bet!"}
                    className="Demo__some-network__share-button"
                  >
                    <RedditIcon size={32} round />
                  </RedditShareButton>

                  <TelegramShareButton
                    url={imageURL}
                    title={"Check out my bet!"}
                    className="Demo__some-network__share-button"
                  >
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>

                  <WhatsappShareButton
                    url={imageURL}
                    title={"Check out my bet!"}
                    separator=":: "
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>

                </div>
              </div>
              : <span className={style.name}>Generating screenshot, please wait...</span>
            }
          </div>
        </div>
        
        <div className={style.action_wrapper}>
          <button onClick={() => {
              setShowSharePopUp(false);
              setIsGenerated(false);
            }}>
            <span>Close</span>
          </button>
        </div>
        
      </div>
    </div>
  )
}

const ProfitCalendar = ({ profitData }) => {
  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      // Format the date to match data
      //const formattedDate = date.toISOString().split('T')[0];
      const formattedDate = dayjs(date).format('YYYY-MM-DD');

      // Finding income data for this date
      const profitInfo = profitData?.find(d => d.date === formattedDate);
      
      // We set the class depending on income
      if (profitInfo) {
        if (profitInfo.clearProfitResult > 0) {
          return style.profit_positive;
        } else if (profitInfo.clearProfitResult < 0) {
          return style.profit_negative;
        }
      }

      // If there is no data, then a day without income
      return style.profit_neutral;
    }
  };

  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      //const formattedDate = date.toISOString().split('T')[0];
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      const profitInfo = profitData?.find(d => d.date === formattedDate);

      // We return income for the day, if any
      return profitInfo ? (
        <p>
          {profitInfo.clearProfitResult > 0 ? '+' : profitInfo.clearProfitResult === 0 ? '' : '-'}${Math.abs(profitInfo.clearProfitResult.toFixed(2))}
        </p>
      ) : null;
    }
  };

  return (
    <Calendar
      tileClassName={tileClassName}
      tileContent={tileContent}
    />
  );
};

function AddBet(props) {
  const dispatch = useDispatch();
  const oddsFormat = useSelector(state => state.preferences.oddsFormat);
  const [surebetSelected, setSurebetSelected] = useState(true);
  const [numOfLegs, setNumOfLegs] = useState(2);
  const [inputHomeTeam, setInputHomeTeam] = useState('');
  const [inputAwayTeam, setInputAwayTeam] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [sport, setSport] = useState(defaultSports[0]);
  const [inputBookmaker, setInputBookmaker] = useState(['', '', '']);
  const [selectedBookmaker, setSelectedBookmaker] = useState(['', '', '']);
  const [inputMarket, setInputMarket] = useState(['', '', '']);
  const [inputOdds, setInputOdds] = useState([0, 0, 0]);
  const [inputAmount, setInputAmount] = useState([0, 0, 0]);
  const [errors, setErrors] = useState({});

  const handleChangeNumOfLegs = (event) => {
    setNumOfLegs(Number(event.target.value));
  };

  const handleChangeHomeTeamInput = (e) => {
    setInputHomeTeam(e.target.value);
  };

  const handleChangeAwayTeamInput = (e) => {
    setInputAwayTeam(e.target.value);
  };

  const handleChangeBookmakerInput = (e, index) => {
    let data = [...inputBookmaker];
    data[index] = e.target.value;
    setInputBookmaker(data);
  };

  const handleChangeSelectedBookmaker = (bookmaker, index) => {
    let data = [...selectedBookmaker];
    data[index] = bookmaker;
    setSelectedBookmaker(data);
    setInputBookmaker(['', '', '']);
  };

  const handleChangeOddsInput = (e, index) => {
    let data = [...inputOdds];
    data[index] = e.target.value;
    setInputOdds(data);
  };

  const handleChangeMarketInput = (e, index) => {
    let data = [...inputMarket];
    data[index] = e.target.value;
    setInputMarket(data);
  };

  const handleChangeAmountInput = (e, index) => {
    let data = [...inputAmount];
    data[index] = e.target.value;
    setInputAmount(data);
  };

  const filteredBookmakers = inputBookmaker.map((b) => b ? 
    defaultBookmakers.filter(bookmaker => bookmaker.toLowerCase().startsWith(b.toLowerCase()))
    : []);

  const validateFields = () => {
    let fieldErrors = {};

    if (!inputHomeTeam) fieldErrors.inputHomeTeam = true;
    if (!inputAwayTeam) fieldErrors.inputAwayTeam = true;
    if (!startDate) fieldErrors.startDate = true;

    if (surebetSelected) {
      for (let i = 0; i < numOfLegs; i++) {
        //if (!inputBookmaker[i]) fieldErrors[`inputBookmaker${i}`] = true;
        if (!inputMarket[i]) fieldErrors[`inputMarket${i}`] = true;
        if (!inputOdds[i] || isNaN(inputOdds[i])) fieldErrors[`inputOdds${i}`] = true;
        if (!inputAmount[i] || isNaN(inputAmount[i])) fieldErrors[`inputAmount${i}`] = true;
      }
    } else {
      if (!inputMarket[0]) fieldErrors[`inputMarket${0}`] = true;
      if (!inputOdds[0] || isNaN(inputOdds[0])) fieldErrors[`inputOdds${0}`] = true;
      if (!inputAmount[0] || isNaN(inputAmount[0])) fieldErrors[`inputAmount${0}`] = true;
    }

    setErrors(fieldErrors);
    return Object.keys(fieldErrors).length === 0;
  };

  const addToBetTracker = async () => {
    if (!validateFields()) return;

    const data = {};
    const currentDate = new Date();
    data.timestamp = currentDate.getTime();

    if (surebetSelected) {
      data.betInfo = {
        "arbitrage_type": numOfLegs === 2 ? "surebet-2way" : "surebet-3way",
        "custom": true,
        "game_name": `${inputHomeTeam} vs ${inputAwayTeam}`,
        "home_team": inputHomeTeam,
        "away_team": inputAwayTeam,
        "start_date": dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        "sport": sport,
        "bets": new Array(numOfLegs).fill(0).map((_, i) => {
          return {
            "decimal_price": oddsFormat !== "American" ? Number(inputOdds[i]) : convertAmericanToEuropean(Number(inputOdds[i])),
            "price": oddsFormat === "American" ? Number(inputOdds[i]) : convertEuropeanToAmerican(Number(inputOdds[i]))?.toFixed(2),
            "sport_key": sport,
            "bookmaker": selectedBookmaker[i],
            "bet_name": inputMarket[i],
            "amount_to_buy": inputAmount[i],
          }
        })
      };

      data.amountToStake = inputAmount.slice(0, numOfLegs);
      data.totalStake = inputAmount.reduce((acc, curr) => acc + Number(curr), 0);

      let profitsAbsolute = inputAmount.slice(0, numOfLegs).map((bet_amount, i) => {
        return Number(bet_amount) * Number(data.betInfo.bets[i].decimal_price) - data.totalStake;
      });

      let profits = profitsAbsolute.map((profitAbsolute, i) => {
        return (Number(profitAbsolute) / data.totalStake) * 100;
      });

      data.profits = profits;
      data.profitsAbsolute = profitsAbsolute;
    } else {
      data.betInfo = {
        "custom": true,
        "sports_book_name": selectedBookmaker[0],
        "name": inputMarket[0],
        "decimal_price": oddsFormat !== "American" ? Number(inputOdds[0]) : convertAmericanToEuropean(Number(inputOdds[0])),
        "price": oddsFormat === "American" ? Number(inputOdds[0]) : convertEuropeanToAmerican(Number(inputOdds[0]))?.toFixed(2),
        "start_date": dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        "game_name": `${inputHomeTeam} vs ${inputAwayTeam}`,
        "home_team": inputHomeTeam,
        "away_team": inputAwayTeam,
        "sport": sport,
      };

      data.amountToStake = [inputAmount[0]];
      data.totalStake = inputAmount[0];
      data.profits = [((data.betInfo.decimal_price - 1) * 100).toFixed(2)];
      data.profitsAbsolute = [((data.betInfo.decimal_price - 1) * data.totalStake).toFixed(2)];
    }

    data.betId = generateUUID();
    data.status = "Pending";

    await dispatch(addUserBet(data));

    props.setModuleName(false);
  };

  const getImage = (path) => {
    try {
      const cleanPath = path.replace(/\//g, '');

      return `${process.env.PUBLIC_URL}/bookmakers-img/${cleanPath}.png`;
    } catch (error) {
      return null;
    }
  };

  return (
    <>
      <div className={style.modular_item}>
        <div className={style.top}>
          <div className={style.name_wrapper}>
            <span className={`${style.name} ${style.green}`}>Add bet manually</span>
          </div>
          <div className={style.right_part}>
            <div className={style.close} onClick={() => props.setModuleName(false)}>
              <img src={require('./img/close.png')} alt="" />
            </div>
          </div>
        </div>
        <div className={style.form_wrapper}>
          <div>
            <FormControlLabel
              control={<CustomCheckbox checked={surebetSelected} onChange={() => setSurebetSelected(!surebetSelected)} />}
              label="Surebet"
            />
            <FormControlLabel
              control={<CustomCheckbox checked={!surebetSelected} onChange={() => setSurebetSelected(!surebetSelected)} />}
              label="EV+"
            />
          </div>
          {surebetSelected ? (
            <>
              <div>
                <div className={style.input_block}>
                  <label className={style.label} htmlFor="select-sport">
                    <span>Select sport</span>
                  </label>
                  <select
                    id="select-sport"
                    value={sport}
                    onChange={(e) => setSport(e.target.value)}
                    className={style.select}
                  >
                    {defaultSports.map((s) => (
                      <option key={s} value={s}>
                        {s}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={`${style.input_block} ${errors.inputHomeTeam ? style.error : ''}`}>
                  <div style={{ paddingTop: '5px', paddingBottom: '5px' }}><span>Enter home team</span></div>
                  <input
                    type="text"
                    placeholder="Enter home team"
                    value={inputHomeTeam}
                    onChange={handleChangeHomeTeamInput}
                  />
                </div>
                <div className={`${style.input_block} ${errors.inputAwayTeam ? style.error : ''}`}>
                  <div style={{ paddingTop: '5px', paddingBottom: '5px' }}><span>Enter away team</span></div>
                  <input
                    type="text"
                    placeholder="Enter away team"
                    value={inputAwayTeam}
                    onChange={handleChangeAwayTeamInput}
                  />
                </div>
                <div className={`${style.datetime_item} ${errors.startDate ? style.error : ''}`}>
                <div style={{ paddingTop: '5px', paddingBottom: '10px' }}><span>Enter the start time of the game</span></div>
                  <CustomDateTimePicker
                    label={<span>Select game start time</span>}
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                  />
                </div>
                <label className={style.label} htmlFor="number-select">
                  <span>Select number of legs</span>
                </label>
                <select
                  id="number-select"
                  value={numOfLegs}
                  onChange={handleChangeNumOfLegs}
                  className={style.select}
                >
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
              {new Array(numOfLegs).fill(0).map((_, i) => (
                <div key={i}>
                  <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <b>Leg {i + 1}</b>
                  </div>
                  <div className={`${style.input_block} ${errors[`inputBookmaker${i}`] ? style.error : ''}`}>
                    <input
                      type="text"
                      id="bookmakers"
                      placeholder="Bookmakers"
                      value={inputBookmaker[i]}
                      onChange={(e) => handleChangeBookmakerInput(e, i)}
                    />
                    {inputBookmaker[i] && (
                      <ul className={style.autocomplete_list}>
                        {filteredBookmakers[i].map((bookmaker, index) => (
                          <div key={index} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel
                              style={{ width: '30px' }}
                              control={
                                <CustomCheckbox
                                  checked={selectedBookmaker[i] === bookmaker}
                                  onChange={(e) => handleChangeSelectedBookmaker(bookmaker, i)}
                                />
                              }
                              label=""
                            />
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => handleChangeSelectedBookmaker(bookmaker, i)}
                            >
                              <span
                                style={selectedBookmaker[i] === bookmaker ? { color: colors.green } : {}}
                              >
                                {bookmaker}
                              </span>
                            </span>
                          </div>
                        ))}
                      </ul>
                    )}
                    {inputBookmaker[i] !== '' ? (
                      <XLogo
                        className={style.clear_icon}
                        style={{ fill: 'red' }}
                        onClick={() => handleChangeBookmakerInput({ target: { value: '' } }, i)}
                      ></XLogo>
                    ) : (
                      <img src={require('./img/search.png')} alt="" />
                    )}
                  </div>
                  {selectedBookmaker[i] ? (
                    <div className={style.selected_bookmaker}>
                      <span>Selected bookmaker: </span>
                      <img src={getImage(selectedBookmaker[i])} alt="" />
                      <span>{selectedBookmaker[i]}</span>
                    </div>
                  ) : null}
                  <div className={`${style.input_block} ${errors[`inputMarket${i}`] ? style.error : ''}`}>
                    <div style={{ paddingTop: '5px', paddingBottom: '5px' }}><span>Enter market name</span></div>
                    <input
                      type="text"
                      id="market"
                      placeholder="Enter market name"
                      value={inputMarket[i]}
                      onChange={(e) => handleChangeMarketInput(e, i)}
                    />
                  </div>
                  <div className={`${style.input_block} ${errors[`inputOdds${i}`] ? style.error : ''}`}>
                    <div style={{ paddingTop: '5px', paddingBottom: '5px' }}><span>Enter odds</span></div>
                    <input
                      type="number"
                      id="odds"
                      placeholder="Odds"
                      value={inputOdds[i]}
                      onChange={(e) => handleChangeOddsInput(e, i)}
                    />
                  </div>
                  <div className={`${style.input_block} ${errors[`inputAmount${i}`] ? style.error : ''}`}>
                    <div style={{ paddingTop: '5px', paddingBottom: '5px' }}><span>Enter bet amount</span></div>
                    <input
                      type="number"
                      id="amount"
                      placeholder="Bet Amount"
                      value={inputAmount[i]}
                      onChange={(e) => handleChangeAmountInput(e, i)}
                    />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <div>
                <div className={style.input_block}>
                  <label className={style.label} htmlFor="select-sport">
                    <span>Select sport</span>
                  </label>
                  <select
                    id="select-sport"
                    value={sport}
                    onChange={(e) => setSport(e.target.value)}
                    className={style.select}
                  >
                    {defaultSports.map((s) => (
                      <option key={s} value={s}>
                        {s}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={`${style.input_block} ${errors.inputHomeTeam ? style.error : ''}`}>
                  <div style={{ paddingTop: '5px', paddingBottom: '5px' }}><span>Enter home team</span></div>
                  <input
                    type="text"
                    placeholder="Enter home team"
                    value={inputHomeTeam}
                    onChange={handleChangeHomeTeamInput}
                  />
                </div>
                <div className={`${style.input_block} ${errors.inputAwayTeam ? style.error : ''}`}>
                  <div style={{ paddingTop: '5px', paddingBottom: '5px' }}><span>Enter away team</span></div>
                  <input
                    type="text"
                    placeholder="Enter away team"
                    value={inputAwayTeam}
                    onChange={handleChangeAwayTeamInput}
                  />
                </div>
                <div className={`${style.datetime_item} ${errors.startDate ? style.error : ''}`}>
                  <div style={{ paddingTop: '5px', paddingBottom: '10px' }}><span>Enter the start time of the game</span></div>
                  <CustomDateTimePicker
                    label={'Select game start time'}
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                  />
                </div>
              </div>
              <div>
                <div style={{ paddingTop: '5px', paddingBottom: '0px' }}><span>Select bookmaker</span></div>
                <div className={`${style.input_block} ${errors[`inputBookmaker0`] ? style.error : ''}`}>
                  <input
                    type="text"
                    id="bookmakers"
                    placeholder="Bookmakers"
                    value={inputBookmaker[0]}
                    onChange={(e) => handleChangeBookmakerInput(e, 0)}
                  />
                  {inputBookmaker[0] && (
                    <ul className={style.autocomplete_list}>
                      {filteredBookmakers[0].map((bookmaker, index) => (
                        <div key={index} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                          <FormControlLabel
                            style={{ width: '30px' }}
                            control={
                              <CustomCheckbox
                                checked={selectedBookmaker[0] === bookmaker}
                                onChange={(e) => handleChangeSelectedBookmaker(bookmaker, 0)}
                              />
                            }
                            label=""
                          />
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => handleChangeSelectedBookmaker(bookmaker, 0)}
                          >
                            <span style={selectedBookmaker[0] === bookmaker ? { color: colors.green } : {}}>
                              {bookmaker}
                            </span>
                          </span>
                        </div>
                      ))}
                    </ul>
                  )}
                  {inputBookmaker[0] !== '' ? (
                    <XLogo
                      className={style.clear_icon}
                      style={{ fill: 'red' }}
                      onClick={() => handleChangeBookmakerInput({ target: { value: '' } }, 0)}
                    ></XLogo>
                  ) : (
                    <img src={require('./img/search.png')} alt="" />
                  )}
                </div>
                {selectedBookmaker[0] ? (
                  <div className={style.selected_bookmaker}>
                    <span>Selected bookmaker: </span>
                    <img src={getImage(selectedBookmaker[0])} alt="" />
                    <span>{selectedBookmaker[0]}</span>
                  </div>
                ) : null}
                <div className={`${style.input_block} ${errors[`inputMarket0`] ? style.error : ''}`}>
                  <div style={{ paddingTop: '5px', paddingBottom: '5px' }}><span>Enter market name</span></div>
                  <input
                    type="text"
                    id="market"
                    placeholder="Enter market name"
                    value={inputMarket[0]}
                    onChange={(e) => handleChangeMarketInput(e, 0)}
                  />
                </div>
                <div className={`${style.input_block} ${errors[`inputOdds0`] ? style.error : ''}`}>
                  <div style={{ paddingTop: '5px', paddingBottom: '5px' }}><span>Enter odds</span></div>
                  <input
                    type="number"
                    id="odds"
                    placeholder="Odds"
                    value={inputOdds[0]}
                    onChange={(e) => handleChangeOddsInput(e, 0)}
                  />
                </div>
                <div className={`${style.input_block} ${errors[`inputAmount0`] ? style.error : ''}`}>
                  <div style={{ paddingTop: '5px', paddingBottom: '5px' }}><span>Enter bet amount</span></div>
                  <input
                    type="number"
                    id="amount"
                    placeholder="Bet Amount"
                    value={inputAmount[0]}
                    onChange={(e) => handleChangeAmountInput(e, 0)}
                  />
                </div>
              </div>
            </>
          )}
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <button style={{ marginTop: '10px' }} onClick={addToBetTracker}>
              <span>Add bet</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const useStyles = makeStyles({
  list: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 'calc(100% - 50px)'
  },
  reload: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: colors.night,
    padding: '10px'
  },
  button: {
    backgroundColor: colors.green,
    fontWeight: 'bold',
    width: '150px'
  }
});

export default BetsListComponent;


const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#99CCF3',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E6',
  700: '#0059B3',
  800: '#004C99',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};


const Listbox = styled('ul')(
  ({ theme }) => `
  font-size: 14px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  min-width: 200px;
  border-radius: 5px; 
  overflow: auto;
  outline: 0px;
  background: #383838;
  border: none;
  color: white;
  box-shadow: none;
  z-index: 1;
  `,
);

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
  list-style: none;
  padding: 12px;
  border-radius: 0;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &.${menuItemClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${menuItemClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[50]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }
  `,
);

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  padding: 8px 16px;
  color: white;
  cursor: pointer;
  background: transparent;
  border: none;
  font-weight: 600;

  &.Mui-expanded {
      .arrow {
          transform: rotate(180deg);
      }
  }
  `,
);

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    //margin: theme.spacing(0.5),
    //border: 0,
    '&.Mui-disabled': {
      //border: 0,
    },
    '&:not(:first-of-type)': {
      //borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      //borderRadius: theme.shape.borderRadius,
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: '#000',
  fontWeight: 500,
  //borderColor: 'white',
  backgroundColor: '#B7B7B7',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'black',
    backgroundColor: '#91E617',
    borderColor: '#91E617',
    fontWeight: 700
  },
  
  '&:hover': {
    color: 'black',
    backgroundColor: '#91E617',
    borderColor: '#91E617',
  },
}));

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 48, 
  height: 24, 
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 2, 
    '&.Mui-checked': {
      transform: 'translateX(24px)', 
      color: '#fff',
      '& + .MuiSwitch-track': {
        //backgroundColor: theme.palette.mode === 'dark' ? '#383838 !important' : '#383838 !important',
        opacity: 1,
      },
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7, 
    },
  },
  '& .MuiSwitch-thumb': {
    width: 20, 
    height: 20, 
    borderRadius: 3,
    backgroundColor: theme.palette.mode === 'dark' ? '#383838 !important' : '#383838 !important'
  },
  '& .MuiSwitch-track': {
    borderRadius: 6 / 2, 
    //backgroundColor: theme.palette.mode === 'dark' ? '#383838 !important' : '#383838 !important',
    border: 'none',
  },
}));


const CustomCheckbox = styled(Checkbox)({
  color: 'white', // Border color
  '&.Mui-checked': {
    color: colors.green,
  },
});

