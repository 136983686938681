import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@material-ui/core';
import colors from '../utils/colors';
import { useNavigate } from 'react-router-dom';
import CustomDateTimePicker from './CustomDateTimePicker';
import { ReactComponent as XLogo } from './img/x_icon.svg';

import style from './style/FiltersStyles.module.scss';

import { useSelector, useDispatch } from 'react-redux';
import { 
  setSelectedSports, 
  setSelectedBookmakers, 
  setTypes, 
  setToDefault,
  setStartDate,
  setEndDate
} from '../features/filter/filterSlice';

import { setUserBetsFiltered } from '../features/bets/betsSlice';
import defaultBookmakers from '../utils/bookmakers';

import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const CustomCheckbox = styled(Checkbox)({
  color: 'white', // Border color
  '&.Mui-checked': {
    color: colors.green,
  },
});

function MyBetFilter(props) {
  const classes = useStyles(); 
  const navigate = useNavigate();

  const userBets = useSelector(state => state.bets.userBets);

  const [displayAllCategories, setDisplayAllCategories] = useState(true);
  const [displaySurebetPrematch, setDisplaySurebetPrematch] = useState(true);
  const [displaySurebetLive, setDisplaySurebetLive] = useState(true);
  const [displayEVPrematch, setDisplayEVPrematch] = useState(true);
  const [displayEVLive, setDisplayEVLive] = useState(true);

  const [displayAllBest, setDisplayAllBets] = useState(true);
  const [display2Way, setDisplay2Way] = useState(true);
  const [display3Way, setDisplay3Way] = useState(true);
  const [displayWhole, setDisplayWhole] = useState(true);
  const [displayMiddle, setDisplayMiddle] = useState(true);
  const [displayQuarter, setDisplayQuarter] = useState(true);

  const mode = useSelector(state => state.settings.mode);
  const subscriptionType = useSelector(state => state.user.subscriptionType);

  const all_sports = useSelector(state => state.filter.allSports);
  const selected_sports = useSelector(state => state.filter.selectedSports);
  const all_bookmakers = useSelector(state => state.filter.allBookmakers);
  const selected_bookmakers = useSelector(state => state.filter.selectedBookmakers);
  const selected_required_bookmakers = useSelector(state => state.filter.selectedRequiredBookmakers);
  const user_required_bookmakers = useSelector(state => state.preferences.requiredBookmakers);
  const user_min_percentage = useSelector(state => state.preferences.minPercentage);
  const user_max_percentage = useSelector(state => state.preferences.maxPercentage);
  const user_period = useSelector(state => state.preferences.timeToMatch);
  const start_date = useSelector(state => state.filter.startDate);
  const end_date = useSelector(state => state.filter.endDate);
  const sorting_type = useSelector(state => state.bets.sorting);
  //const bet_types = useSelector(state => state.filter.types);

  const [displayAllSports, setDisplayAllSports] = useState(false);
  const [displayAllBookmakers, setDisplayAllBookmakers] = useState(false);

  const dispatch = useDispatch();

  const [ isSportsOpen, setIsSportsOpen ] = useState(false);
  const [ isBookmakersOpen, setIsBookmakersOpen ] = useState(false);

  useEffect(() => {
    if (displayAllBest) {
      setDisplay2Way(true);
      setDisplay3Way(true);
      setDisplayWhole(true);
      setDisplayMiddle(true);
      setDisplayQuarter(true);
    }
  }, [displayAllBest]);

  useEffect(() => {
    if (!display2Way || !display3Way || !displayWhole || !displayMiddle || !displayQuarter) {
      setDisplayAllBets(false);
    }
  }, [display2Way, display3Way, displayWhole, displayMiddle, displayQuarter]);

  useEffect(() => {
    if (displayAllCategories) {
      setDisplaySurebetPrematch(true);
      setDisplaySurebetLive(true);
      setDisplayEVPrematch(true);
      setDisplayEVLive(true);
    }
  }, [displayAllCategories]);

  useEffect(() => {
    if (!displaySurebetPrematch || !displaySurebetLive || !displayEVPrematch || !displayEVLive) {
      setDisplayAllCategories(false);
    }
  }, [displaySurebetPrematch, displaySurebetLive, displayEVPrematch, displayEVLive]);

  useEffect(() => {
    if (selected_sports.length === all_sports.length)
      setDisplayAllSports(true);
  }, [selected_sports]);

  useEffect(() => {
    if (selected_bookmakers.length === all_bookmakers.length)
      setDisplayAllBookmakers(true);
  }, [selected_bookmakers]);

  const filterMyBets = (bets) => {
    const types = [];

    if (display2Way) types.push("surebet-2way");
    if (display3Way) types.push("surebet-3way");
    if (displayWhole) types.push("surebet-whole");
    if (displayMiddle) types.push("surebet-middle");
    if (displayQuarter) types.push("surebet-quarter");

    if (!displaySurebetPrematch) {
      bets = bets.filter(b => {
        if (b.betInfo.arbitrage_type) {
          return !(["surebet-2way", "surebet-3way", "surebet-whole", "surebet-middle", "surebet-quarter"].includes(b.betInfo.arbitrage_type) 
            && !b.betInfo.is_live);
        }
        return true;
      });
    }
    if (!displaySurebetLive) {
      bets = bets.filter(b => {
        if (b.betInfo.arbitrage_type) {
          return !(["surebet-2way", "surebet-3way", "surebet-whole", "surebet-middle", "surebet-quarter"].includes(b.betInfo.arbitrage_type) 
            && b.betInfo.is_live);
        }
        return true;
      });
    }
    if (!displayEVPrematch) {
      bets = bets.filter(b => {
        if (!b.betInfo.arbitrage_type) {
          return b.is_live;
        }
        return true;
      });
    }
    if (!displayEVLive) {
      bets = bets.filter(b => {
        if (!b.betInfo.arbitrage_type) {
          return !b.is_live;
        }
        return true;
      });
    }

    bets = bets.filter(b => {
      let flag = true;
      let betInfo = b.betInfo;

      if (betInfo.arbitrage_type) {
        if (!types.includes(betInfo.arbitrage_type))
          flag = false;

        const sport = betInfo.game_id.split("^")[0];
        if (!selected_sports.includes(sport))
          flag = false;

        for (let odd of betInfo.bets) {
          if (!selected_bookmakers.includes(odd.bookmaker))
            flag = false;
        }
        
        // const percentage = betInfo.arbitrage_percentage;

        // if (!(percentage_range[0] < percentage && percentage_range[1] > percentage)) {
        //   flag = false;
        // }

        if (start_date || end_date) {
          if (!end_date) {
            const game_time = b.timestamp;

            if (start_date >= game_time) {
              flag = false;
            }

          } else if(!start_date) {
            const game_time = b.timestamp;

            if (end_date <= game_time) {
              flag = false;
            }
          } else {
            const game_time = b.timestamp;
            if (!(start_date < game_time && end_date > game_time)) {
              flag = false;
            }
          }
        }

        return flag;
      } else {
        const sport = betInfo.sport;
        if (!selected_sports.includes(sport))
          flag = false;

        if (!selected_bookmakers.includes(betInfo.sports_book_name))
          flag = false;

        if (start_date || end_date) {
          if (!end_date) {
            const game_time = b.timestamp;

            if (start_date >= game_time) {
              flag = false;
            }

          } else if(!start_date) {
            const game_time = b.timestamp;

            if (end_date <= game_time) {
              flag = false;
            }
          } else {
            const game_time = b.timestamp;
            if (!(start_date < game_time && end_date > game_time)) {
              flag = false;
            }
          }
        }
        // const percentage = betInfo.ev;

        // if (!(percentage_range[0] < percentage && percentage_range[1] > percentage)) {
        //   flag = false;
        // }

        return flag;
      }
    });

    return bets;
  }

  const searchBets = async () => {
    if (subscriptionType === "free") {
      return;
    }
    
    const types = [];

    if (display2Way) types.push("surebet-2way");
    if (display3Way) types.push("surebet-3way");
    if (displayWhole) types.push("surebet-whole");
    if (displayMiddle) types.push("surebet-middle");
    if (displayQuarter) types.push("surebet-quarter");

    dispatch(setTypes(types));

    if (mode === "mybets") {
      const filtered_bets = filterMyBets(userBets);
      dispatch(setUserBetsFiltered(filtered_bets));
    }
  }   

  const handleSportsChange = (item) => {
    if (subscriptionType === "free") return;
    const newSelectedSports = Array.isArray(selected_sports) ? [...selected_sports] : [];
  
    const index = newSelectedSports.indexOf(item);
    if (index !== -1) {
      newSelectedSports.splice(index, 1);
    } else {
      newSelectedSports.push(item);
    }

    if (newSelectedSports.length !== all_sports.length) setDisplayAllSports(false);
  
    dispatch(setSelectedSports(newSelectedSports));
  };
  
  const handleBookmakersChange = (item) => {
    if (subscriptionType === "free") return;
    if (mode === "ev" || mode === "evLive" || mode === "mybets") {
      const newSelectedBookmakers = Array.isArray(selected_bookmakers) ? [...selected_bookmakers] : [];
  
      const index = newSelectedBookmakers.indexOf(item);
      if (index !== -1) {
        newSelectedBookmakers.splice(index, 1);
      } else {
        newSelectedBookmakers.push(item);
      }

      if (all_bookmakers.length !== newSelectedBookmakers.length) setDisplayAllBookmakers(false);
    
      dispatch(setSelectedBookmakers(newSelectedBookmakers));
    } else {
      const newSelectedBookmakers = Array.isArray(selected_bookmakers) ? [...selected_bookmakers] : [];

      const index = newSelectedBookmakers.indexOf(item);

      if (index !== -1) {
        newSelectedBookmakers.splice(index, 1);
        
      } else {
        newSelectedBookmakers.push(item);
      }

      if (all_bookmakers.length !== newSelectedBookmakers.length) setDisplayAllBookmakers(false);

      dispatch(setSelectedBookmakers(newSelectedBookmakers));
    }
  };

  const clearFilter = () => {
    if (subscriptionType === "free") return;

    setDisplayAllBets(true);
    setDisplayAllCategories(true);

    dispatch(setToDefault(
      {
        required: user_required_bookmakers,
        percentage: [user_min_percentage, user_max_percentage],
        period: user_period
      }
    ));
  }

  const redirectToPage = async (event) => {
    event.preventDefault();
    navigate('/account/subscriptions');
  }

  const clearCategories = () => {
    if (subscriptionType === "free") return;

    setDisplayAllCategories(false);
    setDisplaySurebetPrematch(false);
    setDisplaySurebetLive(false);
    setDisplayEVPrematch(false);
    setDisplayEVLive(false);
  }

  const clearTypes = () => {
    if (subscriptionType === "free") return;

    setDisplayAllBets(false);
    setDisplay2Way(false);
    setDisplay3Way(false);
    setDisplayWhole(false);
    setDisplayMiddle(false);
    setDisplayQuarter(false);
  }

  const clearSports = () => {
    if (subscriptionType === "free") return;

    setDisplayAllSports(false);
    dispatch(setSelectedSports([]));
  }

  const clearBookmakers = () => {
    if (subscriptionType === "free") return;

    setDisplayAllBookmakers(false);
    dispatch(setSelectedBookmakers([]));
  }

  const handleSelectAllBookmakers = () => {
    const new_value = !displayAllBookmakers
    setDisplayAllBookmakers(new_value);
    if (new_value) dispatch(setSelectedBookmakers(all_bookmakers));
  }

  const [inputBookmaker, setInputBookmaker] = useState('');

  const handleChange = (e) => {
    if (subscriptionType === "free") return;

    setInputBookmaker(e.target.value);
  };


  const filteredBookmakers = inputBookmaker
    ? defaultBookmakers.filter(bookmaker => bookmaker.toLowerCase().startsWith(inputBookmaker.toLowerCase()))
    : [];

  return (
    <div className={`${classes.root} ${props.hideFilterWrapper ? style.hidden : ''}`}>
      <div className={`${classes.filter} ${style.filter} ${subscriptionType === "free" ? style.free : ''}`}>
        <div className={style.filter_header}>
          <div className={`${style.filter_button} ${style.saved_filters} ${subscriptionType === "free" ? style.non_clickable_div : ''}`}>
            
          </div>
          <h2 className={classes.heading}>{'Filter (Default)'}</h2>
          <div className={`${style.filter_button} ${style.close_button}`} onClick={() => props.setMobileSubheaderStatus(false)}>
            <CloseIcon color='#fff'></CloseIcon>
          </div>
          <div className={`${style.filter_button} ${style.hide_button}`} onClick={() => props.setHideFilterWrapper(!props.hideFilterWrapper)}>
            <img src={require('./img/left_arrow.png')} alt="" />
          </div>
        </div>
        <div className={`${style.filter_container} ${subscriptionType === "free" ? style.non_clickable_div : ''}`}>
          <span className={style.filter_item_header}>
            Select Category
            <button className={style.close_red_button} onClick={() => clearCategories()}>
              <img src={require('./img/close_round_red.png')} alt="Close icon" />
            </button>
          </span>
          <div className={style.item}>
            <FormControlLabel 
              control={<CustomCheckbox checked={displayAllCategories} onChange={() => setDisplayAllCategories(!displayAllCategories)} />} 
              label="All Categories" 
            />
            <FormControlLabel 
              control={<CustomCheckbox checked={displaySurebetPrematch} onChange={() => setDisplaySurebetPrematch(!displaySurebetPrematch)} />} 
              label="SureBet, Prematch" 
            />
            <FormControlLabel 
              control={<CustomCheckbox checked={displaySurebetLive} onChange={() => setDisplaySurebetLive(!displaySurebetLive)} />} 
              label="SureBet, Live" 
            />
            <FormControlLabel 
              control={<CustomCheckbox checked={displayEVPrematch} onChange={() => setDisplayEVPrematch(!displayEVPrematch)} />} 
              label="EV+, Prematch" 
            />
            <FormControlLabel 
              control={<CustomCheckbox checked={displayEVLive} onChange={() => setDisplayEVLive(!displayEVLive)} />} 
              label="EV+, Live" 
            />
          </div>
        </div>
        <>
          {
            mode !== "ev" && mode !== "evLive" ? 
            <div className={`${style.filter_container} ${subscriptionType === "free" ? style.non_clickable_div : ''}`}>
              <span className={style.filter_item_header}>
                Select Bets
                <button className={style.close_red_button} onClick={() => clearTypes()}>
                  <img src={require('./img/close_round_red.png')} alt="Close icon" />
                </button>
              </span>
              <div className={style.item}>
                <FormControlLabel 
                  control={<CustomCheckbox checked={displayAllBest} onChange={() => setDisplayAllBets(!displayAllBest)} />} 
                  label="All Bets" 
                />
                <FormControlLabel 
                  control={<CustomCheckbox checked={display2Way} onChange={() => setDisplay2Way(!display2Way)} />} 
                  label="2-Way" 
                />
                <FormControlLabel 
                  control={<CustomCheckbox checked={display3Way} onChange={() => setDisplay3Way(!display3Way)} />} 
                  label="3-Way" 
                />
                <FormControlLabel 
                  control={<CustomCheckbox checked={displayWhole} onChange={() => setDisplayWhole(!displayWhole)} />} 
                  label="Whole" 
                />
                <FormControlLabel 
                  control={<CustomCheckbox checked={displayMiddle} onChange={() => setDisplayMiddle(!displayMiddle)} />} 
                  label="Middle" 
                />
                <FormControlLabel 
                  control={<CustomCheckbox checked={displayQuarter} onChange={() => setDisplayQuarter(!displayQuarter)} />} 
                  label="Quarter" 
                />
              </div>
            </div>
            : null
          }
          <div className={`${style.filter_container} ${style.filter_sports_container}`}>
            <div className={`${style.filter_item_header} ${style.filter_item_header_container}`} onClick={() => setIsSportsOpen(!isSportsOpen)}>
              <span className={style.filter_item_header}>
                Select sports
                {isSportsOpen ? <button className={style.close_red_button} onClick={(e) => {
                  e.stopPropagation();
                  clearSports();
                }}>
                  <img src={require('./img/close_round_red.png')} alt="Close icon" />
                </button> : null}
              </span>
              <img src={require('./img/arrow.svg').default} className={isSportsOpen ? style.active : ''} alt="Filter icon" />
            </div>
            <div className={`${style.item} ${isSportsOpen ? style.active : ''}`}>
              <FormControlLabel 
                control={<CustomCheckbox checked={displayAllSports} onChange={() => {
                  const new_value = !displayAllSports
                  setDisplayAllSports(new_value);
                  if (new_value) dispatch(setSelectedSports(all_sports));
                }} />} 
                label="All Sports" 
              />
              {
                all_sports.map((item, index) => 
                  <FormControlLabel 
                    key={index} 
                    control={
                      <CustomCheckbox 
                          checked={selected_sports.includes(item)} 
                          onChange={() => handleSportsChange(item)}
                      />
                      } 
                    label={item} 
                  />
                )
              }
            </div>
          </div>

          <div className={`${style.filter_container} ${style.filter_bookmakers_container}`}>
            <div className={`${style.filter_item_header} ${style.filter_item_header_container}`} onClick={() => setIsBookmakersOpen(!isBookmakersOpen)}>
              <span className={style.filter_item_header}>
                Select bookmakers
                {isBookmakersOpen ? <button className={style.close_red_button} onClick={(e) => {
                  e.stopPropagation();
                  clearBookmakers();
                }}>
                  <img src={require('./img/close_round_red.png')} alt="Close icon" />
                </button> : null}
              </span>
              <img src={require('./img/arrow.svg').default} className={isBookmakersOpen ? style.active : ''} alt="Filter icon" />
            </div>
            {
              isBookmakersOpen ?
              <div className={style.input_block}>
                  <input 
                      type="text" 
                      id='bookmakers' 
                      placeholder='Bookmakers' 
                      value={inputBookmaker}
                      onChange={handleChange}
                  />
                  {inputBookmaker && (
                      <ul className={style.autocomplete_list}>
                      {filteredBookmakers.map((bookmaker, index) => (
                          
                          <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                            <FormControlLabel 
                              style={{width: '30px'}}
                              key={index} 
                              control={
                                <CustomCheckbox 
                                    checked={selected_bookmakers.includes(bookmaker)} 
                                    onChange={() => handleBookmakersChange(bookmaker)}
                                />
                                } 
                              label="" 
                            />
                            <span style={{cursor: 'pointer'}}>
                              <span>{bookmaker}</span>
                            </span>
                          </div>
                      ))}
                      </ul>
                  )}
                  {
                    inputBookmaker !== '' ?
                    <XLogo 
                      className={style.clear_icon} 
                      style={{fill: 'red'}}
                      onClick={() => setInputBookmaker('')}
                    ></XLogo>
                    : <img src={require('./img/search.png')} alt="" />
                  }
              </div> : ''
            }
            <div className={`${style.item} ${style.scroll_item} ${isBookmakersOpen ? style.active : ''}`}>
              <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                <FormControlLabel 
                  style={{width: '30px'}}
                  control={<CustomCheckbox checked={displayAllBookmakers} onChange={handleSelectAllBookmakers} />} 
                  label="" 
                />
                <span style={{cursor: 'pointer'}} onClick={handleSelectAllBookmakers}>
                  <span><b>All Bookmakers</b></span>
                </span>
              </div>
              {
                all_bookmakers.map((item, index) => 
                  <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                    <FormControlLabel 
                      style={{width: '30px'}}
                      key={index} 
                      control={
                        <CustomCheckbox 
                            checked={selected_bookmakers.includes(item)} 
                            onChange={() => handleBookmakersChange(item)}
                        />
                        } 
                      label="" 
                    />
                    <span>
                      <span style={selected_required_bookmakers.includes(item) ? {color: colors.green}: {}}>{item}</span> {selected_bookmakers.includes(item) ? 
                      <FiberManualRecordIcon sx={{ 
                        height: '12px', 
                        width: '12px',
                        color: selected_required_bookmakers.includes(item) ? colors.green : colors.grey
                      }}></FiberManualRecordIcon> : null}
                    </span>
                  </div>
                )
              }
            </div>
          </div>

          <div className={style.datetime_section}>
            <div className={`${style.filter_item_header} ${style.filter_item_header_container}`}>
              <span className={style.filter_item_header}>Select Time Period for Bets Placed</span>
            </div>
            <div className={`${style.datetime_item}`}>
              <CustomDateTimePicker 
                label={'Select start time'} 
                value={start_date}
                onChange={(newValue) => {
                  dispatch(setStartDate(newValue.unix()));
                }}
              />
            </div>
            <div className={`${style.item}`}>
              <CustomDateTimePicker 
                label={'Select end time'} 
                value={end_date}
                onChange={(newValue) => {
                  dispatch(setEndDate(newValue.unix()));
                }}
              />
            </div>
          </div>

          {
            subscriptionType === "free" ?
            <div className={style.free_wrapper}>
                <p>
                  Your FREE subscription does not allow you to view this functionality. <br /><br />
                  <span>Upgrade</span> your plan in order to see all bets and access full functionality.
                </p>
                <button onClick={redirectToPage}>
                  Upgrade Plan
                </button>
            </div> : ''
          }

          <div className={`${style.bottom_wrapper} ${subscriptionType === "free" ? style.non_clickable_div : ''}`}>
            <div className={style.clear_container} onClick={() => clearFilter()}>
              <img src={require('./img/clear_icon.svg').default} alt="Clear icon" />
              <span>Clear Filter</span>
            </div>
            <Button 
              variant="contained" 
              disabled={subscriptionType === "free" ? true : false}
              className={`${style.search_button} ${subscriptionType === "free" ? style.disable : ''}`}
              onClick={searchBets}
            >Apply</Button>
          </div>
        </>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    height: '100%',
    boxSizing: 'border-box',
    background: colors.celadon
  },
  filter: {
    color: 'white',
    height: '100%',
    boxSizing: 'border-box',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  heading: {
    margin: 0,
  },
  datepicker: {
    color: 'white',
  },
  button: {
    background: colors.green,
    fontWeight: 'bold',
    width: '150px'
  },
});

export default MyBetFilter;