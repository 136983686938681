import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import colors from '../utils/colors';
import SliderComponent from './SliderComponent';
import SelectComponent from './SelectComponent';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as XLogo } from './img/x_icon.svg';
import Switch from '@mui/material/Switch';

import style from './style/FiltersStyles.module.scss';

import defaultSports from '../utils/sports';
import defaultBookmakers from '../utils/bookmakers';

import { useSelector, useDispatch } from 'react-redux';
import { 
  setSelectedSports, 
  setSelectedBookmakers, 
  setAllSports,
  setAllBookmakers,
  setSelectedRequiredBookmakers,
  setRange, 
  setTypes, 
  setGamePeriod,
  setBetPeriod,
  setToDefault,
  addFilter,
  deleteFilter,
  setSelectedFilterId,
  setMinOdds,
  setMaxOdds
} from '../features/filter/filterSlice';

import { setIsCheckedForFilter } from '../features/settings/settingsSlice';

import socketServerAPI from './../http/socketServerAPI';

import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const CustomCheckbox = styled(Checkbox)({
  color: 'white', // Border color
  '&.Mui-checked': {
    color: colors.green,
  },
});


const decimalToAmerican = (decimalOdds) => {
  if (decimalOdds >= 2.0) {
    return Math.round((decimalOdds - 1) * 100);
  } else {
    return Math.round(-100 / (decimalOdds - 1));
  }
};

const americanToDecimal = (americanOdds) => {
  if (americanOdds > 0) {
    return ((americanOdds / 100) + 1).toFixed(2);
  } else {
    return ((100 / Math.abs(americanOdds)) + 1).toFixed(2);
  }
};


/**
 * FiltersComponent
 * 
 * This component renders the filter options for users to refine their search for bets across different categories such as bet types,
 * sports, bookmakers, profit range, game period, and bet update period. It supports the creation and application of saved filters
 * for quick access to frequently used filter configurations. Users can toggle between viewing all bets or specifying their preferences
 * for 2-way, 3-way, whole, middle, or quarter bets, select sports and bookmakers, and define profit ranges and time periods for games
 * and bet updates.
 * 
 * Key Features:
 * - Dynamic filtering: Allows users to apply multiple filter criteria simultaneously to refine the list of displayed bets.
 * - Saved filters: Users can save their filter settings for future use, enhancing usability by providing quick access to preferred
 *   search configurations.
 * - Sport and bookmaker selection: Offers a comprehensive list of sports and bookmakers with options to select all or specific entries.
 * - Customizable profit range and time period sliders: Enables users to define the desired profit range and time periods for games and
 *   bet updates.
 * - Responsive UI elements: Includes checkboxes, select dropdowns, and sliders that respond dynamically to user interactions, reflecting
 *   the current filter state.
 * - Integration with Redux: Utilizes Redux for state management, ensuring that filter settings are consistently applied across the app.
 * 
 * The component enhances user experience by providing a flexible and intuitive interface for filtering bets, tailored to the user's
 * specific interests and preferences. It plays a crucial role in helping users navigate through a vast selection of bets, making it
 * easier to find those that match their criteria.
 */
function FiltersComponent(props) {
  const classes = useStyles(); 
  const navigate = useNavigate();

  const userFilters = useSelector(state => state.filter.userFilters);
  const userBets = useSelector(state => state.bets.userBets);

  const [savedFilters, setSavedFilters] = useState(userFilters || {});

  const [displayAllBest, setDisplayAllBets] = useState(true);
  const [display2Way, setDisplay2Way] = useState(true);
  const [display3Way, setDisplay3Way] = useState(true);
  const [displayWhole, setDisplayWhole] = useState(true);
  const [displayMiddle, setDisplayMiddle] = useState(true);
  const [displayQuarter, setDisplayQuarter] = useState(true);
  const [displayMainLines, setDisplayMainLines] = useState(false);
  const [displayStoppaged, setDisplayStoppaged] = useState(false);

  const [displayAllSports, setDisplayAllSports] = useState(false);
  const [displayAllBookmakers, setDisplayAllBookmakers] = useState(false);

  const [showSavedFilters, setShowSavedFilters] = useState(false);
  const [saveFilterName, setSaveFilterName] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [isFilterSaved, setIsFilterSaved] = useState(false);

  const mode = useSelector(state => state.settings.mode);
  const systemSettings = useSelector(state => state.settings.systemSettings);
  const isFetched = useSelector(state => state.settings.isFetched);
  const isCheckedForFilter = useSelector(state => state.settings.isCheckedForFilter);
  const subscriptionType = useSelector(state => state.user.subscriptionType);

  const limit = useSelector(state => state.bets.limit[mode]);

  const all_sports = useSelector(state => state.filter.allSports);
  const selected_sports = useSelector(state => state.filter.selectedSports);
  const all_bookmakers = useSelector(state => state.filter.allBookmakers);
  const selected_bookmakers = useSelector(state => state.filter.selectedBookmakers);
  const selected_required_bookmakers = useSelector(state => state.filter.selectedRequiredBookmakers);
  
  const user_required_bookmakers = useSelector(state => state.preferences.requiredBookmakers);
  const user_min_percentage = useSelector(state => state.preferences.minPercentage);
  const user_max_percentage = useSelector(state => state.preferences.maxPercentage);
  const user_period = useSelector(state => state.preferences.timeToMatch);
  const user_min_odds = useSelector(state => state.preferences.minOdds ?? -10000);
  const user_max_odds = useSelector(state => state.preferences.maxOdds ?? 10000);

  const min_odds = useSelector(state => state.filter.minOdds);
  const max_odds = useSelector(state => state.filter.maxOdds);

  const percentage_range = useSelector(state => state.filter.percentageRange);
  const start_date = useSelector(state => state.filter.startDate);
  const end_date = useSelector(state => state.filter.endDate);
  const game_period = useSelector(state => state.filter.gamePeriod);
  const bet_period = useSelector(state => state.filter.betPeriod);
  const sorting_type = useSelector(state => state.bets.sorting);
  const bet_types = useSelector(state => state.filter.types);
  const no_vig = useSelector(state => state.preferences.noVig);

  const selected_filter_id = useSelector(state => state.filter.selectedFilterId);

  const dispatch = useDispatch();

  const saveFilterNameRef = useRef();

  const [ saveFilterFlag, setSaveFilterFlag ] = useState(savedFilters[selectedFilter] ? true : false);

  const [ isSportsOpen, setIsSportsOpen ] = useState(false);
  const [ isBookmakersOpen, setIsBookmakersOpen ] = useState(false);

  const [ shouldSearchBets, setShouldSearchBets ] = useState(false);

  const [clickedSave, setClickedSave] = useState(false);

  const selected_odds_format = useSelector(state => state.preferences.oddsFormat);

  const [selectedOddsFormatState, setSelectedOddsFormatState] = useState(selected_odds_format);

  const [selectedMinOddsState, setSelectedMinOddsState] = useState(
    selectedOddsFormatState === "Decimal" ? americanToDecimal(min_odds) : min_odds
  );

  const [selectedMaxOddsState, setSelectedMaxOddsState] = useState(
    selectedOddsFormatState === "Decimal" ? americanToDecimal(max_odds) : max_odds
  );

  useEffect(() => {
    setSelectedMinOddsState(selectedOddsFormatState === "Decimal" ? americanToDecimal(user_min_odds) : user_min_odds);
  }, [user_min_odds]);

  useEffect(() => {
    setSelectedMaxOddsState(selectedOddsFormatState === "Decimal" ? americanToDecimal(user_max_odds) : user_max_odds);
  }, [user_max_odds]);

  useEffect(() => {
    dispatch(setMinOdds(selectedOddsFormatState === "Decimal" ? decimalToAmerican(Number(selectedMinOddsState)) : Number(selectedMinOddsState)));
  }, [selectedMinOddsState]);

  useEffect(() => {
    dispatch(setMaxOdds(selectedOddsFormatState === "Decimal" ? decimalToAmerican(Number(selectedMaxOddsState)) : Number(selectedMaxOddsState)));
  }, [selectedMaxOddsState]);

  useEffect(() => {
    setSavedFilters(userFilters);

    if (isFetched) {
      const savedSelectedFilter = localStorage.getItem('selectedFilter') ?? '';

      if (savedSelectedFilter === '') {
        dispatch(setIsCheckedForFilter(true));
      } else {
        if (userFilters[savedSelectedFilter]) {
          setSelectedFilter(savedSelectedFilter);
          updateFilterState(userFilters, savedSelectedFilter);
        } else {
          localStorage.setItem('selectedFilter', '');
        }
      }
    }
  }, [userFilters, isFetched]);

  useEffect(() => {
    if (displayAllBest) {
      setDisplay2Way(true);
      setDisplay3Way(true);
      setDisplayWhole(true);
      setDisplayMiddle(true);
      setDisplayQuarter(true);
      //setDisplayMainLines(false);
    }
  }, [displayAllBest])

  useEffect(() => {
    if (!display2Way || !display3Way || !displayWhole || !displayMiddle || !displayQuarter) {
      setDisplayAllBets(false);
    }
  }, [display2Way, display3Way, displayWhole, displayMiddle, displayQuarter])

  useEffect(() => {
    if (selected_sports.length !== all_sports.length)
      setDisplayAllSports(false);
    else setDisplayAllSports(true);
  }, [selected_sports]);

  useEffect(() => {
    if (selected_bookmakers.length !== all_bookmakers.length)
      setDisplayAllBookmakers(false);
    else setDisplayAllBookmakers(true);
  }, [selected_bookmakers]);

  const saveSetting = () => {
    if (subscriptionType === "free") {
      return;
    }

    if (saveFilterName.length > 0) {
      const currentDate = new Date();
      const month = currentDate.getMonth() + 1;
      const day = currentDate.getDate();
      const year = currentDate.getFullYear();

      // if (selectedFilter && savedFilters[selectedFilter] && selectedFilter !== saveFilterName) {
      //   removeSavedFilter(selectedFilter);
      // }

      const types = [];

      if (display2Way) types.push("surebet-2way");
      if (display3Way) types.push("surebet-3way");
      if (displayWhole) types.push("surebet-whole");
      if (displayMiddle) types.push("surebet-middle");
      if (displayQuarter) types.push("surebet-quarter");
      if (displayMainLines) types.push("surebet-main-lines");
      if (displayStoppaged) types.push("display-stopped-games");

      const newFilter = {
        name: saveFilterName,
        types: types,
        allSports: all_sports,
        selectedSports: selected_sports,
        allBookmakers: all_bookmakers,
        selectedBookmakers: selected_bookmakers,
        selectedRequiredBookmakers: selected_required_bookmakers,
        percentageRange: percentage_range,
        gamePeriod: game_period,
        betPeriod: bet_period,
        minOdds: min_odds,
        maxOdds: max_odds,
        saveDate: `${month}/${day}/${year}`
      };

      setSavedFilters(prevFilters => ({
        ...prevFilters,
        [saveFilterName]: newFilter,
      }));
      
      // saving filter on server
      dispatch(addFilter(newFilter));
      
      if (selectedFilter) {
        setSelectedFilter(saveFilterName);
        localStorage.setItem('selectedFilter', saveFilterName);
      } else {
        setShowSavedFilters(true);
      }
      setSaveFilterName('');
    } else {
      saveFilterNameRef.current.focus();
    }

    setIsFilterSaved(true);
  }

  useEffect(() => {
    if (shouldSearchBets) {
        searchBets();
        setShouldSearchBets(false);
    }
  }, [shouldSearchBets]);

  const updateFilterState = (savedFilters, savedSelectedFilter) => {
    if (savedFilters && savedFilters[savedSelectedFilter]) {
      const filterSetting = savedFilters[savedSelectedFilter];
    
      dispatch(setTypes(filterSetting.types));
      dispatch(setAllSports(filterSetting.allSports));
      dispatch(setSelectedSports(filterSetting.selectedSports));
      dispatch(setAllBookmakers(filterSetting.allBookmakers));
      dispatch(setSelectedBookmakers(filterSetting.selectedBookmakers));
      dispatch(setSelectedRequiredBookmakers(filterSetting.selectedRequiredBookmakers));
      dispatch(setRange(filterSetting.percentageRange));

      dispatch(setGamePeriod(filterSetting.gamePeriod));
      dispatch(setBetPeriod(filterSetting.betPeriod));

      dispatch(setMinOdds(filterSetting.minOdds ?? -10000));
      dispatch(setMaxOdds(filterSetting.maxOdds ?? 10000));
      setSelectedMinOddsState(selectedOddsFormatState === "Decimal" ? americanToDecimal(filterSetting.minOdds ?? -10000) : (filterSetting.minOdds ?? -10000));
      setSelectedMaxOddsState(selectedOddsFormatState === "Decimal" ? americanToDecimal(filterSetting.maxOdds ?? 10000) : (filterSetting.maxOdds ?? 10000));

      dispatch(setSelectedFilterId(filterSetting.id));
    }

    dispatch(setIsCheckedForFilter(true));
  }

  const searchBets = async () => {
    if (subscriptionType === "free") {
      return;
    }
    
    //const types = [];

    // if (display2Way) types.push("surebet-2way");
    // if (display3Way) types.push("surebet-3way");
    // if (displayWhole) types.push("surebet-whole");
    // if (displayMiddle) types.push("surebet-middle");
    // if (displayQuarter) types.push("surebet-quarter");

    // dispatch(setTypes(types));

    if (mode === "prematch" || mode === "live" || mode === "ev" || mode === "evLive") {
      socketServerAPI.getBetsFromServer(mode, {
        page: 1,
        limit,
        types: bet_types,
        sports: selected_sports, 
        bookmakers: selected_bookmakers,
        required_bookmakers: selected_required_bookmakers,
        range: percentage_range,
        start_date,
        end_date,
        game_period,
        bet_period,
        sorting_type,
        no_vig,
        minOdds: min_odds,
        maxOdds: max_odds,
        selected_filter_id
      });
    }

    // if (mode === "mybets") {
    //   const filtered_bets = filterMyBets(userBets);
    //   dispatch(setUserBetsFiltered(filtered_bets));
    // }
  }  

  const searchBetsByFilter = async (filter) => {
    if (mode === "prematch" || mode === "live" || mode === "ev" || mode === "evLive") {
      socketServerAPI.getBetsFromServer(mode, {
        page: 1,
        limit,
        types: filter.types,
        sports: filter.selectedSports, 
        bookmakers: filter.selectedBookmakers,
        required_bookmakers: filter.selectedRequiredBookmakers,
        range: filter.percentageRange,
        start_date,
        end_date,
        game_period: filter.gamePeriod,
        bet_period: filter.betPeriod,
        sorting_type,
        no_vig,
        minOdds: min_odds,
        maxOdds: max_odds,
        selected_filter_id
      });
    }
  }  

  const handleSportsChange = (item) => {
    if (subscriptionType === "free") return;
    const newSelectedSports = Array.isArray(selected_sports) ? [...selected_sports] : [];
  
    const index = newSelectedSports.indexOf(item);
    if (index !== -1) {
      newSelectedSports.splice(index, 1);
    } else {
      newSelectedSports.push(item);
    }

    if (newSelectedSports.length !== all_sports.length) setDisplayAllSports(false);
  
    dispatch(setSelectedSports(newSelectedSports));
  };
  
  const handleBookmakersChange = (item) => {
    if (subscriptionType === "free") return;
    if (mode === "ev" || mode === "evLive" || mode === "mybets") {
      const newSelectedBookmakers = Array.isArray(selected_bookmakers) ? [...selected_bookmakers] : [];
  
      const index = newSelectedBookmakers.indexOf(item);
      if (index !== -1) {
        newSelectedBookmakers.splice(index, 1);
      } else {
        newSelectedBookmakers.push(item);
      }
    
      dispatch(setSelectedBookmakers(newSelectedBookmakers));
    } else {
      const newSelectedBookmakers = Array.isArray(selected_bookmakers) ? [...selected_bookmakers] : [];

      const index = newSelectedBookmakers.indexOf(item);

      if (index !== -1) {
        newSelectedBookmakers.splice(index, 1);

        const newSelectedRequiredBookmakers = Array.isArray(selected_required_bookmakers) ? [...selected_required_bookmakers] : [];
        const indexRequired = newSelectedRequiredBookmakers.indexOf(item);
        if (indexRequired !== -1) {
          newSelectedRequiredBookmakers.splice(indexRequired, 1);
          dispatch(setSelectedRequiredBookmakers(newSelectedRequiredBookmakers));
        }
      } else {
        newSelectedBookmakers.push(item);
      }

      if (all_bookmakers.length !== newSelectedBookmakers.length) setDisplayAllBookmakers(false);

      dispatch(setSelectedBookmakers(newSelectedBookmakers));
    }
  };

  const handleRequiredBookmakersChange = (e, item) => {
    e.stopPropagation();
    if (subscriptionType === "free") return;
    const index = selected_bookmakers.indexOf(item);
    if (index !== -1) {
      if (selected_required_bookmakers.length < 2 && !selected_required_bookmakers.includes(item)) {
        dispatch(setSelectedRequiredBookmakers([...selected_required_bookmakers, item]));
      } else {
        const newSelectedRequiredBookmakers = Array.isArray(selected_required_bookmakers) ? [...selected_required_bookmakers] : [];
        const indexRequired = newSelectedRequiredBookmakers.indexOf(item);
        if (indexRequired !== -1) {
          newSelectedRequiredBookmakers.splice(indexRequired, 1);
        }
  
        dispatch(setSelectedRequiredBookmakers(newSelectedRequiredBookmakers));
      }
    }
  }

  useEffect(() => {
    if (savedFilters && savedFilters[selectedFilter]) {
      const filterSetting = savedFilters[selectedFilter];
      
      setDisplay2Way(filterSetting.types.includes("surebet-2way"));
      setDisplay3Way(filterSetting.types.includes("surebet-3way"));
      setDisplayWhole(filterSetting.types.includes("surebet-whole"));
      setDisplayMiddle(filterSetting.types.includes("surebet-middle"));
      setDisplayQuarter(filterSetting.types.includes("surebet-quarter"));
      setDisplayMainLines(filterSetting.types.includes("surebet-main-lines"));
      setDisplayStoppaged(filterSetting.types.includes("display-stopped-games"));

      dispatch(setAllSports(filterSetting.allSports));
      dispatch(setSelectedSports(filterSetting.selectedSports));
      dispatch(setAllBookmakers(filterSetting.allBookmakers));
      dispatch(setSelectedBookmakers(filterSetting.selectedBookmakers));
      dispatch(setSelectedRequiredBookmakers(filterSetting.selectedRequiredBookmakers));
      dispatch(setRange(filterSetting.percentageRange));
      dispatch(setSelectedFilterId(filterSetting.id));

      dispatch(setGamePeriod(filterSetting.gamePeriod));
      dispatch(setBetPeriod(filterSetting.betPeriod));

      dispatch(setMinOdds(filterSetting.minOdds ?? -10000));
      dispatch(setMaxOdds(filterSetting.maxOdds ?? 10000));
      setSelectedMinOddsState(selectedOddsFormatState === "Decimal" ? americanToDecimal(filterSetting.minOdds ?? -10000) : (filterSetting.minOdds ?? -10000));
      setSelectedMaxOddsState(selectedOddsFormatState === "Decimal" ? americanToDecimal(filterSetting.maxOdds ?? 10000) : (filterSetting.maxOdds ?? 10000));

      setSaveFilterName(selectedFilter);

      if (selectedFilter) {
        searchBetsByFilter(filterSetting); // sending request to get new bets immediately
      }
    } else {
      //clearFilter();
    }

    setSaveFilterFlag(savedFilters[selectedFilter]);
    setShowSavedFilters(false);
  }, [selectedFilter])

  const clearFilter = async () => {
    if (subscriptionType === "free") return;

    setDisplayAllBets(true);
    setDisplayAllSports(false);
    setDisplayAllBookmakers(false);
    setSelectedFilter('');
    setSaveFilterFlag(false);
    dispatch(setSelectedFilterId(null));

    localStorage.setItem('selectedFilter', '');

    dispatch(setToDefault(
      {
        required: user_required_bookmakers,
        percentage: [user_min_percentage, user_max_percentage],
        period: user_period
      }
    ));

    setShouldSearchBets(true);
  }

  const clearTypes = () => {
    if (subscriptionType === "free") return;

    setDisplayAllBets(false);
    setDisplay2Way(false);
    setDisplay3Way(false);
    setDisplayWhole(false);
    setDisplayMiddle(false);
    setDisplayQuarter(false);
    setDisplayMainLines(false);
    setDisplayStoppaged(false);
  }

  const clearSports = () => {
    if (subscriptionType === "free") return;

    setDisplayAllSports(false);
    dispatch(setSelectedSports([]));
  }

  const clearBookmakers = () => {
    if (subscriptionType === "free") return;

    setDisplayAllBookmakers(false);
    dispatch(setSelectedBookmakers([]));
    dispatch(setSelectedRequiredBookmakers([]));
  }

  const removeSavedFilter = (filterName) => {
    const updatedFilters = { ...savedFilters };
    delete updatedFilters[filterName];
    setSavedFilters(updatedFilters);

    if (selectedFilter === filterName) {
      setSelectedFilter('');
      localStorage.setItem('selectedFilter', '');
    }

    clearFilter();

    // delete filter from server
    dispatch(deleteFilter(filterName));
  }

  const isReachedLimit = 
    (
      Object.keys(userFilters).length >= systemSettings.basic_filter_limit && subscriptionType.includes("basic")) 
      || (Object.keys(userFilters).length >= systemSettings.premium_filter_limit && subscriptionType.includes("premium")
    );

  const redirectToPage = async (event) => {
    event.preventDefault();
    navigate('/account/subscriptions');
  }

  const [inputBookmaker, setInputBookmaker] = useState('');

  const handleChange = (e) => {
    if (subscriptionType === "free") return;

    setInputBookmaker(e.target.value);
  };


  const filteredBookmakers = inputBookmaker
    ? defaultBookmakers.filter(bookmaker => bookmaker.toLowerCase().startsWith(inputBookmaker.toLowerCase()))
    : [];

  const handleSelectAllBookmakers = () => {
    const new_value = !displayAllBookmakers
    setDisplayAllBookmakers(new_value);
    if (new_value) dispatch(setSelectedBookmakers(all_bookmakers));
  }

  useEffect(() => {
    if (isFetched && isCheckedForFilter) {
      const types = [];

      if (display2Way) types.push("surebet-2way");
      if (display3Way) types.push("surebet-3way");
      if (displayWhole) types.push("surebet-whole");
      if (displayMiddle) types.push("surebet-middle");
      if (displayQuarter) types.push("surebet-quarter");
      if (displayMainLines) types.push("surebet-main-lines");
      if (displayStoppaged) types.push("display-stopped-games")

      dispatch(setTypes(types));
    }
  }, [display2Way, display3Way, displayWhole, displayMiddle, displayQuarter, displayMainLines, displayStoppaged, displayAllBest]);

  return (
    <div className={`${classes.root} ${props.hideFilterWrapper ? style.hidden : ''}`}>
      {
        clickedSave ? <NotificationPopUp setClickedSave={setClickedSave}></NotificationPopUp> : null
      }
      <div className={`${classes.filter} ${style.filter} ${subscriptionType === "free" ? style.free : ''}`}>
        <div className={style.filter_header}>
          <div className={`${style.filter_button} ${style.saved_filters} ${subscriptionType === "free" ? style.non_clickable_div : ''}`} onClick={() => setShowSavedFilters(!showSavedFilters)}>
            <img src={require('./img/filter_icon.svg').default} alt="Filter icon" className={style.switch_filter} />
            <img src={require('./img/arrow.svg').default} className={showSavedFilters ? style.active : ''} alt="Filter icon" />
          </div>
          <h2 className={classes.heading}>{selectedFilter === '' ? 'Filter (Default)' : <>Filter - <span className={style.filter_header_name}>{selectedFilter}</span></>}</h2>
          <div className={`${style.filter_button} ${style.close_button}`} onClick={() => props.setMobileSubheaderStatus(false)}>
            <CloseIcon color='#fff'></CloseIcon>
          </div>
          <div className={`${style.filter_button} ${style.hide_button}`} onClick={() => props.setHideFilterWrapper(!props.hideFilterWrapper)}>
            <img src={require('./img/left_arrow.png')} alt="" />
          </div>
        </div>
        {
          showSavedFilters ?
          <>
            <div className={style.saved_filters_wrapper}>
              <span className={style.saved_header}>My Filters</span>
              <div className={style.filters_container}>
                {
                  Object.keys(savedFilters).map((filterName) => (
                    <div className={style.saved_item} key={filterName}>
                      <span 
                        className={style.filter_name} 
                        onClick={() => {
                          setSelectedFilter(filterName);
                          localStorage.setItem('selectedFilter', filterName);
                        }}
                      >{filterName}</span>
                      <div className={style.item_container}>
                        <span 
                          onClick={() => {
                            setSelectedFilter(filterName);
                            localStorage.setItem('selectedFilter', filterName);
                          }}
                        >Saved on {savedFilters[filterName].saveDate}</span>
                        <img src={require('./img/close_icon.svg').default} alt="Close icon" onClick={() => removeSavedFilter(filterName)} />
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </> : 
          <>
            {
            mode !== "ev" && mode !== "evLive" ? 
            <div className={`${style.filter_container} ${subscriptionType === "free" ? style.non_clickable_div : ''}`}>
              <span className={style.filter_item_header}>
                Select Bets
                <button className={style.close_red_button} onClick={() => clearTypes()}>
                  <img src={require('./img/close_round_red.png')} alt="Close icon" />
                </button>
              </span>
              <div className={style.item}>
                <FormControlLabel 
                  control={<CustomCheckbox checked={displayAllBest} onChange={() => setDisplayAllBets(!displayAllBest)} />} 
                  label="All Bets" 
                />
                <FormControlLabel 
                  control={<CustomCheckbox checked={display2Way} onChange={() => setDisplay2Way(!display2Way)} />} 
                  label="2-Way" 
                />
                <FormControlLabel 
                  control={<CustomCheckbox checked={display3Way} onChange={() => setDisplay3Way(!display3Way)} />} 
                  label="3-Way" 
                />
                <FormControlLabel 
                  control={<CustomCheckbox checked={displayWhole} onChange={() => setDisplayWhole(!displayWhole)} />} 
                  label="Whole" 
                />
                <FormControlLabel 
                  control={<CustomCheckbox checked={displayMiddle} onChange={() => setDisplayMiddle(!displayMiddle)} />} 
                  label="Middle" 
                />
                <FormControlLabel 
                  control={<CustomCheckbox checked={displayQuarter} onChange={() => setDisplayQuarter(!displayQuarter)} />} 
                  label="Quarter" 
                />
                
                <div style={{width: '100%', paddingTop: '5px', paddingBottom: '5px'}}>
                  <FormControlLabel
                    control={
                      <CustomSwitch 
                        checked={displayMainLines} 
                        onChange={() => {
                          setDisplayMainLines(!displayMainLines);
                          setClickedSave(true);
                        }} 
                      />
                    }
                    label={<span style={{paddingLeft: '10px'}}>Main Lines Only</span>}
                  />
                </div>
                
              </div>
            </div>
            : null
          }

          {/* {
            mode === "evLive" ? 
            <div className={`${style.filter_container} ${subscriptionType === "free" ? style.non_clickable_div : ''}`}>
              <span className={style.filter_item_header}>
                Select Bets
                <button className={style.close_red_button} onClick={() => clearTypes()}>
                  <img src={require('./img/close_round_red.png')} alt="Close icon" />
                </button>
              </span>
              <div className={style.item}>
                
                <div style={{width: '100%', paddingTop: '5px', paddingBottom: '5px'}}>
                  <FormControlLabel
                    control={
                      <CustomSwitch 
                        checked={displayStoppaged} 
                        onChange={() => {
                          setDisplayStoppaged(!displayStoppaged);
                          setClickedSave(true);
                        }} 
                      />
                    }
                    label={<span style={{paddingLeft: '10px'}}>Show Only Games on Stoppage</span>}
                  />
                </div>
                
              </div>
            </div>
            : null
          } */}
          <div className={`${style.filter_container} ${style.filter_sports_container}`}>
            <div className={`${style.filter_item_header} ${style.filter_item_header_container}`} onClick={() => setIsSportsOpen(!isSportsOpen)}>
              <span className={style.filter_item_header}>
                Select sports
                {isSportsOpen ? <button className={style.close_red_button} onClick={(e) => {
                  e.stopPropagation();
                  clearSports();
                }}>
                  <img src={require('./img/close_round_red.png')} alt="Close icon" />
                </button> : null}
              </span>
              <img src={require('./img/arrow.svg').default} className={isSportsOpen ? style.active : ''} alt="Filter icon" />
            </div>
            <div className={`${style.item} ${isSportsOpen ? style.active : ''}`}>
              <FormControlLabel 
                control={<CustomCheckbox checked={displayAllSports} onChange={() => {
                  const new_value = !displayAllSports
                  setDisplayAllSports(new_value);
                  if (new_value) dispatch(setSelectedSports(all_sports));
                }} />} 
                label="All Sports" 
              />
              {
                all_sports.map((item, index) => 
                  <FormControlLabel 
                    key={index} 
                    control={
                      <CustomCheckbox 
                          checked={selected_sports.includes(item)} 
                          onChange={() => handleSportsChange(item)}
                      />
                      } 
                    label={item} 
                  />
                )
              }
            </div>
          </div>

          <div className={`${style.filter_container} ${style.filter_bookmakers_container}`}>
            <div className={`${style.filter_item_header} ${style.filter_item_header_container}`} onClick={() => setIsBookmakersOpen(!isBookmakersOpen)}>
              <span className={style.filter_item_header}>
                Select bookmakers
                {isBookmakersOpen ? <button className={style.close_red_button} onClick={(e) => {
                  e.stopPropagation();
                  clearBookmakers();
                }}>
                  <img src={require('./img/close_round_red.png')} alt="Close icon" />
                </button> : null}
              </span>
              <img src={require('./img/arrow.svg').default} className={isBookmakersOpen ? style.active : ''} alt="Filter icon" />
            </div>
              {
                isBookmakersOpen ?
                <div className={style.input_block}>
                    <input 
                        type="text" 
                        id='bookmakers' 
                        placeholder='Bookmakers' 
                        value={inputBookmaker}
                        onChange={handleChange}
                    />
                    {inputBookmaker && (
                        <ul className={style.autocomplete_list}>
                        {filteredBookmakers.map((bookmaker, index) => (
                            
                            <div key={bookmaker} style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                              <FormControlLabel 
                                style={{width: '30px'}}
                                key={index} 
                                control={
                                  <CustomCheckbox 
                                      checked={selected_bookmakers.includes(bookmaker)} 
                                      onChange={() => handleBookmakersChange(bookmaker)}
                                  />
                                  } 
                                label="" 
                              />
                              <span style={{cursor: 'pointer'}} onClick={(e) => handleRequiredBookmakersChange(e, bookmaker)}>
                                <span style={selected_required_bookmakers.includes(bookmaker) ? {color: colors.green}: {}}>{bookmaker}</span> {selected_bookmakers.includes(bookmaker) ? 
                                <FiberManualRecordIcon sx={{ 
                                  height: '12px', 
                                  width: '12px',
                                  color: selected_required_bookmakers.includes(bookmaker) ? colors.green : colors.grey
                                }}></FiberManualRecordIcon> : null}
                              </span>
                            </div>
                        ))}
                        </ul>
                    )}
                    {
                      inputBookmaker !== '' ?
                      <XLogo 
                        className={style.clear_icon} 
                        style={{fill: 'red'}}
                        onClick={() => setInputBookmaker('')}
                      ></XLogo>
                      : <img src={require('./img/search.png')} alt="" />
                    }
                </div> : ''
              }

            <div className={`${style.item} ${style.scroll_item} ${isBookmakersOpen ? style.active : ''}`}>
              <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                <FormControlLabel 
                  style={{width: '30px'}}
                  control={<CustomCheckbox checked={displayAllBookmakers} onChange={handleSelectAllBookmakers} />} 
                  label="" 
                />
                <span style={{cursor: 'pointer'}} onClick={handleSelectAllBookmakers}>
                  <span><b>All Bookmakers</b></span>
                </span>
              </div>
              {
                all_bookmakers.map((item, index) => 
                  <div key={item} style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                    <FormControlLabel 
                      style={{width: '30px'}}
                      key={index} 
                      control={
                        <CustomCheckbox 
                            checked={selected_bookmakers.includes(item)} 
                            onChange={() => handleBookmakersChange(item)}
                        />
                        } 
                      label="" 
                    />
                    <span style={{cursor: 'pointer'}} onClick={(e) => handleRequiredBookmakersChange(e, item)}>
                      <span style={selected_required_bookmakers.includes(item) ? {color: colors.green}: {}}>{item}</span> {selected_bookmakers.includes(item) ? 
                      <FiberManualRecordIcon sx={{ 
                        height: '12px', 
                        width: '12px',
                        color: selected_required_bookmakers.includes(item) ? colors.green : colors.grey
                      }}></FiberManualRecordIcon> : null}
                    </span>
                  </div>
                )
              }
            </div>
          </div>

          <div className={`${style.filter_container} ${style.filter_slider_container} ${subscriptionType === "free" ? style.non_clickable_div : ''}`}>
            <span className={style.filter_item_header}>Select profit range (%)</span>
            <div className={style.value_wrapper}>
              <span>0%</span>
              <span>50+%</span>
            </div>
            <div className={`${style.item} ${style.line_filter}`}>
              <SliderComponent 
                value={subscriptionType === "free" ? [0, 1] : percentage_range} 
                maxValue={subscriptionType.includes("basic") ? 30 : null}
                setValue={(v) => dispatch(setRange(v))}
              ></SliderComponent>
            </div>
          </div>

          {
            mode === "prematch" || mode === "ev" || mode === "evLive" ? (
            <>
              <div className={`${style.filter_container} ${style.filter_select_container} ${subscriptionType === "free" ? style.non_clickable_div : ''}`}>
                <span className={style.filter_item_header}>Select time period for games (days)</span>
                <div className={`${style.item}`}>
                  <SelectComponent
                    label="Select time period (days)" 
                    data={Array.from({ length: 31 }, (_, index) => index + 1)}
                    selectedData={game_period}
                    onChange={(data) => dispatch(setGamePeriod(data))}
                  ></SelectComponent>
                  <span>days</span>
                </div>
              </div>
              <div className={`${style.filter_container} ${style.filter_select_container} ${subscriptionType === "free" ? style.non_clickable_div : ''}`}>
                <span className={style.filter_item_header}>Select time period for bets update (hours)</span>
                <div className={`${style.item}`}>
                  <SelectComponent
                    label="Select time period (hours)" 
                    data={Array.from({ length: 24 }, (_, index) => index + 1)}
                    selectedData={bet_period}
                    onChange={(data) => dispatch(setBetPeriod(data))}
                  ></SelectComponent>
                  <span>hours</span>
                </div>
              </div>
            </>
            )
            : null
          }

          {
            mode === "prematch" || mode === "live" || mode === "ev" || mode === "evLive" ? (
            <>
              <div className={`${style.filter_container} ${style.filter_select_container} ${subscriptionType === "free" ? style.non_clickable_div : ''}`}>
                <span className={style.filter_item_header}>Select odds range</span>
                <div className={`${style.item}`}>
                  <CustomTextField
                    size="small"
                    type="number"
                    inputProps={selectedOddsFormatState === "Decimal" ? { step: "0.1", min: 1 } : {step: "1"}}
                    value={selectedMinOddsState ?? 0}
                    onChange={(e) => setSelectedMinOddsState(e.target.value)}
                    style={{ marginLeft: 0, marginRight: 8, width: '90px' }}
                    
                  />
                  <span style={{margin: 0}}>
                    -
                  </span>
                  <CustomTextField
                    size="small"
                    type="number"
                    inputProps={selectedOddsFormatState === "Decimal" ? { step: "0.1", min: 1 } : {step: "1"}}
                    value={selectedMaxOddsState ?? 0}
                    onChange={(e) => setSelectedMaxOddsState(e.target.value)}
                    style={{ marginLeft: 8, marginRight: 0, width: '90px' }}
                    
                  />
                </div>
              </div>
            </>
            )
            : null
          }

          {
            subscriptionType === "free" ?
            <div className={style.free_wrapper}>
                <p>
                  Your FREE subscription does not allow you to view this functionality. <br /><br />
                  <span>Upgrade</span> your plan in order to see all bets and access full functionality.
                </p>
                <button onClick={redirectToPage}>
                  Upgrade Plan
                </button>
            </div> : ''
          }
          
          <div className={`${style.save_wrapper} ${subscriptionType === "free" ? style.non_clickable_div : ''}`}>
            {
              !savedFilters[selectedFilter] ? 
              <FormControlLabel 
                className={style.input_wrapper}
                control={
                  <CustomCheckbox 
                    checked={savedFilters[selectedFilter]} 
                    onChange={() => {
                      setSaveFilterFlag(!saveFilterFlag);
                    }} 
                  />
                } 
                label="Save filter" 
              /> : ''
            }
            {
              saveFilterFlag ?
                <>
                  <input
                    ref={saveFilterNameRef}
                    id="outlined-basic" 
                    placeholder='Filter name...'
                    onChange={(e) => setSaveFilterName(e.target.value)}
                    defaultValue={savedFilters[selectedFilter] ? saveFilterName : ''}
                  />
                  {/* <div>
                    <Button variant="outlined">Save filter</Button>
                  </div> */}
                </>
                : null
            }
          </div>
          {
              saveFilterFlag && !isReachedLimit ?
                <Button 
                  variant="contained" 
                  className={`${style.search_button} ${style.save_button}`} 
                  onClick={() => saveSetting()}
                >Save Filter</Button>
                : null
          }
          {
            (saveFilterFlag && isReachedLimit) ?
            <div className={style.filter_wrapper}>
              <p>
                You have reached the maximum amount of ({subscriptionType.includes("basic") ? 5 : 20}) filters allowed by your subscription. <br /><br />
                <span>Upgrade</span> your plan in order to save more filters.
              </p>
              <button onClick={redirectToPage}>
                Upgrade Plan
              </button>
            </div> : null
          }
          { /*
            isFilterSaved ? <span className={style.info_text}>Your filter "{saveFilterName}" was successfully saved.</span> : null
            */
          }
          <div className={`${style.bottom_wrapper} ${subscriptionType === "free" ? style.non_clickable_div : ''}`}>
            <div className={style.clear_container} onClick={() => clearFilter()}>
              <img src={require('./img/clear_icon.svg').default} alt="Clear icon" />
              <span>Clear Filter</span>
            </div>
            <Button 
              variant="contained" 
              disabled={subscriptionType === "free" ? true : false}
              className={`${style.search_button} ${subscriptionType === "free" ? style.disable : ''}`}
              onClick={searchBets}
            >Apply</Button>
          </div>
          </>
        }
      </div>
    </div>
  );
}

function NotificationPopUp(props) {
  const toggler_value = localStorage.getItem('notShowPopUpFilter') === "true";
  const [noMoreDisplay, setNoMoreDisplay] = useState(toggler_value);

  if (toggler_value) {
    return null;
  }

  return(
    <div className={style.modular_wrapper}>
      <div className={style.modular_item}>
        <div className={style.top}>
          <div className={style.name_wrapper}>
            <img className={style.icon} src={require('./img/warning.png')} alt="" />
            <span className={style.subname}>Please now click the APPLY button.</span>
          </div>
          <div className={style.checkbox_area} onClick={() => {
              setNoMoreDisplay(!noMoreDisplay);
          }}>
            <CustomCheckbox 
              checked={noMoreDisplay} 
              
            />
            <span className={style.info}>Don’t show this again</span>
          </div>
        </div>
        
        <div className={style.action_wrapper}>
          
          <button onClick={() => {
            localStorage.setItem('notShowPopUpFilter', noMoreDisplay);
            props.setClickedSave(false);
          }}>
            <span>OK</span>
          </button>
        </div>
        
      </div>
    </div>
  )
}

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: '28px !important',
  height: '16px !important',
  padding: '0 !important',
  display: 'flex !important',
  marginLeft: '2px !important',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: '15px !important',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px) !important',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: '2px !important',
    '&.Mui-checked': {
      transform: 'translateX(12px) !important',
      color: '#fff !important',
      '& + .MuiSwitch-track': {
        opacity: '1 !important',
        backgroundColor: `${colors.green} !important`,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%) !important',
    width: '12px !important',
    height: '12px !important',
    borderRadius: '6px !important',
    transition: `${theme.transitions.create(['width'], {
      duration: 200,
    })} !important`,
  },
  '& .MuiSwitch-track': {
    borderRadius: '16px / 2 !important',
    opacity: '1 !important',
    backgroundColor: `${colors.grey} !important`,
    boxSizing: 'border-box !important',
  },
}));

const CustomTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: '#fff', 
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white', 
    },
    '&:hover fieldset': {
      borderColor: 'white', 
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white', 
    },
    '& .MuiOutlinedInput-notchedOutline': {
      '& legend': {
        display: 'none', 
      },
    },
  },
  marginLeft: 8,
  marginRight: 8,
  width: '60px',
});

const useStyles = makeStyles({
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'start',
    
    height: '100%',
    boxSizing: 'border-box',
    background: colors.celadon
  },
  filter: {
    color: 'white',
    height: '100%',
    boxSizing: 'border-box',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  heading: {
    margin: 0,
  },
  datepicker: {
    color: 'white',
  },
  button: {
    background: colors.green,
    fontWeight: 'bold',
    width: '150px'
  },
});

export default FiltersComponent;