import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Tooltip } from 'react-tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';

import colors from '../utils/colors';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedBet } from '../features/bets/betsSlice';

import style from './style/BetStyles.module.scss';

const calculate_time = (otherTimestamp) => {
  // Current time in Unix Timestamp (in seconds)
  const now = Math.floor(Date.now() / 1000);

  // Time difference in seconds
  const differenceInSeconds = now - otherTimestamp;

  // Determining in which units of measurement to display the difference
  let timeDifference;

  if (differenceInSeconds >= 3600) {
    // Displayed in hours
    const hours = Math.floor(differenceInSeconds / 3600);
    timeDifference = `${hours} h`;

    return timeDifference;
  } else if (differenceInSeconds >= 60) {
    // Displayed in minutes
    const minutes = Math.floor(differenceInSeconds / 60);
    timeDifference = `${minutes} m`;

    return timeDifference;
  } else {
    // Displayed in seconds
    let seconds = differenceInSeconds.toFixed(0);
    if (seconds < 1) seconds = 1;

    timeDifference = `${seconds} s`;

    return timeDifference;
  }
}

/**
 * BetComponent
 * 
 * This component displays individual bets as part of an arbitrage betting opportunity. It is designed to be used
 * within the ArbitrageBetComponent, rendering detailed information about each bet including the bookmaker, 
 * the bet details, and the odds. The component also highlights new bets to catch the user's attention.
 * 
 * Props:
 * - arb_bet: An object containing information about the arbitrage bet, including the bets within it.
 * - bets_group: The group of bets this component is part of, used for displaying aggregated information.
 * 
 * State:
 * - isHighlighted: Boolean state that determines if the bet should be highlighted as new.
 * 
 * The component leverages React's useEffect hook to manage the highlighted state, automatically removing
 * the highlight after a set time. It also uses Redux's useSelector to access global state such as user settings,
 * and useDispatch to interact with Redux's actions.
 * 
 * Each bet within the arb_bet object is displayed in a grid, with specific columns for bookmaker name, 
 * game name, bet details, and odds. If the odds have changed, the component displays both the original 
 * and new odds with appropriate styling to indicate the update.
 * 
 * A 'Calculate' button is provided for each bet, allowing users to interact with the bet for further actions
 * like calculation of potential returns. This component dynamically adjusts the display based on the odds format
 * specified in the user's preferences.
 * 
 * Additionally, if the bet is no longer considered a surebet, it displays a warning message with the updated odds,
 * informing the user of the change.
 */
function BetComponent({arb_bet, bets_group, handleOpenDeleteBetPopup}) {
  const classes = useStyles();

  const mode = useSelector(state => state.settings.mode);
  const oddsFormat = useSelector(state => state.preferences.oddsFormat);
  const dispatch = useDispatch();

  const [isHighlighted, setIsHighlighted] = useState(arb_bet.isNew);

  const selected_filter_id = useSelector(state => state.filter.selectedFilterId);

  // State for storing formatted time
  // const [formattedTime, setFormattedTime] = useState(calculate_time(arb_bet.timestamp).replace(/\s/g, ''));

  // useEffect(() => {
  //   // Time update function
  //   const updateFormattedTime = () => {
  //     setFormattedTime(calculate_time(arb_bet.timestamp).replace(/\s/g, ''));
  //   };

  //   // Creating an update interval
  //   const intervalId = setInterval(updateFormattedTime, 5000);

  //   // Clearing the interval when unmounting a component
  //   return () => clearInterval(intervalId);
  // }, [arb_bet.timestamp]);

  useEffect(() => {
    if (arb_bet.isNew) {
      const timer = setTimeout(() => {
        setIsHighlighted(false);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [arb_bet.isNew]);

  const handleClick = (data, bets_group) => {
    data = {
      ...data,
      groups: bets_group
    };
    dispatch(setSelectedBet({mode, data}));
  }

  const getImage = (path) => {
    try {
      const cleanPath = path.replace(/\//g, '');

      return `${process.env.PUBLIC_URL}/bookmakers-img/${cleanPath}.png`;
    } catch (error) {
      return null;
    }
  };

  return (
    <div className={style.bet_item_container}>
      {
        arb_bet.bets.map((bet, index) => (
        <Grid container item xs={12} key={index} className={`${isHighlighted === true ? 'blink' : null} ${style.bet_item}`} >
          <Grid item xs={3} className={`${classes.item} ${style.bookmaker_name}`}>
            <img src={getImage(bet.bookmaker)}  />
            <Typography>{bet.bookmaker}</Typography>
          </Grid>
          <Grid item xs={4} className={classes.item}>
            <Typography>{arb_bet.game_name}</Typography>
          </Grid>
          <Grid item xs={4} className={classes.item}>
            <Typography>{bet.bet_name.trim()}</Typography>
          </Grid>
          <Grid item xs={1} className={classes.item}>
            <Typography>
              {
                calculate_time(bet.odds_jam_run).replace(/\s/g, '')
              }
              <>
                <a id="Status" style={{paddingLeft: '3px'}}>
                  <img src={require('./img/tooltips.png')} alt="Close icon" />
                </a>
                <Tooltip
                  className='tooltips'
                  key={'right-start'}
                  anchorSelect="#Status"
                  content={`Last detected odds change`}
                  place={'right-start'}
                />
              </>
            </Typography>
          </Grid>
          <Grid item xs={1} className={classes.item} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {
              arb_bet.not_surebet && (bet.price !== bet.new_price) ? 
                <>
                  <Typography><del>{oddsFormat === "American" ? bet.price : bet.decimal_price?.toFixed(2)}</del></Typography>
                  <Typography style={{color: '#FFF700'}}>{oddsFormat === "American" ? bet.new_price : bet.new_decimal_price?.toFixed(2)}</Typography>
                </>
                : <Typography>{oddsFormat === "American" ? bet.price : bet.decimal_price?.toFixed(2)}</Typography>
            }
          </Grid>
          {
            index === 0 ? <Grid item xs={2} className={`${style.calculate_item} ${style.right_item}`} style={
              {
                padding: '5px 5px 0 5px'
              }
            }>
              <div className={style.Calculate_container}>
                { arb_bet.not_surebet ? null : <Typography>{(arb_bet.arbitrage_percentage).toFixed(2)}%</Typography> }
                <Button style={{minWidth: '10px', marginLeft: '4px'}} onClick={() => handleClick(arb_bet, bets_group)}>
                  {
                    arb_bet.not_surebet ? 
                    <img src={require('./img/calculate_red_icon.svg').default} alt="Calculate icon" />
                    : <img src={require('./img/calculate_icon.svg').default} alt="Calculate icon" />
                  }
                </Button>
              </div>
              
            </Grid> :
            index === 1 ? <Grid item xs={2} className={`${style.calculate_item}`} style={
              {
                padding: '0 5px 5px 5px'
              }
            }>
              <div className={style.Calculate_container}>
                <Typography>
                  {
                    // <span className={style.white_text}>ROI:</span> {arb_bet.roi?.toFixed(2)}<br/>
                  }
                  <span className={style.white_text}>Updated:</span> {calculate_time(arb_bet.timestamp).replace(/\s/g, '')} 
                  {
                    selected_filter_id && (mode === "prematch") ?
                    <>
                      <br></br>
                      <span className={style.remove_button} onClick={() => {
                        //filterId, userId, betId, groupId, validUntil, type

                        const data = {
                          filterId: selected_filter_id,
                          userId: null,
                          betId: arb_bet.id,
                          groupId: arb_bet.group_key,
                          validUntil: dayjs(arb_bet.date).add(3, 'hour').valueOf(),
                          mode: "surebet",
                          game_name: arb_bet.game_name,
                          group_name: arb_bet.bets.map((bet) => bet.bet_name?.trim()).join(' // '),
                          bet_name: arb_bet.bets.map((bet) => bet.bet_name?.trim() + ' [' + bet.bookmaker + ']').join(' // ')
                        }

                        handleOpenDeleteBetPopup(data);
                      }}>Remove <DeleteIcon></DeleteIcon></span>
                    </> : null
                  }
                  
                </Typography>
              </div>

            </Grid> :
            <Grid item xs={2} className={classes.item} style={
              {
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
              }
            }>
            </Grid>
          }
        </Grid>)
      )}
      {
        arb_bet.not_surebet ? 
        <div className={style.info_container}>
          <div className={style.attention_container}>
            <span>No longer a surebet</span>
          </div>
          <div>
            <img src={require('./img/warning_yellow.png')} style={{width: '20px', height: '20px', marginRight: '5px'}} alt="" />
            <span>
              { `Odds changed ` }
              { 
                arb_bet.bets.filter((bet) => {
                  if (bet.price !== bet.new_price) {
                    return true;
                  }

                  return false;
                }).map(bet => `to ${oddsFormat === "American" ? bet.new_price : bet.new_decimal_price?.toFixed(2)} on ${bet.bookmaker}`).join(' and ')
              }
            </span>
          </div>  
        </div> : null
      }
    </div>
    
  );
}

const useStyles = makeStyles({
  root: {
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
  },
  header: {
    background: colors.green,
    color: 'white',
  },
  item: {
    padding: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    background: colors.green,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  percentText: {
    fontSize: '20px',
    fontWeight: 'bold',
    paddingLeft: '10px',
    padding: '5px',
    boxSizing: 'border-box',
    
  },
  sport: {
    background: colors.night,
  },
  sportText: {
    fontSize: '20px',
    padding: '5px',
    paddingRight: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  info: {
    background: colors.night,
  },
  infoText: {
    fontSize: '18px',
    padding: '5px',
    paddingRight: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center'
  },
  bets: {
    background: colors.celadon
  },
  delimiter: {
    height: '2px',
    width: '100%',
    backgroundColor: colors.night
  },
  moreButton: {
    cursor: 'pointer',
    width: '100%',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.night,
    color: '#fff',
    borderBottom: `2px outset ${colors.celadon}`,
  }
});

export default BetComponent;