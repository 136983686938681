import style from '../style/MainAdminStyles.module.scss';
import UserDetails from './UserDetails';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import PaymentHistory from './PaymentHistory';
import LastLogin from './LastLogin';

export default function User(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const selectedUser = useSelector(state => state.admins.selectedUser);

    const [pageContent, setPageContent] = useState({name: 'User Details', content: <UserDetails selectedUser={selectedUser} />});
    
    const handleClose = () => {
        navigate('/admin/');
    }

    return(
        <>
            <div className={style.page_name}>#{selectedUser.id} <br />{selectedUser.email} <br /><br />{selectedUser.firstName} {selectedUser.lastName}</div>
            <div className={style.close_button} onClick={handleClose}>
                <img src={require('./img/back.png')} alt="" />
            </div>
            <div className={`${style.container} ${style.user}`}>
                <div className={style.account_navigation}>
                    <div onClick={() => setPageContent({name: 'User Details', content: <UserDetails selectedUser={selectedUser} />})} className={`${style.item} ${pageContent.name === 'User Details' ? style.active : ''}`}>
                        <span>User Details</span>
                    </div>
                    <div onClick={() => setPageContent({name: 'Payment History', content: <PaymentHistory userId={selectedUser.userSub} />})} className={`${style.item} ${pageContent.name === 'Payment History' ? style.active : ''}`}>
                        <span>Payment History</span>
                    </div>
                    <div onClick={() => setPageContent({name: 'Last Login', content: <LastLogin userId={selectedUser.userSub} />})} className={`${style.item} ${pageContent.name === 'Last Login' ? style.active : ''}`}>
                        <span>Last Login</span>
                    </div>
                </div>
                {pageContent.content}
            </div>
        </>
    )
}