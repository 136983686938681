import style from './styles/AccountStyle.module.scss';

import { ReactComponent as PersonalLogo } from './img/customer_man_user_account_profile_icon.svg';
import { ReactComponent as SettingLogo } from './img/filter_filters_funnel_list_navigation_icon.svg';
import { ReactComponent as SubLogo } from './img/sub.svg';
import { ReactComponent as BankLogo } from './img/bank.svg';
import { ReactComponent as TrashLogo } from './img/trash.svg';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import MainAccount from './MainAccount';
import Preferences from './Preferences';
import Subscriptions from './Subscriptions';
import { signOut } from 'aws-amplify/auth';
import Bankroll from './Bankroll';
import RemovedBets from './RemovedBets';

/**
 * Account Component
 * 
 * This component renders the account section of the application, providing navigation
 * to different parts of the account settings such as Personal Profile, Bankroll, Preferences,
 * and Subscriptions. It uses React Router's `useNavigate` and `useLocation` hooks for navigation
 * and state management to display the correct page content based on the current route.
 * 
 * The account navigation menu allows users to switch between different account settings pages,
 * while the main content area displays the content of the selected settings page. The navigation
 * state is managed by listening to changes in the URL pathname, which updates the page content
 * accordingly.
 */
export default function Account() {
    const history = useNavigate();
    const location = useLocation();

    const [pageName, setPageName] = useState();
    const [pageContent, setPageContent] = useState('');

    useEffect(() => {
        const path = location.pathname;
        switch (path) {
            case '/account/preferences':
                setPageName('Preferences');
                setPageContent(<Preferences />)
                break;
            case '/account/subscriptions':
                setPageName('Subscriptions');
                setPageContent(<Subscriptions />)
                break;
            case '/account':
                setPageName('Personal Profile');
                setPageContent(<MainAccount />);
                break;
            case '/account/bankroll':
                setPageName('Your Bankroll');
                setPageContent(<Bankroll />);
                break;
            case '/account/removed-bets':
                setPageName('Removed Bets');
                setPageContent(<RemovedBets />);
                break;
            default:
                break;
        }
    }, [location.pathname]);

    const handleSignOut = async (event) => {
        event.preventDefault();  
        await signOut();
        window.location.href = '/'
    };

    return(
        <div className={style.account_wrapper}>
            <div className={style.menu}>
                <div>
                    <Link to={"/account"} className={`${style.item} ${location.pathname === '/account' ? style.active : ''}`}>
                        {/* <img src={require('./img/personal.svg').default} className={showSavedFilters ? style.active : ''} alt="Personal icon" /> */}
                        {
                            location.pathname === '/account' ? 
                            <PersonalLogo style={{ fill: "#91E617" }} /> : <PersonalLogo style={{ fill: "#fff" }} />
                        }
                        <span>Personal Profile</span>
                    </Link>
                    <Link to={"/account/bankroll"} className={`${style.item} ${location.pathname === '/account/bankroll' ? style.active : ''}`}>
                        <BankLogo />
                        <span>Bankroll</span>
                    </Link>
                    <Link to={"/account/preferences"} className={`${style.item} ${location.pathname === '/account/preferences' ? style.active : ''}`}>
                        {
                            location.pathname === '/account/preferences' ? 
                            <SettingLogo style={{ fill: "#91E617" }} /> : <SettingLogo style={{ fill: "#fff" }} />
                        }
                        <span>Preferences</span>
                    </Link>
                    <Link to={"/account/subscriptions"} className={`${style.item} ${location.pathname === '/account/subscriptions' ? style.active : ''}`}>
                        <SubLogo />
                        <span >Subscriptions</span>
                    </Link>
                    <Link to={"/account/removed-bets"} className={`${style.item} ${location.pathname === '/account/removed-bets' ? style.active : ''}`}>
                        <TrashLogo />
                        <span >Removed Bets</span>
                    </Link>
                </div>
                {/* <button className={style.exit_button} onClick = {handleSignOut}>
                    <img src={require('./img/exit.png')} alt="Exit icon" />
                    <span>Log Out</span>
                </button> */}
            </div>
            <div className={style.account_container}>
                <div className={style.page_name}>
                    <span>{pageName}</span>
                </div>
                {pageContent}
            </div>
        </div>
    )
}
