import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';

import { ReactComponent as ShareLogo } from './img/share.svg';

import colors from '../utils/colors';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedBet, fetchUserBets } from '../features/bets/betsSlice';
import { fetchUserTransactions } from '../features/user/userSlice';

import { convertAmericanToEuropean, convertEuropeanToAmerican } from './../utils/odds_converter';
import { updateUserBet, updateUserBetStatus } from './../features/bets/betsSlice';

import style from './style/BetStyles.module.scss';

function MyBetComponent({data, isEditMode, setEditMode, handleTakeScreenshot, isScreenshotMode, isStarted}) {
  const arb_bet = data.betInfo;

  const classes = useStyles();

  const mode = useSelector(state => state.settings.mode);
  const oddsFormat = useSelector(state => state.preferences.oddsFormat);
  const dispatch = useDispatch();

  const [isHighlighted, setIsHighlighted] = useState(arb_bet.isNew);

  useEffect(() => {
    if (arb_bet.isNew) {
      const timer = setTimeout(() => {
        setIsHighlighted(false);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [arb_bet.isNew]);

  const handleClick = (data) => {
    dispatch(setSelectedBet({mode, data}));
  }

  const getImage = (path) => {
    try {
      const cleanPath = path.replace(/\//g, '');

      return `${process.env.PUBLIC_URL}/bookmakers-img/${cleanPath}.png`;
    } catch (error) {
      return null;
    }
  };

  const [betValues, setBetValues] = useState(arb_bet.arbitrage_type ? arb_bet.bets.map(bet => ({
    price: bet.price,
    decimal_price: bet.decimal_price
  })) : {price: arb_bet.price, decimal_price: convertAmericanToEuropean(Number(arb_bet.price))});

  const [amountsToStake, setAmountsToStake] = useState(data.amountToStake);
  const [profitsAbsolute, setProfitsAbsolute] = useState(data.profitsAbsolute);

  const handleOddsChange = (index, newValue) => {
    const newBetValues = [...betValues];
    let price = null;
    if (oddsFormat === "American") {
      price = convertAmericanToEuropean(newValue);
      newBetValues[index] = {
        ...newBetValues[index],
        price: newValue,
        decimal_price: price
      };
    } else {
      price = newValue;
      newBetValues[index] = {
        ...newBetValues[index],
        decimal_price: price,
        price: convertEuropeanToAmerican(newValue)
      };
    }

    const totalStake = amountsToStake.reduce((sum, amount) => {
      return sum + amount;
    }, 0);

    updateProfits(index, price * amountsToStake[index] - totalStake);
    setBetValues(newBetValues);
  };

  const handleOddsChangeEV = (newValue) => {
    let decimal_price = null;
    if (oddsFormat === "American") {
      decimal_price = convertAmericanToEuropean(newValue);
      setBetValues({
        price: newValue,
        decimal_price
      });
    } else {
      decimal_price = newValue;
      setBetValues({
        price: convertEuropeanToAmerican(newValue),
        decimal_price: newValue
      });
    }

    const totalStake = amountsToStake.reduce((sum, amount) => {
      return sum + amount;
    }, 0);
    
    updateProfits(0, decimal_price * amountsToStake[0] - amountsToStake[0]);
  };

  const handleAmountChange = (index, newValue) => {
    const newAmounts = [...amountsToStake];
    newAmounts[index] = newValue;

    const totalStake = amountsToStake.reduce((sum, amount, i) => {
      if (index !== i) {
        return sum + amount;
      }
      return sum;
    }, Number(newValue));

    updateProfits(index, betValues[index].decimal_price * newValue - totalStake);
    setAmountsToStake(newAmounts);
  };

  const handleAmountChangeEV = (newValue) => {
    const newAmounts = [...amountsToStake];
    newAmounts[0] = newValue;

    updateProfits(0, betValues.decimal_price * newValue - newValue);
    setAmountsToStake(newAmounts);
  };

  const updateProfits = (index, newValue) => {
    const newProfits = [...profitsAbsolute];
    newProfits[index] = newValue.toFixed(2);
    setProfitsAbsolute(newProfits);
  };

  const handleUpdateUserBet = async () => {
    await dispatch(updateUserBet({
      isArbBet: Boolean(arb_bet.arbitrage_type),
      betId: data.id,
      betValues,
      amountsToStake,
      profitsAbsolute
    }));

    const userSub = localStorage.getItem('userSub');
    await dispatch(fetchUserTransactions(userSub));

    setEditMode(false);
  }

  const calclulate_arb_equity = (results, data) => {
    let equity = 0;

    results.forEach((r, idx) => {
      if (r.includes("Refunded")) {
        equity += Number(data.amountToStake[idx]);
      } else if (r.includes("Half Won")) {
        let coef = Number(data.profitsAbsolute[idx]) / Number(data.amountToStake[idx]);
        equity += coef * (Number(data.amountToStake[idx]) / 2) + 2 * (Number(data.amountToStake[idx]) / 2);
      } else if (r.includes("Half Lost")) {
        equity += (Number(data.amountToStake[idx]) / 2);
      } else if (r.includes("Won")) {
        equity += Number(data.totalStake) + Number(data.profitsAbsolute[idx]);
      } else if (r.includes("Lost")) {
        //equity -= Number(data.amountToStake[idx]);
      }
    });

    return equity;
  }

  const handleUpdateUserBetStatus = async (status, index) => {
    if (Boolean(arb_bet.arbitrage_type)) {
      let results;
      if (data.status === "Pending") {
        const total_legs = arb_bet.bets.length;
        results = Array(total_legs).fill('').map((_, idx) => `Pending on Leg ${idx + 1}`);
      } else {
        results = data.status.split('/');
      }
    
      let new_status = `${status} on Leg ${index + 1}`;
      results[index] = new_status;
      let new_equity = calclulate_arb_equity(results, data);
      let new_profit = new_equity - data.totalStake;

      // console.log({
      //   betId: data.betId,
      //   new_status: results,
      //   equity: new_equity,
      //   profit: new_profit,
      //   status: status,
      //   data
      // })

      results = results.join('/');

      await dispatch(updateUserBetStatus({
        betId: data.betId,
        new_status: results,
        equity: new_equity,
        profit: new_profit
      }));
    } else {
      let equity = 0;
      let profit = 0;

      if (status === "Half Won") {
        let coef = Number(data.profitsAbsolute[0]) / Number(data.amountToStake[0]);
        equity = coef * (Number(data.totalStake) / 2) + 2 * (Number(data.totalStake) / 2);
        //profit = coef * (Number(data.totalStake) / 2);
      } else if (status === "Half Lost") {
        equity = (Number(data.totalStake) / 2);
        //profit = -1 * (Number(data.totalStake) / 2);
      } else if (status === "Won") {
        equity = Number(data.totalStake) + Number(data.profitsAbsolute[0]);
        //profit = Number(data.profitsAbsolute[0]);
      } else if (status === "Lost") {
        equity = 0;
        //profit = -1 * Number(data.totalStake);
      } else if (status === "Refunded") {
        equity = Number(data.totalStake);
        //profit = 0;
      }

      profit = equity - data.totalStake;

      await dispatch(updateUserBetStatus({
        betId: data.betId,
        new_status: status,
        equity: equity,
        profit: profit
      }));

      // console.log({
      //   betId: data.betId,
      //   new_status: status,
      //   equity: equity,
      //   profit: profit,
      //   data
      // })
    }

    const userSub = localStorage.getItem('userSub');
    await dispatch(fetchUserTransactions(userSub));
    await dispatch(fetchUserBets(userSub));
  }

  return (
    <div className={style.bet_item_container}>
      {
        arb_bet.arbitrage_type ?
        arb_bet.bets.map((bet, index) => (
        <Grid container item xs={12} key={index} className={`${isHighlighted === true ? 'blink' : null} ${style.bet_item}`} >
          <Grid item xs={2} className={`${classes.item} ${style.bookmaker_name}`}>
            <img src={getImage(bet.bookmaker)}  />
            <Typography>
              {bet.bookmaker} <span className={style.leg_name}>{`(Leg ${index + 1})`}</span>
            </Typography>
            { 
              (data.status === "Pending" || data.status.split('/')[index].includes("Pending")) && !isScreenshotMode && isStarted ? 
              <Dropdown>
                <MenuButton>
                    <img className='arrow' src={require('./img/arrow_green.png')} style={{height: '18px'}} alt="" />
                </MenuButton>

                <Menu slots={{ listbox: Listbox }}>
                    <MenuItem onClick={() => handleUpdateUserBetStatus('Won', index)}>Won</MenuItem>
                    <MenuItem onClick={() => handleUpdateUserBetStatus('Lost', index)}>Lost</MenuItem>
                    <MenuItem onClick={() => handleUpdateUserBetStatus('Refunded', index)}>Refunded</MenuItem>
                    <MenuItem onClick={() => handleUpdateUserBetStatus('Half Won', index)}>Half Won</MenuItem>
                    <MenuItem onClick={() => handleUpdateUserBetStatus('Half Lost', index)}>Half Lost</MenuItem>
                </Menu>
              </Dropdown>
              : null
            }
          </Grid>
          <Grid item xs={5} className={classes.item}>
            <Typography>{arb_bet.game_name}</Typography>
          </Grid>
          <Grid item xs={3} className={classes.item}>
            <Typography>{bet.bet_name.trim()}</Typography>
          </Grid>
          <Grid xs={1} className={`${classes.item} ${isEditMode ? style.bordered_item : ''}`} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {
              isEditMode ? 
                <input 
                  type="text" 
                  className={style.bets_input} 
                  value={oddsFormat === "American" ? betValues[index].price : Number(betValues[index].decimal_price)?.toFixed(2)}
                  onChange={(e) => handleOddsChange(index, Number(e.target.value))}
                ></input>
              : <Typography>{oddsFormat === "American" ? Number(bet.price) : Number(bet.decimal_price)?.toFixed(2)}</Typography>
            }
          </Grid>
          <Grid xs={1} className={`${classes.item} ${isEditMode ? style.bordered_item : ''}`} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {
              isEditMode ? 
                <>$<input type="text" 
                  className={style.bets_input} 
                  value={amountsToStake[index]}
                  onChange={(e) => handleAmountChange(index, Number(e.target.value))}
                ></input></>
              : <Typography>${data.amountToStake[index]}</Typography>
            }
          </Grid>
          <Grid xs={1} className={`${classes.item}`} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography>${profitsAbsolute[index]}</Typography>
          </Grid>
          {
            index === 0 ? <Grid item xs={2} className={style.calculate_item} style={
              {
                padding: '5px 5px 0 5px'
              }
            }>
              <div className={style.Calculate_container}>
                {
                  !arb_bet.custom ? <Typography>{(arb_bet.arbitrage_percentage).toFixed(2)}%</Typography> : null
                }
                
              </div>
              
            </Grid> :
            index === 1 ? <Grid item xs={2} className={`${style.calculate_item} ${style.right_item}`} style={
              {
                padding: '0 5px 5px 5px'
              }
            }>
              <div className={style.Calculate_container}>
                
              </div>

            </Grid> :
            <Grid item xs={2} className={classes.item} style={
              {
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
              }
            }>
            </Grid>
          }
        </Grid>))
      :
      <Grid container item xs={12} className={`${isHighlighted === true ? 'blink' : null} ${style.bet_item}`} >
        <Grid item xs={2} className={`${classes.item} ${style.bookmaker_name}`}>
          <img src={getImage(arb_bet.sports_book_name)}  />
          <Typography>
              {arb_bet.sports_book_name}
          </Typography>
          { 
            data.status === "Pending" && !isScreenshotMode && isStarted ? 
            <Dropdown>
              <MenuButton>
                  <img className='arrow' src={require('./img/arrow_green.png')} style={{height: '18px'}} alt="" />
              </MenuButton>

              <Menu slots={{ listbox: Listbox }}>
                  <MenuItem onClick={() => handleUpdateUserBetStatus('Won')}>Won</MenuItem>
                  <MenuItem onClick={() => handleUpdateUserBetStatus('Lost')}>Lost</MenuItem>
                  <MenuItem onClick={() => handleUpdateUserBetStatus('Refunded')}>Refunded</MenuItem>
                  <MenuItem onClick={() => handleUpdateUserBetStatus('Half Won')}>Half Won</MenuItem>
                  <MenuItem onClick={() => handleUpdateUserBetStatus('Half Lost')}>Half Lost</MenuItem>
              </Menu>
            </Dropdown>
            : null
          }
        </Grid>
        <Grid item xs={5} className={classes.item}>
          <Typography>{`${arb_bet.home_team} vs ${arb_bet.away_team}`}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.item}>
          { !arb_bet.custom ? 
            <Typography>{`${arb_bet.market_name} ${arb_bet.selection} ${arb_bet.selection_line ?? ''} ${arb_bet.selection_points ?? ''}`}</Typography> 
            : <Typography>{`${arb_bet.name}`}</Typography> 
          }
        </Grid>
        <Grid item xs={1} className={`${classes.item} ${isEditMode ? style.bordered_item : ''}`} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {
            isEditMode ? 
              <input 
                type="text" 
                className={style.bets_input} 
                value={oddsFormat === "American" ? betValues.price : Number(betValues.decimal_price)?.toFixed(2)}
                onChange={(e) => handleOddsChangeEV(Number(e.target.value))}
              ></input>
            : <Typography>{oddsFormat === "American" ? arb_bet.price : convertAmericanToEuropean(Number(arb_bet.price))?.toFixed(2)}</Typography>
          }
        </Grid>
        <Grid item xs={1} className={`${classes.item} ${isEditMode ? style.bordered_item : ''}`} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {
            isEditMode ? 
              <>$<input type="text" 
                className={style.bets_input} 
                value={amountsToStake[0]}
                onChange={(e) => handleAmountChangeEV(Number(e.target.value))}
              ></input></>
            : <Typography>${data.amountToStake[0]}</Typography>
          }
        </Grid>
        <Grid item xs={1} className={`${classes.item}`} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Typography>${profitsAbsolute[0]}</Typography>
        </Grid>
        <Grid item xs={2} className={style.calculate_wrapper}>
          <div className={style.calculate_items} style={{ 
            color: colors.green,
          }}>
            <div className={`${style.calculate_ev}`}>
              {
                !arb_bet.custom ? <Typography>{arb_bet.ev.toFixed(2)}%</Typography> : null
              }
              
            </div>
            <div className={style.Calculate_container}>
              {
                !arb_bet.custom ? <Typography><span className={style.white_text}>Winning Prob:</span><br/> {arb_bet.probability}%</Typography> : null
              }
            </div>
            <div className={style.Calculate_container} style={{
              justifyContent: 'flex-end'
            }}>
              {
                !arb_bet.custom ? <Typography>
                  <span className={style.white_text}>Vig:</span> {arb_bet.vig?.toFixed(2)}<br/>
                  <span className={style.white_text}>Width:</span> {!isNaN(arb_bet.width) ? arb_bet.width?.toFixed(0) : 0}<br/>
                </Typography> : null
              }
              
            </div>
          </div>
          
        </Grid>
      </Grid>
      }
      {
        isEditMode && !isScreenshotMode ? <div className={style.save_container}>
          <button 
            variant="contained" 
            className={`${style.cancel_button}`}
            onClick={() => setEditMode(false)}
          >Cancel</button>
          <button 
            variant="contained" 
            className={`${style.save_button}`}
            onClick={() => handleUpdateUserBet()}
          >Save</button>
        </div> : 
        !isScreenshotMode ?
        <div className={style.share_container}>
          <div onClick={handleTakeScreenshot}>
            <ShareLogo style={{ fill: "#91E617", width: '25px', height: '25px' }}></ShareLogo>
          </div>
        </div> : null
      }
    </div>
    
  );
}

const useStyles = makeStyles({
  root: {
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
  },
  header: {
    background: colors.green,
    color: 'white',
  },
  item: {
    padding: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    background: colors.green,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  percentText: {
    fontSize: '20px',
    fontWeight: 'bold',
    paddingLeft: '10px',
    padding: '5px',
    boxSizing: 'border-box',
    
  },
  sport: {
    background: colors.night,
  },
  sportText: {
    fontSize: '20px',
    padding: '5px',
    paddingRight: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  info: {
    background: colors.night,
  },
  infoText: {
    fontSize: '18px',
    padding: '5px',
    paddingRight: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center'
  },
  bets: {
    background: colors.celadon
  },
  delimiter: {
    height: '2px',
    width: '100%',
    backgroundColor: colors.night
  },
  moreButton: {
    cursor: 'pointer',
    width: '100%',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.night,
    color: '#fff',
    borderBottom: `2px outset ${colors.celadon}`,
  }
});

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#99CCF3',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E6',
  700: '#0059B3',
  800: '#004C99',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Listbox = styled('ul')(
  ({ theme }) => `
  font-size: 14px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  min-width: 200px;
  border-radius: 5px; 
  overflow: auto;
  outline: 0px;
  background: #383838;
  border: none;
  color: white;
  box-shadow: none;
  z-index: 1;
  `,
);

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
  list-style: none;
  padding: 12px;
  border-radius: 0;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &.${menuItemClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${menuItemClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[50]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }
  `,
);

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: white;
  cursor: pointer;
  background: transparent;
  border: none;
  font-weight: 600;

  &.Mui-expanded {
      .arrow {
          transform: rotate(180deg);
      }
  }
  `,
);

export default MyBetComponent;