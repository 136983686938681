import style from './style/MainAdminStyles.module.scss';

import { ReactComponent as AccountLogo } from './img/account.svg';
import { ReactComponent as AnalyticsLogo } from './img/statistics.svg';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import UserList from './UserList';
import User from './user/User';
import UserCreation from './user/UserCreation';
import Settings from './user/Settings';
import Analytics from './user/Analytics';

function getFormattedDate() {
    const date = new Date();
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    let year = date.getFullYear().toString().substr(-2); 

    return `${day}/${month}/${year}`;
}

export default function Account() {
    const history = useNavigate();
    const location = useLocation();

    const [pageName, setPageName] = useState();
    const [pageContent, setPageContent] = useState(<UserList />);

    const firstName = useSelector(state => state.user.firstName);
    const lastName = useSelector(state => state.user.lastName);

    useEffect(() => {
        const path = location.pathname;
        switch (path) {
            case '/admin/analytics':
                setPageName(<span><b>Analytics</b> (All Users)<br></br><span style={{fontSize: '14px'}}>as of {getFormattedDate()}</span></span>);
                setPageContent(<Analytics />)
                break;
            case '/admin/settings':
                setPageName('Admin Settings');
                setPageContent(<Settings />)
                break;
            case '/admin/user':
                setPageName('');
                setPageContent(<User />)
                break;
            case '/admin/create-user':
                setPageName('Create New User');
                setPageContent(<UserCreation />)
                break;
            default:
                setPageName('User Management');
                setPageContent(<UserList />);
                break;
        }
    }, [location.pathname]);    

    return(
        <div className={style.account_wrapper}>
            <div className={style.menu_block}>
                <div className={style.admin_name}>
                    {firstName + ' ' + lastName}
                </div>
                <div className={style.menu}>
                    <div>
                        <Link to={"/admin"} className={`${style.item} ${location.pathname === '/admin' ? style.active : ''}`}>
                            {/* <img src={require('./img/personal.svg').default} className={showSavedFilters ? style.active : ''} alt="Personal icon" /> */}
                            {
                                location.pathname === '/admin' ? <img src={require('./img/user_green.png')} style={{height: '25px', paddingRight: '5px'}} alt="" />
                                : <img src={require('./img/user.png')} style={{height: '25px', paddingRight: '5px'}} alt="" />
                            }
                            
                            <span>User Management</span>
                        </Link>
                        <Link to={"/admin/create-user"} className={`${style.item} ${location.pathname === '/admin/create-user' ? style.active : ''}`}>
                            {
                                location.pathname === '/admin/create-user' ? <img src={require('./img/add_user_green.png')} style={{height: '25px', paddingRight: '5px'}} alt="" />
                                : <img src={require('./img/user_add.png')} style={{height: '25px', paddingRight: '5px'}} alt="" />
                            }
                            <span>Create New User</span>
                        </Link>
                        <Link to={"/admin/settings"} className={`${style.item} ${location.pathname === '/admin/settings' ? style.active : ''}`}>
                            {
                                location.pathname === '/admin/settings' ? <img src={require('./img/gear_green.png')} style={{height: '25px', paddingRight: '5px'}} alt="" />
                                : <img src={require('./img/settings.png')} style={{height: '25px', paddingRight: '5px'}} alt="" />
                            }
                            <span>Admin Settings</span>
                        </Link>
                        <Link to={"/admin/analytics"} className={`${style.item} ${location.pathname === '/admin/analytics' ? style.active : ''}`}>
                            {
                                location.pathname === '/admin/analytics' ? <img src={require('./img/statistics_green.png')} style={{height: '25px', paddingRight: '5px'}} alt="" />
                                : <img src={require('./img/analytics.png')} style={{height: '25px', paddingRight: '5px'}} alt="" />
                            }
                            <span>Analytics</span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={style.admin_container}>
                <div className={style.page_name}>
                    <span>{pageName}</span>
                </div>
                {pageContent}
            </div>
        </div>
    )
}
